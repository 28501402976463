import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
// import Chart from "react-google-charts";
import moment from "moment";
import { api } from "../../../services/api";
import _ from "lodash";

// STYLES
import "./BossEmployees.scss";

//ASSETS

// ICONS
import GroupIcon from "@mui/icons-material/Group";
import InputBase from "@mui/material/InputBase";
import { Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import TextFieldMaterial from "@mui/material/TextField";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import AttachFileIcon from "@mui/icons-material/AttachFile";

// Components
import { LoadingCircularSpinner } from "../../../components/LoadingCircularSpinner";
import { EmployeeDetails } from "./components/EmployeeDetails";
import { EmployeesCards } from "../../../components/Common/Employees";

// MODAL

import CustomModal from "../../../shared/Modal";

// MODAL
import CustomWhiteModal from "../../../shared/WhiteModal";

// Actions
import {
  getLoansByemployeeId,
  updateLoanStatusId,
} from "../../../actions/loansAction";
import { updatePayrollAdvanceStatusId } from "../../../actions/payrollAdvanceAction";
import { getBossEmployeeDetail } from "../../../actions/bossAction";
import { setError } from "../../../actions/errorAction";
import { setSuccess } from "../../../actions/successAction";

export class BossEmployees extends Component {
  constructor(props) {
    super(props);

    this.state = {
      general: true,
      showEmployeeDetail: false,
      employeeToggle: [true],
      isSearchingEmployees: false,
      filteredEmployees: [],
      selectedFile: null,
      isLoading: false,
      isFilePicked: false,
      employeeId: 0,
      advanceId: 0,
      loanId: 0,
      accum: 0,
      loansDetailsVisibility: {},
      employeeName: "",
      companyId: null,
      savingType: {
        inmediato: {
          amount: 0,
        },
        mes1: {
          amount: 0,
        },
        mes3: {
          amount: 0,
        },
        mes6: {
          amount: 0,
        },
        mes12: {
          amount: 0,
        },
        mes24: {
          amount: 0,
        },
        mesSeparacion: {
          amount: 0,
        },
      },
      savingToggle: [],
      openModalAddEmployee: false,
      // APROVE ADVANCE
      openModalAskForAdvance: false,
      // REJECT ADVANCE
      openModalDontAskForAdvance: false,
      // APROVE LOAN
      openModalAskForLoan: false,
      // REJECT LOAN
      openModalDontAskForLoan: false,
    };
    //this.selectBar = this.selectBar.bind(this);
    this.renderEmployee = this.renderEmployee.bind(this);
    this.getSelectedOption = this.getSelectedOption.bind(this);
    this.changeToSelectedOption = this.changeToSelectedOption.bind(this);
    this.getTotalAmount = this.getTotalAmount.bind(this);
    this.getStateToggle2 = this.getStateToggle2.bind(this);

    // ADD EMPLOYEE
    this.addEmployee = this.addEmployee.bind(this);
    this.openModalAddEmployee = this.openModalAddEmployee.bind(this);
    this.toggleModalAddEmployee = this.toggleModalAddEmployee.bind(this);

    this.setAdvanceData = this.setAdvanceData.bind(this);
    // APROVE ADVANCE
    this.openModalAskForAdvance = this.openModalAskForAdvance.bind(this);
    this.toggleModalAskForAdvance = this.toggleModalAskForAdvance.bind(this);

    // REJECT ADVANCE
    this.openModalDontAskForAdvance =
      this.openModalDontAskForAdvance.bind(this);
    this.toggleModalDontAskForAdvance =
      this.toggleModalDontAskForAdvance.bind(this);

    this.setLoanData = this.setLoanData.bind(this);
    // APROVE LOAN
    this.openModalAskForLoan = this.openModalAskForLoan.bind(this);
    this.toggleModalAskForLoan = this.toggleModalAskForLoan.bind(this);

    // REJECT LOAN
    this.openModalDontAskForLoan = this.openModalDontAskForLoan.bind(this);
    this.toggleModalDontAskForLoan = this.toggleModalDontAskForLoan.bind(this);
  }

  savingTypes = {
    now: {
      name: "now",
      label: "Inmediato",
      color: "#1d3557",
      dotClass: "dot-black",
      borderClass: "form-left-bord-black",
    },
    month_1: {
      name: "month_1",
      label: "A 1 mes",
      color: "#048aa8",
      dotClass: "dot-blue",
      borderClass: "form-left-bord-blue",
    },
    month_3: {
      name: "month_3",
      label: "A 3 meses",
      color: "#6cb6f5",
      dotClass: "dot-softblue",
      borderClass: "form-left-bord-soft-blue",
    },
    month_6: {
      name: "month_6",
      label: "A 6 meses",
      color: "#ef476f",
      dotClass: "dot-red",
      borderClass: "form-left-bord-red",
    },
    month_12: {
      name: "month_12",
      label: "A 12 meses",
      color: "#fa7e58",
      dotClass: "dot-orange",
      borderClass: "form-left-bord-orange",
    },
    month_24: {
      name: "month_24",
      label: "A 24 meses",
      color: "#f5a61d",
      dotClass: "dot-yellow",
      borderClass: "form-left-bord-yellow",
    },
    unlimit: {
      name: "unlimit",
      label: "A la separación",
      color: "#15db93",
      dotClass: "dot-green",
      borderClass: "form-left-bord-green",
    },
  };

  getTotalAmount = () => {
    var total = _.round(
      Object.values(this.props.savings).reduce(
        (prev, current) => prev + current.amount,
        0
      ),
      2
    );

    return total;
  };

  setEmployeeId = (employeeId, companyId) => {
    this.setState({ showEmployeeDetail: !this.state.showEmployeeDetail });
    this.setState({ employeeId });
    this.setState({ companyId });
  };

  getTotalEmployees = (identifier) => {
    var total;
    if (this.props.companies) {
      total = Object.values(this.props.companies)
        .filter((id) => id.companyId === identifier)
        .reduce((prev, current) => prev + current.totalEmployees, 0);
    }
    return total;
  };

  getSelectedOption(i) {
    if (typeof this.state.employeeToggle[i] === "boolean") {
      return this.state.employeeToggle[i];
    } else {
      return false;
    }
  }

  // MODAL ADD EMPLOYEE

  toggleModalAddEmployee() {
    this.setState({
      openModalAddEmployee: !this.state.openModalAddEmployee,
    });
  }

  openModalAddEmployee() {
    this.setState({
      openModalAddEmployee: true,
    });
  }

  addEmployee() {
    this.setState({
      openModalAddEmployee: false,
    });
  }

  modalBodyAddEmployee = () => {
    // Cuerpo del formulario
    if (!this.state.openModalAddEmployee) return null;
    return (
      <div className={"modal-box"}>
        <h2 className={"whiteModalTitle mbbasex4"}>Nuevo empleado</h2>
        <div className={"twoElements"}>
          <button
            className="gray_button fwbold"
            onClick={this.toggleModalAddEmployee}
          >
            &lt; Regresar
          </button>
          <button
            className="white_button_bluecolor fwbold"
            onClick={this.backToCompanies}
          >
            Agregar empleado
          </button>
        </div>
        <p className={"grayText mediumText m-t-basex2 startAlign"}>
          Información personal
        </p>
        <div className={"twocolumns"}>
          <div className={"element"}>
            <p className={"grayText"}>Nombres</p>
            <TextFieldMaterial
              className="inputBase"
              name="name"
              style={{
                marginBottom: "20px",
              }}
              variant="filled"
              value="Hugo Armando"
            />
          </div>
          <div className={"element"}>
            <p className={"grayText"}>Apellidos</p>
            <TextFieldMaterial
              className="inputBase"
              name="name"
              style={{
                marginBottom: "20px",
              }}
              variant="filled"
              value="Peregrina Sosa"
            />
          </div>
        </div>
        <div className={"twocolumns"}>
          <div className={"element"}>
            <p className={"grayText"}>Nómina</p>
            <TextFieldMaterial
              className="inputBase"
              name="name"
              style={{
                marginBottom: "20px",
              }}
              variant="filled"
              value="$10,000"
            />
          </div>
          <div className={"element"}>
            <p className={"grayText"}>Teléfono registrado</p>
            <TextFieldMaterial
              className="inputBase"
              name="name"
              style={{
                marginBottom: "20px",
              }}
              variant="filled"
              value="33 1027 3672"
            />
          </div>
        </div>
        <div className={"twocolumns"}>
          <div className={"element"}>
            <p className={"grayText"}>Correo electrónico registrado</p>
            <TextFieldMaterial
              className="inputBase"
              name="name"
              style={{
                marginBottom: "20px",
              }}
              variant="filled"
              value="hugoaps@gmail.com"
            />
          </div>
          <div className={"element"}>
            <p className={"grayText"}>Número de empleado</p>
            <TextFieldMaterial
              className="inputBase"
              name="name"
              style={{
                marginBottom: "20px",
              }}
              variant="filled"
              value="35"
            />
          </div>
        </div>
        <div className={"twocolumns"}>
          <div className={"element"}>
            <p className={"grayText"}>Fecha de ingreso</p>
            <TextFieldMaterial
              className="inputBase"
              name="name"
              style={{
                marginBottom: "20px",
              }}
              variant="filled"
              value="04 Septiembre 2019"
            />
          </div>
          <div className={"element"}>
            <p className={"grayText"}>Localidad donde labora</p>
            <TextFieldMaterial
              className="inputBase"
              name="name"
              style={{
                marginBottom: "20px",
              }}
              variant="filled"
              value="Zapopan, Jalisco, México"
            />
          </div>
        </div>
        <p className={"grayText mediumText"}>Cuenta de depósito</p>
        <div className={"twocolumns"}>
          <div className={"element"}>
            <p className={"grayText"}>Titular</p>
            <TextFieldMaterial
              className="inputBase"
              name="name"
              style={{
                marginBottom: "20px",
              }}
              variant="filled"
              value="Hugo Armando Peregrina Sosa"
            />
          </div>
          <div className={"element"}>
            <p className={"grayText"}>Número de tarjeta</p>
            <TextFieldMaterial
              className="inputBase"
              name="name"
              style={{
                marginBottom: "20px",
              }}
              variant="filled"
              value="1234-5432-6734-9807"
            />
          </div>
        </div>
        <div className={"twocolumns"}>
          <div className={"element"}>
            <p className={"grayText"}>CLABE</p>
            <TextFieldMaterial
              className="inputBase"
              name="name"
              style={{
                marginBottom: "20px",
              }}
              variant="filled"
              value="1023456789101112134"
            />
          </div>
        </div>
      </div>
    );
  };

  // MODAL DATA
  setAdvanceData = (advance, modal) => {
    this.setState({
      advanceId: advance,
    });
    if (modal === "rejected") {
      return this.toggleModalDontAskForAdvance();
    } else if (modal === "approved") {
      return this.toggleModalAskForAdvance();
    }
  };

  // APROVE ADVANCE

  toggleModalAskForAdvance() {
    this.setState({
      openModalAskForAdvance: !this.state.openModalAskForAdvance,
    });
  }

  openModalAskForAdvance() {
    this.setState({
      openModalAskForAdvance: true,
    });
  }

  modalBodyAskForAdvance = () => {
    // Cuerpo del formulario
    if (!this.state.openModalAskForAdvance) return null;
    return (
      <div className={"modal-box modal-box-images"}>
        <p className={"modalTitle"}>¿Aprobar adelanto?</p>
        <div className={"grayLine"}></div>
        <div className={"dataElement"}>
          <p className={"grayText"}>Nombre completo</p>
          <p className={"blackText"}>
            {this.props.employee.firstName + " " + this.props.employee.lastName}
          </p>
        </div>
        {this.props.payroll.advances
          .filter((advance) => advance.id === this.state.advanceId)
          .map((item) => (
            <div key={item.id} className={"dataElement mt-basex2"}>
              <p className={"grayText"}>Monto del adelanto</p>
              <p className={"blackText text32"}>
                <span className={"smallText"}>$</span> {item.amount}
              </p>
            </div>
          ))}
        <div className={"box-button"}>
          <button
            className="gray_button"
            onClick={this.toggleModalAskForAdvance}
          >
            REGRESAR
          </button>
          <button
            className={"green-button green-text"}
            onClick={() => this.advanceStatusApproved(this.state.advanceId)}
          >
            APROBAR
          </button>
        </div>
      </div>
    );
  };

  advanceStatusApproved = (id) => {
    const advanceStatusId = 2;
    this.props.dispatch(updatePayrollAdvanceStatusId(id, advanceStatusId));
    setTimeout(() => {
      this.setState({
        openModalAskForAdvance: !this.state.openModalAskForAdvance,
      });
    }, 2000);
  };

  // REJECT ADVANCE

  toggleModalDontAskForAdvance() {
    this.setState({
      openModalDontAskForAdvance: !this.state.openModalDontAskForAdvance,
    });
  }

  openModalDontAskForAdvance() {
    this.setState({
      openModalDontAskForAdvance: true,
    });
  }

  modalBodyDontAskForAdvance = () => {
    // Cuerpo del formulario
    if (!this.state.openModalDontAskForAdvance) return null;
    return (
      <div className={"modal-box modal-box-images"}>
        <p className={"modalTitle"}>¿Rechazar adelanto?</p>
        <div className={"grayLine"}></div>
        <div className={"dataElement"}>
          <p className={"grayText"}>Nombre completo</p>
          <p className={"blackText"}>
            {this.props.employee.firstName + " " + this.props.employee.lastName}
          </p>
        </div>
        {this.props.payroll.advances
          .filter((advance) => advance.id === this.state.advanceId)
          .map((item) => (
            <div key={item.id} className={"dataElement mt-basex2"}>
              <p className={"grayText"}>Monto del adelanto</p>
              <p className={"blackText text32"}>
                <span className={"smallText"}>$</span> {item.amount}
              </p>
            </div>
          ))}
        <div className={"box-button"}>
          <button
            className="gray_button"
            onClick={this.toggleModalDontAskForAdvance}
          >
            REGRESAR
          </button>
          <button
            className={"green-button redText"}
            onClick={() => this.advanceStatusRejected(this.state.advanceId)}
          >
            RECHAZAR
          </button>
        </div>
      </div>
    );
  };

  advanceStatusRejected = (id) => {
    const advanceStatusId = 3;
    this.props.dispatch(updatePayrollAdvanceStatusId(id, advanceStatusId));
    setTimeout(() => {
      this.setState({
        openModalDontAskForAdvance: !this.state.openModalDontAskForAdvance,
      });
    }, 2000);
  };

  // MODAL DATA
  setLoanData = (loan, modal) => {
    this.setState({
      loanId: loan,
    });
    if (modal === "rejected") {
      return this.toggleModalDontAskForLoan();
    } else if (modal === "approved") {
      return this.toggleModalAskForLoan();
    }
  };

  // APROVE LOAN

  toggleModalAskForLoan() {
    this.setState({
      openModalAskForLoan: !this.state.openModalAskForLoan,
    });
  }

  openModalAskForLoan() {
    this.setState({
      openModalAskForLoan: true,
    });
  }

  modalBodyAskForLoan = () => {
    // Cuerpo del formulario
    if (!this.state.openModalAskForLoan) return null;
    return (
      <div className={"modal-box modal-box-images"}>
        <p className={"modalTitle"}>¿Aprobar préstamo?</p>
        <div className={"grayLine"}></div>
        <div className={"dataElement"}>
          <p className={"grayText"}>Nombre completo</p>
          <p className={"blackText"}>
            {this.props.employee.firstName + " " + this.props.employee.lastName}
          </p>
        </div>
        {this.props.loans.loans
          .filter((loan) => loan.id === this.state.loanId)
          .map((item) => (
            <div key={item.id} className={"dataElement mt-basex2"}>
              <p className={"grayText"}>Monto del adelanto</p>
              <p className={"blackText text32"}>
                <span className={"smallText"}>$</span> {item.amount}
              </p>
            </div>
          ))}
        <div className={"box-button"}>
          <button className="gray_button" onClick={this.toggleModalAskForLoan}>
            REGRESAR
          </button>
          <button
            className={"green-button green-text"}
            onClick={() => this.loanStatusApproved(this.state.loanId)}
          >
            APROBAR
          </button>
        </div>
      </div>
    );
  };

  loanStatusApproved = (id) => {
    const loanStatusId = 2;
    this.props.dispatch(updateLoanStatusId(id, loanStatusId));
    setTimeout(() => {
      this.props.dispatch(
        getLoansByemployeeId({
          employeeId: this.props.employee.userId,
          companyId: this.props.employee.companyId,
        })
      );
      this.setState({
        openModalAskForLoan: !this.state.openModalAskForLoan,
      });
    }, 2000);
  };

  // REJECT LOAN

  toggleModalDontAskForLoan() {
    this.setState({
      openModalDontAskForLoan: !this.state.openModalDontAskForLoan,
    });
  }

  openModalDontAskForLoan() {
    this.setState({
      openModalDontAskForLoan: true,
    });
  }

  modalBodyDontAskForLoan = () => {
    // Cuerpo del formulario
    if (!this.state.openModalDontAskForLoan) return null;
    return (
      <div className={"modal-box modal-box-images"}>
        <p className={"modalTitle"}>¿Rechazar préstamo?</p>
        <div className={"grayLine"}></div>
        <div className={"dataElement"}>
          <p className={"grayText"}>Nombre completo</p>
          <p className={"blackText"}>
            {this.props.employee.firstName + " " + this.props.employee.lastName}
          </p>
        </div>
        {this.props.loans.loans
          .filter((loan) => loan.id === this.state.loanId)
          .map((item) => (
            <div key={item.id} className={"dataElement mt-basex2"}>
              <p className={"grayText"}>Monto del adelanto</p>
              <p className={"blackText text32"}>
                <span className={"smallText"}>$</span> {item.amount}
              </p>
            </div>
          ))}
        <div className={"box-button"}>
          <button
            className="gray_button"
            onClick={this.toggleModalDontAskForLoan}
          >
            REGRESAR
          </button>
          <button
            className={"green-button redText"}
            onClick={() => this.loanStatusRejected(this.state.loanId)}
          >
            RECHAZAR
          </button>
        </div>
      </div>
    );
  };

  loanStatusRejected = (id) => {
    const loanStatusId = 3;
    this.props.dispatch(updateLoanStatusId(id, loanStatusId));
    setTimeout(() => {
      this.props.dispatch(
        getLoansByemployeeId({
          employeeId: this.props.employee.userId,
          companyId: this.props.employee.companyId,
        })
      );
      this.setState({
        openModalDontAskForLoan: !this.state.openModalDontAskForLoan,
      });
    }, 2000);
  };

  employeeStatusLabel = (status) => {
    if (status === "Activo") {
      return `Activo`;
    } else if (status === "Desactivo") {
      return `Inactivo`;
    }
  };

  changeToSelectedOption(i) {
    const mod = this.state.employeeToggle.slice();
    mod[0] = false;
    mod[1] = false;
    mod[2] = false;
    this.setState({ employeeToggle: mod });
    if (mod[i] === undefined) {
      mod[i] = true;
    } else {
      mod[i] = !mod[i];
    }
    this.setState({ employeeToggle: mod });
  }

  getStateToggle2(i) {
    if (typeof this.state.savingToggle[i] === "boolean") {
      return this.state.savingToggle[i];
    } else {
      return false;
    }
  }

  setStateToggle2(i) {
    const mod = this.state.savingToggle.slice();
    if (mod[i] === undefined) {
      mod[i] = true;
    } else {
      mod[i] = !mod[i];
    }
    this.setState({ savingToggle: mod });
  }

  renderEmployee(employee) {
    return (
      <div
        className={[
          "tabHeader employee-row",
          employee.status === "Desactivo" ? "disabled-employee" : "",
        ].join(" ")}
        onClick={() => this.setEmployeeId(employee.employeeNumber)}
      >
        <div className={"tabItem"}>
          <p className={"blackText"}>
            {employee.employeeName} {employee.employeeLastName}
          </p>
        </div>
        <div className={"tabItem"}>
          <p className={"blackText"}> {employee.payroll}</p>
        </div>
        <div className={"tabItem"}>
          <p className={"blackText"}> {employee.savingPerMonth}</p>
        </div>
        <div className={"tabItem"}>
          <span
            className={
              employee.status === "Activo" ? "green-active" : "gray-active"
            }
          >
            {this.employeeStatusLabel(employee.status)}
          </span>
        </div>
        <div className={"tabItem"}>
          <p className={"blackText"}>
            <ArrowForwardIosIcon />
          </p>
        </div>
      </div>
    );
  }

  getTotalSavingAmount = (savingId) => {
    const total = _.round(
      this.props.savingHasTransactions.transactions
        .filter((saving) => saving.savingId === savingId)
        .reduce((prev, current) => prev + current.savingTotal, 0),
      2
    );
    return total;
  };

  getSavingYield = (savingId) => {
    const total = _.round(
      this.props.savingHasTransactions.transactions
        .filter((saving) => saving.savingId === savingId)
        .reduce((prev, current) => prev + current.totalYield, 0),
      2
    );
    return total;
  };

  getNextContributionDate = () => {
    var date = new Date();
    const today = date.getDate();

    switch (this.props.employee.payrollType) {
      case 3:
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime();

      case 2:
        if (today < 15) {
          return new Date(date.getFullYear(), date.getMonth(), 15).getTime();
        } else if (today > 15) {
          return new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime();
        }
        break;
      case 1:
        var dayOfWeek = 5; //friday
        var todayDate = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        );
        todayDate.setDate(
          todayDate.getDate() + ((dayOfWeek + 7 - todayDate.getDay()) % 7)
        );
        return todayDate;
      default:
        return "";
    }
  };

  getFinishDate = (timestamp, type) => {
    var date = new Date(timestamp);
    var months = 0;
    var finalDate = new Date();
    switch (type) {
      case "unlimited":
        return `No disponible`;
      case "month_24":
        months = 24;
        finalDate = new Date(
          date.getFullYear(),
          date.getMonth() + months,
          0
        ).getTime();
        return moment.unix(finalDate / 1000).format("DD/MM/YYYY");
      case "month_12":
        months = 12;
        finalDate = new Date(
          date.getFullYear(),
          date.getMonth() + months,
          0
        ).getTime();
        return moment.unix(finalDate / 1000).format("DD/MM/YYYY");
      case "month_6":
        months = 6;
        finalDate = new Date(
          date.getFullYear(),
          date.getMonth() + months,
          0
        ).getTime();
        return moment.unix(finalDate / 1000).format("DD/MM/YYYY");

      case "month_3":
        months = 3;
        finalDate = new Date(
          date.getFullYear(),
          date.getMonth() + months,
          0
        ).getTime();
        return moment.unix(finalDate / 1000).format("DD/MM/YYYY");

      case "month_1":
        months = 1;
        finalDate = new Date(
          date.getFullYear(),
          date.getMonth() + months,
          0
        ).getTime();
        return moment.unix(finalDate / 1000).format("DD/MM/YYYY");
      case "now":
        return `No disponible`;
      default:
        break;
    }

    return _.round(finalDate, 2);
  };

  getSavingPeriods = (savingId) => {
    const total = this.props.savingHasTransactions.transactions.filter(
      (saving) => saving.savingId === savingId
    ).length;
    return total;
  };

  getAccumulatedBalance = (amount, index) => {
    if (index === 0) {
      this.setState({ accum: amount });
      return _.round(this.state.accum, 2);
    } else {
      var accumulated = this.state.accum;
      this.setState({ accum: (accumulated += amount) });
      return _.round(this.state.accum, 2);
    }
  };

  renderSavingDetail(item, index) {
    const leftColor = [
      "form-left-bord-black",
      "form-left-bord-blue",
      "form-left-bord-soft-blue",
      "form-left-bord-red",
      "form-left-bord-orange",
      "form-left-bord-yellow",
      "form-left-bord-green",
    ];

    const translateSavingType = {
      now: "Inmediato",
      month_1: "A 1 mes",
      month_3: "A 3 meses",
      month_6: "A 6 meses",
      month_12: "A 12 meses",
      month_24: "A 24 meses",
      unlimit: "A la separación",
    };

    return (
      <div className={`form-left ${leftColor[index]}`}>
        <div className={"headerTabPrint"}>
          <div className={"headerTabPrintItem headerTabPrintBigItem"}>
            <p className={"headerTabPrintItemSmallTitle"}>Tipo de ahorro</p>
            <p className={"headerTabPrintItemBigTitle"}>
              {translateSavingType[item.type]}
            </p>
          </div>
          <div className={"headerTabPrintItem"}>
            <p className={"headerTabPrintItemSmallTitle"}>Tasa</p>
            <p className={"headerTabPrintItemBigTitle"}>
              {item.yieldRate + this.props.banxico.rate}{" "}
              <span className={"smallText"}>%</span>
            </p>
          </div>
          <div className={"headerTabPrintItem headerTabPrintBigItem"}>
            <p className={"headerTabPrintItemSmallTitle"}>
              Capital de aportación
            </p>
            <p className={"headerTabPrintItemBigTitle"}>
              <span>$</span> {item.amount}
            </p>
          </div>
          <div className={"headerTabPrintItem"}>
            <p className={"headerTabPrintItemSmallTitle"}>Saldo total</p>
            <p className={"headerTabPrintItemBigTitle"}>
              <span>$</span> {this.getTotalSavingAmount(item.id)}
            </p>
          </div>
        </div>
        <div className={"headerTabPrint"}>
          <div className={"headerTabPrintItem headerItemTopTab"}>
            <p className={"headerTabPrintItemSmallTitle"}>Fecha de apertura</p>
            <p className={"headerTabPrintItemBigTitle smallText"}>
              {moment.unix(item.createdAt / 1000).format("DD/MM/YYYY")}
            </p>
          </div>
          <div className={"headerTabPrintItem headerItemTopTab"}>
            <p className={"headerTabPrintItemSmallTitle"}>
              Fecha de finalización
            </p>
            <p className={"headerTabPrintItemBigTitle smallText"}>
              {this.getFinishDate(item.createdAt, item.type)}
            </p>
          </div>
          <div className={"headerTabPrintItem headerItemTopTab"}>
            <p className={"headerTabPrintItemSmallTitle"}>Próxima aportación</p>
            <p className={"headerTabPrintItemBigTitle smallText"}>
              {this.props.employee &&
                moment
                  .unix(this.getNextContributionDate() / 1000)
                  .format("DD/MM/YYYY")}
            </p>
          </div>
          <div className={"headerTabPrintItem headerItemTopTab"}>
            <p className={"headerTabPrintItemSmallTitle"}>
              Ganancias por interés
            </p>
            <p className={"headerTabPrintItemBigTitle smallText"}>
              {this.getSavingYield(item.id)}
            </p>
          </div>
        </div>
        <div className={"twoColumnsInfo"}>
          <div className={"firstElementInfo"}>
            <p className={"grayText"}>Plazos transcurridos</p>
            <p className={"smallBlackLetter"}>
              {this.getSavingPeriods(item.id)}
            </p>
          </div>
          <div
            className={"showMoreInfoSaving"}
            onClick={() => this.setStateToggle2(index)}
          >
            {this.getStateToggle2(index) && <p>Ocultar desgloce</p>}
            {!this.getStateToggle2(index) && <p>Mostrar desgloce</p>}
          </div>
        </div>
        {this.getStateToggle2(index) && (
          <div className={"bodyTabPrint"}>
            <div className={"loadHistoryPayments"}>
              <div className={"loadHistoryPaymentsHeader"}>
                Desgloce de aportaciones
              </div>
              <div className={"loadHistoryPaymentsTitles"}>
                <div className={"loadHistoryPaymentsTitle"}>Periodo</div>
                <div className={"loadHistoryPaymentsTitle"}>Fecha</div>
                <div className={"loadHistoryPaymentsTitle"}>Pago</div>
                <div className={"loadHistoryPaymentsTitle"}>Tasa</div>
                <div className={"loadHistoryPaymentsTitle"}>Saldo</div>
                <div className={"loadHistoryPaymentsTitle"}>Saldo Total</div>
              </div>
              {this.props.savingHasTransactions &&
                this.props.savingHasTransactions.transactions
                  .filter((transaction) => transaction.savingId === item.id)
                  .map((transaction, index) => (
                    <div
                      className={"loadHistoryPaymentsBody"}
                      key={`contribution-${item.type}-${index}`}
                    >
                      <div className={"loadHistoryPaymentsItem"}>
                        {index + 1}
                      </div>
                      <div className={"loadHistoryPaymentsItem"}>
                        {moment
                          .unix(transaction.createdAt / 1000)
                          .format("DD/MM/YYYY")}
                      </div>
                      <div className={"loadHistoryPaymentsItem"}>
                        <span>$</span>
                        {transaction.amount}
                      </div>
                      <div className={"loadHistoryPaymentsItem greenText"}>
                        {transaction.rate}
                        <span>%</span>
                      </div>
                      <div className={"loadHistoryPaymentsItem"}>
                        <span>$</span>
                        {transaction.savingTotal}
                      </div>
                      <div className={"loadHistoryPaymentsItem"}>
                        <span>$</span>
                        {
                          /*item.accumulated*/ this.getAccumulatedBalance(
                            transaction.savingTotal,
                            index
                          )
                        }
                      </div>
                    </div>
                  ))}
            </div>
          </div>
        )}
      </div>
    );
  }

  //// SAVINGS
  getTotalAccumulatedBalance = () => {
    const total = this.props.savingHasTransactions.transactions.reduce(
      (prev, current) => prev + current.savingTotal,
      0
    );

    return _.round(total, 2);
  };

  getLastContributionDate = () => {
    var date = new Date();
    switch (this.props.employee.payrollType) {
      case 3:
        var lastContribution = new Date(
          date.getFullYear(),
          date.getMonth(),
          0
        ).getTime();
        return moment.unix(lastContribution / 1000).format("DD/MM/YYYY");

      case 2:
        var today = date.getDate();
        if (today < 15) {
          lastContribution = new Date(
            date.getFullYear(),
            date.getMonth(),
            0
          ).getTime();
        } else if (today > 15) {
          lastContribution = new Date(
            date.getFullYear(),
            date.getMonth(),
            15
          ).getTime();
        }
        return moment.unix(lastContribution / 1000).format("DD/MM/YYYY");
      case 1:
        var dayOfWeek = 5; //friday
        lastContribution = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        );
        lastContribution.setDate(
          lastContribution.getDate() +
            ((dayOfWeek - 7 - lastContribution.getDay()) % 7)
        );
        return moment.unix(lastContribution / 1000).format("DD/MM/YYYY");
      default:
        return "";
    }
  };

  getTotalSavingsAmount = () => {
    const total = this.props.savings.reduce(
      (prev, current) => prev + current.amount,
      0
    );

    return _.round(total, 2);
  };

  getPayrollWithoutSavings = () => {
    const payroll = parseFloat(this.props.employee.payroll);
    const totalMonthlyProfit = this.getTotalSavingsAmount();

    return _.round(payroll - totalMonthlyProfit, 2);
  };

  getPercentOfPayroll = (savingAmount) => {
    const totalAmount = parseFloat(this.props.employee.payroll);
    if (totalAmount === 0) return 0;
    return _.round((savingAmount * 100) / totalAmount, 2);
  };

  getTotalSpreads = () => {
    const total = this.props.savings.filter(
      (saving) => saving.status === "active"
    ).length;
    return total;
  };

  getTotalYield = () => {
    const total = this.props.savingHasTransactions.transactions.reduce(
      (prev, current) => prev + current.totalYield,
      0
    );
    return total;
  };

  //// PAYROLL ADVANCE
  advanceToBeApproved = () => {
    var total = this.props.payroll.advances.filter(
      (status) => status.status === 1
    ).length;
    return total;
  };

  advanceApproved = () => {
    var total = this.props.payroll.advances.filter(
      (status) => status.status === 2
    ).length;
    return total;
  };

  advanceRejected = () => {
    var total = this.props.payroll.advances.filter(
      (status) => status.status === 3
    ).length;
    return total;
  };

  advanceTotalAmount = () => {
    var total = this.props.payroll.advances
      .filter((status) => status.status !== 3)
      .reduce((prev, current) => prev + current.amount, 0);
    return total;
  };

  advanceStatusChange = (status) => {
    switch (status) {
      case 1:
        return `ad_request`;

      case 2:
        return `paidState`;

      case 3:
        return `rejectedState`;
      default:
        return "";
    }
  };

  advanceStatusLabel = (status) => {
    switch (status) {
      case 1:
        return `Pendiente`;

      case 2:
        return `Pagado`;

      case 3:
        return `Rechazado`;
      default:
        return "";
    }
  };

  //// LOANS
  loanToBeApproved = () => {
    var total = this.props.loans.loans.filter(
      (status) => status.loanStatusId === 1
    ).length;
    return total;
  };

  loanApproved = () => {
    var total = this.props.loans.loans.filter(
      (status) => status.loanStatusId === 2
    ).length;
    return total;
  };

  loanPaid = () => {
    var total = this.props.loans.loans.filter(
      (status) => status.loanStatusId === 4
    ).length;
    return total;
  };

  loanTotalAmount = () => {
    var total = this.props.loans.loans
      .filter((status) => status.loanStatusId === 2)
      .reduce((prev, current) => prev + current.amount, 0);
    return total;
  };

  loanTotalOutstandingBalance = () => {
    var totalDebt = this.props.loans.loans.reduce(
      (prev, current) => prev + current.totalDebt,
      0
    );
    var paid = this.props.loanHasTransactions.transactions
      .filter((loan) => loan.paid === true)
      .reduce((prev, current) => prev + current.paymentPerPeriod, 0);

    totalDebt = totalDebt - paid;
    if (totalDebt < 0) {
      totalDebt = 0;
    }
    return totalDebt;
  };

  loanOutstandingBalance = (loanId) => {
    var totalDebt = this.props.loans.loans
      .filter((loan) => loan.id === loanId)
      .reduce((prev, current) => prev + current.totalDebt, 0);
    var payment = this.props.loanHasTransactions.transactions
      .filter((loan) => loan.loanId === loanId)
      .reduce((prev, current) => prev + current.paymentPerPeriod, 0);
    var paid = this.props.loanHasTransactions.transactions
      .filter((loan) => loan.loanId === loanId && loan.paid === true)
      .reduce((prev, current) => prev + current.paymentPerPeriod, 0);
    var totalTransactions = this.props.loanHasTransactions.transactions.filter(
      (loan) => loan.loanId === loanId && loan.paid === true
    ).length;
    var total = 0;
    if (totalTransactions > 0) {
      total = paid + payment;
      totalDebt = totalDebt - total;
      if (totalDebt < 0) {
        totalDebt = 0;
      }
    }
    return totalDebt;
  };

  loanTotalPayments = (loanId) => {
    var total = this.props.loanHasTransactions.transactions.filter(
      (loan) => loan.loanId === loanId && loan.paid === true
    ).length;
    return total;
  };

  toggleLoanDetailVisibility = (loanId) => {
    const currentVisibility = !!this.state.loansDetailsVisibility[loanId];
    this.setState({
      loansDetailsVisibility: {
        ...this.state.loansDetailsVisibility,
        [loanId]: !currentVisibility,
      },
    });
  };

  getLoanDetailVisibility = (loanId) => {
    return !!this.state.loansDetailsVisibility[loanId];
  };
  componentDidMount = () => {
    const { user, dispatch } = this.props;
    if (user) {
      dispatch(getBossEmployeeDetail({ companyId: user.companyId }));
    }
  };

  componentDidUpdate = (prevProps) => {
    if (this.props.user !== prevProps.user) {
      this.props.dispatch(
        getBossEmployeeDetail({ companyId: this.props.employee.companyId })
      );
    }
  };

  render() {
    const { employeeDetail } = this.props;
    const { selectedFile, isLoading, filteredEmployees } = this.state;

    if (!employeeDetail) {
      return null;
    }

    const handleInputChange = (evt) => {
      const file = evt.target.files[0];
      this.setState({ selectedFile: file });
    };

    const handleSubmitFile = async () => {
      try {
        this.setState({ isLoading: true });
        const formData = new FormData();
        formData.append("file", selectedFile);

        const options = {
          data: formData,
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        const response = await api.post(
          "Salvum/services/layouts/confirmationLayout",
          options
        );
        if (response.code > 300 || response.code > 202 || response.error) {
          this.props.dispatch(
            setError(
              response.message || "Ha ocurrido un error al subir el archivo."
            )
          );
          this.setState({
            isLoading: false,
          });
          return;
        }

        this.props.dispatch(
          setSuccess(response.message || "Archivo subido con éxito.")
        );
        this.setState({
          selectedFile: null,
          isLoading: false,
        });
      } catch (error) {
        this.props.dispatch(
          setError(
            "Ha ocurrido un error interno en nuestro servidor al subir el archivo."
          )
        );
        this.setState({ isLoading: false });
      }
    };

    const filterEmployees = (evt) => {
      const { isSearchingEmployees } = this.state;
      const searchTerm = evt.currentTarget.value;
      if (searchTerm === "") {
        this.setState({ isSearchingEmployees: false });
        return;
      }

      // search by name concatenating both name and last name for the search term.
      const filtered = employeeDetail.employees.filter((employee) =>
        employee.employeeName
          .concat(" ", employee.employeeLastName)
          .toLowerCase()
          .includes(searchTerm)
      );
      this.setState({ filteredEmployees: filtered });

      if (!isSearchingEmployees) {
        this.setState({ isSearchingEmployees: true });
      }
    };

    const employeesBase = this.state.isSearchingEmployees
      ? filteredEmployees
      : employeeDetail.employees;

    return (
      <>
        <LoadingCircularSpinner show={isLoading} />
        <CustomWhiteModal
          isOpen={this.state.openModalAddEmployee}
          toggle={this.toggleModalAddEmployee}
          title="Nuevo empleado"
          subTitle="Lorem ipsum .................."
          body={this.modalBodyAddEmployee()}
        />
        <CustomModal
          isOpen={this.state.openModalAskForAdvance}
          toggle={this.toggleModalAskForAdvance}
          isGreen={true}
          body={this.modalBodyAskForAdvance()}
        />
        <CustomModal
          isOpen={this.state.openModalDontAskForAdvance}
          toggle={this.toggleModalDontAskForAdvance}
          isRed={true}
          body={this.modalBodyDontAskForAdvance()}
        />
        <CustomModal
          isOpen={this.state.openModalAskForLoan}
          toggle={this.toggleModalAskForLoan}
          isGreen={true}
          body={this.modalBodyAskForLoan()}
        />
        <CustomModal
          isOpen={this.state.openModalDontAskForLoan}
          toggle={this.toggleModalDontAskForLoan}
          isRed={true}
          body={this.modalBodyDontAskForLoan()}
        />
        <div className={"bossEmployees"}>
          <div className={"companiesHeroContent"}>
            <div className={"companiesContainer"}>
              <ul className={"breadcrumbs"}>
                <li>Inicio</li>
                <li>Empleados</li>
              </ul>
              <div className={"companiesHero"}>
                <div className={"content"}>
                  <span className={"contentTitle"}>Empleados</span>
                </div>
              </div>
            </div>
          </div>
          <div className={"companiesContent"}>
            <div className={"companiesContentData"}>
              <div className={"principalContentCompany"}>
                <div className={"savingsContent"}>
                  <div className={"tabHeader"}>
                    {!this.state.showEmployeeDetail && (
                      <>
                        <h2 className={"savings_title"}>
                          Listado de empleados
                        </h2>
                        <div className={"icon-container"}>
                          <GroupIcon className={"icon-blue"} />

                          <div className={"grayLine"}></div>
                        </div>
                      </>
                    )}
                  </div>

                  {!this.state.showEmployeeDetail && employeeDetail && (
                    <div className={"savings-content-inside"}>
                      <div className={"tabHeader"}>
                        <div className={"tabItem"}>
                          <p className={"grayText"}>Ahorrando/Registrados</p>
                          <p className={"blackText"}>
                            {employeeDetail.totalNumOfEmployeesSaving}
                            <span className={"smallText"}>
                              /{employeeDetail.totalNumOfEmployees}
                            </span>
                          </p>
                        </div>

                        {/* 
                                            <div className={"infoContent"}>
                                            <button className="white_button">Archivo de confirmación</button>

                                            
                                        </div> */}
                        <div className="tabItem-v2">
                          <p className={"grayText"}>Archivo de confirmación</p>
                          <label id="file-csv" htmlFor="upload-photo">
                            <AttachFileIcon />
                          </label>
                          <input
                            type="file"
                            onChange={handleInputChange}
                            name="photo"
                            id="upload-photo"
                            accept=".xls,.xlsx,.csv"
                          />
                          {selectedFile && (
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div>
                                <label className="grayText">
                                  {selectedFile.name}
                                </label>
                              </div>
                              <Button
                                style={{ maxWidth: "270px" }}
                                color="primary"
                                variant="contained"
                                onClick={handleSubmitFile}
                              >
                                Enviar
                              </Button>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className={"tabHeader"}>
                        <div className={"searchCompany"}>
                          <div className={"searchIconCompany"}>
                            <SearchIcon />
                          </div>
                          <InputBase
                            placeholder="Buscar empleado"
                            classes={{
                              root: "inputRoot",
                              input: "inputInput",
                            }}
                            inputProps={{ "aria-label": "search" }}
                            onChange={filterEmployees}
                          />
                        </div>
                      </div>
                      <div className={"tabHeader"}>
                        <div className={"tabItem"}>
                          <p className={"grayText"}>Nombre ↑</p>
                        </div>
                        <div className={"tabItem"}>
                          <p className={"grayText"}>Nómina ↑</p>
                        </div>
                        <div className={"tabItem"}>
                          <p className={"grayText"}>Ahorro por mes ↑</p>
                        </div>
                        <div className={"tabItem"}>
                          <p className={"grayText"}>Status ↑</p>
                        </div>
                        <div className={"tabItem"}></div>
                      </div>
                      {!this.state.showEmployeeDetail && (
                        <EmployeesCards
                          setEmployeeId={this.setEmployeeId}
                          employees={employeesBase}
                        />
                      )}
                    </div>
                  )}

                  <EmployeeDetails
                    showEmployeeDetail={this.state.showEmployeeDetail}
                    setEmployeeId={this.setEmployeeId}
                    employeesBase={employeesBase}
                    employeeId={this.state.employeeId}
                    companyId={this.state.companyId}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
BossEmployees.propTypes = {
  savingType: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    ...state,
    savingsResume: state.savings.savingsResume,
    employeeList: state.employee.employeeList,
    banxico: state.savings.banxico,
    user: state.user.user,

    //employee selected
    employee: state.boss,
    company: state.company.current,
    loans: state.loans.loans,
    loanHasTransactions: state.loans.loanTransactions,
    savings: state.savings.savings,
    savingHasTransactions: state.savings.savingTransactions,
    payroll: state.advances.payrollAdvance,

    employeeDetail: state.boss.employeeDetail,
  };
}

export default connect(mapStateToProps)(BossEmployees);
