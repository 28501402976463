import React, { useState } from "react";
// ICONS
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const Companies = ({ loansDetails, setCompanyId }) => {
  const [companies, setCompanies] = useState([]);
  const [isFiltering, setIsFiltering] = useState(false);

  const handleFilter = (event) => {
    const searchTerm = event.currentTarget.value;
    const filteredCompanies = loansDetails.companies.filter((company) =>
      company.companyName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (searchTerm !== "") {
      setIsFiltering(true);
      setCompanies(filteredCompanies);
    } else {
      setIsFiltering(false);
    }
  };

  const formattedCompanies = isFiltering ? companies : loansDetails.companies;
  return (
    <>
      <h3 className="totalComapniesTitle">
        Empresas ({formattedCompanies.length})
      </h3>
      <div className="searchCompany">
        <div className="searchIconCompany">
          <SearchIcon />
        </div>
        <InputBase
          placeholder="Buscar empresa…"
          classes={{
            root: "inputRoot",
            input: "inputInput",
          }}
          inputProps={{ "aria-label": "search" }}
          onChange={handleFilter}
        />
      </div>
      <div className="grid grid-cols-5 w-full text-gray-500 mt-6">
        <div className="mx-6 item">
          <p className="grayDarkText">Empresa</p>
        </div>
        <div className="mx-6 item">
          <p className="grayDarkText">Registrados</p>
        </div>
        <div className="mx-6 item">
          <p className="grayDarkText">En Curso</p>
        </div>
        <div className="mx-6 item">
          <p className="grayDarkText">Intereses cobrados</p>
        </div>
        <div className="mx-6 item">
          <p className="grayDarkText">Saldo total prestado</p>
        </div>
      </div>

      {formattedCompanies.map((item) => (
        <CompanyCard
          setCompanyId={setCompanyId}
          key={item.companyName}
          item={item}
        />
      ))}

      {formattedCompanies.length === 0 && (
        <h2 className="text-black text-2xl mt-4">No se encontraron empresas</h2>
      )}
    </>
  );
};

const CompanyCard = ({ item, setCompanyId }) => {
  const setSelectedCompany = (id, companyName) => {
    setCompanyId(id, companyName);
  };
  return (
    <div
      className="w-full cursor-pointer mb-4"
      onClick={() => setSelectedCompany(item.companyId, item.companyName)}
    >
      <div className="grid grid-cols-5 bg-white shadow-xl p-4 rounded-xl w-full text-gray-500 mt-6">
        <div className="mx-6">
          <p className="itemCompanyName">{item.companyName}</p>
        </div>
        <div className="mx-6">
          <p className="">
            {item.numOfActiveLoans}
            <span className="smallText">/ {item.numOfLoans}</span>
          </p>
        </div>
        <div className="mx-6 ">
          <p className="">{item.numOfActiveLoans}</p>
        </div>
        <div className="mx-6">
          <p className="">{item.interestsPaidFormatted}</p>
        </div>
        <div className="mx-6 flex justify-between">
          <p className="">{item.totalAmountLendFormatted}</p>
          <div className="arrowIcon">
            <ArrowForwardIosIcon style={{ fontSize: 16 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Companies;
