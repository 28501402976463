import React from "react";

export const EmployeeDetailItem = ({ data, index }) => {
  if (!data) {
    return null;
  }

  const employeeStatusLabel = (status) => {
    if (status === "Activo") {
      return `Activo`;
    } else if (status === "Desactivo") {
      return `Inactivo`;
    }
  };
  return (
    <div key={index} className=" ">
      <div className="grid grid-cols-3 gap-3">
        <div className="flex flex-col">
          <p className="grayText">Nombres</p>
          <p className="blackText">{data.employeeName}</p>
        </div>
        <div className="flex flex-col">
          <p className="grayText">Apellidos</p>
          <p className="blackText">{data.employeeLastName}</p>
        </div>
        <div className="flex flex-col">
          <p className="grayText">Status</p>
          <span
            className={`${
              data.status === "Activo" ? "green-active" : "gray-active"
            }`}
          >
            {employeeStatusLabel(data.status)}
          </span>
        </div>
        <div className="flex flex-col">
          <p className="grayText">Teléfono registrado</p>
          <p className="blackTextsmall txt14px">{data.phone}</p>
        </div>
        <div className="flex flex-col">
          <p className="grayText">Fecha de ingreso</p>
          <p className="blackTextsmall txt14px">{data.dateOfJoin}</p>
        </div>
        <div className="flex flex-col">
          <p className="grayText">Nómina</p>
          <p className="blackTextsmall txt14px">{data.payroll}</p>
        </div>
        <div className="flex flex-col">
          <p className="grayText">Localidad donde labora</p>
          <p className="blackTextsmall txt14px">{data.city}</p>
        </div>
        <div className="flex flex-col">
          <p className="grayText">Número de empleado</p>
          <p className="blackTextsmall txt14px">{data.employeeNumber}</p>
        </div>
        <div className="flex flex-col">
          <p className="grayText">Nombre de la empresa</p>
          <p className="blackTextsmall txt14px">{data.companyName}</p>
        </div>
        <div className="flex flex-col">
          <p className="grayText">Teléfono de la empresa</p>
          <p className="blackTextsmall txt14px">{data.companyPhoneNumber}</p>
        </div>
        <div className="flex flex-col">
          <p className="grayText">Correo electrónico</p>
          <p className="blackTextsmall txt14px">{data.email}</p>
        </div>
      </div>
    </div>
  );
};

export default EmployeeDetailItem;
