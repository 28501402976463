import React, { useState } from "react";
import persons from "../../../../assets/images/admin/persons.JPG";
import { parse } from "papaparse";

import { instance, config } from "../../../../services/axios";

import { useDispatch } from "react-redux";

import { setError } from "../../../../actions/errorAction";
import { setSuccess } from "../../../../actions/successAction";

import { api } from "../../../../services/api";

import { CustomizedTable } from "./CreatedEmployeesDisplay";

import AttachFileIcon from "@mui/icons-material/AttachFile";

import { Button } from "@mui/material";

export const CreateEmployees = ({ companyId, createdCompany, values }) => {
  const [rows, setRows] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const dispatch = useDispatch();

  const handleSubmitFile = async () => {
    try {
      const formData = new FormData();
      formData.append("file", selectedFile);

      const options = {
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data: response } = await api.post(
        `Salvum/services/boss/loadCompanyRegulation?companyId=${companyId}`,
        options
      );
      if (response.code > 300 || response.code > 202 || response.error) {
        dispatch(
          setError(
            response.message || "Ha ocurrido un error al subir el archivo."
          )
        );
        return;
      }

      dispatch(setSuccess(response.message || "Archivo subido con éxito."));
    } catch (error) {
      dispatch(
        setError(
          "Ha ocurrido un error interno en nuestro servidor al subir el archivo."
        )
      );
    }
  };

  if (!createdCompany || !values) {
    return null;
  }

  const handleInputChange = (evt) => {
    const file = evt.target.files[0];
    setSelectedFile(file);
  };

  const csvRegister = async () => {
    try {
      const csvFile = document.getElementById("csvFile").files[0];

      parse(csvFile, {
        header: true,
        transformHeader: function (h) {
          return h
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace("N°", "Num")
            .replace(/\s/g, "");
        },
        skipEmptyLines: true,
      });

      const formData = new FormData();

      formData.append("file", csvFile);

      // Update the formData object

      const response = await instance.post(
        "Salvum/services/users/uploadUsers",
        formData,
        config
      );

      if (response.code >= 400) {
        dispatch(setError(response.message));
        return;
      }

      dispatch(
        setSuccess(
          response.message || "Archivo de empleados cargado con exito."
        )
      );

      setRows(response.data.data || []);
    } catch (error) {
      const response = error.response.data;

      dispatch(setError(response.message));
    }
  };

  const handleBack = () => {
    setRows([]);
  };

  const formatSavingBankRates = (vals) => {
    return [
      { name: "Inmediato", yieldRate: vals.now },
      { name: "1 Mes", yieldRate: vals.month_1 },
      { name: "3 Meses", yieldRate: vals.month_3 },
      { name: "6 Meses", yieldRate: vals.month_6 },
      { name: "1 Año", yieldRate: vals.month_12 },
      { name: "2 años", yieldRate: vals.month_24 },
      { name: "A la separación", yieldRate: vals.unlimit },
    ];
  };

  return (
    <div className="flex justify-between w-full">
      <section className="border border-gray-400 p-4 rounded-xl">
        <h2 className="font-bold mb-4">Datos de la empresa</h2>

        <div className="flex">
          <h2>Numero de compañia: </h2>
          <p className="ml-2">{companyId}</p>
        </div>
        <div className="flex">
          <h2>Nombre: </h2>
          <p className="ml-2">{createdCompany.name}</p>
        </div>
        <div className="flex">
          <h2>Correo: </h2>
          <p className="ml-2">{createdCompany.email}</p>
        </div>
        <div className="flex">
          <h2>Telefono: </h2>
          <p className="ml-2">{createdCompany.phone}</p>
        </div>
        <div className="flex">
          <h2>RFC: </h2>
          <p className="ml-2">{createdCompany.rfc}</p>
        </div>
        <div className="flex">
          <h2>Razon social: </h2>
          <p className="ml-2">{createdCompany.businessName}</p>
        </div>
        <div className="flex">
          <h2>Correo aprobador: </h2>
          <p className="ml-2">{createdCompany.approver_email}</p>
        </div>
        <div className="flex">
          <h2>Correo autorizador: </h2>
          <p className="ml-2">{createdCompany.authorizator_email}</p>
        </div>

        <h2 className="font-bold my-4">Porcentaje de ahorros</h2>
        {formatSavingBankRates(values)
          .filter((saving) => saving.yieldRate > 0)
          .map((s) => (
            <div key={s.name} className="flex">
              <h2>{s.name}:</h2>
              <p className="ml-2">% {s.yieldRate}</p>
            </div>
          ))}

        <h2 className="font-bold my-4">Días de pago</h2>
        <div className="flex">
          <h2>Pago mensual:</h2>
          <p className="ml-2">Dia {createdCompany.day_of_payment_m}</p>
        </div>
        <div className="flex">
          <h2>Pago quincenal 1:</h2>
          <p className="ml-2"> Dia {createdCompany.day_of_payment_q_1}</p>
        </div>
        <div className="flex">
          <h2>Pago quincenal 2:</h2>
          <p className="ml-2">Dia {createdCompany.day_of_payment_q_2}</p>
        </div>
        <div className="flex">
          <h2>Pago semanal:</h2>
          <p className="ml-2">Dia {createdCompany.day_of_payment_w}</p>
        </div>

        {createdCompany.hasLoans && (
          <>
            <h2 className="font-bold my-4">Préstamos</h2>
            <div className="flex">
              <h2>Porcentaje de comisión:</h2>
              <p className="ml-2">{createdCompany.loanCommissionPercentage}</p>
            </div>
            <div className="flex">
              <h2>Porcentaje de interés:</h2>
              <p className="ml-2">{createdCompany.loanInterestPercentage}</p>
            </div>
            <div className="flex">
              <h2>Antiguedad minima:</h2>
              <p className="ml-2">{createdCompany.loanMinimumLaborSeniority}</p>
            </div>
            <div className="flex">
              <h2>Numero de endorsements:</h2>
              <p className="ml-2">{createdCompany.loanNumberOfEndorsements}</p>
            </div>
          </>
        )}

        {createdCompany.hasPayrollAdvance && (
          <>
            <h2 className="font-bold my-4">Adelantos de nómina</h2>
            <div className="flex">
              <h2>Porcentaje de comisión:</h2>
              <p className="ml-2">
                {createdCompany.payrollAdvanceCommissionPercentage}
              </p>
            </div>
          </>
        )}
      </section>
      {/* <div className="w-fit p-6 bg-white border border-gray-700 rounded-full">
        <span>Nombre:</span>
        <p>{values.name}</p>

      </div> */}
      {rows.length === 0 && (
        <section style={{ marginLeft: "20px" }}>
          <h1 className="text-center my-6">
            Ahora puedes crear empleados para tu empresa
          </h1>
          <p className="text-center mb-4">
            Si deseas crear empleados, selecciona tu archivo CSV en el boton de
            abajo.
          </p>
          <p>
            Para consultar los detalles de esta y otras empresas que has creado,
            dirigete a la pestaña Empresas
          </p>
          <p>en el menu de navegacion.</p>
          <div className={"tablehead"}>
            <h2 className={"smallSubtitle mt-6"}>Empleados</h2>
          </div>
          <div className={"grayLine"}></div>
          <div className={"centerElements"}>
            <div>
              <img alt="persons" src={persons} />
            </div>
            <p className={"grayText mediumText"}>
              Da click al boton para seleccionar tu archivo de carga de
              empleado.
            </p>
            <div className={" mt-10 flex justify-center"}>
              <form>
                <input
                  className="inputfile"
                  type="file"
                  id="csvFile"
                  accept=".csv"
                  name="csvFile"
                  onChange={csvRegister}
                />
                <label className="blueButton mt-10" htmlFor="csvFile">
                  Registro CSV
                </label>
              </form>
              {/* AL PARECER ESTE COMPONENTE NO SE MUESTRA CUANDO SE CREA UNA NUEVA COMPAÑIA
                                      <button className="blueButton" onClick={this.backToCompanies}>Registro CSV</button>*/}
            </div>
          </div>
        </section>
      )}

      {rows.length > 0 && (
        <div className="mx-2 text-black">
          <button
            className="border border-gray-500 px-4 rounded-full my-6 text-lg"
            onClick={handleBack}
          >
            Regresar
          </button>
          <h2 className=" text-2xl mb-10">
            Los siguientes usuarios fueron agregados exitosamente.
          </h2>
          <CustomizedTable rows={rows} />
        </div>
      )}

      <div className="tabItem-v2">
        <p style={{ fontSize: "18 px" }} className={"grayText"}>
          Subir reglamento de empresa
        </p>
        <label id="file-csv" htmlFor="upload-photo">
          <AttachFileIcon />
        </label>
        <input
          type="file"
          onChange={handleInputChange}
          name="photo"
          id="upload-photo"
          accept=".xls,.xlsx,.csv"
        />
        {selectedFile && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <label className="grayText">{selectedFile.name}</label>
            </div>
            <Button
              style={{ maxWidth: "270px" }}
              color="primary"
              variant="contained"
              onClick={handleSubmitFile}
            >
              Enviar
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateEmployees;
