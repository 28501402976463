import React from "react";

export const AdminCompaniesHeader = () => {
  return (
    <div className={"companiesHeroContent"}>
      <div className={"companiesContainer"}>
        <ul className={"breadcrumbs"}>
          <li>Inicio</li>
          <li>Empresas</li>
        </ul>
        <div className={"companiesHero"}>
          <div className={"content"}>
            <span className={"contentTitle"}>Empresas</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminCompaniesHeader;
