import request from "../utils/request";
import { api } from "../services/api";

import {
  GET_BOSS_BY_USER_ID_BEGIN,
  GET_BOSS_BY_USER_ID_SUCCESS,
  GET_BOSS_BY_USER_ID_FAILURE,
  GET_BOSS_HOME_INFO_BEGIN,
  GET_BOSS_HOME_INFO_SUCCESS,
  GET_BOSS_HOME_INFO_FAILURE,
  GET_BOSS_EMPLOYEE_DETAIL_BEGIN,
  GET_BOSS_EMPLOYEE_DETAIL_SUCCESS,
  GET_BOSS_EMPLOYEE_DETAIL_FAILURE,
  GET_PENDING_LOANS_FOR_BOSS_BEGIN,
  GET_PENDING_LOANS_FOR_BOSS_SUCCESS,
  GET_PENDING_LOANS_FOR_BOSS_FAILURE,
  GET_BOSS_COMPANY_SUMMARY_SUCCESS,
  GET_BOSS_COMPANY_SUMMARY_FAILURE,
} from "../constants/actionTypes";
import { setError } from "./errorAction";

export const getBossByUserIdBegin = () => ({
  type: GET_BOSS_BY_USER_ID_BEGIN,
});

export const getBossByUserIdSuccess = (boss) => ({
  type: GET_BOSS_BY_USER_ID_SUCCESS,
  boss,
});

export const getBossByUserIdFailure = (error) => ({
  type: GET_BOSS_BY_USER_ID_FAILURE,
  error,
});

export const getBossHomeInfoBegin = () => ({
  type: GET_BOSS_HOME_INFO_BEGIN,
});

export const getBossHomeInfoSuccess = (homeInfo) => ({
  type: GET_BOSS_HOME_INFO_SUCCESS,
  homeInfo,
});

export const getBossHomeInfoFailure = (error) => ({
  type: GET_BOSS_HOME_INFO_FAILURE,
  error,
});

export const getBossEmployeeDetailBegin = () => ({
  type: GET_BOSS_EMPLOYEE_DETAIL_BEGIN,
});

export const getBossEmployeeDetailSuccess = (employeeDetail) => ({
  type: GET_BOSS_EMPLOYEE_DETAIL_SUCCESS,
  employeeDetail,
});

export const getBossEmployeeDetailFailure = (error) => ({
  type: GET_BOSS_EMPLOYEE_DETAIL_FAILURE,
  error,
});

export const getPendingLoansForBossBegin = () => ({
  type: GET_PENDING_LOANS_FOR_BOSS_BEGIN,
});

export const getPendingLoansForBossSuccess = (pendingLoans) => ({
  type: GET_PENDING_LOANS_FOR_BOSS_SUCCESS,
  pendingLoans,
});

export const getPendingLoansForBossFailure = () => ({
  type: GET_PENDING_LOANS_FOR_BOSS_FAILURE,
});

export const getBossCompanySummarySuccess = (companySummary) => ({
  type: GET_BOSS_COMPANY_SUMMARY_SUCCESS,
  companySummary,
});

export const getBossCompanySummaryFailure = (error) => ({
  type: GET_BOSS_COMPANY_SUMMARY_FAILURE,
  error,
});

///
export function getBossByUserId(userId) {
  return (dispatch, getState) => {
    dispatch(getBossByUserIdBegin());
    return request({
      partialUrl: "/api/v1/bosses/find-boss-by-user-id",
      method: "POST",
      state: getState().user.request,
      body: {
        userId,
      },
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(getBossByUserIdSuccess(data.data));
      })
      .catch((err) => {
        return dispatch(getBossByUserIdFailure(err));
      });
  };
}

export const getBossHomeInfo = ({ companyId }) => {
  return async (dispatch) => {
    try {
      dispatch(getBossHomeInfoBegin());
      const response = await api.get(
        `Salvum/services/boss/getBossHomeInfo?companyId=${companyId}`
      );
      if (response.error) {
        dispatch(setError(response.message));
      }
      dispatch(getBossHomeInfoSuccess(response.data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getBossHomeInfoFailure(error));
    }
  };
};

export const getBossEmployeeDetail = ({ companyId }) => {
  return async (dispatch) => {
    try {
      dispatch(getBossEmployeeDetailBegin());
      const response = await api.get(
        `Salvum/services/boss/getBossEmployeeDetail?companyId=${companyId}`
      );
      if (response.error) {
        dispatch(setError(response.message));
      }
      dispatch(getBossEmployeeDetailSuccess(response.data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getBossEmployeeDetailFailure(error));
    }
  };
};

export const getPendingLoansForBoss = ({ companyId, bossUserId }) => {
  return async (dispatch) => {
    try {
      dispatch(getPendingLoansForBossBegin());
      const response = await api.get(
        `Salvum/services/loans/getPendingLoansForBoss?companyId=${companyId}&bossUserId=${bossUserId}`
      );
      if (response.error) {
        dispatch(setError(response.message));
      }
      dispatch(getPendingLoansForBossSuccess(response.data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getPendingLoansForBossFailure(error));
    }
  };
};

export const getBossCompanySummary = () => {
  return async (dispatch) => {
    try {
      const response = await api.get(
        "Salvum/services/boss/getBossCompanySummary"
      );
      if (response.error) {
        dispatch(setError(response.message));
      }
      dispatch(getBossCompanySummarySuccess(response.data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getBossCompanySummaryFailure(error));
    }
  };
};
