import axios from "axios";
import { apiBaseUrlAws } from "../actions/consts";

export const instance = axios.create({
  baseURL: apiBaseUrlAws,
});

export const config = {
  headers: {
    "Content-type": "application/json",
  },
};
