import React from "react";
import ahorro from "../../../assets/images/employee/asociadosahorropuerco.svg";

const StartSaving = ({ onStart }) => {
  return (
    <div className="savingsStart">
      <div className="savingsContainer">
        <ul className="breadcrumbs">
          <li>Inicio</li>
          <li>Ahorros</li>
        </ul>
        <div className="savingsHero">
          <div className="content">
            <span className="contentTitle">Ahorros</span>
            <p>
              En Salvum te ayudamos a administrar tu ahorro de acuerdo a tus
              diferentes objetivos de vida &nbsp;
              <span className="text-style-1">
                (corto, mediano y largo plazo).
              </span>
            </p>
            <p>
              Ahorra con tasas de interés competitivas y de manera segura.&nbsp;
              <span className="text-style-1">
                Entre mayor sea tu plazo de ahorro, mayor serán tus ganancias
              </span>
            </p>
            <button onClick={onStart}>COMENZAR A AHORRAR</button>
          </div>
          <div className="savingHeroRight">
            <img alt="Caja de Ahorro" src={ahorro} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartSaving;
