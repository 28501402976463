import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../../../../../../components/Modal";
import "./Modals.scss";
import { api } from "../../../../../../../services/api";

import { setError } from "../../../../../../../actions/errorAction";
import { setSuccess } from "../../../../../../../actions/successAction";

import { getPendingLoansForAdmin } from "../../../../../../../actions/loansAction";

export const RejectLoanModal = ({ isOpen = false, setIsOpen, loanId }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const closeConfirmModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = async () => {
    try {
      const { id: adminUserId, companyId } = user;
      const response = await api.get(
        "Salvum/services/loans/updateLoanStatusForBoss?loanId=" +
          loanId +
          "&bossUserId=" +
          adminUserId +
          "&nextStep=false"
      );

      if (response.error) {
        await dispatch(setError(response.message));
      } else {
        await dispatch(setSuccess(response.message));
        await dispatch(getPendingLoansForAdmin({ companyId, adminUserId }));
      }
      closeConfirmModal();
    } catch (error) {
      const message = error.response.data.message || error.message;
      await dispatch(setError(message));
      closeConfirmModal();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={closeConfirmModal}
        title="Rechazar préstamo"
      >
        <h4>¿Está seguro de rechazar este préstamo?</h4>

        <div className="buttons-container">
          <button
            className="transition ease-in-out border-black border rounded-md p-2 hover:bg-gray-50"
            onClick={closeConfirmModal}
            type="button"
            style={{ marginRight: "8px" }}
          >
            En otro momento
          </button>
          <button
            className="transition bg-red-500 text-white ease-in-out border-black border rounded-md p-2 hover:bg-red-700"
            type="submit"
            onClick={handleSubmit}
          >
            Si, rechazar.
          </button>
        </div>
      </Modal>
    </>
  );
};

export default RejectLoanModal;
