import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import BossAppBar from "./BossAppBar/BossAppBar";
import BossMainContent from "./BossMainContent/BossMainContent";
import BossFooter from "../EmployeeLayout/EmployeeFooter/EmployeeFooter";

export class BossLayout extends Component {
  render() {
    return (
      <>
        <BossAppBar />
        <BossMainContent>{this.props && this.props.children}</BossMainContent>
        <BossFooter />
      </>
    );
  }
}

BossLayout.propTypes = {
  children: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(BossLayout);
