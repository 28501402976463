import React from "react";
import { useDispatch } from "react-redux";
import { TextField } from "@mui/material";

import { userLogin } from "../../actions/userActions";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./LoginForm.scss";

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object({
  email: Yup.string()
    .email("Por favor, ingresa un formato de email valido")
    .required("Este campo es requerido, ingresa tu correo para iniciar sesion"),
  password: Yup.string().required(
    "Este campo es requerido para iniciar sesion"
  ),
});

export const LoginForm = ({ setRecoveryPassword }) => {
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    const { email, password } = values;
    await dispatch(userLogin(email, password));
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  return (
    <div className="form">
      <form
        method="POST"
        onSubmit={formik.handleSubmit}
        className="form__login"
      >
        <h2 className="form__title">ENTRA A TU PORTAL</h2>
        <div className="form__group">
          <span className="form__label">Correo electrónico</span>
          <TextField
            fullWidth
            id="email"
            name="email"
            label="Ingrese su correo electronico"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
        </div>
        <div className="form__group">
          <span className="form__label">Contraseña</span>
          <TextField
            fullWidth
            id="password"
            name="password"
            type="password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            label="Ingrese su contraseña"
          />
        </div>
        <div className="flex w-full h-10 justify-center mt-4">
          <span
            className="recover-button"
            onClick={() => setRecoveryPassword(true)}
          >
            Olvidé mi contraseña
          </span>
        </div>
        <button className="login-inside">Acceder a portal</button>
      </form>
    </div>
  );
};
