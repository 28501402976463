import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import EmployeeAppBar from "./EmployeeAppBar/EmployeeAppBar";
import EmployeeMainContent from "./EmployeeMainContent/EmployMainContent";
import EmployeeFooter from "./EmployeeFooter/EmployeeFooter";

export class EmployeeLayout extends Component {
  render() {
    return (
      <>
        <EmployeeAppBar />
        <EmployeeMainContent>
          {this.props && this.props.children}
        </EmployeeMainContent>
        <EmployeeFooter />
      </>
    );
  }
}

EmployeeLayout.propTypes = {
  children: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(EmployeeLayout);
