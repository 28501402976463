import React from "react";

// Components
import { TextField } from "@mui/material";
import { Buttons } from "./Buttons";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

// Step 1
export const PayrollAdvances = ({
  step,
  formik,
  handleNext = () => {},
  handleBack,
  handleSkip,
  handleChange,
  checked,
}) => {
  if (step !== 2) {
    return null;
  }

  return (
    <div className="flex flex-col items-center p-6">
      <h2>
        Si deseas agregar adelantos de nómina, habilita la opcion y agrega la
        comisión deseada.
      </h2>
      <FormGroup>
        <FormControlLabel
          control={<Switch onChange={handleChange} checked={checked} />}
          label="Agregar adelantos de nómina"
        />
      </FormGroup>
      <div className="grid grid-cols-1 gap-1 bg-white p-6 rounded-lg">
        <TextField
          fullWidth
          id="payrollAdvanceCommissionPercentage"
          name="payrollAdvanceCommissionPercentage"
          type="number"
          disabled={!checked}
          label="Comisión (%)"
          value={formik.values.payrollAdvanceCommissionPercentage}
          onChange={formik.handleChange}
        />
      </div>
      <Buttons
        handleNext={handleNext}
        activeStep={step}
        handleBack={handleBack}
        handleSkip={handleSkip}
      />
    </div>
  );
};

export default PayrollAdvances;
