import React, { useEffect, useState } from "react";

// Actions
import {
  getSavingSummary,
  getSavingSummaryById,
} from "../../../actions/adminAction";
import { getBossEmployeeDetail } from "../../../actions/bossAction";

// Styles
import "./AdminSavings.scss";

// Components
import { Companies } from "./components/Companies";
import {
  EmployeesCards,
  SavingsDetails,
} from "../../../components/Common/Employees";

// Stores
import { useDispatch, useSelector } from "react-redux";

export const AdminSavingsv2 = () => {
  const dispatch = useDispatch();
  const [useGeneralSummary, setUseGeneralSummary] = useState(true);
  const [companyId, setCompanyId] = useState("general");
  const [employeeId, setEmployeeId] = useState(null);

  const generalSavingSummary = useSelector(
    (state) => state.admin.savingSummary
  );
  const summaryById = useSelector((state) => state.admin.savingSummaryById);
  const employeesDetails = useSelector((state) => state.boss.employeeDetail);

  useEffect(() => {
    dispatch(getSavingSummary());
  }, []);

  const setSavingsSummaryById = async (companyId) => {
    if (companyId === "general") {
      setUseGeneralSummary(true);
    } else if (useGeneralSummary) {
      setUseGeneralSummary(false);
    }

    await dispatch(getSavingSummaryById({ companyId }));
  };

  const setSelectedCompany = async (companyId) => {
    setCompanyId(companyId);

    if (companyId === "general") return;

    await dispatch(getBossEmployeeDetail({ companyId }));
  };

  if (!generalSavingSummary) {
    return null;
  }

  const savingSummary = useGeneralSummary ? generalSavingSummary : summaryById;

  return (
    <div className="savingsAdmin w-full">
      <div className="companiesHeroContent">
        <div className="companiesContainer">
          <ul className="breadcrumbs">
            <li>Inicio</li>
            <li>Ahorros</li>
          </ul>
          <div className="companiesHero">
            <div className="content">
              <span className="contentTitle">Ahorros</span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex w-full">
        <Companies
          companyId={companyId}
          setSelectedCompany={setSelectedCompany}
          setSavingsSummary={setSavingsSummaryById}
          setEmployeeId={setEmployeeId}
        />

        <div className="m-6 w-full flex flex-col">
          {!savingSummary && (
            <h1 className="text-black text-3xl my-20">
              La compañía seleccionada no tiene detalles para mostrar
            </h1>
          )}

          {savingSummary && (
            <div className=" w-full bg-white flex flex-col text-black">
              <h2 className="text-3xl text-italic">Ahorros</h2>
              <div className="grayLine" />
              <div className="grid grid-cols-5 gap-5">
                <div className="flex flex-col items-center">
                  <h3 className="text-2xl">{savingSummary.companyName}</h3>
                </div>

                <div className="flex flex-col items-center">
                  <h3 className="text-xs text-gray-500">Ahorros activos</h3>
                  <p className="text-lg">{savingSummary.activeSavings}</p>
                </div>

                <section className="flex flex-col items-center">
                  <h3 className="text-xs text-gray-500">
                    Aportación de nómina
                  </h3>
                  <p>{savingSummary.monthlyIncomeFormatted}</p>
                </section>

                <section className="flex flex-col items-center">
                  <h3 className="text-xs text-gray-500">
                    Saldo total acumulado
                  </h3>
                  <p>{savingSummary.totalAcumFormatted}</p>
                </section>

                <section className="flex flex-col items-center">
                   <h3 className="text-xs text-gray-500">
                    Tasa banxico del mes
                  </h3>
                  <p>{savingSummary.banxicoRate}</p> 
                </section>
              </div>
              <div className="grayLine" />
              <div className="grid-savings-table">
                <div className="flex flex-col justify-center items-center ">
                  <h3 className="text-xs text-gray-500">Tipo de ahorro</h3>
                </div>

                <section className="flex flex-col justify-center items-center">
                  <h3 className="text-xs text-gray-500">Personas inscritas</h3>
                </section>

                <section className="flex flex-col justify-center items-center">
                  <h3 className="text-xs text-gray-500">Tasa de referencia</h3>
                </section>

                <section className="flex flex-col justify-center items-center">
                  <h3 className="text-xs text-gray-500">
                    Aportación de nómina
                  </h3>
                </section>

                <section className="flex flex-col justify-center items-center">
                  <h3 className="text-xs text-gray-500">
                    Aportaciones acumuladas
                  </h3>
                </section>
                <section className="flex flex-col justify-center items-center">
                  <h3 className="text-xs text-gray-500">
                    Saldo total acumulado
                  </h3>
                </section>
                {savingSummary.table.map((item) => (
                  <React.Fragment key={item.companyName}>
                    <div className="flex items-center mb-2">
                      <div
                        style={{ backgroundColor: item.colorCode }}
                        className="h-3 w-3 rounded-full"
                      />
                      <p className="ml-2">{item.slotName}</p>
                    </div>

                    <div className="flex flex-col justify-center items-center">
                      <p>{item.persons}</p>
                    </div>

                    <div className="flex flex-col justify-center items-center">
                      <p>{item.rate}</p>
                    </div>

                    <section className="flex flex-col justify-center items-center">
                      <p>{item.monthlyIncomeFormatted}</p>
                    </section>

                    <section className="flex flex-col justify-center items-center">
                      <p>{item.monthlyIncomeFormatted}</p>
                    </section>

                    <section className="flex flex-col justify-center items-center">
                      <p>{item.totalAcumFormatted}</p>
                    </section>
                  </React.Fragment>
                ))}
              </div>
            </div>
          )}

          {!employeeId && employeesDetails && (
            <EmployeesCards
              setEmployeeId={setEmployeeId}
              employees={employeesDetails.employees}
            />
          )}

          {employeeId && (
            <SavingsDetails
              companyId={companyId}
              employeeId={employeeId}
              setEmployeeId={setEmployeeId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminSavingsv2;
