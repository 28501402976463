import React, { useState } from "react";
import { Modal } from "../../../../../../components/Modal";

// Redux
import { useDispatch } from "react-redux";

// Axios config
import { instance, config } from "../../../../../../services/axios";

// Actions
import { setSuccess } from "../../../../../../actions/successAction";
import { setError } from "../../../../../../actions/errorAction";
import { getEmployees } from "../../../../../../actions/adminAction";

import { CircularProgress } from "@mui/material";

export const DeleteEmployeeModal = ({
  isOpen,
  closeModal,
  employeeName,
  employeeId,
  companyId,
}) => {
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);
  if (!isOpen) {
    return null;
  }

  const handleSubmit = async () => {
    try {
      setIsDeleting(true);
      const response = await instance.post(
        `Salvum/services/boss/deleteEmployee?companyId=${companyId}&employeeId=${employeeId}`,
        {},
        config
      );
      if (response.data.error) {
        dispatch(
          setError(
            response.data.message ||
              `Ha ocurrido un error al intentar eliminar a ${employeeName}`
          )
        );
      } else {
        await dispatch(getEmployees({ companyId }));
        dispatch(
          setSuccess(
            response.data.message ||
              `${employeeName} ha sido eliminado exitosamente`
          )
        );
      }
      closeModal();
      setIsDeleting(false);
    } catch (error) {
      setIsDeleting(false);
      dispatch(setError(`Ha ocurrido un error: ${error}`, "error"));
      closeModal();
    }
  };

  return (
    <Modal isOpen={isOpen} handleClose={closeModal} title="Eliminar empleado.">
      <h3 className="text-center mt-6">Usted está apunto de eliminar a </h3>
      <h4 className="text-xl text-center my-5">{employeeName}</h4>

      <div className="flex flex-col items-center justify-center">
        {isDeleting && <CircularProgress />}

        {!isDeleting && (
          <>
            <h4 className="text-center mb-4">¿Desea continuar?</h4>
            <div className="flex justify-center">
              <button
                onClick={closeModal}
                variant="outlined"
                type="button"
                className="transition mr-8 ease-in-out px-4 py-1 rounded-2xl bg-white text-black cursor-pointer hover:bg-gray-50 border border-gray-600"
              >
                Cancelar
              </button>
              <button
                className="transition ease-in-out px-4 py-1 rounded-2xl bg-red-600 text-white cursor-pointer hover:bg-red-700 border border-gray-600"
                disabled={isDeleting}
                onClick={handleSubmit}
              >
                Eliminar
              </button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default DeleteEmployeeModal;
