import React, { Component } from "react";
import { connect } from "react-redux";
import "./EmployeeHome.scss";
import {
  getEmployeeByUserId,
  getEmployeeNumberDetails,
} from "../../../actions/employeeAction";
import {
  getTotalSavingsEmployee,
  getSavingsSlots,
} from "../../../actions/savingsAction";
import { SavingsHorizons } from "../EmployeeSavings/SavingResume/Components/SavingsHorizons";
import { withRouter } from "react-router-dom";

export class EmployeeHome extends Component {
  savingTypes = {
    1: {
      name: "now",
      label: "Inmediato",
      color: "#1d3557",
      dotClass: "dot-black",
    },
    2: {
      name: "month_1",
      label: "A 1 mes",
      color: "#048aa8",
      dotClass: "dot-blue",
    },
    3: {
      name: "month_3",
      label: "A 3 meses",
      color: "#6cb6f5",
      dotClass: "dot-softblue",
    },
    4: {
      name: "month_6",
      label: "A 6 meses",
      color: "#ef476f",
      dotClass: "dot-red",
    },
    5: {
      name: "month_12",
      label: "A 12 meses",
      color: "#fa7e58",
      dotClass: "dot-orange",
    },
    6: {
      name: "month_24",
      label: "A 24 meses",
      color: "#f5a61d",
      dotClass: "dot-yellow",
    },
    7: {
      name: "unlimit",
      label: "A la separación",
      color: "#15db93",
      dotClass: "dot-green",
    },
  };

  componentDidMount = () => {
    this.props.dispatch(
      getEmployeeByUserId(this.props.user.id, this.props.user.companyId)
    );
    this.props.dispatch(
      getEmployeeNumberDetails({
        employeeId: this.props.user.id,
        companyId: this.props.user.companyId,
      })
    );
    this.props.dispatch(
      getTotalSavingsEmployee({
        employeeId: this.props.user.id,
        companyId: this.props.user.companyId,
      })
    );
    this.props.dispatch(
      getSavingsSlots({
        employeeId: this.props.user.id,
        companyId: this.props.user.companyId,
      })
    );
  };

  handleRedirectSavings = () => {
    this.props.history.push("/employee/ahorros");
  };

  render = () => {
    const hasSavings = this.props.savingSlots
      ? !this.props.savingSlots?.isEmployeeBrandNew
      : false;

    return (
      <div className="mainContainer" style={{ flex: "1" }}>
        <div className="hero" style={{ flex: 1 }}>
          <div className="hero-block">
            <div className="hero-subBlock">
              <div className="off-Main">
                <span className="greeting">Hola&nbsp;</span>
                {this.props.employee && (
                  <span className="userName">{`${this.props.employee.firstname} ${this.props.employee.lastname}`}</span>
                )}
              </div>
              <div className="off-Main">
                <span className="welcome">¡Bienvenido!</span>
              </div>
              <EmptySavings hasSavings={hasSavings} />
            </div>
          </div>
        </div>

        {this.props.savingSlots && (
          <div className="flex mx-10 relative">
            <SavingDetails
              hasSavings={hasSavings}
              savingSlots={this.props.savingSlots.slots}
              employeeDetails={this.props.employeeDetails}
            />

            <EmployeeDetails employeeDetails={this.props.employeeDetails} />
          </div>
        )}
      </div>
    );
  };
}

const EmptySavings = ({ hasSavings }) => {
  if (hasSavings) {
    return null;
  }

  return (
    <>
      <p className="my-5 text-xl mr-32">
        Para disfrutar de todo lo que ofrecemos en Salvum, debes tener un ahorro
        configurado en tu cuenta primero.
      </p>
      <p className="my-5 text-xl mr-32">
        Crea nuevos ahorros dirigiendote a la pestaña de Ahorros en el menú de
        navegación en la parte de arriba.
      </p>
    </>
  );
};

const SavingDetails = ({ hasSavings, savingSlots = [], employeeDetails }) => {
  if (!hasSavings || !savingSlots) {
    return null;
  }

  return (
    <div className="home-resume poppins">
      <div className="form-left">
        <div className="form-message">Ahorros</div>
        <div className="border-option" />
        {!hasSavings && (
          <div className="form-row">
            <div className="off-item">
              <span className="text-lg">No tienes ahorros activos</span>
            </div>
          </div>
        )}
        {hasSavings && (
          <>
            <div className="form-row">
              <div className="off-item">
                <div className="item">
                  <span className="title">Ahorro por periodo</span>
                  <span className="home-subtitle">
                    {employeeDetails.currentSavingAmountPerPeriod}
                  </span>
                </div>
              </div>
              <div className="off-item" />
              <div className="off-item">
                <div className="item">
                  <span className="title">Total acumulado</span>
                  <span className="home-subtitle">
                    {employeeDetails.savingAcumFormatted}
                  </span>
                </div>
              </div>
              <div className="off-item" />
            </div>
            <div className="form-row">
              <div className="off-item">
                <div className="item">
                  <SavingsHorizons savingSlots={savingSlots} />
                </div>
              </div>
              {/* <div className="off-item">
                <div className="item">
                  <span className="title">Tasa Vigente</span>
                  <div className="dotter" key={`saving-amount-`}>
                    <div className="data-focus">
                      {employeeDetails.currentRateForSavings}
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const EmployeeDetails = ({ employeeDetails }) => {
  if (!employeeDetails) {
    return null;
  }

  return (
    <div className="poppins flex flex-col z-10">
      
      <div className="flex flex-col items-center p-4 bg-white  text-black rounded-xl shadow-xl">
        <span className="text-2xl italic font-light">Préstamos</span>
        <div className="grayLine" />
        {employeeDetails.currentActiveLoans > 0 ? (
          <>
            <span className="text-base text-center font-light">En curso:</span>
            <span className="text-lg">
              {employeeDetails.currentActiveLoans}
            </span>
            <span className="text-base text-center font-light mt-4">
              Saldo pendiente para pagar:
            </span>
            <span className="text-lg">
              {employeeDetails.remainingBalanceToPayFormatted}
            </span>
          </>
        ) : (
          <p className="text-base text-center font-light mt-4">
            Actualmente no tienes préstamos en curso.{" "}
          </p>
        )}
      </div>

      <div className="flex flex-col items-center p-4 bg-white mt-10 text-black rounded-xl shadow-xl">
        <span className="text-2xl italic font-light">Adelantos</span>
        <div className="grayLine" />
        {employeeDetails.cashAdvanceQuantity > 0 && (
          <>
            <span className="text-base text-center font-light">
              Total registrados
            </span>
            <span className="text-lg">
              <span className="numberTop">
                {employeeDetails.cashAdvanceQuantity}{" "}
              </span>
            </span>
            <span className="text-base text-center font-light">
              Saldo total aprobado
            </span>
            <span className="text-lg">
              {employeeDetails.totalCashAdvanceRequestedFormatted}
            </span>
          </>
        )}

        {employeeDetails.cashAdvanceQuantity === 0 && (
          <p className="text-base text-center font-light">
            Aún no tienes adelantos registrados.
          </p>
        )}
      </div>
      
    </div>
  );
};

function mapStateToProps(state) {
  return {
    userDetails: state.user,
    user: state.user.user,
    employee: state.employee.current,
    employeeDetails: state.employee.numberDetails,
    summary: state.employee.summary,
    totalSavingsEmployee: state.savings.totalSavingsEmployee,
    banxico: state.savings.banxico,
    savingSlots: state.savings.savingsSlots,
  };
}

export default withRouter(connect(mapStateToProps)(EmployeeHome));
