import React, { useState } from "react";

// Components
import { TextField } from "@mui/material";
import { Buttons } from "./Buttons";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Switch from "@mui/material/Switch";

// Step 1
export const SavingTypes = ({
  step,
  formik,
  handleNext = () => {},
  handleBack,
  handleSkip,
  checked,
  handleChange,
}) => {
  const [nowChecked, setNowChecked] = useState(
    formik.values.now !== null &&
      formik.values.now !== undefined &&
      formik.values.now !== 0
  );
  const [month_1_Checked, setMonth_1_Checked] = useState(
    formik.values.month_1 !== null &&
      formik.values.month_1 !== undefined &&
      formik.values.month_1 !== 0
  );
  const [month_3_Checked, setMonth_3_Checked] = useState(
    formik.values.month_3 !== null &&
      formik.values.month_3 !== undefined &&
      formik.values.month_3 !== 0
  );
  const [month_6_Checked, setMonth_6_Checked] = useState(
    formik.values.month_6 !== null &&
      formik.values.month_6 !== undefined &&
      formik.values.month_6 !== 0
  );
  const [month_12_Checked, setMonth_12_Checked] = useState(
    formik.values.month_12 !== null &&
      formik.values.month_12 !== undefined &&
      formik.values.month_12 !== 0
  );
  const [month_24_Checked, setMonth_24_Checked] = useState(
    formik.values.month_24 !== null &&
      formik.values.month_24 !== undefined &&
      formik.values.month_24 !== 0
  );
  const [unlimitChecked, setUnlimitChecked] = useState(
    formik.values.unlimit !== null &&
      formik.values.unlimit !== undefined &&
      formik.values.unlimit !== 0
  );

  const [errors, setErrors] = useState({});

  const validateForm = async () => {
    const errors = await formik.validateForm();
    const errorNames = Object.keys(errors);
    setErrors(errors);
    if (
      !errorNames.includes("now") &&
      !errorNames.includes("month_1") &&
      !errorNames.includes("month_3") &&
      !errorNames.includes("month_6") &&
      !errorNames.includes("month_12") &&
      !errorNames.includes("month_24") &&
      !errorNames.includes("unlimit")
    ) {
      handleNext();
    }
  };

  return (
    <div className="flex flex-col items-center p-6">
      <h2>Lista de ahorros actuales.</h2>

      <h2>
        Tambien puede habilitar nuevos ahorros seleccionandolos e insertando un
        valor de spread para cada uno.
      </h2>

      <h2>
        Si desea deshabilitar los ahorros para esta empresa, solo deshabilite la
        opcion Agregar ahorros.
      </h2>
      <FormGroup>
        <FormControlLabel
          control={<Switch onChange={handleChange} checked={checked} />}
          label="Agregar ahorros"
        />
      </FormGroup>
      <div className="grid grid-cols-2 gap-2 bg-white p-6 rounded-lg">
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={nowChecked}
                onChange={(e) => setNowChecked(e.target.checked)}
                disabled={!checked}
              />
            }
            label="Inmediato"
          />
          {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
        </FormGroup>
        <TextField
          fullWidth
          id="now"
          name="now"
          type="number"
          disabled={!checked || !nowChecked}
          label="Spread inmediato"
          value={formik.values.now}
          onChange={formik.handleChange}
          error={errors.now && Boolean(formik.errors.now)}
          helperText={errors.now && formik.errors.now}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={month_1_Checked}
                onChange={(e) => setMonth_1_Checked(e.target.checked)}
                disabled={!checked}
              />
            }
            label="1 Mes"
          />
          {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
        </FormGroup>
        <TextField
          fullWidth
          id="month_1"
          name="month_1"
          type="number"
          disabled={!checked || !month_1_Checked}
          label="Spread a 1 Mes"
          value={formik.values.month_1}
          onChange={formik.handleChange}
          error={errors.month_1 && Boolean(formik.errors.month_1)}
          helperText={errors.month_1 && formik.errors.month_1}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={month_3_Checked}
                onChange={(e) => setMonth_3_Checked(e.target.checked)}
                disabled={!checked}
              />
            }
            label="3 Meses"
          />
          {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
        </FormGroup>
        <TextField
          fullWidth
          id="month_3"
          name="month_3"
          type="number"
          disabled={!checked || !month_3_Checked}
          label="Spread a 3 Meses"
          value={formik.values.month_3}
          onChange={formik.handleChange}
          error={errors.month_3 && Boolean(formik.errors.month_3)}
          helperText={errors.month_3 && formik.errors.month_3}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={month_6_Checked}
                onChange={(e) => setMonth_6_Checked(e.target.checked)}
                disabled={!checked}
              />
            }
            label="6 Meses"
          />
          {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
        </FormGroup>
        <TextField
          fullWidth
          id="month_6"
          name="month_6"
          type="number"
          disabled={!checked || !month_6_Checked}
          label="Spread a 6 Meses"
          value={formik.values.month_6}
          onChange={formik.handleChange}
          error={errors.month_6 && Boolean(formik.errors.month_6)}
          helperText={errors.month_6 && formik.errors.month_6}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={month_12_Checked}
                onChange={(e) => setMonth_12_Checked(e.target.checked)}
                disabled={!checked}
              />
            }
            label="1 Año"
          />
          {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
        </FormGroup>
        <TextField
          fullWidth
          id="month_12"
          name="month_12"
          type="number"
          disabled={!checked || !month_12_Checked}
          label="Spread a 1 Año"
          value={formik.values.month_12}
          onChange={formik.handleChange}
          error={errors.month_12 && Boolean(formik.errors.month_12)}
          helperText={errors.month_12 && formik.errors.month_12}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={month_24_Checked}
                onChange={(e) => setMonth_24_Checked(e.target.checked)}
                disabled={!checked}
              />
            }
            label="2 Años"
          />
          {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
        </FormGroup>
        <TextField
          fullWidth
          id="month_24"
          name="month_24"
          type="number"
          disabled={!checked || !month_24_Checked}
          label="Spread a 2 Años"
          value={formik.values.month_24}
          onChange={formik.handleChange}
          error={errors.month_24 && Boolean(formik.errors.month_24)}
          helperText={errors.month_24 && formik.errors.month_24}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={unlimitChecked}
                onChange={(e) => setUnlimitChecked(e.target.checked)}
                disabled={!checked}
              />
            }
            label="A la separación"
          />
          {/* <FormControlLabel disabled control={<Checkbox />} label="Disabled" /> */}
        </FormGroup>
        <TextField
          fullWidth
          id="unlimit"
          name="unlimit"
          type="number"
          disabled={!checked || !unlimitChecked}
          label="Spread a la separación"
          value={formik.values.unlimit}
          onChange={formik.handleChange}
          error={errors.unlimit && Boolean(formik.errors.unlimit)}
          helperText={errors.unlimit && formik.errors.unlimit}
        />
      </div>
      <Buttons
        handleNext={validateForm}
        activeStep={step}
        handleBack={handleBack}
        handleSkip={handleSkip}
      />
    </div>
  );
};

export default SavingTypes;
