import React, { useState } from "react";
import { Button } from "@mui/material";
import { Modal } from "../../../../../../../components/Modal";
import "./Modals.scss";

export const PayLoansWithSavings = ({
  isOpen,
  setOpenPayLoansWithSavings,
  setCurrentValue,
  onClickAction,
  currentValue,
  saving,
}) => {
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);

  const closeConfirmModal = () => {
    setConfirmModalIsOpen(false);
  };

  const closeModal = () => {
    setOpenPayLoansWithSavings(false);
    setCurrentValue("");
  };

  const handleClickPay = async () => {
    await onClickAction({ actionId: currentValue });
    closeModal();
    closeConfirmModal();
  };

  const handleContinue = () => {
    setConfirmModalIsOpen(true);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={closeModal}
        title="Pago de préstamo con ahorros"
      >
        <h3 style={{ textAlign: "center" }}>
          Usted tiene un total ahorrado de:{" "}
        </h3>

        <h2 style={{ textAlign: "center" }}>
          {saving.valueFormatted}
        </h2>

        <div className="buttons-container">
          <Button
            onClick={closeModal}
            variant="outlined"
            type="button"
            style={{ marginRight: "12px" }}
          >
            Cancelar
          </Button>
          <Button
            className="custom-button-success"
            color="primary"
            variant="contained"
            type="submit"
            onClick={handleContinue}
          >
            Proceder al pago
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={confirmModalIsOpen}
        handleClose={closeConfirmModal}
        title="Confirmación de pago."
      >
        <h4>
          La acción de pagar préstamos con tu ahorro usará el total del ahorro
          vencido y liquidará todos los préstamos que se puedan con la cantidad
          antes descrita tomando orden cronológico. Si tu ahorro vencido es
          mayor a la totalidad de tus préstamos activos, el restante se asignará
          al cajón inmediato.
        </h4>

        <h4>¿Desea continuar?</h4>

        <div className="buttons-container">
          <Button
            onClick={closeConfirmModal}
            variant="outlined"
            type="button"
            style={{ marginRight: "8px" }}
          >
            En otro momento
          </Button>
          <Button
            className="custom-button-success"
            color="success"
            variant="contained"
            type="submit"
            onClick={handleClickPay}
          >
            Pagar ahora
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default PayLoansWithSavings;
