import React, { useState } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export const LoanCard = ({ item }) => {
  const [showDetails, setShowDetails] = useState(false);
  const loanStatusChange = (loanStatus) => {
    switch (loanStatus) {
      case "Pendiente":
        return `toBeApproved`;

      case "Aprobado":
        return `approved`;

      case "Rechazado":
        return `rejected`;

      case "Liquidado":
        return `paid`;

      case "Dispersado":
        return "dispersado";

      case "Autorizado":
        return "autorizado";
    }
  };
  return (
    <div className={"PaymentRegisterContainer"}>
      <div className={"PaymentRegister"}>
        <div className={"status"}>
          <p className={"tableTitle"}>Estatus</p>
          {
            <div
              className={`statusLoad ${loanStatusChange(
                item.loanStatusFormatted
              )}`}
            >
              {item.loanStatusFormatted}
            </div>
          }
        </div>
        <div className={"amount"}>
          <p className={"tableTitle"}>Monto de préstamo</p>
          <p className={"amountLoad"}>{item.loanQuanityFormatted}</p>
        </div>
        <div className={"amount"}>
          <p className={"tableTitle"}>Plazo</p>
          <p className={"amountLoad"}>{item.periods}</p>
        </div>
        <div className={"amount"}>
          <p className={"tableTitle"}>Saldo por liquidar</p>
          <p className={"amountLoad"}>{item.loanQuanityToCloseFormatted}</p>
        </div>
        <div className={"amount"}>
          <p className={"tableTitle"}>Pago periodo</p>
          <p className={"amountLoad"}>{item.paymentPerPeriodFormatted}</p>
        </div>
        <div className={"headerTabPrintItem"}>
          {showDetails && (
            <ArrowDropUpIcon
              className={"arrow"}
              onClick={() => setShowDetails(false)}
            />
          )}
          {!showDetails && (
            <ArrowDropDownIcon
              className={"arrow"}
              onClick={() => setShowDetails(true)}
            />
          )}
        </div>
      </div>
      {showDetails && (
        <div>
          {/*this.createLoanTransaction(item.id)*/}
          <div className={"loadHistoryLine"}></div>
          <div className={"PaymentRegister"}>
            <div className={("status", "exped_date")}>
              <p className={"tableTitle"}>Fecha de expedición</p>
              <div className={"dateLoad"}>{item.creationDateFormatted}</div>
            </div>
            <div className={"amount"}>
              <p className={"tableTitle"}>Última aportación</p>
              <div className={"dateLoad"}>{item.lastPaymentDateFormatted}</div>
            </div>
            <div className={"date"}>
              <p className={"tableTitle"}>Pagos realizados</p>
              <div className={"dateLoad"}>{item.paymentsGiven}</div>
            </div>
          </div>
          <div className={"loadHistoryPayments"}>
            {item.loanStatus !== 1 && (
              <>
                <div className={"loadHistoryPaymentsHeader"}>
                  Desglose de pagos
                </div>
                <div className={"loadHistoryPaymentsTitles"}>
                  <div className={"loadHistoryPaymentsTitle"}>Periodo</div>
                  <div className={"loadHistoryPaymentsTitle"}>
                    Fecha de pago
                  </div>
                  <div className={"loadHistoryPaymentsTitle"}>Pago</div>
                  <div className={"loadHistoryPaymentsTitle"}>A capital</div>
                  <div className={"loadHistoryPaymentsTitle"}>Interés</div>
                  <div className={"loadHistoryPaymentsTitle"}>
                    Saldo por liquidar
                  </div>
                </div>
              </>
            )}

            {item.loanStatus === 1 && (
              <div className={"PaymentNoRegister"}>
                <p>Préstamo pendiente de aprobación</p>
              </div>
            )}
            {(item.loanStatus === 2 || item.loanStatus === 4 || item.loanStatus === 6) &&
              item.table &&
              item.table.map((loan) => (
                <div key={loan.id} className={"loadHistoryPaymentsBody"}>
                  <div className={"loadHistoryPaymentsItem"}>{loan.period}</div>
                  <div className={"loadHistoryPaymentsItem"}>
                    {loan.paymentDateFormatted}
                  </div>
                  <div className={"loadHistoryPaymentsItem"}>
                    {loan.paymentFormatted}
                  </div>
                  <div className={"loadHistoryPaymentsItem"}>
                    {loan.capitalFormatted}
                  </div>
                  <div className={"loadHistoryPaymentsItem"}>
                    {loan.interestFormatted}
                  </div>
                  <div className={"loadHistoryPaymentsItem"}>
                    {loan.remainingBalanceFormatted}
                  </div>
                </div>
              ))}
            {item.loanStatus === 3 && (
              <div className={"PaymentNoRegister"}>
                <p>Aún no has abonado a tu préstamo</p>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
