import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import userReducer from "./usersReducer";
import savigsReducer from "./savingsReduce";
import employeeReducer from "./employeeReducer";
import loansReducer from "./loansReducer";
import bossReducer from "./bossReducer";
import adminReducer from "./adminReducer";
import companyReducer from "./companyReducer";
import payrollAdvanceReducer from "./payrollAdvanceReducer";
import recoveryPasswordReducer from "./recoveryPasswordReducer";
import errorReducer from "./errorReducer";
import successReducer from "./successReducer";
import globalReducer from "./globalReducer";

const rootReducer = (history) =>
  combineReducers({
    global: globalReducer,
    router: connectRouter(history),
    error: errorReducer,
    success: successReducer,
    user: userReducer,
    savings: savigsReducer,
    // notification: notificationReducer,
    employee: employeeReducer,
    loans: loansReducer,
    boss: bossReducer,
    admin: adminReducer,
    company: companyReducer,
    advances: payrollAdvanceReducer,
    recovery: recoveryPasswordReducer,
  });

export default rootReducer;
