import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router";

import { resetPassword } from "../../actions/recoveryPasswordAction";
import { setSuccess } from "../../actions/successAction";

import { useForm } from "../../hooks/useForm";

import "./ResetPassword.scss";

export const ResetPassword = () => {
  const { tokenId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const initialForm = { password: "", confirmPassword: "" };
  const [formValues, handleInputChange] = useForm(initialForm);
  const { password, confirmPassword } = formValues;

  const [passwordError, setPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  const onSubmitForm = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setConfirmPasswordError(
        "Este campo debe coincidir con el que está encima."
      );
    } else {
      setConfirmPasswordError("");
      if (password.length < 5) {
        setPasswordError(
          "Asegúrese de que su contraseña tenga al menos 5 caracteres"
        );
      } else {
        setPasswordError("");
        dispatch(resetPassword(password, tokenId));
        await dispatch(setSuccess("Contraseña cambiada exitosamente"));
        setTimeout(() => {
          history.push("/");
        }, 1000);
      }
    }
  };

  return (
    <div className="reset-password flex flex-col">
      <img
        className="mb-10 w-50 rounded h-50"
        src="https://salvum-docs.s3.us-west-1.amazonaws.com/SalvumLogo.png"
      />
      <div className="reset-password__container">
        <h1>Por favor ingresa tu nueva contraseña</h1>
        <div className="reset-password__alert-info">
          <span>
            Por favor asegúrese de que la contraseña tenga por lo menos 5
            caracteres{" "}
          </span>
        </div>
        <form method="POST" onSubmit={onSubmitForm}>
          <div className="form_row">
            <div className="form__group">
              <span className="form__label">Contraseña</span>
              <input
                className="form_input"
                id="password"
                name="password"
                type="password"
                placeholder="5+ caracteres"
                autoComplete="off"
                onChange={handleInputChange}
                required
              />
              <span className="error_notification">{passwordError}</span>
            </div>
            <div className="form__group">
              <span className="form__label">Confirmar contraseña</span>
              <input
                className="form_input"
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                autoComplete="off"
                onChange={handleInputChange}
              />
              <span className="error_notification">{confirmPasswordError}</span>
            </div>
          </div>
          <div className="btn__container">
            <button className="btn-send">Establecer nueva contraseña</button>
          </div>
        </form>
      </div>
    </div>
  );
};
