import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { recoveryPassword } from "../../actions/recoveryPasswordAction";
import { useForm } from "../../hooks/useForm";

import "./RecoveryPassword.scss";

export const RecoveryPassword = ({ backLogin, changePassword }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.recovery.data || {});

  const initialForm = { email: "" };
  const [formValues, handleInputChange] = useForm(initialForm);
  const { email } = formValues;

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(recoveryPassword(email));
  };

  setTimeout(() => {
    // setOpen(!open);
    if (data.code === 200) {
      backLogin();
    }
  }, 5000);

  return (
    <div className="form">
      <form method="POST" className="form__login" onSubmit={handleSubmit}>
        <h2 className="form__title">
          {changePassword ? "Cambiar" : "Recuperar"} contraseña
        </h2>

        <div className="form__group recovery_email">
          <span className="form__label">Correo electrónico</span>
          <input
            className="input-info"
            id="email"
            name="email"
            type="text"
            placeholder="ejemplo@salvum.mx"
            autoComplete="off"
            onChange={handleInputChange}
          />
        </div>

        <button
          disabled={!email}
          className={!email ? "btn-disabled" : "login-inside"}
        >
          {changePassword ? "Cambiar" : "Recuperar"} contraseña
        </button>
        <button className="recovery-btn" type="button" onClick={backLogin}>
          Cancelar
        </button>
      </form>
    </div>
  );
};

export default RecoveryPassword;
