import React from "react";

import logo from "../../assets/images/general/logoSalvumWhite.svg";
import facebook from "../../assets/images/landingPage/facebook-icon.svg";
import twitter from "../../assets/images/landingPage/twitter-icon.svg";

import "./Footer.scss";

export const Footer = () => {
  return (
    <div className="footer-landing">
      <div className="footer-landing__container">
        <div className="logo">
          <img src={logo} style={{ maxWidth: "45px" }} alt="Logo" />
        </div>
        <div className="privacidad">
          <a href="https://salvum-docs.s3.us-west-1.amazonaws.com/AvisoPrivacidad.pdf" target="_blank" rel="noreferrer">
            Aviso de privacidad
          </a>
        </div>
        <div className="social-media">
          {/* <a href="http://twitter.com" target="_blank" rel="noreferrer">
            <img src={facebook} alt="Logo" />
          </a>
          <a href="http://twitter.com" target="_blank" rel="noreferrer">
            <img src={twitter} alt="Logo" />
          </a> */}
        </div>
        <div className="copy">
          <span>@Salvum 2023 Todos los derechos reservados</span>
        </div>
      </div>
      {/* <div>

      </div> */}
    </div>
  );
};
