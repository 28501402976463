import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import logo from "../../assets/images/general/logoSalvumWhite.svg";
import piggybank from "../../assets/images/landingPage/piggybank.svg";
import ahorros from "../../assets/images/landingPage/landing-horros.png";
import shape1 from "../../assets/images/landingPage/shape1.svg";
import shape2 from "../../assets/images/landingPage/shape2.svg";

import integracionIcon from "../../assets/images/landingPage/integracion_icon.svg";
import ahorrosIcon from "../../assets/images/landingPage/ahorros_icon.svg";
import impulsoIcon from "../../assets/images/landingPage/impulso_icon.svg";
import multiplesIcon from "../../assets/images/landingPage/multiples_icon.svg";
import prestamosIcon from "../../assets/images/landingPage/prestamos_icon.svg";
import tasasIcon from "../../assets/images/landingPage/tasas_icon.svg";

import { LoginForm } from "../../components/LoginForm";
import RecoveryPassword from "../../components/RecoveryPassword";

import "./LandingPage.scss";
import { ContactForm } from "../../components/ContactForm";
import { Footer } from "../../components/Footer";

export const LandingPage = (props) => {
  const user = useSelector((state) => state.user || {});
  const [isRecoveryPassword, setRecoveryPassword] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState("inicio");

  const login = () => {
    if (user.isAuthenticated) {
      return props.history.push(`/${user.role}`);
    }
  };

  useEffect(() => {
    login();
  }, [login]);

  const handleClickHome = () => {
    window.scrollTo(0, 0);
    setActiveMenuItem("inicio");
  };

  const handleClickSavings = () => {
    setActiveMenuItem("ahorros");
  };

  const handleClickContact = () => {
    setActiveMenuItem("contacto");
  };

  return (
    <div>
      <header style={{ position: "fixed", zIndex: 100 }} className="header">
        <nav className="nav">
          <img src={logo} alt="Logo" />
          <div className="nav__links">
            <a
              className={activeMenuItem === "inicio" ? "active" : ""}
              onClick={handleClickHome}
            >
              Inicio
            </a>
            <a
              className={activeMenuItem === "ahorros" ? "active" : ""}
              onClick={handleClickSavings}
              href="#ahorros"
            >
              Caja Salvum
            </a>
            <a
              className={activeMenuItem === "contacto" ? "active" : ""}
              onClick={handleClickContact}
              href="#contacto"
            >
              Contáctanos
            </a>
          </div>
        </nav>
      </header>
      <main className="mt-20">
        <section id="inicio" className="main">
          <div className="main__slide">
            <div className="hero-landing">
              <h1>Ahorros</h1>
              <p>Inteligentes - Seguros - A la mano</p>
              <img src={piggybank} alt="hero salvum" />
            </div>
            {isRecoveryPassword ? (
              <RecoveryPassword
                backLogin={() => setRecoveryPassword(!isRecoveryPassword)}
              />
            ) : (
              <LoginForm setRecoveryPassword={setRecoveryPassword} />
            )}
          </div>
        </section>
        <section id="ahorros" className="ahorros">
          <div className="ahorros__shape1">
            <img src={shape1} alt="shape1" />
          </div>
          <div className="ahorros__container">
            <h1>Cuidamos y potenciamos el patrimonio de tus empleados</h1>
            <img src={ahorros} alt="Ahorros landing page" />
          </div>
          <div className="ahorros__shape2">
            <img src={shape2} alt="shape2" />
          </div>
        </section>
        <p className="ahorros-subtitle">
          Vanguardistas en mejorar la situación financiera de tus empleados a
          través de un vehículo diseñado para que el ahorro sea parte de su día
          a día
        </p>
        <section className="beneficios">
          <div className="beneficios__container">
            <div className="item">
              <div className="card integracion">
                <div className="icon">
                  <img src={integracionIcon} alt="icono integracion" />
                </div>
                <h2>Integración</h2>
                <p>Sin problemas ni riesgos para tu empresa</p>
              </div>
            </div>
            <div className="item">
              <div className="card tasas">
                <div className="icon">
                  <img src={tasasIcon} alt="icono tasas" />
                </div>
                <h2>Tasas</h2>
                <p>De rendimiento competitivas</p>
              </div>
            </div>
            <div className="item">
              <div className="card prestamos">
                <div className="icon">
                  <img src={prestamosIcon} alt="icono prestamos" />
                </div>
                <h2>Préstamos</h2>
                <p>Al menor costo</p>
              </div>
            </div>
            <div className="item">
              <div className="card multiples">
                <div className="icon">
                  <img src={multiplesIcon} alt="icono multiples" />
                </div>
                <h2>Multiples</h2>
                <p>Maneras de ahorrar</p>
              </div>
            </div>
            <div className="item">
              <div className="card impulso">
                <div className="icon">
                  <img src={impulsoIcon} alt="icono impulso" />
                </div>
                <h2>Impulso</h2>
                <p>En productividad y satisfacción laboral</p>
              </div>
            </div>
            <div className="item">
              <div className="card ahorros-border">
                <div className="icon">
                  <img src={ahorrosIcon} alt="icono impulso" />
                </div>
                <h2>Ahorros</h2>
                <p>Flexibles y autogestionables</p>
              </div>
            </div>
          </div>
        </section>
        <section className="known">
          <div className="known__container">
            <div className="title">
              <h1>SABÍAS QUE... </h1>
            </div>
            <div className="row__line">
              <div className="box1" />
              <div className="box2" />
            </div>
            <div className="row__text">
              <div className="empty" />
              <div className="number">1</div>
              <div className="subtitle">
                <h2>Casi nadie ahorra</h2>
                <p>
                  Solo <strong>la mitad de la población</strong> lo hace.
                </p>
              </div>
            </div>
            <div className="row__line">
              <div className="box1" />
              <div className="box2" />
            </div>
            <div className="row__text">
              <div className="empty" />
              <div className="number">2</div>
              <div className="subtitle">
                <h2>Enfermar por estrés</h2>
                <p>
                  Una de las principales causas de enfermedades es el{" "}
                  <strong>estrés financiero.</strong>{" "}
                </p>
              </div>
            </div>
            <div className="row__line">
              <div className="box1" />
              <div className="box2" />
            </div>
            <div className="row__text">
              <div className="empty" />
              <div className="number">3</div>
              <div className="subtitle">
                <h2>Deudas altas e informales </h2>
                <p>
                  La mayor parte de la población tiene{" "}
                  <strong>deudas informales</strong> a tasas demasiado altas.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="known__message">
            <p>
              <strong>SALVUM</strong> Te proporciona las mejores herramientas
              digitales para que ayudes a que tus empleados a conseguir sus
              objetivos financieros.
            </p>
          </div>
          <di id="contacto" />
        </section>

        <section>
          <ContactForm />
        </section>
        <Footer />
      </main>
    </div>
  );
};
