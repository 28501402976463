import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = () => {
  return (
    <div>
      <h4>
        Por el momento no podemos recargar la plataforma, por favor inicia
        sesion de nuevo
      </h4>
      <Link to="/"> Regresar </Link>
    </div>
  );
};

export default NotFoundPage;
