import {
  GET_LOANS_BY_EMPLOYEE_ID_BEGIN,
  GET_LOANS_BY_EMPLOYEE_ID_SUCCESS,
  GET_LOANS_BY_EMPLOYEE_ID_FAILURE,
  CREATE_LOANS_BEGIN,
  CREATE_LOANS_SUCCESS,
  CREATE_LOANS_FAILURE,
  CLEAR_CREATED_LOAN,
  GET_LOANS_BY_BOSS_AND_STATUS_ID_BEGIN,
  GET_LOANS_BY_BOSS_AND_STATUS_ID_SUCCESS,
  GET_LOANS_BY_BOSS_AND_STATUS_ID_FAILURE,
  GET_LOANS_BY_BOSS_ID_BEGIN,
  GET_LOANS_BY_BOSS_ID_SUCCESS,
  GET_LOANS_BY_BOSS_ID_FAILURE,
  GET_LOANS_RESUME_BY_BOSS_ID_BEGIN,
  GET_LOANS_RESUME_BY_BOSS_ID_SUCCESS,
  GET_LOANS_RESUME_BY_BOSS_ID_FAILURE,
  GET_LOAN_TRANSACTIONS_BY_EMPLOYEE_ID_BEGIN,
  GET_LOAN_TRANSACTIONS_BY_EMPLOYEE_ID_SUCCESS,
  GET_LOAN_TRANSACTIONS_BY_EMPLOYEE_ID_FAILURE,
  GET_LOAN_TRANSACTIONS_BY_COMPANY_ID_BEGIN,
  GET_LOAN_TRANSACTIONS_BY_COMPANY_ID_SUCCESS,
  GET_LOAN_TRANSACTIONS_BY_COMPANY_ID_FAILURE,
  UPDATE_LOAN_STATUS_ID_BEGIN,
  UPDATE_LOAN_STATUS_ID_SUCCESS,
  UPDATE_LOAN_STATUS_ID_FAILURE,
  USER_LOGOUT_SUCCESS,
  GET_LOANS_BY_EMPLOYEE_ID_AND_COMPANY_ID_BEGIN,
  GET_LOANS_BY_EMPLOYEE_ID_AND_COMPANY_ID_SUCCESS,
  GET_LOANS_BY_EMPLOYEE_ID_AND_COMPANY_ID_FAILURE,
  CALCULATE_AMORTIZATION_TABLE_BEGIN,
  CALCULATE_AMORTIZATION_TABLE_SUCCESS,
  CALCULATE_AMORTIZATION_TABLE_FAILURE,
  GET_LOAN_GENERAL_DETAILS_BEGIN,
  GET_LOAN_GENERAL_DETAILS_SUCCESSFUL,
  GET_LOAN_GENERAL_DETAILS_FAILURE,
  GET_LOANS_GENERAL_ADMIN_RESPONSE_BEGIN,
  GET_LOANS_GENERAL_ADMIN_RESPONSE_SUCCESS,
  GET_LOANS_GENERAL_ADMIN_RESPONSE_FAILURE,
  GET_PENDING_LOANS_FOR_ADMIN_SUCCESS,
  GET_PENDING_LOANS_FOR_ADMIN_FAILURE,
} from "../constants/actionTypes";

import initialState from "./initialState";

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function loansReducer(state = initialState.loans, action) {
  switch (action.type) {
    case GET_LOANS_BY_EMPLOYEE_ID_BEGIN:
      return state;
    case GET_LOANS_BY_EMPLOYEE_ID_SUCCESS:
      return {
        ...state,
        loans: action.loans,
      };
    case GET_LOANS_BY_EMPLOYEE_ID_FAILURE:
      return state;
    case CREATE_LOANS_BEGIN:
      return state;
    case CREATE_LOANS_SUCCESS: {
      const newLoan = JSON.parse(JSON.stringify(state.newLoan));
      newLoan.created = true;
      return {
        ...state,
        newLoan,
      };
    }
    case CREATE_LOANS_FAILURE:
      return state;
    case CLEAR_CREATED_LOAN: {
      const newLoan = JSON.parse(JSON.stringify(state.newLoan));
      newLoan.created = false;
      return {
        ...state,
        newLoan,
      };
    }
    case GET_LOANS_BY_BOSS_AND_STATUS_ID_BEGIN:
      return state;
    case GET_LOANS_BY_BOSS_AND_STATUS_ID_SUCCESS:
      return {
        ...state,
        loansByStatus: action.loansByStatus,
      };
    case GET_LOANS_BY_BOSS_AND_STATUS_ID_FAILURE:
      return state;
    case GET_LOANS_BY_BOSS_ID_BEGIN:
      return state;
    case GET_LOANS_BY_BOSS_ID_SUCCESS:
      return {
        ...state,
        loans: action.loans,
      };
    case GET_LOANS_BY_BOSS_ID_FAILURE:
      return state;
    case GET_LOANS_RESUME_BY_BOSS_ID_BEGIN:
      return state;
    case GET_LOANS_RESUME_BY_BOSS_ID_SUCCESS:
      return {
        ...state,
        loansResume: action.loansResume,
      };
    case GET_LOANS_RESUME_BY_BOSS_ID_FAILURE:
      return state;
    case UPDATE_LOAN_STATUS_ID_BEGIN:
      return state;
    case UPDATE_LOAN_STATUS_ID_SUCCESS:
      return state;
    case UPDATE_LOAN_STATUS_ID_FAILURE:
      return state;
    case GET_LOAN_TRANSACTIONS_BY_EMPLOYEE_ID_BEGIN:
      return state;
    case GET_LOAN_TRANSACTIONS_BY_EMPLOYEE_ID_SUCCESS:
      return {
        ...state,
        loanTransactions: action.loanTransactions,
      };
    case GET_LOAN_TRANSACTIONS_BY_EMPLOYEE_ID_FAILURE:
      return state;
    case GET_LOAN_TRANSACTIONS_BY_COMPANY_ID_BEGIN:
      return state;
    case GET_LOAN_TRANSACTIONS_BY_COMPANY_ID_SUCCESS:
      return {
        ...state,
        loanTransactions: action.loanTransactions,
      };
    case GET_LOAN_TRANSACTIONS_BY_COMPANY_ID_FAILURE:
      return state;
    case USER_LOGOUT_SUCCESS:
      return {
        ...initialState.loans,
      };
    case GET_LOANS_BY_EMPLOYEE_ID_AND_COMPANY_ID_BEGIN:
      return {
        ...state,
        loading: true,
        loansByEmployeeAndCompany: [],
      };
    case GET_LOANS_BY_EMPLOYEE_ID_AND_COMPANY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_LOANS_BY_EMPLOYEE_ID_AND_COMPANY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        loansByEmployeeAndCompany: action.payload,
      };

    case CALCULATE_AMORTIZATION_TABLE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case CALCULATE_AMORTIZATION_TABLE_SUCCESS:
      return {
        ...state,
        loading: false,
        amortizationTableInfo: action.payload,
      };

    case CALCULATE_AMORTIZATION_TABLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_LOAN_GENERAL_DETAILS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_LOAN_GENERAL_DETAILS_SUCCESSFUL:
      return {
        ...state,
        loanGeneralDetails: action.loanGeneralDetails,
        loading: false,
      };
    case GET_LOAN_GENERAL_DETAILS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case GET_LOANS_GENERAL_ADMIN_RESPONSE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_LOANS_GENERAL_ADMIN_RESPONSE_SUCCESS:
      return {
        ...state,
        loansGeneralAdminResponse: action.loansGeneralAdminResponse,
        loading: false,
        error: false,
      };
    case GET_LOANS_GENERAL_ADMIN_RESPONSE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case GET_PENDING_LOANS_FOR_ADMIN_SUCCESS:
      return {
        ...state,
        pendingLoansAdmin: action.pendingLoansAdmin,
        error: false,
        loading: false,
      };
    case GET_PENDING_LOANS_FOR_ADMIN_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
