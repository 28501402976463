import axios from "axios";
import { apiBaseUrlAws } from "./consts";

import {
  RECOVERY_PASSWORD_LOADING,
  RECOVERY_PASSWORD_SUCCESS,
  RECOVERY_PASSWORD_FAILURE,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from "../constants/actionTypes";
import { setError } from "./errorAction";
import { setSuccess } from "./successAction";

export const recoveryPasswordLoading = () => ({
  type: RECOVERY_PASSWORD_LOADING,
});

export const recoveryPasswordSuccess = (user) => ({
  type: RECOVERY_PASSWORD_SUCCESS,
  user,
});

export const recoveryPasswordFailure = (error) => ({
  type: RECOVERY_PASSWORD_FAILURE,
  error,
});

export const resetPasswordLoading = () => ({
  type: RESET_PASSWORD_LOADING,
});

export const resetPasswordSuccess = (user) => ({
  type: RESET_PASSWORD_SUCCESS,
  user,
});

export const resetPasswordFailure = (error) => ({
  type: RESET_PASSWORD_FAILURE,
  error,
});

export function recoveryPassword(email) {
  return async (dispatch) => {
    try {
      dispatch(recoveryPasswordLoading());

      const response = await axios.get(
        `${apiBaseUrlAws}/Salvum/services/users/requestPasswordChange?userMail=${email}`
      );

      if (response.data.code === 200 && response.data.updated) {
        await dispatch(recoveryPasswordSuccess(response.data));
        await dispatch(
          setSuccess(
            response.data.message ||
              "Hemos enviado un email a tu correo electronico."
          )
        );
      } else {
        dispatch(
          setError(
            response.data.message ||
              "Ha ocurrido un error al intentar cambiar tu contraseña"
          )
        );
      }
    } catch (error) {
      let message;
      if (error.response) {
        message = error.response.data.message || error.message;
      } else {
        message = error.message;
      }
      dispatch(setError(message));
      dispatch(recoveryPasswordFailure(error));
    }
  };
}

export const resetPassword = (newPassword, token) => {
  return async (dispatch) => {
    try {
      dispatch(resetPasswordLoading());

      const response = await axios.get(
        `${apiBaseUrlAws}/Salvum/services/users/resetPassword?newPassword=${newPassword}&resetPasswordToken=${token}`
      );

      if (response.data.code === 200 && response.data.updated) {
        await dispatch(resetPasswordSuccess(response.data));
        await dispatch(setSuccess(response.data.message));
      } else {
        dispatch(
          setError(
            response.data.message ||
              "Ha ocurrido un error al intentar cambiar tu contraseña"
          )
        );
      }
    } catch (error) {
      dispatch(resetPasswordSuccess(error.response.data));
      dispatch(resetPasswordFailure(error));
    }
  };
};

export function cleanAlertMessages() {
  return (dispatch) => {
    dispatch(recoveryPasswordSuccess({}));
  };
}
