import React, { Component } from "react";
import { connect } from "react-redux";

import { NavLink, Redirect, withRouter } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import logo from "../../../../assets/images/general/logoSalvumWhite.svg";

import { routes } from "../../../../Routers";

import PropTypes from "prop-types";

// import {userLogout} from '../../../../actions/userActions';

// import { useDispatch } from 'react-redux'

import "./AdminAppBar.scss";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { userLogout } from "../../../../actions/userActions";

export class AdminAppBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
    };

    this.handleMenu = this.handleMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.redirectStart = this.redirectStart.bind(this);
  }

  handleMenu(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  redirectStart() {
    localStorage.removeItem("userSession");
    this.props.dispatch(userLogout());
    this.props.history.push("/");
  }

  render() {
    const auth = true;

    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
          }}
        />
      );
    }

    return (
      <>
        <AppBar position="fixed" className="appBar2">
          <Toolbar>
            <IconButton onClick={this.redirectStart} edge="start">
              <img alt="logo" src={logo} />
            </IconButton>

            <div className="linkContainer">
              {routes({ role: this.props.user.role }).map((r, index) => {
                return (
                  <NavLink
                    to={`/${r.role}/${r.path}`}
                    className="link"
                    activeClassName="linkActive"
                    key={index}
                    exact
                  >
                    {r.menuTitle || r.title}
                  </NavLink>
                );
              })}
            </div>

            {auth && (
              <div>
                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <span className="profileName">
                    {this.props.admin.fullName}
                  </span>
                  <ArrowDropDownIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.redirectStart}>
                    Terminar sesión
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </>
    );
  }
}

AdminAppBar.propTypes = {
  history: PropTypes.any,
  dispatch: PropTypes.any,
  user: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    ...state,
    user: state.user,
    admin: state.user.user,
  };
}

export default withRouter(connect(mapStateToProps)(AdminAppBar));
