import request from "../utils/request";
import loanServices from "../services/loanServices";
import { api } from "../services/api";

import {
  GET_LOANS_BY_EMPLOYEE_ID_BEGIN,
  GET_LOANS_BY_EMPLOYEE_ID_SUCCESS,
  GET_LOANS_BY_EMPLOYEE_ID_FAILURE,
  CREATE_LOANS_BEGIN,
  CREATE_LOANS_SUCCESS,
  CREATE_LOANS_FAILURE,
  CLEAR_CREATED_LOAN,
  GET_LOANS_BY_BOSS_AND_STATUS_ID_BEGIN,
  GET_LOANS_BY_BOSS_AND_STATUS_ID_SUCCESS,
  GET_LOANS_BY_BOSS_AND_STATUS_ID_FAILURE,
  GET_LOANS_BY_BOSS_ID_BEGIN,
  GET_LOANS_BY_BOSS_ID_SUCCESS,
  GET_LOANS_BY_BOSS_ID_FAILURE,
  GET_LOANS_RESUME_BY_BOSS_ID_BEGIN,
  GET_LOANS_RESUME_BY_BOSS_ID_SUCCESS,
  GET_LOANS_RESUME_BY_BOSS_ID_FAILURE,
  GET_LOAN_TRANSACTIONS_BY_COMPANY_ID_BEGIN,
  GET_LOAN_TRANSACTIONS_BY_COMPANY_ID_SUCCESS,
  GET_LOAN_TRANSACTIONS_BY_COMPANY_ID_FAILURE,
  UPDATE_LOAN_STATUS_ID_BEGIN,
  UPDATE_LOAN_STATUS_ID_SUCCESS,
  UPDATE_LOAN_STATUS_ID_FAILURE,
  GET_LOANS_BY_EMPLOYEE_ID_AND_COMPANY_ID_BEGIN,
  GET_LOANS_BY_EMPLOYEE_ID_AND_COMPANY_ID_SUCCESS,
  GET_LOANS_BY_EMPLOYEE_ID_AND_COMPANY_ID_FAILURE,
  CALCULATE_AMORTIZATION_TABLE_BEGIN,
  CALCULATE_AMORTIZATION_TABLE_SUCCESS,
  CALCULATE_AMORTIZATION_TABLE_FAILURE,
  GET_LOAN_GENERAL_DETAILS_BEGIN,
  GET_LOAN_GENERAL_DETAILS_SUCCESSFUL,
  GET_LOAN_GENERAL_DETAILS_FAILURE,
  GET_LOANS_GENERAL_ADMIN_RESPONSE_BEGIN,
  GET_LOANS_GENERAL_ADMIN_RESPONSE_SUCCESS,
  GET_LOANS_GENERAL_ADMIN_RESPONSE_FAILURE,
  GET_PENDING_LOANS_FOR_ADMIN_SUCCESS,
  GET_PENDING_LOANS_FOR_ADMIN_FAILURE,
} from "../constants/actionTypes";
import { setError } from "./errorAction";

export const getLoansByEmployeeIdBegin = () => ({
  type: GET_LOANS_BY_EMPLOYEE_ID_BEGIN,
});

export const getLoansByEmployeeIdSuccess = (loans) => ({
  type: GET_LOANS_BY_EMPLOYEE_ID_SUCCESS,
  loans,
});

export const getLoansByEmployeeIdFailure = (error) => ({
  type: GET_LOANS_BY_EMPLOYEE_ID_FAILURE,
  error,
});

/// Transactions Company
export const getLoanTransactionsByCompanyIdBegin = () => ({
  type: GET_LOAN_TRANSACTIONS_BY_COMPANY_ID_BEGIN,
});

export const getLoanTransactionsByCompanyIdSuccess = (loanTransactions) => ({
  type: GET_LOAN_TRANSACTIONS_BY_COMPANY_ID_SUCCESS,
  loanTransactions,
});

export const getLoanTransactionsByCompanyIdFailure = (error) => ({
  type: GET_LOAN_TRANSACTIONS_BY_COMPANY_ID_FAILURE,
  error,
});

export const createLoansBegin = () => ({
  type: CREATE_LOANS_BEGIN,
});

export const createLoansSuccess = () => ({
  type: CREATE_LOANS_SUCCESS,
});

export const createLoansFailure = () => ({
  type: CREATE_LOANS_FAILURE,
});

export const clearCreatedLaon = () => ({
  type: CLEAR_CREATED_LOAN,
});

export const getLoansByBossAndStatusIdBegin = () => ({
  type: GET_LOANS_BY_BOSS_AND_STATUS_ID_BEGIN,
});

export const getLoansByBossAndStatusIdSuccess = (loansByStatus) => ({
  type: GET_LOANS_BY_BOSS_AND_STATUS_ID_SUCCESS,
  loansByStatus,
});

export const getLoansByBossAndStatusIdFailure = () => ({
  type: GET_LOANS_BY_BOSS_AND_STATUS_ID_FAILURE,
});

export const getLoansByBossIdBegin = () => ({
  type: GET_LOANS_BY_BOSS_ID_BEGIN,
});

export const getLoansByBossIdSuccess = (loans) => ({
  type: GET_LOANS_BY_BOSS_ID_SUCCESS,
  loans,
});

export const getLoansByBossIdFailure = () => ({
  type: GET_LOANS_BY_BOSS_ID_FAILURE,
});

export const getLoansResumeByBossIdBegin = () => ({
  type: GET_LOANS_RESUME_BY_BOSS_ID_BEGIN,
});

export const getLoansResumeByBossIdSuccess = (loansResume) => ({
  type: GET_LOANS_RESUME_BY_BOSS_ID_SUCCESS,
  loansResume,
});

export const getLoansResumeByBossIdFailure = () => ({
  type: GET_LOANS_RESUME_BY_BOSS_ID_FAILURE,
});

export const updateLoanStatusIdBegin = () => ({
  type: UPDATE_LOAN_STATUS_ID_BEGIN,
});

export const updateLoanStatusIdSuccess = (loanStatus) => ({
  type: UPDATE_LOAN_STATUS_ID_SUCCESS,
  loanStatus,
});

export const updateLoanStatusIdFailure = () => ({
  type: UPDATE_LOAN_STATUS_ID_FAILURE,
});

export const getLoanGeneralDetailsBegin = () => ({
  type: GET_LOAN_GENERAL_DETAILS_BEGIN,
});

export const getLoanGeneralDetailsSuccessful = (details) => ({
  type: GET_LOAN_GENERAL_DETAILS_SUCCESSFUL,
  loanGeneralDetails: details,
});

export const getLoanGeneralDetailsFailure = (error) => ({
  type: GET_LOAN_GENERAL_DETAILS_FAILURE,
  error: error,
});

export const getLoansGeneralAdminResponseBegin = () => ({
  type: GET_LOANS_GENERAL_ADMIN_RESPONSE_BEGIN,
});

export const getLoansGeneralAdminResponseSuccess = (
  loansGeneralAdminResponse
) => ({
  type: GET_LOANS_GENERAL_ADMIN_RESPONSE_SUCCESS,
  loansGeneralAdminResponse,
});

export const getLoansGeneralAdminResponseFailure = (error) => ({
  type: GET_LOANS_GENERAL_ADMIN_RESPONSE_FAILURE,
  error,
});

export const getPendingLoansForAdminSuccess = (pendingLoansAdmin) => ({
  type: GET_PENDING_LOANS_FOR_ADMIN_SUCCESS,
  pendingLoansAdmin,
});

export const getPendingLoansForAdminFailure = (error) => ({
  type: GET_PENDING_LOANS_FOR_ADMIN_FAILURE,
  error,
});

///
export function getLoansByemployeeId({ employeeId, companyId }) {
  return async (dispatch) => {
    try {
      dispatch(getLoansByEmployeeIdBegin());
      const response = await api.get(
        `Salvum/services/loans/getLoanHistory?employeeId=${employeeId}&companyId=${companyId}`
      );
      if (response.error) {
        dispatch(setError(response.message));
      }
      dispatch(getLoansByEmployeeIdSuccess(response.data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getLoansByEmployeeIdFailure(error));
    }
  };
}

export function getLoanTransactionsByCompanyId(companyId) {
  return (dispatch, getState) => {
    dispatch(getLoanTransactionsByCompanyIdBegin());
    return request({
      partialUrl: `/api/v1/loans/find-loan-transactions-by-company-id/${companyId}`,
      method: "GET",
      state: getState().user.request,
      body: {
        companyId,
      },
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(getLoanTransactionsByCompanyIdSuccess(data.data));
      })
      .catch((err) => {
        console.error(err);
        return dispatch(getLoanTransactionsByCompanyIdFailure(err));
      });
  };
}

export function createLoans(loan) {
  return (dispatch, getState) => {
    dispatch(createLoansBegin());
    return request({
      partialUrl: "/api/v1/loans/create-loan",
      method: "POST",
      state: getState().user.request,
      body: loan,
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(createLoansSuccess(data.data));
      })
      .catch((err) => {
        console.error(err);
        return dispatch(createLoansFailure(err));
      });
  };
}

export function getLoansByBossAndStatusId(companyId, loanStatusId) {
  return (dispatch, getState) => {
    dispatch(getLoansByBossAndStatusIdBegin());
    return request({
      partialUrl: `/api/v1/loans/find-loans-by-boss/${companyId}/and-status-id/${loanStatusId}`,
      method: "GET",
      state: getState().user.request,
      body: {
        companyId,
        loanStatusId,
      },
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(getLoansByBossAndStatusIdSuccess(data.data));
      })
      .catch((err) => {
        return dispatch(getLoansByBossAndStatusIdFailure(err));
      });
  };
}

export function getLoansByBossId(companyId) {
  return (dispatch, getState) => {
    dispatch(getLoansByBossIdBegin());
    return request({
      partialUrl: `/api/v1/loans/find-loan-by-boss-id/${companyId}`,
      method: "GET",
      state: getState().user.request,
      body: {
        companyId,
      },
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(getLoansByBossIdSuccess(data.data));
      })
      .catch((err) => {
        return dispatch(getLoansByBossIdFailure(err));
      });
  };
}

export function getLoansResumeByBossId(companyId) {
  return (dispatch, getState) => {
    dispatch(getLoansResumeByBossIdBegin());
    return request({
      partialUrl: `/api/v1/loans/generate-loans-resumen-by-boss-id/${companyId}`,
      method: "GET",
      state: getState().user.request,
      body: {
        companyId,
      },
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(getLoansResumeByBossIdSuccess(data.data));
      })
      .catch((err) => {
        console.error(err);
        return dispatch(getLoansResumeByBossIdFailure(err));
      });
  };
}

export function updateLoanStatusId(id, loanStatusId) {
  return (dispatch, getState) => {
    dispatch(updateLoanStatusIdBegin());
    return request({
      partialUrl: `/api/v1/loans/change-loan-status/${id}/${loanStatusId}`,
      method: "PUT",
      state: getState().user.request,
      body: {
        id,
        loanStatusId,
      },
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(updateLoanStatusIdSuccess(data.data));
      })
      .catch((err) => {
        console.error(err);
        return dispatch(updateLoanStatusIdFailure(err));
      });
  };
}

export const getInfoLoanByemployeeIdAndCompanyId = (employeeId, companyId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_LOANS_BY_EMPLOYEE_ID_AND_COMPANY_ID_BEGIN });

      const res = await loanServices.getLoanInfoByEmployee(
        employeeId,
        companyId
      );
      const data = res.data;

      dispatch({
        type: GET_LOANS_BY_EMPLOYEE_ID_AND_COMPANY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch({
        type: GET_LOANS_BY_EMPLOYEE_ID_AND_COMPANY_ID_FAILURE,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
};

export const getAmortizationTable = (
  creditAmount,
  creditPeriods,
  employeeId,
  companyId
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CALCULATE_AMORTIZATION_TABLE_BEGIN });

      const res = await loanServices.getCalculatedAmortizationTable(
        creditAmount,
        creditPeriods,
        employeeId,
        companyId
      );
      const data = res.data;

      dispatch({
        type: CALCULATE_AMORTIZATION_TABLE_SUCCESS,
        payload: data,
      });
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch({
        type: CALCULATE_AMORTIZATION_TABLE_FAILURE,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
};

export const getLoanGeneralDetails = ({ employeeId, companyId }) => {
  return async (dispatch) => {
    try {
      dispatch(getLoanGeneralDetailsBegin());
      const response = await api.get(
        `Salvum/services/loans/getLoanGeneralDetail?employeeId=${employeeId}&companyId=${companyId}`
      );

      if (response.error) {
        dispatch(setError(response.message));
      }

      dispatch(getLoanGeneralDetailsSuccessful(response.data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getLoanGeneralDetailsFailure(error));
    }
  };
};

export const getLoanGeneralAdminResponse = () => {
  return async (dispatch) => {
    try {
      dispatch(getLoansGeneralAdminResponseBegin());
      const response = await api.get(
        "Salvum/services/loans/getLoansGeneralAdminResponse"
      );

      if (response.error) {
        dispatch(setError(response.message));
      }
      dispatch(getLoansGeneralAdminResponseSuccess(response.data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getLoansGeneralAdminResponseFailure(error));
    }
  };
};

export const getPendingLoansForAdmin = ({ companyId, adminUserId }) => {
  return async (dispatch) => {
    try {
      const response = await api.get(
        `Salvum/services/loans/getPendingLoansForAdmin?companyId=${companyId}&adminUserId=${adminUserId}`
      );

      if (response.error) {
        dispatch(setError(response.message));
      }
      dispatch(getPendingLoansForAdminSuccess(response.data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getPendingLoansForAdminFailure(error));
    }
  };
};
