import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export const CustomizedTable = ({ rows = [] }) => {
  if (rows.length === 0) {
    return null;
  }
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Nombre</StyledTableCell>
            <StyledTableCell align="right">Apellidos</StyledTableCell>
            <StyledTableCell align="right">Nómina</StyledTableCell>
            <StyledTableCell align="right">
              Numero de tarjeta (16 digits)
            </StyledTableCell>
            <StyledTableCell align="right">CLABE</StyledTableCell>
            <StyledTableCell align="right">CURP</StyledTableCell>
            <StyledTableCell align="right">Salario neto</StyledTableCell>
            <StyledTableCell align="right">RFC</StyledTableCell>
            <StyledTableCell align="right">SSN</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row">
                {row.firstname}
              </StyledTableCell>
              <StyledTableCell align="right">{row.lastname}</StyledTableCell>
              <StyledTableCell align="right">{row.payroll}</StyledTableCell>
              <StyledTableCell align="right">
                {row.bankdatacardnumber}
              </StyledTableCell>
              <StyledTableCell align="right">
                {row.bankdataclabe}
              </StyledTableCell>
              <StyledTableCell align="right">{row.curp}</StyledTableCell>
              <StyledTableCell align="right">{row.netpay}</StyledTableCell>
              <StyledTableCell align="right">{row.rfc}</StyledTableCell>
              <StyledTableCell align="right">{row.ssn}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomizedTable;
