import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSavingsSlots } from "../../../../actions/savingsAction";
import { SavingsPercentagesUsed } from "./Components/SavingsPercentagesUsed";
import { SavingsDetails } from "./Components/SavingDetails/SavingsDetails";
// import { Beneficiaries } from './Components/Beneficiaries';
// import { BeneficiariesContent } from './Components/BeneficiariesContent';
import "./SavingResume.scss";
import { LoadingCircularSpinner } from "../../../../components/LoadingCircularSpinner";

export const SavingsResumev2 = () => {
  // state values
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  // stores
  const savings = useSelector((state) => state.savings.savings);
  const employee = useSelector((state) => state.employee.current);

  useEffect(() => {
    if (savings && employee) {
      dispatch(
        getSavingsSlots({
          employeeId: employee?.userid,
          companyId: employee?.companyid,
        })
      );
    }
  }, [savings, employee]);

  return (
    <div className="printContent">
      <LoadingCircularSpinner show={isLoading} />

      <>
        <div className="saving-resume-blue">
          <div className="maxContHer">
            <ul className="breadcrumbs">
              <li>Inicio</li>
              <li>Ahorros</li>
            </ul>
            <div className="savings-hero">
              <div className="content">
                <div className="content-title">Ahorros</div>
                <div className="content-subTitle">Resumen de tus ahorros</div>
              </div>
            </div>
          </div>
        </div>

        <SavingsPercentagesUsed savings={savings} employee={employee} />

        <div className="savings-container-secondPart">
          <SavingsDetails employee={employee} setIsLoading={setIsLoading} />

          {/* <Beneficiaries
            /> */}
        </div>
      </>
      {/* 
        <BeneficiariesContent
          addBeneficiary={addBeneficiary}
          toggleShowBeneficiaries={toggleShowBeneficiaries}
          onChangeBeneficiary={onChangeBeneficiary}
          removeBeneficiary={removeBeneficiary}
          beneficiaries={beneficiariesStateValue}
        /> */}
    </div>
  );
};

export default SavingsResumev2;
