import React, { useEffect, useState } from "react";

// Actions
import { getBossCompanySummary } from "../../../actions/bossAction";
import { getCompanyDetail, getEmployees } from "../../../actions/adminAction";

// Styles
import "./AdminCompanies.scss";

// Components
import { AdminCompaniesHeader } from "./components/AdminCompaniesHeader";
import { TextField } from "@mui/material";
import { CompanyDetailTabs } from "./components/CompanyDetailTabs";

// Icons
import { Search as SearchIcon } from "@mui/icons-material";
import { DoubleArrow } from "@mui/icons-material/";

// Stores
import { useSelector, useDispatch } from "react-redux";

// Assets
import logo from "../../../assets/images/admin/woman.JPG";

import { useFormik } from "formik";
// Config
import { validationSchema, initialValues } from "./FormikConfig";

// Actions
import { setError } from "../../../actions/errorAction";
import { setSuccess } from "../../../actions/successAction";

import { instance, config } from "../../../services/axios";

export const AdminCompanies = () => {
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(0);
  const [filteredCompanies, setFilteredCompanies] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const companySummary = useSelector((state) => state.boss.companySummary);
  const companyDetail = useSelector((state) => state.admin.companyDetail);
  const employees = useSelector((state) => state.admin.employees);
  const [loansChecked, setLoansChecked] = useState(false);
  const [savingsChecked, setSavingsChecked] = useState(false);
  const [payrollChecked, setPayrollChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    dispatch(getBossCompanySummary());
  }, []);

  const formatSavingAccreditationCatalog = (values) => {
    const savingAccreditationCatalog = [];

    // format the 6 possible loans credit table
    for (let i = 0; i < 6; i++) {
      savingAccreditationCatalog.push({
        seniority: values[`seniority_${i + 1}`],
        payrolls: values[`payrolls_${i + 1}`],
        installments: values[`installments_${i + 1}`],
      });
    }

    return savingAccreditationCatalog;
  };

  const formatSavingBankRates = (values) => {
    return [
      { name: "now", yieldRate: values.now },
      { name: "month_1", yieldRate: values.month_1 },
      { name: "month_3", yieldRate: values.month_3 },
      { name: "month_6", yieldRate: values.month_6 },
      { name: "month_12", yieldRate: values.month_12 },
      { name: "month_24", yieldRate: values.month_24 },
      { name: "unlimit", yieldRate: values.unlimit },
    ];
  };

  const handleSubmit = async (values) => {
    try {
      const savingAccreditationCatalog =
        formatSavingAccreditationCatalog(values);
      const savingBankRates = formatSavingBankRates(values);

      const payload = {
        companyId,
        approver_email: values.approver_email,
        authorizator_email: values.authorizator_email,
        businessName: values.businessName,
        day_of_payment_m: values.day_of_payment_m,
        day_of_payment_q_1: values.day_of_payment_q_1,
        day_of_payment_q_2: values.day_of_payment_q_2,
        day_of_payment_w: values.day_of_payment_w,
        email: values.email,
        hasLoans: loansChecked,
        hasPayrollAdvance: payrollChecked,
        hasSavings: savingsChecked,
        loanCommissionPercentage: loansChecked
          ? values.loanCommissionPercentage
          : undefined,
        loanInterestPercentage: loansChecked
          ? values.loanInterestPercentage
          : undefined,
        loanMinimumLaborSeniority: loansChecked
          ? values.loanMinimumLaborSeniority
          : undefined,
        loanNumberOfEndorsements: loansChecked
          ? values.loanNumberOfEndorsements
          : undefined,
        name: values.name,
        payrollAdvanceCommissionPercentage: payrollChecked
          ? values.payrollAdvanceCommissionPercentage
          : undefined,
        phone: values.phone,
        rfc: values.rfc,
        savingBankRates,
        savingAccreditationCatalog,
      };

      const response = await instance.post(
        `Salvum/services/boss/updateCompany`,
        payload,
        config
      );

      if (response.code > 300) {
        dispatch(setError(response.message) || "Ha ocurrido un error");
        return;
      }

      await dispatch(
        setSuccess(
          response.message || "Datos de empresa actualizados exitosamente."
        )
      );

      setActiveStep(0);
      await getCompanyDetail({ companyId });
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
    }
  };

  const transformDetailsToData = (data) => {
    let savings;
    let savingAccreditationCatalog;
    if (data.savingBankRates) {
      if (data.savingBankRates.length > 0) {
        data.savingBankRates.forEach((saving) => {
          const [name, value] = Object.values(saving);
          savings = {
            ...savings,
            [name]: value,
          };
        });
      } else {
        savings = {
          ...savings,
          now: 0,
          month_1: 0,
          month_3: 0,
          month_6: 0,
          month_12: 0,
          month_24: 0,
          unlimit: 0,
        };
      }
    }

    if (data.savingAccreditationCatalog) {
      if (data.savingAccreditationCatalog.length > 0) {
        data.savingAccreditationCatalog.forEach((catalog, index) => {
          const [val1, val2, val3] = Object.values(catalog);
          const [key1, key2, key3] = Object.keys(catalog);
          savingAccreditationCatalog = {
            ...savingAccreditationCatalog,
            [`${key1}_${index + 1}`]: val1,
            [`${key2}_${index + 1}`]: val2,
            [`${key3}_${index + 1}`]: val3,
          };
        });
      }
    }

    return {
      ...data,
      ...savings,
      ...savingAccreditationCatalog,
    };
  };

  const updateFormikValues = async () => {
    const parsedValues = transformDetailsToData(companyDetail);
    setSavingsChecked(companyDetail.hasSavings);
    setLoansChecked(companyDetail.hasLoans);
    setPayrollChecked(companyDetail.hasPayrollAdvance);

    await formik.setValues(parsedValues);
    setIsLoading(false);
  };

  useEffect(() => {
    if (companyDetail) {
      updateFormikValues();
    }
  }, [companyDetail]);

  const formik = useFormik({
    validationSchema,
    onSubmit: handleSubmit,
    initialValues,
  });

  const handleChange = (evt) => {
    const searchTerm = evt.currentTarget.value;
    const fCompanies = companySummary.filter((company) =>
      company.companyName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (searchTerm !== null || searchTerm !== "") {
      setFilteredCompanies(fCompanies);
    } else {
      setFilteredCompanies(null);
    }
  };

  const handleShowCompanyDetails = async (event) => {
    try {
      const id = event.currentTarget.id;
      setCompanyId(id);
      setActiveStep(0);
      setIsLoading(true);
      dispatch(getCompanyDetail({ companyId: id }));
      dispatch(getEmployees({ companyId: id }));
      setIsLoading(false);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  if (!companySummary) {
    return null;
  }

  const companies = filteredCompanies || companySummary;

  return (
    <div className="companies">
      <AdminCompaniesHeader />

      <div className="w-full bg-white">
        <div className="flex rounded-2xl bg-white w-full">
          <div className="p-4 rounded-2xl companies-left flex flex-col text-black text-sm border border-slate-600">
            <p className="mb-4 text-lg text-black">Empresas</p>

            <div className="flex items-end">
              <SearchIcon className="mr-1" />
              <TextField
                id="standard-basic"
                onChange={handleChange}
                label="Buscar empresas"
                variant="standard"
              />
            </div>

            <div className="mt-6">
              {companies.map((company) => (
                <div
                  key={company.companyId}
                  id={company.companyId}
                  className={`transition ease-in-out py-2 company-item px-6 border mt-2 border-slate-300 rounded-2xl ${
                    Number(companyId) === company.companyId ? "item-active" : ""
                  }`}
                  onClick={handleShowCompanyDetails}
                >
                  <p>{company.companyName}</p>
                </div>
              ))}
            </div>
          </div>

          {!companyId && (
            <div className="text-black flex flex-col items-center m-20">
              <div className="text-select">
                <div className="arrowIcons w-fit">
                  <DoubleArrow style={{ fontSize: 40 }} />
                </div>
                <p>
                  Selecciona una de las empresas registradas en Salvum para
                  visualizar todos sus registros.
                </p>
              </div>
              <img alt="logo" src={logo} style={{ width: "400px" }} />
            </div>
          )}

          {companyId && (
            <CompanyDetailTabs
              companyDetail={companyDetail}
              companyId={companyId}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              employees={employees}
              formik={formik}
              isLoading={isLoading}
              setLoansChecked={setLoansChecked}
              setPayrollChecked={setPayrollChecked}
              setSavingsChecked={setSavingsChecked}
              savingsChecked={savingsChecked}
              loansChecked={loansChecked}
              payrollChecked={payrollChecked}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminCompanies;
