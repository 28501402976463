import React, { useEffect } from "react";

// Store
import { useDispatch, useSelector } from "react-redux";

// Components
import Chart from "react-google-charts";

// Actions
import { getBossHomeInfo } from "../../../actions/bossAction";

// Styles
import "./BossHome.scss";

import { api } from "../../../services/api";

import { setError } from "../../../actions/errorAction";
import { setSuccess } from "../../../actions/successAction";
import { useState } from "react";
import { CircularProgress } from "@mui/material";

export const BossHome = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [isRequesting, setIsRequesting] = useState(false);

  useEffect(() => {
    dispatch(getBossHomeInfo({ companyId: user.companyId }));
  }, []);

  const bossHomeInfo = useSelector((state) => state.boss.homeInfo);
  const handleRequestDescountsFile = async () => {
    try {
      setIsRequesting(true);
      const response = await api.get(
        `Salvum/services/layouts/discountsLayout?companyId=${user.companyId}`
      );
      if (response.data.error) {
        dispatch(setError(response.data.message));
      }

      dispatch(
        setSuccess(response.data.message || "Solicitud enviada con exito")
      );
      setIsRequesting(false);
    } catch (error) {
      setIsRequesting(false);
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
    }
  };

  return (
    <div className="homeBoss">
      <div className={"companiesHeroContent"}>
        <div className={"hero-container"}>
          <div className={"hero-block"}>
            <div>
              <span className={"greeting text-2xl"}>Hola&nbsp;</span>
              <span className={"username text-2xl"}>{user.fullName}.</span>
            </div>
            <div id={"welcome-block"}>
              <span className={"welcome"}>¡Bienvenido!</span>
            </div>
          </div>
        </div>
      </div>

      {bossHomeInfo && (
        <div className={"companiesContent"}>
          <div className={"companiesContentData"}>
            <div className={"contentPrincipalColumn"}>
              <div className={"principalContentCompany"}>
                <div className={"savingsContent"}>
                  <h2 className={"savings_title"}>Valor de caja</h2>

                  <div className={"grayLine"}></div>

                  <div className={"graphicHeader"}>
                    <div className={"option"}>
                      <p className={"grayText"}>Acumulación de Ahorros (12 Meses)</p>
                      <p className={"blackText"}>
                        {/* <span className={"smallText"}>$</span>
                        <span className={"greenText"}>&#8593; </span> */}
                      </p>
                    </div>
                  </div>
                  <Chart
                    width={"100%"}
                    height={"300px"}
                    chartType="Bar"
                    loader={<div>Loading Chart</div>}
                    data={[
                      ["Mes", ""],
                      ...bossHomeInfo.boxValues.map((saving) => [
                        saving.monthFormatted,
                        saving.value,
                        
                      ]),
                    ]}
                    options={{
                      title: "",
                      hAxis: { title: "Mes", titleTextStyle: { color: "#048aa8" } },
                      vAxis: { minValue: 0 },
                    }}
                    // For tests
                    rootProps={{ "data-testid": "1" }}
                  />
                </div>
              </div>
              <div className={"principalContentCompany"}>
                <div className={"savingsContent"}>
                  <h2 className={"savings_title"}>Ahorros</h2>
                  <div className={"grayLine"}></div>
                  <div className={"tabHeader"}>
                    <div className={"tabItem"}>
                      <p className={"grayText"}>Ahorros activos</p>
                      <p className={"blackText"}>
                        {bossHomeInfo.activeSavings}
                      </p>
                    </div>
                    <div className={"tabItem"}>
                      <p className={"grayText"}>Saldo total acumulado</p>
                      <p className="blackText">
                        {bossHomeInfo.sumOfTotalAcumSavingsFormatted}
                      </p>
                    </div>
                  </div>
                  <div className={"grayLine"}></div>
                  <div className="tabHeader">
                    <div className={"tabItem"}>
                      <p className={"grayText"}>Interés</p>
                      <p className="blackText">
                        {bossHomeInfo.interestsFormatted}
                      </p>
                    </div>

                    <div className={"tabItem"}>
                      <p className={"grayText"}>Saldo total de aportaciónes</p>
                      <p className="blackText">
                        {bossHomeInfo.sumOfSavingInputsFormatted}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"principalContentCompany"}>
                <div className={"sideOpt w-full"}>
                  <h2 className={"savings_title"}>Préstamos</h2>
                  <div className={"grayLine"}></div>
                  <div id="item-container" className="flex justify-between">
                    <div className="flex flex-col">
                      <p className={"grayText"}>Total registrados</p>
                      <p className={"blackText"}>
                        {bossHomeInfo.numOfTotalLoansGiven}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className={"grayText"}>Intereses totales cobrados</p>
                      <p className={"blackText"}>
                        {bossHomeInfo.sumOfInterestsPaidFormatted}
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className={"grayText"}>Saldo total prestado</p>
                      <p className={"blackText"}>
                        {bossHomeInfo.sumOfLoansGivenFormatted}
                      </p>
                    </div>
                  </div>
                  <div className={"grayLine"}></div>
                  <div className="flex justify-between">
                    <div className="flex flex-col">
                      <p className={"grayText"}>En curso</p>
                      <p className={"blackText"}>
                        {bossHomeInfo.numOfTotalLoansGivenActive}
                      </p>
                    </div>
                    {/* <div className="flex flex-col">
                      <p className={"grayText"}>Intereses totales por cobrar</p>
                      <p className={"blackText"}>
                        {bossHomeInfo.sumOfInterestsToRecoverFormatted}
                      </p>
                    </div> */}
                    <div className="flex flex-col">
                      <p className={"grayText"}>Saldo pendiente por pagar</p>
                      <p className={"blackText"}>
                        {bossHomeInfo.sumOfLoansCapitalPendingToPayFormatted}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"sideContent"}>
              <div className={"principalContentCompany"}>
                <div className={"sideOpt"}>
                  <h2 className={"savings_title"}>Empleados</h2>
                  <div className={"grayLine"}></div>
                  <p className={"grayText"}>Ahorrando / Registrados</p>¡
                  <p className={"blackText"}>
                    {bossHomeInfo.numOfEmployeesSaving}
                    <span className={"smallText"}>
                      /{bossHomeInfo.totalNumOfEmployees}
                    </span>
                  </p>
                </div>
              </div>

              <div className={"principalContentCompany text-black text-center"}>
                <div className={"sideOpt"}>
                  {!isRequesting ? (
                    <button onClick={handleRequestDescountsFile}>
                      Solicitar archivo de descuentos
                    </button>
                  ) : (
                    <CircularProgress />
                  )}
                </div>
              </div>

              <div className={"principalContentCompany"}>
                <div className={"sideOpt"}>
                  <h2 className={"savings_title"}>Adelantos</h2>
                  <div className={"grayLine"}></div>
                  <p className={"grayText"}>Total registrados</p>
                  <p className={"blackText"}>
                    {bossHomeInfo.numOfTotalCashAdvancesGiven}
                    <span className={"greenText"}>&#8593;</span>
                  </p>
                  <p className={"grayText marginTopbase"}>
                    Saldo total adelantado
                  </p>
                  <p className={"blackText"}>
                    {bossHomeInfo.sumOfTotalCashAdvanceGivenFormatted}
                  </p>
                  <div className={"grayLine"}></div>
                  <p className={"grayText marginTopbase"}>Totales en curso</p>
                  <p className={"blackText"}>
                    {bossHomeInfo.numOfActiveCashAdvance}
                  </p>
                  <p className={"grayText marginTopbase"}>
                    Saldo total en curso
                  </p>
                  <p className={"blackText"}>
                    {bossHomeInfo.sumOfActiveCashAdvanceFormatted}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {!bossHomeInfo && (
        <h2 className="text-black text-center my-6 text-3xl">
          ¡Ups! Parece que todavía no hay detalles que mostrar aquí.
        </h2>
      )}
    </div>
  );
};

export default BossHome;
