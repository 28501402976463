import {
  GET_EMPLOYEE_BY_USER_ID_BEGIN,
  GET_EMPLOYEE_BY_USER_ID_SUCCESS,
  GET_EMPLOYEE_BY_USER_ID_FAILURE,
  GET_EMPLOYEES_BY_BOSS_ID_BEGIN,
  GET_EMPLOYEES_BY_BOSS_ID_SUCCESS,
  GET_EMPLOYEES_BY_BOSS_ID_FAILURE,
  GET_EMPLOYEES_BY_COMPANY_ID_BEGIN,
  GET_EMPLOYEES_BY_COMPANY_ID_FAILURE,
  GET_EMPLOYEES_BY_COMPANY_ID_SUCCESS,
  ADD_EMPLOYEE_BEGIN,
  ADD_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_FAILURE,
  GET_EMPLOYEE_SUMMARY_BEGIN,
  GET_EMPLOYEE_SUMMARY_SUCCESS,
  GET_EMPLOYEE_SUMMARY_FAILURE,
  USER_LOGOUT_SUCCESS,
  GET_EMPLOYEE_NUMBER_DETAILS_BEGIN,
  GET_EMPLOYEE_NUMBER_DETAILS_SUCCESS,
  GET_EMPLOYEE_NUMBER_DETAILS_FAILURE,
  GET_EMPLOYEE_SAVING_DETAIL_RESPONSE_SUCCESS,
  GET_EMPLOYEE_SAVING_DETAIL_RESPONSE_FAILURE,
  GET_EMPLOYEE_CASH_ADVANCE_DETAIL_SUCCESS,
  GET_EMPLOYEE_CASH_ADVANCE_DETAIL_FAILURE,
  GET_EMPLOYEE_LOAN_DETAIL_SUCCESS,
  GET_EMPLOYEE_LOAN_DETAIL_FAILURE,
} from "../constants/actionTypes";

import initialState from "./initialState";

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function employeeReducer(state = initialState.employee, action) {
  switch (action.type) {
    case GET_EMPLOYEE_BY_USER_ID_BEGIN:
      return state;
    case GET_EMPLOYEE_BY_USER_ID_SUCCESS:
      return {
        ...state,
        current: action.employee,
      };
    case GET_EMPLOYEE_BY_USER_ID_FAILURE:
      return state;
    case GET_EMPLOYEES_BY_BOSS_ID_BEGIN:
      return state;
    case GET_EMPLOYEES_BY_BOSS_ID_SUCCESS:
      return {
        ...state,
        employeeList: action.employeeList,
      };
    case GET_EMPLOYEES_BY_BOSS_ID_FAILURE:
      return state;
    case GET_EMPLOYEES_BY_COMPANY_ID_BEGIN:
      return {
        ...state,
        loadingList: true,
        errorList: null,
        successList: false,
      };
    case GET_EMPLOYEES_BY_COMPANY_ID_FAILURE:
      return {
        ...state,
        loadingList: false,
        errorList: action.error,
        successList: false,
      };
    case GET_EMPLOYEES_BY_COMPANY_ID_SUCCESS:
      return {
        ...state,
        list: {
          count: action.count,
          rows: action.rows,
        },
        loadingList: false,
        successList: true,
      };
    case ADD_EMPLOYEE_BEGIN:
      return {
        ...state,
        loadingAdd: true,
        successAdd: false,
        errorAdd: null,
      };
    case ADD_EMPLOYEE_FAILURE:
      return {
        ...state,
        loadingAdd: false,
        successAdd: false,
        errorAdd: action.error,
      };

    case ADD_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loadingAdd: false,
        successAdd: true,
      };
    case GET_EMPLOYEE_SUMMARY_BEGIN:
      return {
        ...state,
      };
    case GET_EMPLOYEE_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: action.summary,
      };
    case GET_EMPLOYEE_SUMMARY_FAILURE:
      return {
        ...state,
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...initialState.employee,
      };
    case GET_EMPLOYEE_NUMBER_DETAILS_BEGIN:
      return {
        ...state,
      };
    case GET_EMPLOYEE_NUMBER_DETAILS_SUCCESS:
      return {
        ...state,
        numberDetails: action.numberDetails,
      };
    case GET_EMPLOYEE_NUMBER_DETAILS_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case GET_EMPLOYEE_SAVING_DETAIL_RESPONSE_SUCCESS:
      return {
        ...state,
        savingDetailResponse: action.savingDetailResponse,
        loading: false,
      };
    case GET_EMPLOYEE_SAVING_DETAIL_RESPONSE_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case GET_EMPLOYEE_CASH_ADVANCE_DETAIL_SUCCESS:
      return {
        ...state,
        cashAdvanceDetail: action.cashAdvanceDetail,
        loading: false,
      };
    case GET_EMPLOYEE_CASH_ADVANCE_DETAIL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case GET_EMPLOYEE_LOAN_DETAIL_SUCCESS:
      return {
        ...state,
        loanDetail: action.loanDetail,
        loading: false,
      };
    case GET_EMPLOYEE_LOAN_DETAIL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
