import React from "react";
import { EmployeeDetailItem } from "./EmployeeDetailItem";
import { EmployeeDetailsTab } from "./EmployeeDetailTabs";

// Icons
import { Group as GroupIcon } from "@mui/icons-material";
import "./EmployeeDetails.scss";

export const EmployeeDetails = ({
  showEmployeeDetail,
  employeesBase = [],
  employeeId,
  setEmployeeId,
  companyId,
}) => {
  if (!showEmployeeDetail) {
    return null;
  }

  return (
    <div>
      <div className="tabHeader">
        <h2 className="savings_title">Detalle de empleado</h2>

        <div className="icon-container">
          <GroupIcon className="icon-blue" />
        </div>
      </div>
      <button
        className="transition ease-in-out text-black button py-1 px-3 border my-3 rounded-full"
        onClick={setEmployeeId}
      >
        &lt; Regresar
      </button>
      <div className={"grayLine"} />

      {employeesBase
        .filter((employee) => employee.employeeNumber === employeeId)
        .map((data, index) => (
          <EmployeeDetailItem
            key={data.employeeName}
            data={data}
            index={index}
          />
        ))}
      <EmployeeDetailsTab employeeId={employeeId} companyId={companyId} />
    </div>
  );
};

export default EmployeeDetails;
