import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

// Components
import { CompanyDetails } from "./CompanyDetails";
import { EmployeesDetails } from "./EmployeesDetails";

import { CircularProgress } from "@mui/material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className="mt-4"
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

export const CompanyDetailTabs = ({
  companyDetail,
  employees,
  companyId,
  activeStep,
  setActiveStep,
  setLoansChecked,
  setSavingsChecked,
  setPayrollChecked,
  formik,
  isLoading,
  payrollChecked,
  loansChecked,
  savingsChecked,
}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="w-full">
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label="Detalles" />
          <Tab label="Empleados" />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <CompanyDetails
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            companyDetail={companyDetail}
            companyId={companyId}
            formik={formik}
            setLoansChecked={setLoansChecked}
            setPayrollChecked={setPayrollChecked}
            setSavingsChecked={setSavingsChecked}
            savingsChecked={savingsChecked}
            loansChecked={loansChecked}
            payrollChecked={payrollChecked}
          />
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <EmployeesDetails employees={employees} companyId={companyId} />
      </TabPanel>
    </div>
  );
};

export default CompanyDetailTabs;
