import NotFoundPage from "./components/NotFoundPage";
import InboxIcon from "@mui/icons-material/MoveToInbox";

import EmployeeLayout from "./components/layouts/EmployeeLayout/EmployeeLayout";
import EmployeeHome from "./pages/Employee/EmployeeHome/EmployeeHome";
import EmployeeSavings from "./pages/Employee/EmployeeSavings/EmplyeeSavings";
import EmployeeLoan from "./pages/Employee/EmployeeLoan/EmployeeLoan";
import EmployeePayRoll from "./pages/Employee/EmployeePayRoll/EmployeePayRoll";
import EmployeeProfile from "./pages/Employee/Profile/Profile";

import AdminLayout from "./components/layouts/AdminLayout/AdminLayout";
// import AdminCompanies from "./pages/Admin/AdminCompanies/AdminCompanies";
import { AdminCompanies } from "./pages/Admin/AdminCompanies/AdminCompaniesv2";

// import AdminSavings from "./pages/Admin/AdminSavings/AdminSavings";
import { AdminSavingsv2 as AdminSavings } from "./pages/Admin/AdminSavings/AdminSavingsv2";
import AdminLoan from "./pages/Admin/AdminLoan/AdminLoan";
import AdminPayRoll from "./pages/Admin/AdminPayRoll/AdminPayRoll";
import AdminHome from "./pages/Admin/AdminHome/AdminHome";
// import AdminAddCompany from "./pages/Admin/AdminAddCompany/AdminAddCompany";
import { AdminAddCompany } from "./pages/Admin/AdminAddCompany/AdminAddCompanyv2";

import BossLayout from "./components/layouts/BossLayout/BossLayout";
import BossHome from "./pages/Boss/BossHome/BossHome";
import BossEmployees from "./pages/Boss/BossEmployees/BossEmployees";
import BossSavings from "./pages/Boss/BossSavings/BossSavings";
import BossLoan from "./pages/Boss/BossLoan/BossLoan";
import BossPayRolls from "./pages/Boss/BossPayRolls/BossPayRolls";

export const routes = (params) => {
  // Si tiene icono es porque se van a mostrar sobre la barra lateral
  // Para cambiar de menu, hay que cambiar el estado en initialState.js a "admin" o "employee" dependiendo
  const availableRoutes = [
    {
      role: "employee",
      title: "Inicio",
      path: "",
      icon: InboxIcon,
      exact: true,
      authenticatedAccess: true,
      Layout: EmployeeLayout,
      Component: EmployeeHome,
      showInMenu: true,
    },
    {
      role: "employee",
      title: "Ahorros",
      path: "ahorros",
      icon: InboxIcon,
      exact: true,
      authenticatedAccess: true,
      Layout: EmployeeLayout,
      Component: EmployeeSavings,
      showInMenu: true,
    },
    {
      role: "employee",
      title: "Préstamos",
      path: "prestamos",
      icon: InboxIcon,
      exact: true,
      authenticatedAccess: true,
      Layout: EmployeeLayout,
      Component: EmployeeLoan,
      showInMenu: true,
    },
    {
      role: "employee",
      title: "Adelantos de nómina",
      menuTitle: "Adelantos",
      path: "adelantos-de-nomina",
      icon: InboxIcon,
      exact: true,
      authenticatedAccess: true,
      Layout: EmployeeLayout,
      Component: EmployeePayRoll,
      showInMenu: true,
    },
    {
      role: "employee",
      title: "Perfil",
      path: "perfil",
      icon: InboxIcon,
      exact: true,
      authenticatedAccess: true,
      Layout: EmployeeLayout,
      Component: EmployeeProfile,
      showInMenu: false,
    },
    {
      role: "administrador",
      title: "Inicio",
      path: "",
      icon: InboxIcon,
      exact: true,
      authenticatedAccess: true,
      Layout: AdminLayout,
      Component: AdminHome,
      showInMenu: true,
    },
    {
      role: "administrador",
      title: "Empresas",
      path: "empresas",
      icon: InboxIcon,
      exact: true,
      authenticatedAccess: true,
      Layout: AdminLayout,
      Component: AdminCompanies,
      showInMenu: true,
    },
    {
      role: "administrador",
      title: "Ahorros",
      path: "ahorros",
      icon: InboxIcon,
      exact: true,
      authenticatedAccess: true,
      Layout: AdminLayout,
      Component: AdminSavings,
      showInMenu: true,
    },
    {
      role: "administrador",
      title: "Préstamos",
      path: "prestamos",
      icon: InboxIcon,
      exact: true,
      authenticatedAccess: true,
      Layout: AdminLayout,
      Component: AdminLoan,
      showInMenu: true,
    },
    {
      role: "administrador",
      title: "Adelantos de nómina",
      menuTitle: "Adelantos",
      path: "adelantos-nomina",
      icon: InboxIcon,
      exact: true,
      authenticatedAccess: true,
      Layout: AdminLayout,
      Component: AdminPayRoll,
      showInMenu: true,
    },
    {
      role: "administrador",
      title: "Agregar empresa",
      path: "agregar-empresa",
      icon: InboxIcon,
      exact: true,
      authenticatedAccess: true,
      Layout: AdminLayout,
      Component: AdminAddCompany,
      showInMenu: false,
    },
    {
      role: "boss",
      title: "Inicio",
      path: "",
      icon: InboxIcon,
      exact: true,
      authenticatedAccess: true,
      Layout: BossLayout,
      Component: BossHome,
      showInMenu: true,
    },
    {
      role: "boss",
      title: "Empleados",
      path: "empleados",
      icon: InboxIcon,
      exact: true,
      authenticatedAccess: true,
      Layout: BossLayout,
      Component: BossEmployees,
      showInMenu: true,
    },
    {
      role: "boss",
      title: "Ahorros",
      path: "ahorros",
      icon: InboxIcon,
      exact: true,
      authenticatedAccess: true,
      Layout: BossLayout,
      Component: BossSavings,
      showInMenu: true,
    },
    {
      role: "boss",
      title: "Préstamos",
      path: "prestamos",
      icon: InboxIcon,
      exact: true,
      authenticatedAccess: true,
      Layout: BossLayout,
      Component: BossLoan,
      showInMenu: true,
    },
    {
      role: "boss",
      title: "Adelantos de nómina",
      menuTitle: "Adelantos",
      path: "adelantos-de-nomina",
      icon: InboxIcon,
      exact: true,
      authenticatedAccess: true,
      Layout: BossLayout,
      Component: BossPayRolls,
      showInMenu: true,
    },
    {
      // role:'',
      // route: '',
      // icon: '',
      Component: NotFoundPage,
      showInMenu: false,
    },
  ];

  if (typeof params.showInMenu === "boolean") {
    return availableRoutes.filter((ar) =>
      ar.role === params.role && ar.icon && params.showInMenuar
        ? ar.showInMenu === true
        : ar.showInMenu !== false
    );
  }
  return availableRoutes.filter((ar) => ar.role === params.role);
};
