import request from "../utils/request";

import {
  CREATE_COMPANY_BEGIN,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_FAILURE,
  UPDATE_COMPANY_BEGIN,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILURE,
  GET_COMPANY_BY_ID_BEGIN,
  GET_COMPANY_BY_ID_SUCCESS,
  GET_COMPANY_BY_ID_FAILURE,
  GET_COMPANIES_BEGIN,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAILURE,
  GET_COMPANY_SUMMARY_BEGIN,
  GET_COMPANY_SUMMARY_SUCCESS,
  GET_COMPANY_SUMMARY_FAILURE,
  GET_COMPANIES_SUMMARY_BEGIN,
  GET_COMPANIES_SUMMARY_SUCCESS,
  GET_COMPANIES_SUMMARY_FAILURE,
  GET_PAYROLL_ADVANCE_BY_COMPANY_ID_BEGIN,
  GET_PAYROLL_ADVANCE_BY_COMPANY_ID_SUCCESS,
  GET_PAYROLL_ADVANCE_BY_COMPANY_ID_FAILURE,
} from "../constants/actionTypes";

import { api } from "../services/api";

export const createCompanyBegin = () => ({
  type: CREATE_COMPANY_BEGIN,
});

export const createCompanySuccess = (companyId) => ({
  type: CREATE_COMPANY_SUCCESS,
  companyId,
});

export const createCompanyFailure = (error) => ({
  type: CREATE_COMPANY_FAILURE,
  error,
});

export const updateCompanyBegin = () => ({
  type: UPDATE_COMPANY_BEGIN,
});

export const updateCompanySuccess = (companyId) => ({
  type: UPDATE_COMPANY_SUCCESS,
  companyId,
});

export const updateCompanyFailure = (error) => ({
  type: UPDATE_COMPANY_FAILURE,
  error,
});

export const getCompanyByIdBegin = () => ({
  type: GET_COMPANY_BY_ID_BEGIN,
});

export const getCompanyByIdSuccess = (company) => ({
  type: GET_COMPANY_BY_ID_SUCCESS,
  company,
});

export const getCompanyByIdFailure = (error) => ({
  type: GET_COMPANY_BY_ID_FAILURE,
  error,
});

export const getCompaniesBegin = () => ({
  type: GET_COMPANIES_BEGIN,
});

export const getCompaniesSuccess = (resume) => ({
  type: GET_COMPANIES_SUCCESS,
  resume,
});

export const getCompaniesFailure = (error) => ({
  type: GET_COMPANIES_FAILURE,
  error,
});

export const getPayrollAdvanceBegin = () => ({
  type: GET_PAYROLL_ADVANCE_BY_COMPANY_ID_BEGIN,
});

export const getPayrollAdvanceSuccess = (payrollAdvanceList) => ({
  type: GET_PAYROLL_ADVANCE_BY_COMPANY_ID_SUCCESS,
  payrollAdvanceList,
});

export const getPayrollAdvanceFailure = (error) => ({
  type: GET_PAYROLL_ADVANCE_BY_COMPANY_ID_FAILURE,
  error,
});

export const getCompanySummaryBegin = () => ({
  type: GET_COMPANY_SUMMARY_BEGIN,
});

export const getCompanySummarySuccess = (summary) => ({
  type: GET_COMPANY_SUMMARY_SUCCESS,
  summary,
});

export const getCompanySummaryFailure = (error) => ({
  type: GET_COMPANY_SUMMARY_FAILURE,
  error,
});

export const getCompaniesSummaryBegin = () => ({
  type: GET_COMPANIES_SUMMARY_BEGIN,
});

export const getCompaniesSummarySuccess = (summaryAdmin) => ({
  type: GET_COMPANIES_SUMMARY_SUCCESS,
  summaryAdmin,
});

export const getCompaniesSummaryFailure = (error) => ({
  type: GET_COMPANIES_SUMMARY_FAILURE,
  error,
});

export function createCompany(newCompany) {
  return (dispatch, getState) => {
    dispatch(createCompanyBegin());
    return request({
      partialUrl: "/api/v1/companies",
      method: "POST",
      state: getState().user.request,
      body: newCompany,
    })
      .then((request) => request.json())
      .then((res) => {
        // dispatch(createCompanySuccess(res.data.companyId));
        dispatch(getCompanyById(res.data.companyId));
      })
      .catch((err) => {
        return dispatch(createCompanyFailure(err));
      });
  };
}

export function updateCompany(newCompany) {
  return (dispatch, getState) => {
    dispatch(updateCompanyBegin());
    return request({
      partialUrl: "/api/v1/companies/update",
      method: "PUT",
      state: getState().user.request,
      body: newCompany,
    })
      .then((request) => request.json())
      .then((res) => {
        // dispatch(createCompanySuccess(res.data.companyId));
        dispatch(getCompanyById(res.data.companyId));
      })
      .catch((err) => {
        return dispatch(updateCompanyFailure(err));
      });
  };
}

export function getCompanyById(companyId) {
  return async (dispatch) => {
    try {
      dispatch(getCompanyByIdBegin());
      const res = await api.get(
        `Salvum/services/boss/getCompanyDetail?companyId=${companyId}`
      );
      dispatch(getCompanyByIdSuccess(res.data));
    } catch (error) {
      dispatch(getCompanyByIdFailure(error));
    }
  };
}

export function getPayrollAdvanceByCompanyId(companyId) {
  return (dispatch, getState) => {
    dispatch(getPayrollAdvanceBegin());
    return request({
      partialUrl: `/api/v1/payroll-advance/find-payroll-advance-by-company-id/${companyId}`,
      method: "GET",
      state: getState().user.request,
    })
      .then((request) => request.json())
      .then((res) => {
        dispatch(getPayrollAdvanceSuccess(res.data));
      })
      .catch((err) => {
        return dispatch(getPayrollAdvanceFailure(err));
      });
  };
}

export function getCompanies() {
  return (dispatch, getState) => {
    dispatch(getCompaniesBegin());
    return request({
      partialUrl: "/api/v1/companies",
      method: "GET",
      state: getState().user.request,
    })
      .then((request) => request.json())
      .then((res) => {
        dispatch(getCompaniesSuccess(res.data));
      })
      .catch((err) => {
        return dispatch(getCompaniesFailure(err));
      });
  };
}

export function getCompanySummary(companyId) {
  return (dispatch, getState) => {
    dispatch(getCompanySummaryBegin());
    return request({
      partialUrl: `/api/v1/companies/${companyId}/summary`,
      method: "GET",
      state: getState().user.request,
    })
      .then((response) => response.json())
      .then((response) => {
        dispatch(getCompanySummarySuccess(response.data));
      })
      .catch((err) => {
        return dispatch(getCompanySummaryFailure(err));
      });
  };
}

export function getCompaniesSummary() {
  return (dispatch, getState) => {
    dispatch(getCompaniesSummaryBegin());
    return request({
      partialUrl: "/api/v1/admin/summary",
      method: "GET",
      state: getState().user.request,
    })
      .then((response) => response.json())
      .then((response) => {
        dispatch(getCompaniesSummarySuccess(response.data));
      })
      .catch((err) => {
        return dispatch(getCompaniesSummaryFailure(err));
      });
  };
}
