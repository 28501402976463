import {
  GET_PAYROLL_ADVANCE_BY_EMPLOYEE_ID_BEGIN,
  GET_PAYROLL_ADVANCE_BY_EMPLOYEE_ID_SUCCESS,
  GET_PAYROLL_ADVANCE_BY_EMPLOYEE_ID_FAILURE,
  CREATE_PAYROLL_ADVANCE_BEGIN,
  CREATE_PAYROLL_ADVANCE_SUCCESS,
  CREATE_PAYROLL_ADVANCE_FAILURE,
  CLEAR_CREATED_PAYROLL_ADVANCE,
  GET_PAYROLL_ADVANCE_BY_BOSS_AND_STATUS_ID_BEGIN,
  GET_PAYROLL_ADVANCE_BY_BOSS_AND_STATUS_ID_SUCCESS,
  GET_PAYROLL_ADVANCE_BY_BOSS_AND_STATUS_ID_FAILURE,
  GET_PAYROLL_ADVANCE_BY_BOSS_ID_BEGIN,
  GET_PAYROLL_ADVANCE_BY_BOSS_ID_SUCCESS,
  GET_PAYROLL_ADVANCE_BY_BOSS_ID_FAILURE,
  GET_PAYROLL_ADVANCE_RESUME_BY_BOSS_ID_BEGIN,
  GET_PAYROLL_ADVANCE_RESUME_BY_BOSS_ID_SUCCESS,
  GET_PAYROLL_ADVANCE_RESUME_BY_BOSS_ID_FAILURE,
  UPDATE_PAYROLL_ADVANCE_STATUS_ID_BEGIN,
  UPDATE_PAYROLL_ADVANCE_STATUS_ID_SUCCESS,
  UPDATE_PAYROLL_ADVANCE_STATUS_ID_FAILURE,
  USER_LOGOUT_SUCCESS,
  REQUEST_PAYROLL_ADVANCE_BEGIN,
  REQUEST_PAYROLL_ADVANCE_SUCCESS,
  REQUEST_PAYROLL_ADVANCE_FAILURE,
  GET_CASH_ADVANCE_DASHBOARD_SUCCESS,
  GET_CASH_ADVANCE_DASHBOARD_FAILURE,
  GET_PENDING_CASH_ADVANCE_FOR_ADMIN_FAILURE,
  GET_PENDING_CASH_ADVANCE_FOR_ADMIN_SUCCESS,
  GET_PENDING_CASH_ADVANCE_FOR_BOSS_FAILURE,
  GET_PENDING_CASH_ADVANCE_FOR_BOSS_SUCCESS,
  GET_CASH_ADVANCE_EMPLOYEE_HOME_INFO_SUCCESS,
  GET_CASH_ADVANCE_EMPLOYEE_HOME_INFO_FAILURE,
} from "../constants/actionTypes";

import initialState from "./initialState";

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function payrollAdvanceReducer(
  state = initialState.advances,
  action
) {
  switch (action.type) {
    case GET_PAYROLL_ADVANCE_BY_EMPLOYEE_ID_BEGIN:
      return state;
    case GET_PAYROLL_ADVANCE_BY_EMPLOYEE_ID_SUCCESS:
      return {
        ...state,
        payrollAdvance: action.payrollAdvance,
      };
    case GET_PAYROLL_ADVANCE_BY_EMPLOYEE_ID_FAILURE:
      return state;
    case CREATE_PAYROLL_ADVANCE_BEGIN:
      return state;
    case CREATE_PAYROLL_ADVANCE_SUCCESS: {
      const newPayrollAdvance = JSON.parse(
        JSON.stringify(state.newPayrollAdvance)
      );
      newPayrollAdvance.created = true;
      return {
        ...state,
        newPayrollAdvance,
      };
    }
    case CREATE_PAYROLL_ADVANCE_FAILURE:
      return state;
    case CLEAR_CREATED_PAYROLL_ADVANCE: {
      const newPayrollAdvance = JSON.parse(
        JSON.stringify(state.newPayrollAdvance)
      );
      newPayrollAdvance.created = false;
      return {
        ...state,
        newPayrollAdvance,
      };
    }

    case REQUEST_PAYROLL_ADVANCE_BEGIN:
      return {
        ...state,
        loading: true,
      };

    case REQUEST_PAYROLL_ADVANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        requestPayrollAdvanceInfo: action.payload,
      };

    case REQUEST_PAYROLL_ADVANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_PAYROLL_ADVANCE_BY_BOSS_AND_STATUS_ID_BEGIN:
      return state;
    case GET_PAYROLL_ADVANCE_BY_BOSS_AND_STATUS_ID_SUCCESS:
      return {
        ...state,
        payrollAdvanceByStatus: action.payrollAdvanceByStatus,
      };
    case GET_PAYROLL_ADVANCE_BY_BOSS_AND_STATUS_ID_FAILURE:
      return state;
    case GET_PAYROLL_ADVANCE_BY_BOSS_ID_BEGIN:
      return state;
    case GET_PAYROLL_ADVANCE_BY_BOSS_ID_SUCCESS:
      return {
        ...state,
        payrollAdvance: action.payrollAdvance,
      };
    case GET_PAYROLL_ADVANCE_BY_BOSS_ID_FAILURE:
      return state;
    case GET_PAYROLL_ADVANCE_RESUME_BY_BOSS_ID_BEGIN:
      return state;
    case GET_PAYROLL_ADVANCE_RESUME_BY_BOSS_ID_SUCCESS:
      return {
        ...state,
        payrollAdvanceResume: action.payrollAdvanceResume,
      };
    case GET_PAYROLL_ADVANCE_RESUME_BY_BOSS_ID_FAILURE:
      return state;
    case UPDATE_PAYROLL_ADVANCE_STATUS_ID_BEGIN:
      return state;
    case UPDATE_PAYROLL_ADVANCE_STATUS_ID_SUCCESS:
      return state;
    case UPDATE_PAYROLL_ADVANCE_STATUS_ID_FAILURE:
      return state;
    case USER_LOGOUT_SUCCESS:
      return {
        ...initialState.payrollAdvance,
      };
    case GET_CASH_ADVANCE_DASHBOARD_SUCCESS:
      return {
        ...state,
        cashAdvanceDashboard: action.cashAdvanceDashboard,
        loading: false,
      };
    case GET_CASH_ADVANCE_DASHBOARD_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    case GET_PENDING_CASH_ADVANCE_FOR_ADMIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        pendingCashAdvanceForAdmin: action.pendingCashAdvanceForAdmin,
      };
    }
    case GET_PENDING_CASH_ADVANCE_FOR_ADMIN_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case GET_PENDING_CASH_ADVANCE_FOR_BOSS_SUCCESS:
      return {
        ...state,
        pendingCashAdvanceForBoss: action.pendingCashAdvanceForBoss,
        loading: false,
      };
    case GET_PENDING_CASH_ADVANCE_FOR_BOSS_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case GET_CASH_ADVANCE_EMPLOYEE_HOME_INFO_SUCCESS:
      return {
        ...state,
        cashAdvanceEmployeeHomeInfo: action.cashAdvanceEmployeeHomeInfo,
        loading: false,
      };
    case GET_CASH_ADVANCE_EMPLOYEE_HOME_INFO_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
