import React, { useState, useEffect } from "react";

import { TextField } from "@mui/material";

// Icons
import { Search as SearchIcon } from "@mui/icons-material";

// Actions
import { getBossCompanySummary } from "../../../../actions/bossAction";
import { getSavingSummary } from "../../../../actions/adminAction";

// Stores
import { useSelector, useDispatch } from "react-redux";

export const Companies = ({
  setSavingsSummary,
  companyId,
  setSelectedCompany,
  setEmployeeId,
}) => {
  const dispatch = useDispatch();
  const [filteredCompanies, setFilteredCompanies] = useState(null);

  const companySummary = useSelector((state) => state.boss.companySummary);

  useEffect(() => {
    dispatch(getBossCompanySummary());
  }, []);

  if (!companySummary) {
    return null;
  }

  const handleChange = (evt) => {
    const searchTerm = evt.currentTarget.value;
    const filteredCompanies = companySummary.filter((company) =>
      company.companyName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    if (searchTerm !== null || searchTerm !== "") {
      setFilteredCompanies(filteredCompanies);
    } else {
      setFilteredCompanies(null);
    }
  };

  const handleShowCompanyDetails = async (event) => {
    try {
      const id = event.currentTarget.id;
      setSelectedCompany(id);
      setSavingsSummary(id);
      setEmployeeId(null);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const companies = filteredCompanies || companySummary;

  const handleRetrieveDetails = (event) => {
    const id = event.currentTarget.id;
    setSelectedCompany(id);
    dispatch(getBossCompanySummary());
    dispatch(getSavingSummary());
  };

  return (
    <div className="p-4 flex flex-col border border-gray-600 text-black text-sm w-56">
      <p className="mb-4 text-2xl text-black">Empresas</p>

      <div className="flex items-end">
        <SearchIcon className="mr-1" />
        <TextField
          fullWidth
          id="standard-basic"
          onChange={handleChange}
          label="Buscar empresas"
          variant="standard"
        />
      </div>

      <div
        id="general"
        className={`transition ease-in-out m-1 py-2 company-item px-6 border mt-2 border-slate-300 rounded-2xl ${
          companyId === "general" ? "item-active" : ""
        }`}
        onClick={handleRetrieveDetails}
      >
        <p>Resumen general</p>
      </div>

      <div className="mt-6 flex flex-col w-full">
        {companies.map((company) => (
          <div
            key={company.companyId}
            id={company.companyId}
            className={`transition ease-in-out m-1 py-2 company-item px-6 border mt-2 border-slate-300 rounded-2xl ${
              Number(companyId) === company.companyId ? "item-active" : ""
            }`}
            onClick={handleShowCompanyDetails}
          >
            <p>{company.companyName}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Companies;
