import {
  GET_ADMIN_BY_USER_ID_BEGIN,
  GET_ADMIN_BY_USER_ID_SUCCESS,
  GET_ADMIN_BY_USER_ID_FAILURE,
  USER_LOGOUT_SUCCESS,
  GET_ADMIN_HOME_INFO_SUCCESS,
  GET_ADMIN_HOME_INFO_FAILURE,
  GET_COMPANY_DETAIL_SUCCESS,
  GET_COMPANY_DETAIL_FAILURE,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILURE,
  GET_SAVING_SUMMARY_SUCCESS,
  GET_SAVING_SUMMARY_FAILURE,
  GET_SAVING_SUMMARY_BY_ID_SUCCESS,
  GET_SAVING_SUMMARY_BY_ID_FAILURE,
} from "../constants/actionTypes";

import initialState from "./initialState";

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function adminReducer(state = initialState.admin, action) {
  switch (action.type) {
    case GET_ADMIN_BY_USER_ID_BEGIN:
      return state;
    case GET_ADMIN_BY_USER_ID_SUCCESS: {
      return {
        ...state,
        ...action.admin,
      };
    }
    case GET_ADMIN_BY_USER_ID_FAILURE:
      return state;
    case USER_LOGOUT_SUCCESS:
      return {
        ...initialState.admin,
      };
    case GET_ADMIN_HOME_INFO_SUCCESS:
      return {
        ...state,
        adminHomeInfo: action.adminHomeInfo,
        loading: false,
      };
    case GET_ADMIN_HOME_INFO_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case GET_COMPANY_DETAIL_SUCCESS:
      return {
        ...state,
        companyDetail: action.companyDetail,
        loading: false,
      };
    case GET_COMPANY_DETAIL_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case GET_EMPLOYEES_SUCCESS:
      return {
        ...state,
        employees: action.employees,
        loading: false,
      };
    case GET_EMPLOYEES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case GET_SAVING_SUMMARY_SUCCESS:
      return {
        ...state,
        savingSummary: action.savingSummary,
        loading: false,
      };
    case GET_SAVING_SUMMARY_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case GET_SAVING_SUMMARY_BY_ID_SUCCESS:
      return {
        ...state,
        savingSummaryById: action.savingSummaryById,
        loading: false,
      };
    case GET_SAVING_SUMMARY_BY_ID_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
