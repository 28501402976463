import request from "../utils/request";
import axios from "axios";
import { api } from "../services/api";
import { publicUrl, apiBaseUrlAws } from "./consts";
import { setError } from "./errorAction";
import { setSuccess } from "./successAction";

import {
  SAVINGS_CREATING_BEGIN,
  SAVINGS_CREATING_SUCEESS,
  SAVINGS_CREATING_FAILURE,
  SAVINGS_ADD_BENEFICIARY_BEGIN,
  SAVINGS_ADD_BENEFICIARY_SUCEESS,
  SAVINGS_ADD_BENEFICIARY_FAILURE,
  CLEAR_SAVINGS_CREATING_FAILURE,
  SAVINGS_REMOVE_BENEFICIARY_BEGIN,
  SAVINGS_REMOVE_BENEFICIARY_SUCEESS,
  SAVINGS_REMOVE_BENEFICIARY_FAILURE,
  CREATE_SAVING_BANK_BEGIN,
  CREATE_SAVING_BANK_SUCCESS,
  CREATE_SAVING_BANK_FAILURE,
  GET_SAVING_BANKS_BY_EMPLOYEE_ID_BEGIN,
  GET_SAVING_BANKS_BY_EMPLOYEE_ID_SUCCESS,
  GET_SAVING_BANKS_BY_EMPLOYEE_ID_FAILURE,
  TESTING_SAVINGS_RESET_BEGIN,
  TESTING_SAVINGS_RESET_SUCCESS,
  TESTING_SAVINGS_RESET_FAILURE,
  GET_SAVINGS_BANKS_RESUME_BY_BOSS_ID_BEGIN,
  GET_SAVINGS_BANKS_RESUME_BY_BOSS_ID_SUCCESS,
  GET_SAVINGS_BANKS_RESUME_BY_BOSS_ID_FAILURE,
  GET_SAVING_BANKS_BY_BOSS_ID_BEGIN,
  GET_SAVING_BANKS_BY_BOSS_ID_SUCCESS,
  GET_SAVING_BANKS_BY_BOSS_ID_FAILURE,
  CREATE_SAVINGS_TRANSACTIONS_BEGIN,
  CREATE_SAVINGS_TRANSACTIONS_SUCCESS,
  CREATE_SAVINGS_TRANSACTIONS_FAILURE,
  UPDATE_SAVINGS_TRANSACTIONS_BEGIN,
  UPDATE_SAVINGS_TRANSACTIONS_SUCCESS,
  UPDATE_SAVINGS_TRANSACTIONS_FAILURE,
  GET_TOP_SAVINGS_ACCOUNT_BEGIN,
  GET_TOP_SAVINGS_ACCOUNT_SUCCESS,
  GET_TOP_SAVINGS_ACCOUNT_FAILURE,
  GET_TOTAL_SAVINGS_EMPLOYEE_BEGIN,
  GET_TOTAL_SAVINGS_EMPLOYEE_SUCCESS,
  GET_TOTAL_SAVINGS_EMPLOYEE_FAILURE,
  GET_SAVINGS_SLOTS_BEGIN,
  GET_SAVINGS_SLOTS_SUCCESS,
  GET_SAVINGS_SLOTS_FAILURE,
  GET_SAVINGS_TABLE_PER_EMPLOYEE_BEGIN,
  GET_SAVINGS_TABLE_PER_EMPLOYEE_SUCCESS,
  GET_SAVINGS_TABLE_PER_EMPLOYEE_FAILURE,
  GET_PAYROLL_STATUS_INFO_BEGIN,
  GET_PAYROLL_STATUS_INFO_SUCCESSFUL,
  GET_PAYROLL_STATUS_INFO_FAILURE,
} from "../constants/actionTypes";

export const savingsCreatingBegin = () => ({
  type: SAVINGS_CREATING_BEGIN,
});

export const savingsCreatingSuceess = () => ({
  type: SAVINGS_CREATING_SUCEESS,
});

export const savingsCreatingFailure = () => ({
  type: SAVINGS_CREATING_FAILURE,
});

export const createSavingsTransactionsBegin = () => ({
  type: CREATE_SAVINGS_TRANSACTIONS_BEGIN,
});

export const createSavingsTransactionsSuccess = () => ({
  type: CREATE_SAVINGS_TRANSACTIONS_SUCCESS,
});

export const createSavingsTransactionsFailure = () => ({
  type: CREATE_SAVINGS_TRANSACTIONS_FAILURE,
});

export const updateSavingsTransactionsBegin = () => ({
  type: UPDATE_SAVINGS_TRANSACTIONS_BEGIN,
});

export const updateSavingsTransactionsSuccess = () => ({
  type: UPDATE_SAVINGS_TRANSACTIONS_SUCCESS,
});

export const updateSavingsTransactionsFailure = () => ({
  type: UPDATE_SAVINGS_TRANSACTIONS_FAILURE,
});

export const clearSavingsCreating = () => ({
  type: CLEAR_SAVINGS_CREATING_FAILURE,
});

export const savingsAddBeneficyBegin = () => ({
  type: SAVINGS_ADD_BENEFICIARY_BEGIN,
});

export const savingsAddBeneficySuceess = () => ({
  type: SAVINGS_ADD_BENEFICIARY_SUCEESS,
});

export const savingsAddBeneficyFailure = () => ({
  type: SAVINGS_ADD_BENEFICIARY_FAILURE,
});

export const savingsRemoveBeneficyBegin = () => ({
  type: SAVINGS_REMOVE_BENEFICIARY_BEGIN,
});

export const savingsRemoveBeneficySuceess = (index) => ({
  type: SAVINGS_REMOVE_BENEFICIARY_SUCEESS,
  index,
});

export const savingsRemoveBeneficyFailure = () => ({
  type: SAVINGS_REMOVE_BENEFICIARY_FAILURE,
});

export const createSavingBankBegin = () => ({
  type: CREATE_SAVING_BANK_BEGIN,
});

export const createSavingBankSuccess = () => ({
  type: CREATE_SAVING_BANK_SUCCESS,
});

export const createSavingBankFailure = () => ({
  type: CREATE_SAVING_BANK_FAILURE,
});

export const getSavingBanksByEmployeeIdBegin = () => ({
  type: GET_SAVING_BANKS_BY_EMPLOYEE_ID_BEGIN,
});

export const getSavingBanksByEmployeeIdSuccess = (data) => ({
  type: GET_SAVING_BANKS_BY_EMPLOYEE_ID_SUCCESS,
  data,
});

export const getSavingBanksByEmployeeIdFailure = () => ({
  type: GET_SAVING_BANKS_BY_EMPLOYEE_ID_FAILURE,
});

export const testSavingResetBegin = () => ({
  type: TESTING_SAVINGS_RESET_BEGIN,
});

export const testSavingResetSuccess = () => ({
  type: TESTING_SAVINGS_RESET_SUCCESS,
});

export const testSavingResetFailure = () => ({
  type: TESTING_SAVINGS_RESET_FAILURE,
});

export const getSavingsBanksResumeByBossIdBegin = () => ({
  type: GET_SAVINGS_BANKS_RESUME_BY_BOSS_ID_BEGIN,
});

export const getSavingsBanksResumeByBossIdSuccess = (companySavingsResume) => ({
  type: GET_SAVINGS_BANKS_RESUME_BY_BOSS_ID_SUCCESS,
  companySavingsResume,
});
export const getSavingsBanksResumeByBossIdFailure = () => ({
  type: GET_SAVINGS_BANKS_RESUME_BY_BOSS_ID_FAILURE,
});

export const getSavingBanksByBossIdBegin = () => ({
  type: GET_SAVING_BANKS_BY_BOSS_ID_BEGIN,
});

export const getSavingBanksByBossIdSuccess = (savings) => ({
  type: GET_SAVING_BANKS_BY_BOSS_ID_SUCCESS,
  savings,
});

export const getSavingBanksByBossIdFailure = () => ({
  type: GET_SAVING_BANKS_BY_BOSS_ID_FAILURE,
});

export const getTopSavingsAccountBegin = () => ({
  type: GET_TOP_SAVINGS_ACCOUNT_BEGIN,
});

export const getTopSavingsAccountSuccess = (topSavingsAccount) => ({
  type: GET_TOP_SAVINGS_ACCOUNT_SUCCESS,
  topSavingsAccount: topSavingsAccount,
});

export const getTopSavingsAccountFailure = () => ({
  type: GET_TOP_SAVINGS_ACCOUNT_FAILURE,
});

export const getTotalSavingsEmployeeBegin = () => ({
  type: GET_TOTAL_SAVINGS_EMPLOYEE_BEGIN,
});

export const getTotalSavingsEmployeeSuccess = (totalSavingsEmployee) => ({
  type: GET_TOTAL_SAVINGS_EMPLOYEE_SUCCESS,
  totalSavingsEmployee: totalSavingsEmployee,
});

export const getTotalSavingsEmployeeFailure = () => ({
  type: GET_TOTAL_SAVINGS_EMPLOYEE_FAILURE,
});

export const getSavingsSlotsBegin = () => ({
  type: GET_SAVINGS_SLOTS_BEGIN,
});

export const getSavingsSlotsSucess = (savingsSlots) => ({
  type: GET_SAVINGS_SLOTS_SUCCESS,
  savingsSlots,
});

export const getSavingsSlotsFailure = () => ({
  type: GET_SAVINGS_SLOTS_FAILURE,
});

export const getSavingsTablePerEmployeeBegin = () => ({
  type: GET_SAVINGS_TABLE_PER_EMPLOYEE_BEGIN,
});

export const getSavingsTablePerEmployeeSuccess = (savingsTable) => ({
  type: GET_SAVINGS_TABLE_PER_EMPLOYEE_SUCCESS,
  savingsTable,
});

export const getSavingsTablePerEmployeeFailure = () => ({
  type: GET_SAVINGS_TABLE_PER_EMPLOYEE_FAILURE,
});

export const getPayrollStatusInfoBegin = () => ({
  type: GET_PAYROLL_STATUS_INFO_BEGIN,
});

export const getPayrollStatusInfoSuccess = (statusInfo) => ({
  type: GET_PAYROLL_STATUS_INFO_SUCCESSFUL,
  payrollStatusInfo: statusInfo,
});

export const getPayrollStatusInfoFailure = (error) => ({
  type: GET_PAYROLL_STATUS_INFO_FAILURE,
  error,
});

/// /

export function creating() {
  return (dispatch) => {
    dispatch(savingsCreatingBegin());
    return dispatch(savingsCreatingSuceess());
  };
}

export function addBeneficy() {
  return (dispatch) => {
    dispatch(savingsAddBeneficyBegin());
    return dispatch(savingsAddBeneficySuceess());
  };
}

export function removeBeneficy(index) {
  return (dispatch) => {
    dispatch(savingsRemoveBeneficyBegin());
    return dispatch(savingsRemoveBeneficySuceess(index));
  };
}

export function createSavingBank(newSaving) {
  return (dispatch, getState) => {
    dispatch(createSavingBankBegin());
    return request({
      partialUrl: "/api/v1/savings",
      method: "POST",
      state: getState().user.request,
      body: newSaving,
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(setSuccess(data.data.message));
        dispatch(createSavingBankSuccess(data.data));
      })
      .catch((err) => {
        const message = err.response.data.message || err.message;
        dispatch(setError(message));
        return dispatch(createSavingBankFailure(err));
      });
  };
}

export function testSavingReset(employeeId) {
  return (dispatch, getState) => {
    dispatch(testSavingResetBegin());
    return request({
      partialUrl: "/api/v1/savings-banks/test-reset",
      method: "POST",
      state: getState().user.request,
      body: { employeeId },
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(testSavingResetSuccess(data.data));
      })
      .catch((err) => {
        return dispatch(testSavingResetFailure(err));
      });
  };
}

export function getSavingsBanksResumeByBossId(companyId) {
  return (dispatch, getState) => {
    dispatch(getSavingsBanksResumeByBossIdBegin());
    return request({
      partialUrl: `/api/v1/savings-banks/generate-savings-resumen-by-boss-id/${companyId}`,
      method: "GET",
      state: getState().user.request,
      body: { companyId },
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(getSavingsBanksResumeByBossIdSuccess(data.data));
      })
      .catch((err) => {
        return dispatch(getSavingsBanksResumeByBossIdFailure(err));
      });
  };
}

export function getSavingBanksByBossId(bossId) {
  return (dispatch, getState) => {
    dispatch(getSavingBanksByBossIdBegin());
    return request({
      partialUrl: "/api/v1/savings-banks/find-saving-banks-by-boss-id",
      method: "POST",
      state: getState().user.request,
      body: { bossId },
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(getSavingBanksByBossIdSuccess(data.data));
      })
      .catch((err) => {
        return dispatch(getSavingBanksByBossIdFailure(err));
      });
  };
}

export function createSavingsTransactions() {
  return (dispatch, getState) => {
    dispatch(createSavingsTransactionsBegin());
    return request({
      partialUrl: "/api/v1/savings/create-savings-transactions",
      method: "GET",
      state: getState().user.request,
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(createSavingsTransactionsSuccess(data.data));
      })
      .catch((err) => {
        return dispatch(createSavingsTransactionsFailure(err));
      });
  };
}

export function updateSavingsTransactions() {
  return (dispatch, getState) => {
    dispatch(updateSavingsTransactionsBegin());
    return request({
      partialUrl: "/api/v1/savings/update-savings-transactions",
      method: "GET",
      state: getState().user.request,
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(updateSavingsTransactionsSuccess(data.data));
      })
      .catch((err) => {
        return dispatch(updateSavingsTransactionsFailure(err));
      });
  };
}

export function getTopSavingsAccount(companyId) {
  return async (dispatch) => {
    try {
      dispatch(getTopSavingsAccountBegin());
      const response = await axios.get(
        `${publicUrl}/api/v1/savings-banks/find-saving-banks-top?companyId=${companyId}`
      );
      dispatch(getTopSavingsAccountSuccess(response.data));
    } catch (error) {
      dispatch(getTopSavingsAccountSuccess(error.response.data));
      dispatch(getTopSavingsAccountFailure(error));
    }
  };
}

export const getTotalSavingsEmployee = ({ companyId, employeeId }) => {
  return async (dispatch) => {
    try {
      dispatch(getTotalSavingsEmployeeBegin());
      const response = await axios.get(
        `${apiBaseUrlAws}/Salvum/services/savings/getTotalSavings?employeeId=${employeeId}&companyId=${companyId}`
      );
      dispatch(getTotalSavingsEmployeeSuccess(response.data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getTotalSavingsEmployeeFailure(error));
    }
  };
};

export const getSavingsSlots = ({ companyId, employeeId }) => {
  return async (dispatch) => {
    try {
      dispatch(getSavingsSlotsBegin());
      const response = await axios.get(
        `${apiBaseUrlAws}/Salvum/services/savings/getSavingSlots?employeeId=${employeeId}&companyId=${companyId}`
      );
      if (response.data.error) {
        setError(response.data.message);
      }

      dispatch(getSavingsSlotsSucess(response.data));
    } catch (error) {
      if (companyId != null || employeeId != null) {      
        const message = error.response.data.message || error.message;
        dispatch(setError(message));
        dispatch(getSavingsSlotsFailure());
      }
    }
  };
};

export const getSavingsTablePerEmployee = ({ companyId, employeeId }) => {
  return async (dispatch) => {
    try {
      dispatch(getSavingsTablePerEmployeeBegin());
      const response = await axios.get(
        `${apiBaseUrlAws}/Salvum/services/savings/getSavingsTablePerEmployee?employeeId=${employeeId}&companyId=${companyId}`
      );
      dispatch(getSavingsTablePerEmployeeSuccess(response.data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getSavingsTablePerEmployeeFailure());
    }
  };
};

export const getPayrollStatusInfo = ({ companyId, employeeId }) => {
  return async (dispatch) => {
    try {
      dispatch(getPayrollStatusInfoBegin());
      const response = await api.get(
        `Salvum/services/savings/getPayrollStatusInfo?employeeId=${employeeId}&companyId=${companyId}`
      );

      if (response.error) {
        dispatch(setError(response.message));
      }
      dispatch(getPayrollStatusInfoSuccess(response.data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getPayrollStatusInfoFailure(error));
    }
  };
};
