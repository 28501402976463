import axios from "axios";
import { apiBaseUrlAws } from "../actions/consts";

/*
 for those api calls which doesn't explictly need redux store
 to save data to share across the platform, like post, put and delete calls.
*/

const axiosCall = async (method, url, options = {}) => {
  try {
    return await axios({
      method: method,
      url: `${apiBaseUrlAws}/${url}`,
      data: options.data,
      headers: {
        "Content-type": "application/json",
        ...options.headers,
      },
      params: options.params,
    });
  } catch (err) {
    const { response } = err;
    if (response) {
      const {
        data: { message = "Server error" },
      } = response;
      return { error: true, message };
    } else {
      return { error: true, message: err.message };
    }
  }
};

export const api = {
  post: async (url, options) => {
    return axiosCall("post", url, options);
  },
  get: async (url, options) => {
    return axiosCall("get", url, options);
  },
  put: async (url, options) => {
    return axiosCall("put", url, options);
  },
  delete: async (url, options) => {
    return axiosCall("delete", url, options);
  },
};

export default api;
