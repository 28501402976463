import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "react-google-charts";
import { savingTypes } from "./SavingsUtils";
import { getEmployeeSavingDetailResponse } from "../../../../../../actions/employeeAction";

export const SavingsDetails = ({ employeeId, companyId }) => {
  const dispatch = useDispatch();

  const savingDetailResponse = useSelector(
    (state) => state.employee.savingDetailResponse
  );

  useEffect(() => {
    dispatch(
      getEmployeeSavingDetailResponse({
        companyId: companyId,
        employeeId,
      })
    );
  }, []);

  const chartData = savingDetailResponse
    ? [
        ["Tiempo de nómina", "Porcentaje"],
        ...savingDetailResponse.typesOfSaving,
        savingDetailResponse.usedPercentageOfPayroll,
      ]
    : [];

  const chartColors = savingDetailResponse
    ? [
        ...savingDetailResponse.typesOfSaving.map(
          (saving) => savingTypes[saving] && savingTypes[saving].color
        ),
        "#fafafa",
      ]
    : [];

  const isChartValid = savingDetailResponse
    ? savingDetailResponse.typesOfSaving.filter((saving) => saving.amount)
    : [];

  return (
    <div>
      {savingDetailResponse && (
        <div className="grid grid-cols-3 gap-3">
          {isChartValid.length > 0 && (
            <div className="mb-3  flex flex-col">
              <h3 className="title text-sm text-black-50 mb-2">
                Total usado de tu nómina
              </h3>
              <div className="chartDiv">
                <Chart
                  width="200px"
                  height="200px"
                  chartType="PieChart"
                  loader={<div>Cargando..</div>}
                  data={chartData}
                  options={{
                    pieHole: 0.7,
                    legend: "none",
                    pieSliceText: "none",
                    colors: chartColors,
                  }}
                  rootProps={{ "data-testid": "3" }}
                />
              </div>
            </div>
          )}

          <div className="mb-3  flex flex-col ">
            <h3 className="text-sm mb-2">Saldo total acumulado</h3>
            <p className="text-xl">{savingDetailResponse.totalAcumFormatted}</p>
          </div>

          <div className="mb-3  flex flex-col ">
            <h3 className="text-sm mb-2">Última aportación</h3>
            <p className="text-xl">
              {savingDetailResponse.lastPaymentFormatted}
            </p>
          </div>

          <div className="mb-3  flex flex-col ">
            <h3 className="text-sm mb-2">Nómina</h3>
            <p className="text-xl">{savingDetailResponse.payrollFormatted}</p>
          </div>

          <div className="mb-3  flex flex-col ">
            <h3 className="text-sm mb-2">Aportación por periodos</h3>
            <p className="text-xl">
              {savingDetailResponse.principalGivenFormatted}
            </p>
          </div>

          <div className="mb-3  flex flex-col ">
            <h3 className="text-sm mb-2">Ganancias por interés</h3>
            <p className="text-xl">
              {savingDetailResponse.earningsPerInterestFormatted}
            </p>
          </div>
        </div>
      )}
      {!savingDetailResponse && (
        <div>
          <h1 className="text-black text-3xl">
            {" "}
            No se han encontrado detalles para este empleado
          </h1>
        </div>
      )}
    </div>
  );
};

export default SavingsDetails;
