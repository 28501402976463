import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

export class AdminMainContent extends Component {
  render() {
    return (
      <main style={{ marginTop: "60px" }}>
        {this.props && this.props.children}
      </main>
    );
  }
}

AdminMainContent.propTypes = {
  children: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(AdminMainContent);
