/* eslint-disable no-undef */
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

// Components
import { CompanyDetails } from "./Steps/CompanyDetails";
import { SavingTypes } from "./Steps/SavingTypes";
import { PayrollAdvances } from "./Steps/PayrollAdvances";
import { PaymentDays } from "./Steps/PaymentDays";
import { Authorizers } from "./Steps/Authorizers";
import { Loans } from "./Steps/Loans";
import { Reglamento } from "./Steps/Reglamento";

const steps = [
  "Datos de la empresa",
  "Tipos de ahorros",
  "Adelantos",
  "Días de pago",
  "Aprobadores y autorizadores",
  "Reglamento de empresa",
  "Préstamos",
];

export const HorizontalLinearStepper = ({
  companyDetail,
  activeStep,
  setActiveStep,
  setLoansChecked,
  setSavingsChecked,
  setPayrollChecked,
  formik,
  savingsChecked,
  loansChecked,
  payrollChecked,
}) => {
  const [skipped, setSkipped] = useState(new Set());
  const [companyCreated] = useState(false);

  const isStepOptional = (step) => {
    // Tipos de ahorros, Adelantos y Préstamos son opcionales.
    return step === 1 || step === 2;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSavingsChange = (event) => {
    setSavingsChecked(event.target.checked);
  };

  const handlePayrollChange = (event) => {
    setPayrollChecked(event.target.checked);
  };

  const handleLoansChange = (event) => {
    setLoansChecked(event.target.checked);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <div className="w-full">
      {!companyCreated && (
        <>
          <p className="text-center my-6 text-xl">
            Aqui puedes ver y actualizar la informacion de {companyDetail.name}.
          </p>

          <p className="text-center my-6 text-xl">
            Los datos que modifiques serán actualizados y almacenados en la base
            de datos, al presionar Actualizar datos al en el paso #7.
          </p>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Opcional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <form onSubmit={formik.handleSubmit}>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
              ) : (
                formik && (
                  <React.Fragment>
                    {activeStep === 0 && (
                      <CompanyDetails
                        step={activeStep}
                        formik={formik}
                        handleNext={handleNext}
                        skipped={skipped}
                        handleBack={handleBack}
                        handleSkip={handleSkip}
                      />
                    )}

                    {activeStep === 1 && (
                      <SavingTypes
                        step={activeStep}
                        formik={formik}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        handleSkip={handleSkip}
                        companyDetail={companyDetail}
                        checked={savingsChecked}
                        handleChange={handleSavingsChange}
                        skipped={skipped}
                      />
                    )}

                    {activeStep === 2 && (
                      <PayrollAdvances
                        step={activeStep}
                        formik={formik}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        handleSkip={handleSkip}
                        checked={payrollChecked}
                        handleChange={handlePayrollChange}
                        skipped={skipped}
                      />
                    )}
                    {activeStep === 3 && (
                      <PaymentDays
                        step={activeStep}
                        formik={formik}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        handleSkip={handleSkip}
                        skipped={skipped}
                      />
                    )}

                    {activeStep === 4 && (
                      <Authorizers
                        step={activeStep}
                        formik={formik}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        handleSkip={handleSkip}
                        skipped={skipped}
                      />
                    )}

                    {activeStep === 5 && (
                      <Reglamento
                        step={activeStep}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        handleSkip={handleSkip}
                        skipped={skipped}
                        companyId={companyDetail.companyId}
                      />
                    )}

                    {activeStep === 6 && (
                      <Loans
                        step={activeStep}
                        formik={formik}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        handleSkip={handleSkip}
                        checked={loansChecked}
                        handleChange={handleLoansChange}
                        skipped={skipped}
                      />
                    )}
                  </React.Fragment>
                )
              )}
            </form>
          </Box>
        </>
      )}
    </div>
  );
};

export default HorizontalLinearStepper;
