import * as React from "react";
import { CircularProgress } from "@mui/material";
import "./LoadingCircularSpinner.scss";

export const LoadingCircularSpinner = ({ show = false }) => {
  return (
    show && (
      <div className="circular-spinner-container">
        <CircularProgress
          style={{ width: "70px", height: "70px" }}
          color="success"
        />
      </div>
    )
  );
};

export default LoadingCircularSpinner;
