import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeLoanDetail } from "../../../../../../actions/employeeAction";
import { BossLoansTable } from "../../../../../../components/Tables";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { ApproveLoanModal, RejectLoanModal } from "./Modals";
// import moment from 'moment'

const useColors = () => {
  return {
    Autorizado: {
      color: "white",
      backgroundColor: "#15db93",
      fontSize: "14px",
    },
    Pendiente: {
      color: "black",
      backgroundColor: "#ffc300",
      fontSize: "14px",
    },
    Aprobado: {
      color: "white",
      backgroundColor: "#15db93",
      fontSize: "14px",
    },
    Rechazado: {
      color: "white",
      backgroundColor: "#d9484e",
      fontSize: "14px",
    },
    Liquidado: {
      color: "white",
      backgroundColor: "#4897d9",
      fontSize: "14px",
    },
    Dispersado: {
      color: "black",
      backgroundColor: "#ffc300",
      fontSize: "14px",
    },
  };
};

export const LoansDetails = ({ companyId, companyName, employeeId }) => {
  const dispatch = useDispatch();
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [loanId, setLoanId] = useState(null);
  const pendingLoans = useSelector((state) => state.employee.loanDetail);
  const user = useSelector((state) => state.user.user);
  useEffect(() => {
    dispatch(
      getEmployeeLoanDetail({ companyId, bossUserId: user.id, employeeId })
    );
  }, []);

  return (
    <div>
      <ApproveLoanModal
        setIsOpen={setIsApproveModalOpen}
        isOpen={isApproveModalOpen}
        companyId={companyId}
        employeeId={employeeId}
        loanId={loanId}
      />
      <RejectLoanModal
        setIsOpen={setIsRejectModalOpen}
        isOpen={isRejectModalOpen}
        loanId={loanId}
      />
      <h2
        className="text-black text-2xl mt-6 mb-6"
        style={{ fontSize: "48px" }}
      >
        {companyName}
      </h2>
      <div
        style={{
          width: "100%",
          height: "2px",
          color: "black",
          border: "2px solid",
          marginBottom: "20px",
        }}
      />
      <div className="mb-10 principalContentCompany">
        <div className="savingsContent">
          <div className="tabHeader3">
            <h2 className="text-black text-2xl">Resumen de préstamos</h2>
          </div>
          <div className="grayLine"></div>
          <div className="grid grid-cols-3 grap-3 tabHeader2">
            <div className="m-4 tabItem">
              <p className="grayText">Por aprobar</p>
              <p className="blackText">
                <span className="blue-text">
                  {pendingLoans && pendingLoans.loansSummary.pending}
                </span>
              </p>
            </div>
            <div className="m-4 tabItem">
              <p className="grayText">Pagados</p>
              <p className="blackText">
                <span className="green-text">
                  {pendingLoans && pendingLoans.loansSummary.paid}
                </span>
              </p>
            </div>
            <div className="m-4 tabItem">
              <p className="grayText">En curso</p>
              <p className="blackText">
                {pendingLoans && pendingLoans.loansSummary.inCurse}
              </p>
            </div>
            <div className="m-4 tabItem">
              <p className="grayText">Rechazados</p>
              <p className="blackText">
                <span className="red-text">
                  {pendingLoans && pendingLoans.loansSummary.rejected}
                </span>
              </p>
            </div>
            <div className="m-4 tabItem">
              <p className="grayText">Saldo total prestado</p>
              <p className="blackText">
                <span className="smallText">
                  {pendingLoans && pendingLoans.loansSummary.totalAmountLend}
                </span>
              </p>
            </div>
            <div className="m-4 tabItem">
              <p className="grayText">
                Saldo pendiente de empleado por liquidar
              </p>
              <p className="blackText">
                <span className="smallText">
                  {pendingLoans &&
                    pendingLoans.loansSummary.pendingAmountToRecover}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>

      {pendingLoans &&
        pendingLoans.pendingLoans &&
        pendingLoans.pendingLoans.length > 0 && (
          <>
            <h3 style={{ color: "#c5c5c5", fontSize: "36px" }}>
              Préstamos pendientes.
            </h3>
            {pendingLoans.pendingLoans.map((item) => (
              <PendingLoans
                companyName={companyName}
                key={item.loanId}
                loan={item}
                setIsApproveModalOpen={setIsApproveModalOpen}
                setIsRejectModalOpen={setIsRejectModalOpen}
                setLoanId={setLoanId}
              />
            ))}
          </>
        )}
      {pendingLoans &&
        pendingLoans.pendingLoans &&
        pendingLoans.pendingLoans.length === 0 && (
          <h3 style={{ color: "#c5c5c5", fontSize: "36px" }}>
            No tiene préstamos pendientes.
          </h3>
        )}
      <h3 style={{ color: "#c5c5c5", fontSize: "36px" }}>Historial</h3>
      {pendingLoans &&
        pendingLoans.history.map((loan) => (
          <HistoryLoans key={loan.loanId} item={loan} />
        ))}
    </div>
  );
};

const PendingLoans = ({
  loan,
  setIsApproveModalOpen,
  setIsRejectModalOpen,
  setLoanId,
}) => {
  return (
    <>
      <BossLoansTable
        setLoanId={setLoanId}
        row={loan}
        setIsApproveModalOpen={setIsApproveModalOpen}
        setIsRejectModalOpen={setIsRejectModalOpen}
      />
    </>
  );
};

const HistoryLoans = ({ item }) => {
  const [showTable, setShowTable] = useState(false);
  const toggleShowTable = () => {
    setShowTable(!showTable);
  };
  const colors = useColors();
  return (
    <div className="principalContentCompany text-black mb-10">
      <div className="savingsContent">
        <div className="flex justify-between tabHeader2">
          <div className="tabItemC first">
            <p className="grayText">Monto de préstamo</p>
            <p className="blackText">{item.loanQuanityFormatted}</p>
          </div>
          <div className="tabItemC">
            <p className="grayText">Plazo</p>
            <p className="blackText"> {item.periods}</p>
          </div>
          <div className="tabItemC">
            <p className="grayText">Saldo por liquidar</p>
            <p className="blackText"> {item.amountToClose}</p>
          </div>
          <div className="tabItemC name">
            <p className="grayText">Nombre</p>
            <p className="name">{item.employeeName}</p>
          </div>
          <div className="tabItemC">
            <div className="container-box">
              <p className="grayText">Estatus</p>
              <span
                className="pr-6 pl-6 pt-1 pb-1 rounded-full"
                style={{ ...colors[item.loanStatusFormatted] }}
              >
                {item.loanStatusFormatted}
              </span>
            </div>
          </div>
          {!showTable && (
            <ChevronDownIcon
              className="w-10 h-10 text-black"
              onClick={toggleShowTable}
            />
          )}
          {showTable && (
            <ChevronUpIcon
              className="w-10 h-10 text-black"
              onClick={toggleShowTable}
            />
          )}
        </div>
        {showTable && (
          <>
            <div className="grayLine"></div>
            <div className="flex justify-between">
              <div className="tabItemB"></div>
              <div className="tabItemB">
                <p className="grayText">Fecha de expedición</p>
                <p className="blackTextDate">{item.creationDateFormatted}</p>
              </div>
              <div className="tabItemB">
                <p className="grayText">Última aportación</p>
                <p className="blackTextDate">
                  {/* {moment.unix(item.updatedAt / 1000).format("DD/MM/YYYY")} */}
                  No disponible
                </p>
              </div>
              <div className="tabItemB">
                <p className="grayText">Pagos realizados</p>
                No disponible
              </div>
              <div className="tabItemB"></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LoansDetails;
