import React, { Component } from "react";
import { connect } from "react-redux";
import {
  clearCreatedLaon,
  getLoansByemployeeId,
  getInfoLoanByemployeeIdAndCompanyId,
  getAmortizationTable,
  getLoanGeneralDetails,
} from "../../../actions/loansAction";
import { getCompanyById } from "../../../actions/companyAction";
import "./EmployeeLoan.scss";

import { LoanForm } from "./LoanForm/LoanForm";
import _ from "lodash";
import { LoanCard } from "./LoanCard";

const paymentsTable = [];

export class EmployeeLoan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: paymentsTable,
      loansDetailsVisibility: {},
      openModalAskForLoad: false,
      openLoanForm: false,
      marks: [
        {
          value: 0,
          label: "0°C",
        },
        {
          value: 2500,
          label: "20°C",
        },
      ],
      endorsements: [],
      contadorArray: 1,
      sliderValue: 0,
      valorSlider: 0,
      meses: 0,
      endorsement1: 0,
      endorsement2: 0,
      endorsement3: 0,
      interestFormula: 0,
      paymentFormula: 0,
      adeudoTotal: 0,
      diaDelSiguientePago: 0,
      diaDeSolicitud: 0,
      montoBase: 0,
      montoPorDiferenciaDeDias: 0,
      capital: 0,
      pagos: 0,
      saldoInsoluto: 0,
      balanceInicial: 0,
      companyLaborSeniority: 0,
      seniority_1: false,
      seniority_2: false,
      seniority_5: false,
      seniority_6: false,
      disableSelect: false,
      disableEndorsement1: false,
      disableEndorsement2: false,
      disableEndorsement3: false,
      toggleTerms: false,
      disableTerms: false,
      loanInterestPercentage: 0,
      loanCommissionPercentage: 0,
      loanMaxAmount: 0,
      loanAmount1: 0,
      loanAmount2: 0,
      loanAmount3: 0,
      loansData: {
        loanId: 1,
        loanBossId: 1,
        loanEmployeeId: 1,
        loanAmount: 0,
        loanStatusId: 1,
      },
      loanPayment: true,
    };

    this.openLoanForm = this.openLoanForm.bind(this);

    this.onChangeSaveData1 = this.onChangeSaveData1.bind(this);
    this.onChangeSaveData2 = this.onChangeSaveData2.bind(this);
    this.onChangeSaveData3 = this.onChangeSaveData3.bind(this);
    this.onChangeMeses = this.onChangeMeses.bind(this);
    this.isChecked = this.isChecked.bind(this);
    this.calculateAmortizationTable =
      this.calculateAmortizationTable.bind(this);
  }

  loanStatus = {
    to_be_approved: {
      name: "to_be_approved",
      label: "Por Aprobar",
      backgroundColor: "#ffc300",
    },
    approved: {
      name: "approved",
      label: "Aprobado",
      backgroundColor: "#15db93",
    },
    rejected: {
      name: "rejected",
      label: "Rechazado",
      backgroundColor: "#d9484e",
    },
    paid: { name: "paid", label: "Pagado", backgroundColor: "#4897d9" },
  };

  componentDidMount = () => {
    const { dispatch, user } = this.props;
    const { id: employeeId, companyId } = user || {};
    //this.props.dispatch(getEmployeeByUserId(this.props.user.id));
    dispatch(getCompanyById(companyId));
    dispatch(getLoansByemployeeId({ employeeId, companyId }));
    dispatch(getInfoLoanByemployeeIdAndCompanyId(employeeId, companyId));
    dispatch(getLoanGeneralDetails({ employeeId, companyId }));
  };

  calculateAmortizationTable = (creditAmount, periods) => {
    this.props.dispatch(
      getAmortizationTable(
        creditAmount,
        periods,
        this.props.employee.userid,
        this.props.employee.companyid
      )
    );
  };

  toggleLoanDetailVisibility = (loanId) => {
    const currentVisibility = !!this.state.loansDetailsVisibility[loanId];
    this.setState({
      loansDetailsVisibility: {
        ...this.state.loansDetailsVisibility,
        [loanId]: !currentVisibility,
      },
    });
  };

  getLoanDetailVisibility = (loanId) => {
    return !!this.state.loansDetailsVisibility[loanId];
  };

  onChangeSaveData1(event) {
    this.setState({ endorsement1: event.target.value });

    if (this.props.company.loanNumberOfEndorsements > 1) {
      this.setState({ disableEndorsement2: true });
    } else if (this.props.company.loanNumberOfEndorsements == 1) {
      this.setState({ disableTerms: true });
    }
  }

  onChangeSaveData2(event) {
    this.setState({ endorsement2: event.target.value });

    if (this.props.company.loanNumberOfEndorsements > 2) {
      this.setState({ disableEndorsement3: true });
    } else if (this.props.company.loanNumberOfEndorsements == 2) {
      this.setState({ disableTerms: true });
    }
  }

  onChangeSaveData3(event) {
    this.setState({
      endorsement3: event.target.value,
      disableTerms: true,
    });
  }

  onChangeMeses() {
    var e = document.getElementById("meses").value;
    this.setState({ meses: e });

    if (this.state.meses > 0) {
      this.setState({ disableEndorsement1: true });
    } else {
      this.setState({ disableEndorsement1: false });
    }
    return this.deleteArray();
  }

  showSelectOptions() {
    if (this.state.companyLaborSeniority == 1) {
      this.setState({ seniority_1: true });
    } else if (
      this.state.companyLaborSeniority >= 2 &&
      this.state.companyLaborSeniority < 5
    ) {
      this.setState({
        seniority_1: true,
        seniority_2: true,
      });
    } else if (this.state.companyLaborSeniority == 5) {
      this.setState({
        seniority_1: true,
        seniority_2: true,
        seniority_5: true,
      });
    } else if (this.state.companyLaborSeniority >= 6) {
      this.setState({
        seniority_1: true,
        seniority_2: true,
        seniority_5: true,
        seniority_6: true,
      });
    }
  }

  payrollType = () => {
    switch (this.props.employee.payrolltype) {
      case 3:
        var date = new Date();
        this.setState({
          diaDeSolicitud: date.getDate(),
          diaDelSiguientePago: new Date(
            date.getFullYear(),
            date.getMonth() + 1,
            0
          ).getDate(),
          pagos: 1,
        });

        return this.adeudoTotal();

      case 2:
        date = new Date();

        this.setState({ diaDeSolicitud: date.getDate() });
        if (this.state.diaDeSolicitud < 15) {
          this.setState({
            diaDelSiguientePago: 15,
          });
        } else if (this.state.diaDeSolicitud > 15) {
          this.setState({
            diaDelSiguientePago: new Date(
              date.getFullYear(),
              date.getMonth() + 1,
              0
            ).getDate(),
          });
        }

        this.setState({ pagos: 2 });
        return this.adeudoTotal();
      case 1:
        date = new Date();

        var dayOfWeek = 5; //friday
        var nextPayday = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        );
        nextPayday.setDate(
          nextPayday.getDate() + ((dayOfWeek + 7 - nextPayday.getDay()) % 7)
        );

        this.setState({
          diaDeSolicitud: date.getDate(),
          diaDelSiguientePago: nextPayday.getDate(),
          pagos: 4,
        });

        return this.adeudoTotal();
    }
  };

  deleteArray = () => {
    var arrayLength = this.state.data.length,
      array = this.state.data;
    this.setState({ contadorArray: 1 });
    array.splice(0, arrayLength);
    return this.payrollType();
  };

  finalBalance = (loanId, status) => {
    var payments = 0,
      total = 0,
      interest = 0,
      totalPayment = 0;

    //var adeudoTotal = 0;
    if (
      this.props.loanHasTransactions &&
      this.props.loanHasTransactions.transactions.length > 0
    ) {
      const transactions = this.props.loanHasTransactions.transactions.filter(
        (p) => p.loanId == loanId && p.paid == true
      ).length;
      payments = this.props.loanHasTransactions.transactions
        .filter((p) => p.loanId == loanId && p.paid == true)
        .reduce((prev, current) => prev + current.paymentPerPeriod, 0);
      interest = this.props.loanHasTransactions.transactions
        .filter((p) => p.loanId == loanId && p.paid == true)
        .reduce((prev, current) => prev + current.interest, 0);
      if (transactions > 0) {
        total = this.props.loanHasTransactions.transactions
          .filter((p) => p.loanId == loanId && p.paid == true && p.period === 1)
          .reduce((prev, current) => prev + current.initialBalance * 1, 0);
      } else {
        total = this.props.loans.loans
          .filter((p) => p.id == loanId)
          .reduce((prev, current) => prev + current.totalDebt, 0);
      }
    }
    if (status == 2 || status == 4) {
      totalPayment = total + interest - payments;
      if (totalPayment < 1) {
        totalPayment = 0;
      }
    } else if (status == 1 || status == 3) {
      totalPayment = this.props.loans.loans
        .filter((p) => p.id == loanId)
        .reduce((prev, current) => prev + current.totalDebt, 0);
    }

    return _.round(totalPayment, 2);
  };

  totalPayments = (loanId) => {
    const total = this.props.loanHasTransactions.transactions.filter(
      (p) => p.loanId == loanId && p.paid == true
    ).length;
    return total;
  };

  getDate = () => {
    var date = Date.now();
    //const date = d.now()
    return date;
  };

  getPayrollType = (type) => {
    switch (type) {
      case 1:
        return `Semanal`;

      case 2:
        return `Quincenal`;

      case 3:
        return `Mensual`;
    }
  };

  getNextPayday = (type) => {
    var date = new Date();
    const today = date.getDate();

    switch (type) {
      case 3:
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime();

      case 2:
        if (today < 15) {
          return new Date(date.getFullYear(), date.getMonth(), 15).getTime();
        } else if (today > 15) {
          return new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime();
        }
        break;
      case 1:
        var dayOfWeek = 5; //friday
        var todayDate = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        );
        todayDate.setDate(
          todayDate.getDate() + ((dayOfWeek + 7 - todayDate.getDay()) % 7)
        );
        return todayDate;
    }
  };
  //END MODAL BODY ASK FOR LOAD

  isChecked() {
    this.setState({
      toggleTerms: !this.state.toggleTerms,
    });
  }

  openLoanForm() {
    this.setState({
      openLoanForm: !this.state.openLoanForm,
    });
  }

  toggleModalAskForLoad = () => {
    this.setState({
      openLoanForm: !this.state.openLoanForm,
    });
  };

  loanStatusLabel = (loanStatus) => {
    switch (loanStatus) {
      case 1:
        return `Pendiente`;

      case 2:
        return `Aprobado`;

      case 3:
        return `Rechazado`;

      case 4:
        return `Pagado`;
    }
  };

  lastPaymentDate = (loanId) => {
    this.props.loanHasTransactions.transactions
      .filter((loan) => loan.loanId == loanId)
      .map((loan, i, arr) => {
        if (arr.length - 1 === i) {
          // last one
          const last = loan.paymentDate;
          return last;
        }
      });
  };

  render = () => {
    const { loanGeneralDetails } = this.props;
    //const totalLoans = this.props.loans.loans.length;
    if (this.props.newLoan && this.props.newLoan.created) {
      this.props.dispatch(clearCreatedLaon());
    }

    const hasSavings = this.props.savingSlots
      ? !this.props.savingSlots.isEmployeeBrandNew
      : false;

    return (
      <>
        {this.state.openLoanForm && (
          <LoanForm
            isOpen={this.openLoanForm}
            toggle={this.toggleModalAskForLoad}
            calculateAmortizationTable={this.calculateAmortizationTable}
          />
        )}

        {!hasSavings && (
          <div className={"load"}>
            <div className={"loadHeroContent"}>
              <div className={"loadContainer"}>
                <ul className={"breadcrumbs"}>
                  <li>Inicio</li>
                  <li>Préstamos</li>
                </ul>
                <div className="loadHero">
                  <div className="content">
                    <p className="contentTitle">Préstamos</p>
                    <p className="text-style-1 text-2xl mr-20">
                      Para solicitar un préstamo en Salvum, primero necesitas
                      comenzar a ahorrar con nosotros.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {!this.state.openLoanForm && hasSavings && (
          <>
            <div className={"load"}>
              <div className={"loadHeroContent"}>
                <div className={"loadContainer"}>
                  <ul className={"breadcrumbs"}>
                    <li>Inicio</li>
                    <li>Préstamos</li>
                  </ul>

                  <div className={"loadHero"}>
                    <div className={"content"}>
                      <span className={"contentTitle"}>Préstamos</span>
                      <p>
                        <span className={"text-style-1"}>
                          Para solicitar un préstamo es necesario que cumplas
                          con los siguientes requisitos:
                        </span>
                      </p>
                      <p>
                        <ul>
                          <li>
                            1.- Haber cumplido con un año laboral en la empresa
                          </li>
                          <li>2.- Estar inscrito a préstamos</li>
                          <li>3.- No tener 3 adeudos en curso</li>
                        </ul>
                      </p>
                      <p>
                        Una vez cumplidos estos requisitos podrás solicitar tus
                        préstamos dependiendo de la &nbsp;
                        <span className={"text-style-1"}>
                          antigüedad mensual que tengas en la empresa donde
                          laboras.
                        </span>
                      </p>
                    </div>

                    <div className={"sideTableContainer"}>
                      <div className={"sideTableContent"}>
                        <div className={"sideItem"}>
                          <span className={"key"}>
                            Descuento máximo por mes
                          </span>

                          <span className={"value"}>
                            {(loanGeneralDetails &&
                              loanGeneralDetails.creditAvailableFormatted) ||
                              "No disponible"}
                          </span>
                        </div>
                        <div className={"sideItem"}>
                          <span className={"key"}>Solicitado</span>
                          <span className={"value"}>
                            {(loanGeneralDetails &&
                              loanGeneralDetails.creditRequestedFormatted) ||
                              "No disponible"}
                          </span>
                        </div>
                        <hr />
                        <div className={"sideItem"}>
                          <span className={"key"}>Días laborados</span>
                          <span className={"value green"}>
                            {(loanGeneralDetails &&
                              loanGeneralDetails.daysWorkedFormatted) ||
                              "No disponible"}
                          </span>
                        </div>
                        <div className={"sideItem"}>
                          <span className={"key"}>Inscrito a préstamos</span>
                          <span className={"value green"}>
                            {(loanGeneralDetails &&
                              loanGeneralDetails.loanServiceEnrolledFormatted) ||
                              "No disponible"}
                          </span>
                        </div>
                        <div className={"sideItem"}>
                          <span className={"key"}>Adeudos</span>
                          <span className={"value green"}>
                            {(loanGeneralDetails &&
                              loanGeneralDetails.currentLoans) ||
                              "No disponible"}
                          </span>
                        </div>
                        {/*
                                            
                                        <button 
                                            disabled={
                                                this.props.company && !this.props.company.hasLoans ||
                                                this.generateDayWorked() < 365 ||
                                                this.props.loans && this.generateTextSolicitarPrestamo() >= 3
                                            } 
                                            onClick={this.openModalAskForLoad}>
                                                Solicitar préstamo
                                        </button>

                                        //Deprecated ene21
                                        <button 
                                            onClick={this.openModalAskForLoad}>
                                                Solicitar préstamo
                                        </button>

                                        */}

                        <button onClick={this.openLoanForm}>
                          Solicitar préstamo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                <div className={"loadHistoryContent"}>
                  <div className={"loadHistory"}>
                    <div className={"loadMaxHistory"}>
                      <h2>
                        Historial de préstamos (
                        {this.props.loans && this.props.loans.loans.length})
                      </h2>
                      <div className={"loadHistoryLine"}></div>
                      {this.props.loans && this.props.loans.loans.length <= 0 && (
                        <div className={"PaymentNoRegister"}>
                          <p>Sin préstamos registrados</p>
                        </div>
                      )}
                      {this.props.loans &&
                        this.props.loans.loans.length > 0 &&
                        this.props.loans.loans.map((item, index) => (
                          <LoanCard key={index} item={item} index={index} />
                        ))}
                    </div>
                  </div>
                </div>
              }
              {/*<div className={"loadHistoryContent"}>
                        <div className={"loadHistory"}>
                        <div className={"loadMaxHistory"}>
                            <h2>Historial de horizontes ({this.state.loans && this.state.loans.length})</h2>
                            <div className={"loadHistoryLine"}></div>
                            { this.state.loans && this.state.loans.length <= 0 &&
                                <div className={"PaymentNoRegister"}>
                                    <p>Sin préstamos registrados</p>
                                </div>
                            }
                            { this.state.loans && this.state.loans.length > 0 &&
                                this.state.loans.map(item => this.renderLoanCard(item))
                            }
                        </div>
                        </div>
                    </div>*/}
            </div>
          </>
        )}
      </>
    );
  };
}

function mapStateToProps(state) {
  return {
    ...state,
    user: state.user.user,
    loans: state.loans.loans,
    summary: state.employee.summary,
    newLoan: state.loans.newLoan,
    employee: state.employee.current,
    company: state.company.current,
    employees: state.employee.list,
    loanGeneralDetails: state.loans.loanGeneralDetails,
    loanHasTransactions: state.loans.loanTransactions,
    savingSlots: state.savings.savingsSlots,
  };
}

export default connect(mapStateToProps)(EmployeeLoan);
