import {
  USER_LOGIN_BEGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT_BEGIN,
  USER_LOGOUT_SUCCESS,
  USER_LOGOUT_FAILURE,
  CONTACT_LOADING,
  CONTACT_SUCCESS,
  CONTACT_FAILURE,
  CHECK_FOR_SESSION_BEGIN,
  CHECK_FOR_SESSION_SUCCESS,
  CHECK_FOR_SESSION_FAILURE,
} from "../constants/actionTypes";

import initialState from "./initialState";

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case USER_LOGIN_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGIN_SUCCESS: {
      const stateOld = JSON.parse(JSON.stringify(state));
      stateOld.request.headers.Authorization = `${action.user.jwt}`;
      stateOld.role = action.user.data.role.name;
      return {
        ...stateOld,
        loading: false,
        isAuthenticated: true,
        user: action.user.data,
        token: action.user.jwt,
        lastLogin: action.user.lastLogin,
        checkingForSession: false,
      };
    }
    case USER_LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error || "algun error",
      };

    case CHECK_FOR_SESSION_BEGIN:
      return {
        ...state,
        checkingForSession: true,
      };
    case CHECK_FOR_SESSION_SUCCESS: {
      const stateOld = JSON.parse(JSON.stringify(state));
      stateOld.request.headers.Authorization = `${action.user.jwt}`;
      stateOld.role = action.user.data.role.name;
      return {
        ...stateOld,
        loading: false,
        isAuthenticated: true,
        checkingForSession: false,
        lastLogin: action.user.lastLogin,
        user: action.user.data,
      };
    }
    case CHECK_FOR_SESSION_FAILURE: {
      return {
        ...state,
        checkingForSession: false,
        redirectToLogin: true,
      };
    }
    case USER_LOGOUT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...initialState.user,
      };
    case USER_LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error || "Se ha generado algun error",
      };

    case CONTACT_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: "",
      };
    case CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        contactData: action.message,
        errorMessage: "",
      };
    case CONTACT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.error || "Fallo la solicitud",
      };

    default:
      return state;
  }
}
