import React, { useEffect } from "react";

// Stores
import { useDispatch, useSelector } from "react-redux";

// Actions
import { getEmployeeCashAdvanceDetail } from "../../../../../../actions/employeeAction";

// Utils
import { loanStatusChange } from "../../../../../../utils/statusColors";

export const AdvancesDetails = ({ employeeId, companyId }) => {
  const dispatch = useDispatch();

  const cashAdvanceDetail = useSelector(
    (state) => state.employee.cashAdvanceDetail
  );

  useEffect(() => {
    dispatch(
      getEmployeeCashAdvanceDetail({
        employeeId,
        companyId,
      })
    );
  }, []);

  if (!cashAdvanceDetail) {
    return null;
  }

  return (
    <div>
      <div
        id="main-details-container"
        className="flex justify-between border rounded-2xl p-4"
      >
        <div className="flex flex-col">
          <h4 className="text-gray-500 text-sm">Por aprobar</h4>
          <p className="text-center mt-2">
            {cashAdvanceDetail.pendingForApprove}
          </p>
        </div>

        <div className="flex flex-col">
          <h4 className="text-gray-500 text-sm">Aceptados</h4>
          <p className="text-center mt-2">{cashAdvanceDetail.approved}</p>
        </div>

        <div className="flex flex-col">
          <h4 className="text-gray-500 text-sm">Rechazados</h4>
          <p className="text-center mt-2">{cashAdvanceDetail.rejected}</p>
        </div>

        <div className="flex flex-col">
          <h4 className="text-gray-500 text-sm">Monto total</h4>
          <p className="text-center mt-2">
            {cashAdvanceDetail.amountFormatted}
          </p>
        </div>
      </div>
      <div className="grayLine" />
      <section>
        <h2 className="my-10 text-2xl">
          Solicitudes ({cashAdvanceDetail.pending.length || 0})
        </h2>

        <div className="flex justify-between border rounded-2xl p-5">
          {cashAdvanceDetail.pending.map((request, index) => (
            <>
              <div
                key={`peding-request-${index + 1}`}
                className="flex flex-col"
              >
                <h4 className="text-gray-500 text-sm">Monto del adelanto</h4>

                <p className="text-center text-base text-black mt-2">
                  {request.amountFormatted}
                </p>
              </div>

              <div
                key={`peding-request-${index + 1}`}
                className="flex flex-col"
              >
                <h4 className="text-gray-500 text-sm">Fecha de petición</h4>

                <p className="text-center text-base text-black mt-2">
                  {request.requestedDateFormatted}
                </p>
              </div>

              <div
                key={`peding-request-${index + 1}`}
                className="flex flex-col"
              >
                <h4 className="text-gray-500 text-sm">Estatus</h4>

                <p
                  className={`${loanStatusChange(
                    request.statusFormatted
                  )} text-center text-base text-white mt-2`}
                >
                  {request.statusFormatted}
                </p>
              </div>
            </>
          ))}
        </div>
      </section>

      <div className="grayLine" />
      <section>
        <h2 className="my-10 text-2xl">
          Histórico ({cashAdvanceDetail.history.length || 0})
        </h2>

        {cashAdvanceDetail.history.map((request, index) => (
          <div
            key={`peding-request-${index + 1}`}
            className="mb-6 flex justify-between border rounded-2xl p-5"
          >
            <div className="flex flex-col">
              <h4 className="text-gray-500 text-sm">Monto del adelanto</h4>

              <p className="text-center text-base text-black mt-2">
                {request.amountFormatted}
              </p>
            </div>

            <div key={`peding-request-${index + 1}`} className="flex flex-col">
              <h4 className="text-gray-500 text-sm">Fecha de petición</h4>

              <p className="text-center text-base text-black mt-2">
                {request.requestedDateFormatted}
              </p>
            </div>

            <div key={`peding-request-${index + 1}`} className="flex flex-col">
              <h4 className="text-gray-500 text-sm">Estatus</h4>

              <p
                className={`${loanStatusChange(
                  request.statusFormatted
                )} text-center text-base text-white mt-2`}
              >
                {request.statusFormatted}
              </p>
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default AdvancesDetails;
