import request from "../utils/request";
import payrollAdvanceServices from "../services/payrollAdvanceServices";
import { api } from "../services/api";
import {
  CREATE_PAYROLL_ADVANCE_BEGIN,
  CREATE_PAYROLL_ADVANCE_SUCCESS,
  CREATE_PAYROLL_ADVANCE_FAILURE,
  CLEAR_CREATED_PAYROLL_ADVANCE,
  GET_PAYROLL_ADVANCE_BY_BOSS_AND_STATUS_ID_BEGIN,
  GET_PAYROLL_ADVANCE_BY_BOSS_AND_STATUS_ID_SUCCESS,
  GET_PAYROLL_ADVANCE_BY_BOSS_AND_STATUS_ID_FAILURE,
  GET_PAYROLL_ADVANCE_BY_BOSS_ID_BEGIN,
  GET_PAYROLL_ADVANCE_BY_BOSS_ID_SUCCESS,
  GET_PAYROLL_ADVANCE_BY_BOSS_ID_FAILURE,
  GET_PAYROLL_ADVANCE_RESUME_BY_BOSS_ID_BEGIN,
  GET_PAYROLL_ADVANCE_RESUME_BY_BOSS_ID_SUCCESS,
  GET_PAYROLL_ADVANCE_RESUME_BY_BOSS_ID_FAILURE,
  UPDATE_PAYROLL_ADVANCE_STATUS_ID_BEGIN,
  UPDATE_PAYROLL_ADVANCE_STATUS_ID_SUCCESS,
  UPDATE_PAYROLL_ADVANCE_STATUS_ID_FAILURE,
  REQUEST_PAYROLL_ADVANCE_BEGIN,
  REQUEST_PAYROLL_ADVANCE_SUCCESS,
  REQUEST_PAYROLL_ADVANCE_FAILURE,
  GET_CASH_ADVANCE_DASHBOARD_SUCCESS,
  GET_CASH_ADVANCE_DASHBOARD_FAILURE,
  GET_PENDING_CASH_ADVANCE_FOR_ADMIN_FAILURE,
  GET_PENDING_CASH_ADVANCE_FOR_ADMIN_SUCCESS,
  GET_PENDING_CASH_ADVANCE_FOR_BOSS_SUCCESS,
  GET_PENDING_CASH_ADVANCE_FOR_BOSS_FAILURE,
  GET_CASH_ADVANCE_EMPLOYEE_HOME_INFO_SUCCESS,
  GET_CASH_ADVANCE_EMPLOYEE_HOME_INFO_FAILURE,
} from "../constants/actionTypes";
import { setError } from "./errorAction";
import { setSuccess } from "./successAction";

export const createPayrollAdvanceBegin = () => ({
  type: CREATE_PAYROLL_ADVANCE_BEGIN,
});

export const createPayrollAdvanceSuccess = () => ({
  type: CREATE_PAYROLL_ADVANCE_SUCCESS,
});

export const createPayrollAdvanceFailure = () => ({
  type: CREATE_PAYROLL_ADVANCE_FAILURE,
});

export const clearCreatedPayrollAdvance = () => ({
  type: CLEAR_CREATED_PAYROLL_ADVANCE,
});

export const getPayrollAdvanceByBossAndStatusIdBegin = () => ({
  type: GET_PAYROLL_ADVANCE_BY_BOSS_AND_STATUS_ID_BEGIN,
});

export const getPayrollAdvanceByBossAndStatusIdSuccess = (
  payrollAdvanceByStatus
) => ({
  type: GET_PAYROLL_ADVANCE_BY_BOSS_AND_STATUS_ID_SUCCESS,
  payrollAdvanceByStatus,
});

export const getPayrollAdvanceByBossAndStatusIdFailure = () => ({
  type: GET_PAYROLL_ADVANCE_BY_BOSS_AND_STATUS_ID_FAILURE,
});

export const getPayrollAdvanceByBossIdBegin = () => ({
  type: GET_PAYROLL_ADVANCE_BY_BOSS_ID_BEGIN,
});

export const getPayrollAdvanceByBossIdSuccess = (payrollAdvance) => ({
  type: GET_PAYROLL_ADVANCE_BY_BOSS_ID_SUCCESS,
  payrollAdvance,
});

export const getPayrollAdvanceByBossIdFailure = () => ({
  type: GET_PAYROLL_ADVANCE_BY_BOSS_ID_FAILURE,
});

export const getPayrollAdvanceResumeByBossIdBegin = () => ({
  type: GET_PAYROLL_ADVANCE_RESUME_BY_BOSS_ID_BEGIN,
});

export const getPayrollAdvanceResumeByBossIdSuccess = (
  payrollAdvanceResume
) => ({
  type: GET_PAYROLL_ADVANCE_RESUME_BY_BOSS_ID_SUCCESS,
  payrollAdvanceResume,
});

export const getPayrollAdvanceResumeByBossIdFailure = () => ({
  type: GET_PAYROLL_ADVANCE_RESUME_BY_BOSS_ID_FAILURE,
});

export const updatePayrollAdvanceStatusIdBegin = () => ({
  type: UPDATE_PAYROLL_ADVANCE_STATUS_ID_BEGIN,
});

export const updatePayrollAdvanceStatusIdSuccess = (payrollAdvanceStatus) => ({
  type: UPDATE_PAYROLL_ADVANCE_STATUS_ID_SUCCESS,
  payrollAdvanceStatus,
});

export const updatePayrollAdvanceStatusIdFailure = () => ({
  type: UPDATE_PAYROLL_ADVANCE_STATUS_ID_FAILURE,
});

export const getCashAdvanceDashboardSuccess = (cashAdvanceDashboard) => ({
  type: GET_CASH_ADVANCE_DASHBOARD_SUCCESS,
  cashAdvanceDashboard,
});

export const getCashAdvanceDashboardFailure = (error) => ({
  type: GET_CASH_ADVANCE_DASHBOARD_FAILURE,
  error,
});

export const getPendingCashAdvanceForAdminSuccess = (
  pendingCashAdvanceForAdmin
) => ({
  type: GET_PENDING_CASH_ADVANCE_FOR_ADMIN_SUCCESS,
  pendingCashAdvanceForAdmin,
});

export const getPendingCashAdvanceForAdminFailure = (error) => ({
  type: GET_PENDING_CASH_ADVANCE_FOR_ADMIN_FAILURE,
  error,
});

export const getPendingCashAdvanceForBossSuccess = (
  pendingCashAdvanceForBoss
) => ({
  type: GET_PENDING_CASH_ADVANCE_FOR_BOSS_SUCCESS,
  pendingCashAdvanceForBoss,
});

export const getPendingCashAdvanceForBossFailure = (error) => ({
  type: GET_PENDING_CASH_ADVANCE_FOR_BOSS_FAILURE,
  error,
});

export const getCashAdvanceEmployeeHomeInfoSuccess = (
  cashAdvanceEmployeeHomeInfo
) => ({
  type: GET_CASH_ADVANCE_EMPLOYEE_HOME_INFO_SUCCESS,
  cashAdvanceEmployeeHomeInfo,
});

export const getCashAdvanceEmployeeHomeInfoFailure = (error) => ({
  type: GET_CASH_ADVANCE_EMPLOYEE_HOME_INFO_FAILURE,
  error,
});

export function createPayrollAdvance(payrollAdvance) {
  return (dispatch, getState) => {
    dispatch(createPayrollAdvanceBegin());
    return request({
      partialUrl: `/api/v1/payroll-advance/create-payroll-advance`,
      method: "POST",
      state: getState().user.request,
      body: payrollAdvance,
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(createPayrollAdvanceSuccess(data.data));
      })
      .catch((err) => {
        return dispatch(createPayrollAdvanceFailure(err));
      });
  };
}

export function getPayrollAdvanceByBossAndStatusId(
  companyId,
  payrollAdvanceStatusId
) {
  return (dispatch, getState) => {
    dispatch(getPayrollAdvanceByBossAndStatusIdBegin());
    return request({
      partialUrl: `/api/v1/payroll-advance/find-payroll-advance-by-boss/${companyId}/and-status-id/${payrollAdvanceStatusId}`,
      method: "GET",
      state: getState().user.request,
      body: {
        companyId,
        payrollAdvanceStatusId,
      },
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(getPayrollAdvanceByBossAndStatusIdSuccess(data.data));
      })
      .catch((err) => {
        return dispatch(getPayrollAdvanceByBossAndStatusIdFailure(err));
      });
  };
}

export function getPayrollAdvanceByBossId(companyId) {
  return (dispatch, getState) => {
    dispatch(getPayrollAdvanceByBossIdBegin());
    return request({
      partialUrl: `/api/v1/payroll-advance/find-payroll-advance-by-boss-id/${companyId}`,
      method: "GET",
      state: getState().user.request,
      body: {
        companyId,
      },
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(getPayrollAdvanceByBossIdSuccess(data.data));
      })
      .catch((err) => {
        return dispatch(getPayrollAdvanceByBossIdFailure(err));
      });
  };
}

export function getPayrollAdvanceResumeByBossId(companyId) {
  return (dispatch, getState) => {
    dispatch(getPayrollAdvanceResumeByBossIdBegin());
    return request({
      partialUrl: `/api/v1/payroll-advance/generate-payroll-advance-resumen-by-boss-id/${companyId}`,
      method: "GET",
      state: getState().user.request,
      body: {
        companyId,
      },
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(getPayrollAdvanceResumeByBossIdSuccess(data.data));
      })
      .catch((err) => {
        return dispatch(getPayrollAdvanceResumeByBossIdFailure(err));
      });
  };
}

export function updatePayrollAdvanceStatusId(id, payrollAdvanceStatusId) {
  return (dispatch, getState) => {
    dispatch(updatePayrollAdvanceStatusIdBegin());
    return request({
      partialUrl: `/api/v1/payroll-advance/change-payroll-advance-status/${id}/${payrollAdvanceStatusId}`,
      method: "PUT",
      state: getState().user.request,
      body: {
        id,
        payrollAdvanceStatusId,
      },
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(updatePayrollAdvanceStatusIdSuccess(data.data));
      })
      .catch((err) => {
        return dispatch(updatePayrollAdvanceStatusIdFailure(err));
      });
  };
}

export const requestPayrollAdvance = (companyId, employeeId, amount) => {
  return async (dispatch) => {
    try {
      dispatch({ type: REQUEST_PAYROLL_ADVANCE_BEGIN });

      const res = await payrollAdvanceServices.createRequestPayrollAdvance(
        companyId,
        employeeId,
        amount
      );

      const data = res.data;

      if (data.code > 200 && data.code < 300) {
        dispatch(setSuccess(data.message));
      } else {
        dispatch(
          setError(
            data.message || "Ha ocurrido un error al solicitar tu adelanto"
          )
        );
      }
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch({
        type: REQUEST_PAYROLL_ADVANCE_FAILURE,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };
};

export const getCashAdvanceDashboard = (companyId) => {
  return async (dispatch) => {
    try {
      const res = await api.get(
        `Salvum/services/cashadvance/getCashAdvanceDashboard${
          companyId ? `companyId=${companyId}` : ""
        }`
      );

      if (res.error) {
        dispatch(setError(res.message));
      }
      const data = res.data;

      dispatch(getCashAdvanceDashboardSuccess(data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getCashAdvanceDashboardFailure(error));
    }
  };
};

export const clearPayrollAdvanceInfo = () => {
  return (dispatch) => {
    dispatch({
      type: REQUEST_PAYROLL_ADVANCE_SUCCESS,
      payload: {},
    });
  };
};

export const getPendingCashAdvanceForAdmin = ({ adminUserId, companyId }) => {
  return async (dispatch) => {
    try {
      const res = await api.get(
        `Salvum/services/cashadvance/getPendingCashAdvanceForAdmin?adminUserId=${adminUserId}&companyId=${companyId}`
      );

      if (res.error) {
        dispatch(setError(res.message));
      }
      const data = res.data;

      dispatch(getPendingCashAdvanceForAdminSuccess(data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getPendingCashAdvanceForAdminFailure(error));
    }
  };
};

export const getPendingCashAdvanceForBoss = ({ bossUserId, companyId }) => {
  return async (dispatch) => {
    try {
      const res = await api.get(
        `Salvum/services/cashadvance/getPendingCashAdvanceForBoss?bossUserId=${bossUserId}&companyId=${companyId}`
      );

      if (res.error) {
        dispatch(setError(res.message));
      }

      const data = res.data;

      dispatch(getPendingCashAdvanceForBossSuccess(data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getPendingCashAdvanceForBossFailure(error));
    }
  };
};

export const getCashAdvanceEmployeeHomeInfo = ({ employeeId, companyId }) => {
  return async (dispatch) => {
    try {
      const res = await api.get(
        `Salvum/services/cashadvance/getCashAdvanceEmployeeHomeInfo?employeeId=${employeeId}&companyId=${companyId}`
      );

      if (res.error) {
        dispatch(setError(res.message));
      }

      const data = res.data;

      dispatch(getCashAdvanceEmployeeHomeInfoSuccess(data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getCashAdvanceEmployeeHomeInfoFailure(error));
    }
  };
};
