import * as Yup from "yup";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const rfcRegExp =
  /^([A-Z,Ñ,&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[A-Z|\d]{3})$/;

export const validationSchema = Yup.object({
  name: Yup.string().required("Este campo es requerido."),
  businessName: Yup.string().required("Este campo es requerido."),
  phone: Yup.string()
    .required("Este campo es requrido")
    .matches(phoneRegExp, "Ingresa un numero telefonico valido."),
  rfc: Yup.string()
    .required("Este campo es requrido")
    .matches(rfcRegExp, "Ingresa un RFC válido."),
  email: Yup.string()
    .required("Ingresa tu correo electronico.")
    .min(7, "El correo debe contener al menos 3 caracteres.")
    .max(50, "El correo no debe contener más de 50 caracteres.")
    .email("Por favor, ingresa un formato de email valido"),
  approver_email: Yup.string()
    .required("Ingresa el correo de aprobador.")
    .min(7, "El correo debe contener al menos 3 caracteres.")
    .max(50, "El correo no debe contener más de 50 caracteres.")
    .email("Por favor, ingresa un formato de email valido"),
  authorizator_email: Yup.string()
    .required("Ingresa el correo de autorizador.")
    .min(7, "El correo debe contener al menos 3 caracteres.")
    .max(50, "El correo no debe contener más de 50 caracteres.")
    .email("Por favor, ingresa un formato de email valido"),
  day_of_payment_m: Yup.number()
    .min(0, "No puedes configurar días menores a 0")
    .max(31, "El máximo dia permitido es 31"),
  day_of_payment_q_1: Yup.number()
    .min(0, "No puedes configurar días menores a 0")
    .max(31, "El máximo dia permitido es 31"),
  day_of_payment_q_2: Yup.number()
    .min(0, "No puedes configurar días menores a 0")
    .max(31, "El máximo dia permitido es 31"),
  now: Yup.number()
    .min(-100, "El minimo debe ser -100")
    .max(100, "El máximo debe ser 100"),
  month_1: Yup.number()
    .min(-100, "El minimo debe ser -100")
    .max(100, "El máximo debe ser 100"),
  month_3: Yup.number()
    .min(-100, "El minimo debe ser -100")
    .max(100, "El máximo debe ser 100"),
  month_6: Yup.number()
    .min(-100, "El minimo debe ser -100")
    .max(100, "El máximo debe ser 100"),
  month_12: Yup.number()
    .min(-100, "El minimo debe ser -100")
    .max(100, "El máximo debe ser 100"),
  month_24: Yup.number()
    .min(-100, "El minimo debe ser -100")
    .max(100, "El máximo debe ser 100"),
  unlimit: Yup.number()
    .min(-100, "El minimo debe ser -100")
    .max(100, "El máximo debe ser 100"),
});

export const initialValues = {
  name: "",
  businessName: "",
  phone: "",
  email: "",
  rfc: "",
  approver_email: "",
  authorizator_email: "",
  day_of_payment_m: 0,
  day_of_payment_q_1: 0,
  day_of_payment_q_2: 0,
  day_of_payment_w: "",
  hasPayrollAdvance: null,
  payrollAdvanceCommissionPercentage: "",
  hasLoans: null,
  loanCommissionPercentage: 0,
  loanInterestPercentage: 0,
  loanNumberOfEndorsements: 0,
  loanMinimumLaborSeniority: 0,
  hasSavings: null,
  savingBankRates: null,
  seniority_1: 0,
  seniority_2: 0,
  seniority_3: 0,
  seniority_4: 0,
  seniority_5: 0,
  seniority_6: 0,
  payrolls_1: 0,
  payrolls_2: 0,
  payrolls_3: 0,
  payrolls_4: 0,
  payrolls_5: 0,
  payrolls_6: 0,
  installments_1: 0,
  installments_2: 0,
  installments_3: 0,
  installments_4: 0,
  installments_5: 0,
  installments_6: 0,
  now: 0,
  month_1: 0,
  month_3: 0,
  month_6: 0,
  month_12: 0,
  month_24: 0,
  unlimit: 0,
};
