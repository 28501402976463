import React from "react";

// Redux
import { useSelector } from "react-redux";

// Router
import { Link } from "react-router-dom";

// Images
import logo from "../../../../assets/images/general/logoSalvumWhite.svg";

// Styles
import "./EmployeeFooter.scss";

// Dates
import moment from "moment";
import { ContactMail, Mail } from "@mui/icons-material";

export const Footer = () => {
  const user = useSelector((state) => state.user);

  let newDate;
  if (user.lastLogin) {
    newDate = new Date(user.lastLogin);
  }

  return (
    <div className="footer" style={{ position: "relative" }}>
      <div className="footerContainer">
        <div className="logo">
          <img alt="Salvum" src={logo} />
          <span>&copy;Salvum 2023 Todos los derechos reservados</span>
        </div>
        <div className="links">
          <a href="https://salvum-docs.s3.us-west-1.amazonaws.com/AvisoPrivacidad.pdf" target="_blank">Aviso de privacidad</a>
        </div>
      </div>
      {user.lastLogin && (
        <div
          style={{
            position: "absolute",
            bottom: 55,
            right: 100,
            fontSize: "12px",
          }}
        >
          <p>
            Última fecha de conexión:{" "}
            {moment(newDate).format("YYYY-MM-DD HH:mm:ss A")}
          </p>
        </div>
      )}
    </div>
  );
};

export default Footer;
