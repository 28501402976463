import React, { useState } from "react";

import { Buttons } from "./Buttons";

import { setSuccess } from "../../../../../../../actions/successAction";
import { setError } from "../../../../../../../actions/errorAction";
import { api } from "../../../../../../../services/api";

import AttachFileIcon from "@mui/icons-material/AttachFile";

import { Button } from "@mui/material";

import { useDispatch } from "react-redux";

import { CircularProgress } from "@mui/material";

export const Reglamento = ({ handleNext, step, companyId, handleBack }) => {
  const dispatch = useDispatch();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmitFile = async () => {
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", selectedFile);

      const options = {
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const { data: response } = await api.post(
        `Salvum/services/boss/loadCompanyRegulation?companyId=${companyId}`,
        options
      );
      if (response.code > 300 || response.code > 202 || response.error) {
        dispatch(
          setError(
            response.message || "Ha ocurrido un error al subir el archivo."
          )
        );
        setIsLoading(false);
        return;
      }

      dispatch(setSuccess(response.message || "Archivo subido con éxito."));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      dispatch(
        setError(
          "Ha ocurrido un error interno en nuestro servidor al subir el archivo."
        )
      );
    }
  };

  const handleInputChange = (evt) => {
    const file = evt.target.files[0];
    setSelectedFile(file);
  };
  return (
    <div className="flex flex-col items-center p-6">
      <h2>Aqui puedes actualizar o subir el reglamento de la compañia.</h2>
      <div className="tabItem-v2">
        <p style={{ fontSize: "28 px" }} className={"grayText"}>
          Subir reglamento de empresa
        </p>
        <label id="file-csv" htmlFor="upload-photo">
          <AttachFileIcon />
        </label>
        <input
          type="file"
          onChange={handleInputChange}
          name="photo"
          id="upload-photo"
          accept=".pdf"
        />
        {selectedFile && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div>
              <label className="grayText">{selectedFile.name}</label>
            </div>
            <Button
              style={{ maxWidth: "270px" }}
              color="primary"
              variant="contained"
              onClick={handleSubmitFile}
            >
              Enviar
            </Button>
          </div>
        )}
      </div>

      {isLoading && <CircularProgress />}

      {!isLoading && (
        <Buttons
          disabled={isLoading}
          handleNext={handleNext}
          handleBack={handleBack}
          activeStep={step}
        />
      )}
    </div>
  );
};

export default Reglamento;
