import request from "../utils/request";
import { api } from "../services/api";

import {
  GET_EMPLOYEE_BY_USER_ID_BEGIN,
  GET_EMPLOYEE_BY_USER_ID_SUCCESS,
  GET_EMPLOYEE_BY_USER_ID_FAILURE,
  GET_EMPLOYEES_BY_BOSS_ID_BEGIN,
  GET_EMPLOYEES_BY_BOSS_ID_SUCCESS,
  GET_EMPLOYEES_BY_BOSS_ID_FAILURE,
  GET_EMPLOYEES_BY_COMPANY_ID_BEGIN,
  GET_EMPLOYEES_BY_COMPANY_ID_SUCCESS,
  GET_EMPLOYEES_BY_COMPANY_ID_FAILURE,
  ADD_EMPLOYEE_BEGIN,
  ADD_EMPLOYEE_SUCCESS,
  ADD_EMPLOYEE_FAILURE,
  GET_EMPLOYEE_NUMBER_DETAILS_BEGIN,
  GET_EMPLOYEE_NUMBER_DETAILS_SUCCESS,
  GET_EMPLOYEE_NUMBER_DETAILS_FAILURE,
  GET_EMPLOYEE_SAVING_DETAIL_RESPONSE_SUCCESS,
  GET_EMPLOYEE_SAVING_DETAIL_RESPONSE_FAILURE,
  GET_EMPLOYEE_CASH_ADVANCE_DETAIL_SUCCESS,
  GET_EMPLOYEE_CASH_ADVANCE_DETAIL_FAILURE,
  GET_EMPLOYEE_LOAN_DETAIL_SUCCESS,
  GET_EMPLOYEE_LOAN_DETAIL_FAILURE,
} from "../constants/actionTypes";
import { setError } from "./errorAction";

export const getEmployeeByUserIdBegin = () => ({
  type: GET_EMPLOYEE_BY_USER_ID_BEGIN,
});

export const getEmployeeByUserIdSuccess = (employee) => ({
  type: GET_EMPLOYEE_BY_USER_ID_SUCCESS,
  employee,
});

export const getEmployeeByUserIdFailure = (error) => ({
  type: GET_EMPLOYEE_BY_USER_ID_FAILURE,
  error,
});

export const getEmployeesByBossIdBegin = () => ({
  type: GET_EMPLOYEES_BY_BOSS_ID_BEGIN,
});

export const getEmployeesByBossIdSuccess = (employeeList) => ({
  type: GET_EMPLOYEES_BY_BOSS_ID_SUCCESS,
  employeeList,
});

export const getEmployeesByBossIdFailure = () => ({
  type: GET_EMPLOYEES_BY_BOSS_ID_FAILURE,
});

export const getEmployeesByCompanyIdBegin = () => ({
  type: GET_EMPLOYEES_BY_COMPANY_ID_BEGIN,
});

export const getEmployeesByCompanyIdSuccess = ({ count, rows }) => ({
  type: GET_EMPLOYEES_BY_COMPANY_ID_SUCCESS,
  count,
  rows,
});

export const getEmployeesByCompanyIdFailure = (error) => ({
  type: GET_EMPLOYEES_BY_COMPANY_ID_FAILURE,
  error,
});

export const addEmployeeBegin = () => ({
  type: ADD_EMPLOYEE_BEGIN,
});

export const addEmployeeSuccess = (response) => ({
  type: ADD_EMPLOYEE_SUCCESS,
  response,
});

export const addEmployeeFailure = (error) => ({
  type: ADD_EMPLOYEE_FAILURE,
  error,
});

export const getEmployeeNumberDetailsBegin = () => ({
  type: GET_EMPLOYEE_NUMBER_DETAILS_BEGIN,
});

export const getEmployeeNumberDetailsSuccess = (numberDetails) => ({
  type: GET_EMPLOYEE_NUMBER_DETAILS_SUCCESS,
  numberDetails,
});

export const getEmployeeNumberDetailsFailure = (error) => ({
  type: GET_EMPLOYEE_NUMBER_DETAILS_FAILURE,
  error,
});

export const getEmployeeSavingDetailResponseSuccess = (
  savingDetailResponse
) => ({
  type: GET_EMPLOYEE_SAVING_DETAIL_RESPONSE_SUCCESS,
  savingDetailResponse,
});

export const getEmployeeSavingDetailResponseFailure = (error) => ({
  type: GET_EMPLOYEE_SAVING_DETAIL_RESPONSE_FAILURE,
  error,
});

export const getEmployeeCashAvanceDetailSuccess = (cashAdvanceDetail) => ({
  type: GET_EMPLOYEE_CASH_ADVANCE_DETAIL_SUCCESS,
  cashAdvanceDetail,
});

export const getEmployeeCashAvanceDetailFailure = (error) => ({
  type: GET_EMPLOYEE_CASH_ADVANCE_DETAIL_FAILURE,
  error,
});

export const getEmployeeLoanDetailSuccess = (loanDetail) => ({
  type: GET_EMPLOYEE_LOAN_DETAIL_SUCCESS,
  loanDetail,
});

export const getEmployeeLoanDetailFailure = (error) => ({
  type: GET_EMPLOYEE_LOAN_DETAIL_FAILURE,
  error,
});

///

export function getEmployeeByUserId(userId, companyId) {
  return async (dispatch) => {
    try {
      dispatch(getEmployeeByUserIdBegin());
      const data = await api.get(
        `/Salvum/services/users/employees/by-user/${userId}?companyId=${companyId}`
      );
      dispatch(getEmployeeByUserIdSuccess(data.data.data));
    } catch (error) {
      return dispatch(getEmployeeByUserIdFailure(error));
    }
  };
}

export function getEmployeesByBossId(companyId) {
  return (dispatch, getState) => {
    dispatch(getEmployeesByBossIdBegin());
    return request({
      partialUrl: `/api/v1/employees/find-employees-by-boss-id/${companyId}`,
      method: "GET",
      state: getState().user.request,
      body: {
        companyId,
      },
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(getEmployeesByBossIdSuccess(data.data));
      })
      .catch((err) => {
        return dispatch(getEmployeesByBossIdFailure(err));
      });
  };
}

export function getEmployeesByCompanyId(companyId) {
  return (dispatch, getState) => {
    dispatch(getEmployeesByCompanyIdBegin());
    return request({
      partialUrl: `/api/v1/company/${companyId}/employees`,
      method: "GET",
      state: getState().user.request,
    })
      .then((response) => response.json())
      .then((response) => {
        dispatch(getEmployeesByCompanyIdSuccess(response.data));
      })
      .catch((err) => {
        return dispatch(getEmployeesByCompanyIdFailure(err));
      });
  };
}

export function addEmployee(companyId, employee) {
  return (dispatch, getState) => {
    dispatch(addEmployeeBegin());
    return request({
      partialUrl: `/api/v1/company/${companyId}/employees`,
      method: "POST",
      state: getState().user.request,
      body: employee,
    })
      .then((response) => response.json())
      .then(() => {
        dispatch(addEmployeeSuccess());
      })
      .catch((err) => {
        return dispatch(addEmployeeFailure(err));
      });
  };
}

export const getEmployeeNumberDetails = ({ employeeId, companyId }) => {
  return async (dispatch) => {
    try {
      dispatch(getEmployeeNumberDetailsBegin());

      const response = await api.get(
        `Salvum/services/loans/getEmployeeNumberDetails?employeeId=${employeeId}&companyId=${companyId}`
      );

      if (response.error) {
        dispatch(
          setError(
            response.message ||
              "Ha ocurrido un error al consultar los detalles de tu cuenta."
          )
        );
      }
      dispatch(getEmployeeNumberDetailsSuccess(response.data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
    }
  };
};

export const getEmployeeSavingDetailResponse = ({ companyId, employeeId }) => {
  return async (dispatch) => {
    try {
      const response = await api.get(
        `Salvum/services/savings/getEmployeeSavingDetailResponse?companyId=${companyId}&employeeId=${employeeId}`
      );
      if (response.error) {
        dispatch(setError(response.message));
      }
      dispatch(getEmployeeSavingDetailResponseSuccess(response.data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getEmployeeSavingDetailResponseFailure(error));
    }
  };
};

export const getEmployeeCashAdvanceDetail = ({ companyId, employeeId }) => {
  return async (dispatch) => {
    try {
      const response = await api.get(
        `Salvum/services/cashadvance/getEmployeeCashAdvanceDetailResponse?companyId=${companyId}&employeeId=${employeeId}`
      );
      if (response.error) {
        dispatch(setError(response.message));
      }
      dispatch(getEmployeeCashAvanceDetailSuccess(response.data));
    } catch (error) {
      const message = (error.response.data.message = error.message);
      dispatch(setError(message));
      dispatch(getEmployeeCashAvanceDetailFailure(error));
    }
  };
};

export const getEmployeeLoanDetail = ({
  companyId,
  employeeId,
  bossUserId,
}) => {
  return async (dispatch) => {
    try {
      const response = await api.get(
        `Salvum/services/loans/getEmployeeLoanDetailResponse?companyId=${companyId}&employeeId=${employeeId}&bossUserId=${bossUserId}`
      );
      if (response.error) {
        dispatch(setError(response.message));
      }
      dispatch(getEmployeeLoanDetailSuccess(response.data));
    } catch (error) {
      const message = (error.response.data.message = error.message);
      dispatch(setError(message));
      dispatch(getEmployeeLoanDetailFailure(error));
    }
  };
};
