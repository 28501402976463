import { instance, config } from "./axios";

const getLoanInfoByEmployee = (employeeId, companyId) => {
  return instance.get(
    `/Salvum/services/loans/getUserLoanParams?employeeId=${employeeId}&companyId=${companyId}`
  );
};

const getCalculatedAmortizationTable = (
  creditAmount,
  creditPeriods,
  employeeId,
  companyId
) => {
  return instance.post(
    "/Salvum/services/loans/calculateAmortizationTable",
    {
      creditAmount: creditAmount,
      creditPeriods: creditPeriods,
      employeeId: employeeId,
      companyId: companyId,
    },
    config
  );
};

export default {
  getLoanInfoByEmployee,
  getCalculatedAmortizationTable,
};
