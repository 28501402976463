import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../../../../components/Modal";
import "./Modals.scss";
import { api } from "../../../../../services/api";
import { getPendingLoansForAdmin } from "../../../../../actions/loansAction";

import { setError } from "../../../../../actions/errorAction";
import { setSuccess } from "../../../../../actions/successAction";

export const RejectLoanModal = ({
  isOpen = false,
  setIsOpen,
  loanId,
  companyId,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const closeConfirmModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = async () => {
    try {
      const { id: adminUserId } = user;
      const response = await api.get(
        "Salvum/services/loans/updateLoanStatusForAdmin?loanId=" +
          loanId +
          "&adminUserId=" +
          adminUserId +
          "&nextStep=false",
        {
          params: {
            loanId,
            adminUserId: adminUserId,
            nextStep: false,
          },
        }
      );
      if (response.error) {
        dispatch(
          setError(
            response.message || "Ha occurido un error al rechazar el préstamo."
          )
        );
      } else {
        dispatch(setSuccess(response.message || "Préstamo rechazado."));
        await dispatch(getPendingLoansForAdmin({ companyId, adminUserId }));
      }
      closeConfirmModal();
    } catch (error) {
      dispatch(setError("Ha occurido un error al rechazar el préstamo."));
      closeConfirmModal();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={closeConfirmModal}
        title="Rechazar préstamo"
      >
        <h4>¿Está seguro de rechazar este préstamo?</h4>

        <div className="buttons-container">
          <button
            className="transition ease-in-out border-black border rounded-md p-2 hover:bg-gray-50"
            onClick={closeConfirmModal}
            type="button"
            style={{ marginRight: "8px" }}
          >
            En otro momento
          </button>
          <button
            className="transition bg-red-500 text-white ease-in-out border-black border rounded-md p-2 hover:bg-red-700"
            type="submit"
            onClick={handleSubmit}
          >
            Si, rechazar.
          </button>
        </div>
      </Modal>
    </>
  );
};

export default RejectLoanModal;
