import * as React from "react";
import MUITable from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/outline";
const useColors = () => {
  return {
    Autorizado: {
      color: "white",
      backgroundColor: "#15db93",
      fontSize: "14px",
    },
    Pendiente: {
      color: "black",
      backgroundColor: "#ffc300",
      fontSize: "14px",
    },
    Aprobado: {
      color: "white",
      backgroundColor: "#15db93",
      fontSize: "14px",
    },
    Rechazado: {
      color: "white",
      backgroundColor: "#d9484e",
      fontSize: "14px",
    },
    Liquidado: {
      color: "white",
      backgroundColor: "#4897d9",
      fontSize: "14px",
    },
    Dispersado: {
      color: "black",
      backgroundColor: "#ffc300",
      fontSize: "14px",
    },
  };
};

export const AdminLoansTable = ({
  row,
  setIsApproveModalOpen = () => {},
  setIsRejectModalOpen = () => {},
  setLoanId,
}) => {
  const colors = useColors();

  const handleAccept = () => {
    setLoanId(row.loanId);
    setIsApproveModalOpen(true);
  };

  const handleReject = () => {
    setLoanId(row.loanId);
    setIsRejectModalOpen(true);
  };
  return (
    <TableContainer
      sx={{ padding: "2rem" }}
      className="rounded-full mb-10"
      component={Paper}
    >
      <MUITable
        sx={{ minWidth: 650, borderRadius: "1rem" }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontFamily: "Poppins" }} className="grayText">
              Monto de préstamo
            </TableCell>
            <TableCell sx={{ fontFamily: "Poppins" }} className="grayText">
              Plazo
            </TableCell>
            <TableCell sx={{ fontFamily: "Poppins" }} className="grayText">
              Nombre de empleado
            </TableCell>
            <TableCell sx={{ fontFamily: "Poppins" }} className="grayText">
              Estatus
            </TableCell>
            <TableCell sx={{ fontFamily: "Poppins" }} className="grayText">
              Aprobar
            </TableCell>
            <TableCell sx={{ fontFamily: "Poppins" }} className="grayText">
              Rechazar
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow
            id={row.employeeName}
            key={row.employeeName}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
            <TableCell
              sx={{ fontFamily: "Poppins", fontSize: "22px", fontWeight: 300 }}
              className="blackText"
              component="th"
              scope="row"
            >
              {row.loanQuanityFormatted}
            </TableCell>
            <TableCell
              sx={{ fontFamily: "Poppins", fontSize: "22px", fontWeight: 300 }}
              className="blackText"
              component="th"
              scope="row"
            >
              {row.periods}
            </TableCell>
            <TableCell sx={{ fontFamily: "Poppins" }}>
              {row.employeeName}
            </TableCell>
            <TableCell className="text-bg-white rounded-2xl p-10 h-10">
              <div
                style={{
                  fontFamily: "Poppins",
                  ...colors["Dispersado"],
                }}
                className="text-white p-1 text-center rounded-full"
              >
                {row.loanStatusFormatted}
              </div>
            </TableCell>
            <TableCell align="center">
              <CheckCircleIcon
                onClick={handleAccept}
                className="transition ease-in-out h-9 w-9 text-green-500 hover:text-green-700 hover:cursor-pointer focus:ring"
              />
            </TableCell>
            <TableCell align="center">
              <XCircleIcon
                onClick={handleReject}
                className="transition ease-in-out h-9 w-9 text-red-500 hover:text-red-700 hover:cursor-pointer focus:ring"
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </MUITable>
    </TableContainer>
  );
};

// ...colors[row.loanStatusFormatted]

export default AdminLoansTable;
