import React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

//Styles
import "./EmployeeItem.scss";

export const EmployeesCards = ({ employees = [], setEmployeeId }) => {
  return (
    <div className="w-full self-center">
      <h2 className="text-black text-2xl my-6">
        Empleados ({employees.length})
      </h2>
      <div className="flex flex-col">
        {employees.map((employee) => (
          <EmployeeCardItem
            setEmployeeId={setEmployeeId}
            employee={employee}
            key={employee.employeeName}
          />
        ))}
      </div>
    </div>
  );
};

export const EmployeeCardItem = ({ employee = [], setEmployeeId }) => {
  const employeeStatusLabel = (status) => {
    if (status === "Activo") {
      return `Activo`;
    } else if (status === "Desactivo") {
      return `Inactivo`;
    }
  };

  return (
    // onClick={() => this.employeeDetail(employee.id, e.user.id, e.companyId)}
    <div
      className={`${[
        "tabHeader employee-row",
        employee.status === "Desactivo" ? "disabled-employee" : "",
      ].join(
        " "
      )} w-3/4 self-center grid grid-cols-5 gap-5 border border-gray-800 rounded-2xl p-4 w-full items-center mb-6 justify-between employee-item-container`}
      onClick={() => setEmployeeId(employee.employeeNumber, employee.companyId)}
    >
      <div className="tabItem">
        <p className="blackText text-base m-2">
          {employee.employeeName} {employee.employeeLastName}
        </p>
      </div>
      <div className="tabItem">
        <p className="blackText text-base m-2"> {employee.payroll}</p>
      </div>
      <div className="tabItem">
        <p className="blackText text-base m-2"> {employee.savingPerMonth}</p>
      </div>
      <div className="tabItem">
        <span
          className={
            employee.status === "Activo" ? "green-active" : "gray-active"
          }
        >
          {employeeStatusLabel(employee.status)}
        </span>
      </div>
      <div className="tabItem">
        <p className="blackText m-2">
          <ArrowForwardIosIcon className="chevron-icon" />
        </p>
      </div>
    </div>
  );
};
