import React from "react";

export const Header = () => {
  return (
    <div className="companiesHeroContent">
      <div className="companiesContainer mb-10">
        <ul className="breadcrumbs">
          <li>Inicio</li>
          <li>Empresas</li>
          <li>Nueva empresa</li>
        </ul>
        <div className="companiesHero">
          <div className="content">
            <span className="contentTitle">Nueva empresa</span>
            <p className="subTitleContent">Instrucciones</p>
            <p className="paragraphContent">
              Para registrar un empresa, debes completar los 6 pasos descritos
              abajo. Cada uno de ellos te permitira ingresar informacion
              relevante para cada seccion de la compañia. Algunos son
              opcionales, otros no, Puedes omitir aquellos que son opcionales y
              necesitaras llenar aquellos que son requeridos. Cuando estes
              listo, para continuar presiona en el boton Registrar compañia al
              completar u omitir el paso 6.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
