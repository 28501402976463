import {
  GET_BOSS_BY_USER_ID_BEGIN,
  GET_BOSS_BY_USER_ID_SUCCESS,
  GET_BOSS_BY_USER_ID_FAILURE,
  USER_LOGOUT_SUCCESS,
  GET_BOSS_HOME_INFO_BEGIN,
  GET_BOSS_HOME_INFO_SUCCESS,
  GET_BOSS_HOME_INFO_FAILURE,
  GET_BOSS_EMPLOYEE_DETAIL_BEGIN,
  GET_BOSS_EMPLOYEE_DETAIL_SUCCESS,
  GET_BOSS_EMPLOYEE_DETAIL_FAILURE,
  GET_PENDING_LOANS_FOR_BOSS_BEGIN,
  GET_PENDING_LOANS_FOR_BOSS_SUCCESS,
  GET_PENDING_LOANS_FOR_BOSS_FAILURE,
  GET_BOSS_COMPANY_SUMMARY_SUCCESS,
  GET_BOSS_COMPANY_SUMMARY_FAILURE,
} from "../constants/actionTypes";

import initialState from "./initialState";

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function bossReducer(state = initialState.boss, action) {
  switch (action.type) {
    case GET_BOSS_BY_USER_ID_BEGIN:
      return state;
    case GET_BOSS_BY_USER_ID_SUCCESS: {
      return {
        ...state,
        ...action.boss,
      };
    }
    case GET_BOSS_BY_USER_ID_FAILURE:
      return state;
    case USER_LOGOUT_SUCCESS:
      return {
        ...initialState.boss,
      };
    case GET_BOSS_HOME_INFO_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_BOSS_HOME_INFO_SUCCESS:
      return {
        ...state,
        homeInfo: action.homeInfo,
        loading: false,
      };
    case GET_BOSS_HOME_INFO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error || "No se pudo completar la operacion",
      };
    case GET_BOSS_EMPLOYEE_DETAIL_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_BOSS_EMPLOYEE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        employeeDetail: action.employeeDetail,
      };
    case GET_BOSS_EMPLOYEE_DETAIL_FAILURE:
      return {
        ...state,
        loading: false,
        erorr: action.error || "Hubo un problema al cargar el servicio",
      };
    case GET_PENDING_LOANS_FOR_BOSS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_PENDING_LOANS_FOR_BOSS_SUCCESS:
      return {
        ...state,
        loading: false,
        pendingLoans: action.pendingLoans,
      };
    case GET_PENDING_LOANS_FOR_BOSS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error || "Hubo un problema al cargar los préstamos.",
      };
    case GET_BOSS_COMPANY_SUMMARY_SUCCESS:
      return {
        ...state,
        loading: false,
        companySummary: action.companySummary,
      };
    case GET_BOSS_COMPANY_SUMMARY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
