import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AdminAppBar from "./AdminAppBar/AdminAppBar";
import AdminMainContent from "./AdminMainContent/AdminMainContent";
import AdminFooter from "../EmployeeLayout/EmployeeFooter/EmployeeFooter";

export class AdminLayout extends Component {
  render() {
    return (
      <>
        <AdminAppBar />
        <AdminMainContent>{this.props && this.props.children}</AdminMainContent>
        <AdminFooter />
      </>
    );
  }
}

AdminLayout.propTypes = {
  children: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    ...state,
  };
}

export default connect(mapStateToProps)(AdminLayout);
