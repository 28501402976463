import React from "react";
import { HorizontalLinearStepper } from "./Stepper";

export const CompanyDetails = ({
  companyDetail = {},
  companyId,
  activeStep,
  setActiveStep,
  setLoansChecked,
  setSavingsChecked,
  setPayrollChecked,
  formik,
  savingsChecked,
  loansChecked,
  payrollChecked,
}) => {
  return (
    <div className="flex flex-col justify-center w-full h-fit text-black p-10">
      <h2 className="text-center text-3xl">Detalles de la empresa</h2>
      <HorizontalLinearStepper
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        companyDetail={companyDetail}
        companyId={companyId}
        formik={formik}
        setLoansChecked={setLoansChecked}
        setPayrollChecked={setPayrollChecked}
        setSavingsChecked={setSavingsChecked}
        savingsChecked={savingsChecked}
        loansChecked={loansChecked}
        payrollChecked={payrollChecked}
      />
    </div>
  );
};
