import React from "react";
import AlertMaterial from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import { Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import "./Alert.scss";

import { hideSuccess } from "../../actions/successAction";

export const SuccessAlert = () => {
  const success = useSelector((state) => state.success.success);
  const isOpen = useSelector((state) => state.success.isOpen);

  const dispatch = useDispatch();

  if (!isOpen) {
    return null;
  }

  if (isOpen) {
    setTimeout(() => {
      dispatch(hideSuccess());
    }, 6000);
  }

  const closeAlert = () => {
    dispatch(hideSuccess());
  };

  return (
    <div
      id="SuccessAlert"
      style={{
        position: "fixed",
        zIndex: 10000000,
        alignSelf: "center",
        justifySelf: "center",
      }}
    >
      <Slide in={isOpen} direction="down" timeout={500}>
        <AlertMaterial
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={closeAlert}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {success}
        </AlertMaterial>
      </Slide>
    </div>
  );
};

export default SuccessAlert;
