import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPendingCashAdvanceForAdmin } from "../../../../actions/payrollAdvanceAction";
import { AdminPayRollTable } from "../../../../components/Tables";
// import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { ApproveLoanModal, RejectLoanModal } from "./Modals";
// import moment from 'moment'

const useColors = () => {
  return {
    Autorizado: {
      color: "white",
      backgroundColor: "#15db93",
      fontSize: "14px",
    },
    Pendiente: {
      color: "black",
      backgroundColor: "#ffc300",
      fontSize: "14px",
    },
    Aprobado: {
      color: "white",
      backgroundColor: "#15db93",
      fontSize: "14px",
    },
    Rechazado: {
      color: "white",
      backgroundColor: "#d9484e",
      fontSize: "14px",
    },
    Liquidado: {
      color: "white",
      backgroundColor: "#4897d9",
      fontSize: "14px",
    },
    Dispersado: {
      color: "black",
      backgroundColor: "#ffc300",
      fontSize: "14px",
    },
  };
};

export const CompanyLoansDetails = ({
  companyId,
  companyName,
  adminUserId,
}) => {
  const dispatch = useDispatch();
  const [isApproveModalOpen, setIsApproveModalOpen] = useState(false);
  const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);
  const [cashAdvanceId, setCashAdvanceId] = useState(null);
  const pendingCashAdvance = useSelector(
    (state) => state.advances.pendingCashAdvanceForAdmin
  );
  useEffect(() => {
    dispatch(getPendingCashAdvanceForAdmin({ companyId, adminUserId }));
  }, []);

  return (
    <div>
      <ApproveLoanModal
        setIsOpen={setIsApproveModalOpen}
        isOpen={isApproveModalOpen}
        cashAdvanceId={cashAdvanceId}
      />
      <RejectLoanModal
        setIsOpen={setIsRejectModalOpen}
        isOpen={isRejectModalOpen}
        cashAdvanceId={cashAdvanceId}
      />
      <h2
        className="text-black text-2xl mt-6 mb-6"
        style={{ fontSize: "48px" }}
      >
        {companyName}
      </h2>
      <div
        style={{
          width: "100%",
          height: "2px",
          color: "black",
          border: "2px solid",
          marginBottom: "20px",
        }}
      />
      <div className="mb-10 principalContentCompany">
        <div className="savingsContent">
          <div className="tabHeader3">
            <h2 className="text-black text-2xl">
              Resumen de adelantos de nómina
            </h2>
          </div>
          <div className="grayLine"></div>
          <div className="flex justify-between tabHeader2">
            <div className="m-4 tabItem">
              <p className="grayText">Por aprobar</p>
              <p className="blackText">
                <span className="blue-text">
                  {pendingCashAdvance &&
                    pendingCashAdvance.cashAdvanceSummary.pending}
                </span>
              </p>
            </div>
            <div className="m-4 tabItem">
              <p className="grayText">Aprobados</p>
              <p className="blackText">
                <span className="green-text">
                  {pendingCashAdvance &&
                    pendingCashAdvance.cashAdvanceSummary.approved}
                </span>
              </p>
            </div>
            <div className="m-4 tabItem">
              <p className="grayText">Rechazados</p>
              <p className="blackText">
                <span className="red-text">
                  {pendingCashAdvance &&
                    pendingCashAdvance.cashAdvanceSummary.rejected}
                </span>
              </p>
            </div>
            <div className="m-4 tabItem">
              <p className="grayText">Saldo total prestado</p>
              <p className="blackText">
                <span className="smallText">
                  {pendingCashAdvance &&
                    pendingCashAdvance.cashAdvanceSummary.totalAmountLend}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <h3 style={{ color: "#122745", fontSize: "36px" }}>
        Adelantos de nómina pendientes.
      </h3>

      {pendingCashAdvance &&
        pendingCashAdvance.pendingCashAdvanceToApprove &&
        pendingCashAdvance.pendingCashAdvanceToApprove.length > 0 && (
          <>
            {pendingCashAdvance.pendingCashAdvanceToApprove.map((item) => (
              <PendingCashAdvances
                companyName={companyName}
                key={item.cashAdvanceId}
                loan={item}
                setIsApproveModalOpen={setIsApproveModalOpen}
                setIsRejectModalOpen={setIsRejectModalOpen}
                setCashAdvanceId={setCashAdvanceId}
              />
            ))}
          </>
        )}
      {pendingCashAdvance &&
        pendingCashAdvance.pendingCashAdvanceToApprove &&
        pendingCashAdvance.pendingCashAdvanceToApprove.length === 0 && (
          <h3
            className="mb-20 mt-6 text-2xl text-center"
            style={{ color: "#c5c5c5" }}
          >
            No tiene adelantos de nómina pendientes de aprobación.
          </h3>
        )}
      <h3 style={{ color: "#122745", fontSize: "36px" }}>Historial</h3>
      {pendingCashAdvance &&
        pendingCashAdvance.history.map((loan) => (
          <HistoryLoans key={loan.loanId} item={loan} />
        ))}
    </div>
  );
};

const PendingCashAdvances = ({
  loan,
  setIsApproveModalOpen,
  setIsRejectModalOpen,
  setCashAdvanceId,
}) => {
  return (
    <>
      <AdminPayRollTable
        setCashAdvanceId={setCashAdvanceId}
        row={loan}
        setIsApproveModalOpen={setIsApproveModalOpen}
        setIsRejectModalOpen={setIsRejectModalOpen}
      />
    </>
  );
};

const HistoryLoans = ({ item }) => {
  const [showTable] = useState(false);

  const colors = useColors();
  return (
    <div className="principalContentCompany text-black mb-10">
      <div className="savingsContent">
        <div className="flex justify-between tabHeader2 flex-wrap">
          <div className="tabItemC first mx-4 my-2">
            <p className="grayText">Monto de adelanto</p>
            <p className="blackText">{item.amountFormatted}</p>
          </div>
          <div className="tabItemC mx-4 my-2">
            <p className="grayText">Fecha de solicitud</p>
            <p className="blackText"> {item.requestedDateFormatted}</p>
          </div>
          <div className="tabItemC name mx-4 my-2">
            <p className="grayText">Nombre</p>
            <p className="name">{item.employeeName}</p>
          </div>
          <div className="tabItemC mx-4 my-2">
            <div className="container-box">
              <p className="grayText">Estatus</p>
              <span
                className="pr-6 pl-6 pt-1 pb-1 rounded-full"
                style={{ ...colors[item.loanStatusFormatted] }}
              >
                {item.statusFormatted}
              </span>
            </div>
          </div>
          {/* {!showTable && (
            <ChevronDownIcon
              className="w-10 h-10 text-black"
              onClick={toggleShowTable}
            />
          )}
          {showTable && (
            <ChevronUpIcon
              className="w-10 h-10 text-black"
              onClick={toggleShowTable}
            />
          )} */}
        </div>
        {showTable && (
          <>
            <div className="grayLine"></div>
            <div className="flex justify-between">
              <div className="tabItemB"></div>
              <div className="tabItemB">
                <p className="grayText">Fecha de expedición</p>
                <p className="blackTextDate">{item.creationDateFormatted}</p>
              </div>
              <div className="tabItemB">
                <p className="grayText">Última aportación</p>
                <p className="blackTextDate">
                  {/* {moment.unix(item.updatedAt / 1000).format("DD/MM/YYYY")} */}
                  No disponible
                </p>
              </div>
              <div className="tabItemB">
                <p className="grayText">Pagos realizados</p>
                No disponible
              </div>
              <div className="tabItemB"></div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CompanyLoansDetails;
