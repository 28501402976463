import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "react-google-charts";
import { savingTypes } from "../SavingsUtils";
import {
  getTotalSavingsEmployee,
  getPayrollStatusInfo,
} from "../../../../../actions/savingsAction";
import _ from "lodash";
import { SavingsHorizons } from "./SavingsHorizons";

export const SavingsPercentagesUsed = (props) => {
  const dispatch = useDispatch();
  const totalSavingsEmployee = useSelector(
    (state) => state.savings.totalSavingsEmployee
  );
  const payrollInfoStatus = useSelector(
    (state) => state.savings.payrollStatusInfo
  );
  const savingSlots = useSelector((state) => state.savings.savingsSlots);

  const { companyid, userid } = props.employee || {};

  useEffect(() => {
    if (props.employee) {
      dispatch(
        getTotalSavingsEmployee({ companyId: companyid, employeeId: userid })
      );
      dispatch(
        getPayrollStatusInfo({ companyId: companyid, employeeId: userid })
      );
    }
  }, [props.employee]);

  if (!savingSlots) {
    return null;
  }

  const getPayrollWithoutSavings = () => {
    const payroll = parseFloat(payrollInfoStatus.netPay);

    const result = _.round(payroll - payrollInfoStatus.netPayUsed, 2);

    return result < 0 ? 0 : result;
  };

  if (!payrollInfoStatus) {
    return null;
  }

  const chartData = [
    ["Tiempo de nómina", "Porcentaje"],
    ...savingSlots.slots.map((saving) => [
      saving.savingBankTypeIdFormatted,
      saving.currentAmountToSave,
    ]),
    ["Salario restante", getPayrollWithoutSavings()],
  ];

  const chartColors = [
    ...savingSlots.slots.map(
      (saving) => savingTypes[saving.savingBankTypeIdFormatted].color
    ),
    "#fafafa",
  ];

  return (
    <div className="savings-container-resume">
      <div className="saving-resume-white">
        <div className="savings-resume">
          <div className="chartRow">
            <div className="chartItem">
              <span className="title">
                Porcentaje total utilizado de tu nómina
              </span>
              <div className="chartDiv">
                <Chart
                  width="200px"
                  height="200px"
                  chartType="PieChart"
                  loader={<div>Cargando..</div>}
                  data={chartData}
                  options={{
                    pieHole: 0.7,
                    legend: "none",
                    pieSliceText: "none",
                    colors: chartColors,
                  }}
                  rootProps={{ "data-testid": "3" }}
                />
              </div>
              <div className="chartText">
                <span className="chartTextTop">
                  <span className="topAmount">
                    {payrollInfoStatus.netPayUsedFormatted}
                  </span>
                </span>
                <span className="chartTextSlash">/</span>
                <span className="chartTextBottom">
                  <span className="bottomAmount">
                    {payrollInfoStatus.netPayFormatted}
                  </span>
                </span>
              </div>
            </div>
            <SavingsHorizons savingSlots={savingSlots.slots} />

            {totalSavingsEmployee && (
              <div className="chartItem">
                <div className="chartSubItem">
                  <div className="title2">Última aportación</div>
                  {totalSavingsEmployee.lastSavingDateFormatted ||
                    "No disponible"}
                </div>

                <div className="">
                  <div className="title2">Saldo total acumulado</div>
                  <div className="total-gain">
                    {totalSavingsEmployee.grandTotalSumAcumFormatted ||
                      "No disponible"}
                  </div>
                </div>
                <div>
                  <div className="title2">Saldo total disponible</div>
                  <div className="total-gain">
                    {totalSavingsEmployee.grandTotalSumAvailableFormatted ? (
                      <span className="currencySymbol">
                        {totalSavingsEmployee.grandTotalSumAvailableFormatted}
                      </span>
                    ) : (
                      "No disponible"
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavingsPercentagesUsed;
