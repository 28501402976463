import React, { Component } from "react";
import { connect } from "react-redux";

import { getLoanGeneralAdminResponse } from "../../../actions/loansAction";
import { Companies, CompanyLoansDetails } from "./components";
import { ArrowCircleLeftIcon } from "@heroicons/react/outline";

// STYLES
import "./AdminLoan.scss";

export class AdminLoan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyId: null,
      companyName: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(getLoanGeneralAdminResponse());
  }

  setCompanyId = (companyId, companyName) => {
    this.setState({ companyId, companyName });
  };

  closeAlert = () => {
    this.setState({
      alertIsOpen: false,
    });
  };

  handleBack = () => {
    this.setState({ companyId: null });
  };

  render = () => {
    const { loansGeneralAdminResponse } = this.props;
    const { companyId } = this.state;
    if (!loansGeneralAdminResponse) {
      return null;
    }

    return (
      <div className="loanAdmin">
        <div className="companiesHeroContent flex flex-col items-center">
          <div className="companiesContainer">
            <ul className="breadcrumbs">
              <li>Inicio</li>
              <li>Préstamos</li>
            </ul>
            <div className="companiesHero">
              <div className="content">
                <span className="contentTitle">Préstamos</span>
              </div>
            </div>
          </div>
          {companyId !== null && (
            <button
              onClick={this.handleBack}
              className="transition ease-in-out ml-20 flex text-white rounded-2xl self-start hover:text-blue-400"
            >
              <ArrowCircleLeftIcon className="mr-1 w-6 h-6" />
              Regresar
            </button>
          )}
        </div>
        {companyId === null && (
          <div className="companiesContent">
            <div className="companiesContentData">
              <div className="principalContentCompany">
                <div className="w-full flex flex-col">
                  <h2 className="text-black text-2xl w-full">Préstamos</h2>
                  <div className="grayLine"></div>
                  <div className="tabHeader grid grid-cols-3 mb-4">
                    <div className="tabItem">
                      <p className="grayText">Total registrados</p>
                      <p className="blackText">
                        {loansGeneralAdminResponse.currentActiveLoans}
                        <span className="smallText">
                          / {loansGeneralAdminResponse.totalNumOfLoans}
                        </span>
                      </p>
                    </div>
                    <div className="tabItem">
                      <p className="grayText">Intereses totales cobrados</p>
                      <p className="blackText">
                        {
                          loansGeneralAdminResponse.totalInterestsChargedFormatted
                        }
                      </p>
                    </div>
                    <div className="tabItem">
                      <p className="grayText">Saldo total prestado</p>
                      <p className="blackText">
                        {loansGeneralAdminResponse.totalAmountLendFormatted}
                      </p>
                    </div>
                  </div>
                  <div className="tabHeader grid grid-cols-3">
                    <div className="tabItem">
                      <p className="grayText">En curso</p>
                      <p className="blackText">
                        {loansGeneralAdminResponse.currentActiveLoans}
                      </p>
                    </div>
                    <div className="tabItem">
                      <p className="grayText">Intereses totales por cobrar</p>
                      <p className="blackText">
                        {
                          loansGeneralAdminResponse.totalInterestsToChargeFormatted
                        }
                      </p>
                    </div>
                    <div className="tabItem">
                      <p className="grayText">Saldo pendiente por pagar</p>
                      <p className="blackText">
                        {
                          loansGeneralAdminResponse.totalAmountPendingToPayFormatted
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grayLine bigLine"></div>
              <Companies
                setCompanyId={this.setCompanyId}
                loansDetails={loansGeneralAdminResponse}
              />
            </div>
          </div>
        )}
        {companyId !== null && (
          <CompanyLoansDetails
            companyName={this.state.companyName}
            companyId={companyId}
            setCompanyId={this.setCompanyId}
          />
        )}
      </div>
    );
  };
}

function mapStateToProps(state) {
  return {
    ...state,
    loansGeneralAdminResponse: state.loans.loansGeneralAdminResponse,
    pendingLoansAdmin: state.loans.pendingLoansAdmin,
  };
}

export default connect(mapStateToProps)(AdminLoan);
