import moment from "moment";
import _ from "lodash";

export const savingTypes = {
  Inmediato: {
    color: "#1d3557",
    dotClass: "dot-black",
    borderClass: "form-left-bord-black",
  },
  "1 Mes": {
    color: "#048aa8",
    dotClass: "dot-blue",
    borderClass: "form-left-bord-blue",
  },
  "3 Meses": {
    color: "#6cb6f5",
    dotClass: "dot-softblue",
    borderClass: "form-left-bord-soft-blue",
  },
  "6 Meses": {
    color: "#ef476f",
    dotClass: "dot-red",
    borderClass: "form-left-bord-red",
  },
  "1 Año": {
    color: "#fa7e58",
    dotClass: "dot-orange",
    borderClass: "form-left-bord-orange",
  },
  "2 Años": {
    color: "#f5a61d",
    dotClass: "dot-yellow",
    borderClass: "form-left-bord-yellow",
  },
  Ilimitado: {
    color: "#15db93",
    dotClass: "dot-green",
    borderClass: "form-left-bord-green",
  },
  "": { name: "", label: "", color: "", dotClass: "", borderClass: "" },
};

export const chartColors = [
  { color: "#1d3557", dotClass: "dot-black" },
  { color: "#048aa8", dotClass: "dot-blue" },
  { color: "#6cb6f5", dotClass: "dot-softblue" },
  { color: "#ef476f", dotClass: "dot-red" },
  { color: "#fa7e58", dotClass: "dot-orange" },
  { color: "#f5a61d", dotClass: "dot-yellow" },
  { color: "#15db93", dotClass: "dot-green" },
];

export const getFinishDate = (timestamp, type) => {
  const date = new Date(timestamp);
  let months = 0;
  let finalDate = new Date();
  switch (type) {
    case "Inmediato":
      return "No disponible";
    case "2 Años":
      months = 24;
      finalDate = new Date(
        date.getFullYear(),
        date.getMonth() + months,
        0
      ).getTime();
      return moment.unix(finalDate / 1000).format("DD/MM/YYYY");
    case "1 Año":
      months = 12;
      finalDate = new Date(
        date.getFullYear(),
        date.getMonth() + months,
        0
      ).getTime();
      return moment.unix(finalDate / 1000).format("DD/MM/YYYY");
    case "6 Meses":
      months = 6;
      finalDate = new Date(
        date.getFullYear(),
        date.getMonth() + months,
        0
      ).getTime();
      return moment.unix(finalDate / 1000).format("DD/MM/YYYY");

    case "3 Meses":
      months = 3;
      finalDate = new Date(
        date.getFullYear(),
        date.getMonth() + months,
        0
      ).getTime();
      return moment.unix(finalDate / 1000).format("DD/MM/YYYY");

    case "1 Mes":
      months = 1;
      finalDate = new Date(
        date.getFullYear(),
        date.getMonth() + months,
        0
      ).getTime();
      return moment.unix(finalDate / 1000).format("DD/MM/YYYY");
    case "Ahora":
      return "No disponible";
  }

  return _.round(finalDate, 2);
};

export const getNextContributionDate = (type) => {
  const date = new Date();
  const today = date.getDate();

  switch (type) {
    case 3:
      return new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime();

    case 2:
      if (today < 15) {
        return new Date(date.getFullYear(), date.getMonth(), 15).getTime();
      } else if (today > 15) {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime();
      }
      break;
    case 1:
      var dayOfWeek = 5; // friday
      var todayDate = new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate()
      );
      todayDate.setDate(
        todayDate.getDate() + ((dayOfWeek + 7 - todayDate.getDay()) % 7)
      );
      return todayDate;
  }
};
