import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import "./EmployeeSavings.scss";
import _ from "lodash";

const SavingAmountInput = ({
  savingAmount,
  totalRate,
  onChange,
  annualProfit,
  label,
}) => {
  const numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };
  return (
    <div className="form-row">
      <div className="iconContainer">
        <InfoIcon className="icon" />
      </div>
      <div className="item">
        <span className="title">Tipo de ahorro</span>
        <span className="value value-data value-data-2">{label}</span>
      </div>
      <div className="item">
        <span className="title">Tu aportación</span>
        <span className="value value-data">
          <input
            onWheel={numberInputOnWheelPreventChange}
            type="number"
            style={{ width: "200px" }}
            className="input-savings"
            placeholder="$0"
            value={savingAmount}
            onChange={(e) => onChange(e.target.value)}
            required
          />
        </span>
      </div>
      <div className="item">
        <span className="title">Tasa vigente</span>
        <span className="value value-info">
          {_.round(totalRate, 2)}
          <span className="currencySymbolSaving">%</span>
        </span>
      </div>
      <div className="item">
        <span className="title">Ahorro a un año</span>
        <span className="value value-info">
          <span className="currencySymbolSaving">$ </span>
          {annualProfit}
        </span>
      </div>
    </div>
  );
};

export default SavingAmountInput;
