import React from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import NotFoundPage from "./components/NotFoundPage";
import { routes } from "./Routers";
import { LandingPage } from "./pages/LandingPage";
import { ResetPassword } from "./pages/ResetPassword";
import { checkCurrentSession } from "./actions/userActions";
import "./index.css";

import { SuccessAlert, ErrorAlert } from "./components/Alert";

class App extends React.Component {
  componentDidMount = () => {
    const token = localStorage.getItem("userSession");

    // if it is already authenticated or the user is on the login page, don't check for session
    if (
      !this.props.user.isAuthenticated &&
      window.location.pathname !== "/" &&
      token
    ) {
      this.props.dispatch(checkCurrentSession(this.props.history));
    }
  };

  render() {
    const CustomRoute = ({
      exact,
      path = null,
      role,
      Component,
      Layout = null,
      index,
      authenticatedAccess = false,
    }) => {
      return (
        <Route
          key={index}
          exact={exact}
          path={`/${role}/${path}`}
          render={(routeProps) => {
            if (authenticatedAccess && !this.props.isAuthenticated) {
              return (
                <Redirect
                  to={{
                    pathname: "/",
                    state: { from: routeProps.location },
                  }}
                />
              );
            } else if (Layout) {
              return (
                <Layout>
                  <div className="flex flex-col">
                    <Component {...routeProps} />
                  </div>
                </Layout>
              );
            } else {
              return (
                <div>
                  <Route
                    key={index}
                    exact={exact}
                    path={`/${role}/${path}`}
                    component={Component}
                  />
                </div>
              );
            }
          }}
        />
      );
    };

    if (
      this.props.user.checkingForSession &&
      window.location.pathname !== "/" &&
      !window.location.pathname.includes("reset/")
    ) {
      return null;
    }

    return (
      <div className="flex flex-col">
        <ErrorAlert />
        <SuccessAlert />
        <Switch>
          <Route exact path="/" component={LandingPage} />
          {routes({ role: this.props.user.role }).map((r, index) =>
            CustomRoute({ ...r, index })
          )}
          <Route exact path="/reset/:tokenId" component={ResetPassword} />
          <Route component={NotFoundPage} />
        </Switch>
      </div>
    );
  }
}

App.propTypes = {
  isAuthenticated: PropTypes.any,
  user: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    isAuthenticated: state.user.isAuthenticated,
    user: state.user,
  };
}

export default withRouter(connect(mapStateToProps)(App));

// export default hot(module)(App);
