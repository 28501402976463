import React, { useState } from "react";
import { savingTypes } from "../../SavingsUtils";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { Select } from "../../../../../../components/Select";
import {
  ArrangeSavings,
  PayLoansWithSavings,
  ReinvestSavingsModal,
  UpdateSaving,
} from "./Modals";
import { api } from "../../../../../../services/api";
import { getSavingsSlots } from "../../../../../../actions/savingsAction";
import { setError } from "../../../../../../actions/errorAction";
import { setSuccess } from "../../../../../../actions/successAction";
import { useDispatch } from "react-redux";
import moment from "moment";
import _ from "lodash";

export const SavingSlot = ({ employee, saving, setIsLoading }) => {
  const [currentValue, setCurrentValue] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [savingInput, setSavingInput] = useState(
    saving.currentAmountToSaveFormatted
  );
  const [openModalWithdrawQuantity, setOpenModalWithdrawQuantity] =
    useState(false);
  const [openUdpateSavings, setOpenUpdateSavings] = useState(false);
  const [openPayLoansWithSavings, setOpenPayLoansWithSavings] = useState(false);
  const [openReinvestModal, setOpenReinvestModal] = useState(false);

  const dispatch = useDispatch();

  const onClickAction = async ({ actionId, selectedSavingTypeId = null }) => {
    try {
      setIsLoading(true);

      const options = {
        data: {
          savingId: saving.savingId,
          actionId,
          optionalAvailableSavingTypeIdToReinvest: selectedSavingTypeId,
        },
      };

      const result = await api.post(
        "Salvum/services/savings/actionOnSaving",
        options
      );

      if (result.error) {
        setIsLoading(false);
        dispatch(setError(result.message || "No pudimos completar la acción."));
        return;
      }

      // refreshes slots on successful action
      await dispatch(
        getSavingsSlots({
          companyId: employee.companyId,
          employeeId: employee.userId,
        })
      );

      setIsLoading(false);
      dispatch(setSuccess(result.message || "Accion completada con exito."));
    } catch (error) {
      setIsLoading(false);
      dispatch(
        setError(
          "No pudimos completar la acción. Ha ocurrido un error en nuestros servidores."
        )
      );
    }
  };

  const handleChange = (event) => {
    setCurrentValue(event.target.value);
    switch (event.target.value) {
      // Reinvertir ahorros
      case 1:
        setOpenReinvestModal(true);
        break;
      // Pagar préstamos con ahorros
      case 2:
        setOpenPayLoansWithSavings(true);
        break;
      // Disponer ahorros
      case 3:
        setOpenModalWithdrawQuantity(true);
        break;
      default:
        return "";
    }
  };

  if (!saving) {
    return null;
  }

  const toggleShowDetatils = () => {
    setShowDetails(!showDetails);
  };

  const showUpdateModal = () => {
    setOpenUpdateSavings(true);
  };

  return (
    <div
      className={`form-left ${
        savingTypes[saving.savingBankTypeIdFormatted].borderClass
      }`}
    >
      <ArrangeSavings
        isOpen={openModalWithdrawQuantity}
        onClickAction={onClickAction}
        setCurrentValue={setCurrentValue}
        setOpenModalWithdrawQuantity={setOpenModalWithdrawQuantity}
        currentValue={currentValue}
        saving={saving}
      />
      <PayLoansWithSavings
        isOpen={openPayLoansWithSavings}
        onClickAction={onClickAction}
        setCurrentValue={setCurrentValue}
        setOpenPayLoansWithSavings={setOpenPayLoansWithSavings}
        currentValue={currentValue}
        saving={saving}
      />
      <ReinvestSavingsModal
        isOpen={openReinvestModal}
        onClickAction={onClickAction}
        setCurrentValue={setCurrentValue}
        setOpenReinvestModal={setOpenReinvestModal}
        savingSlot={saving}
        currentValue={currentValue}
      />
      <UpdateSaving
        setOpenUpdateSavings={setOpenUpdateSavings}
        isOpen={openUdpateSavings}
        saving={saving}
        setSavingInput={setSavingInput}
      />
      {/* <CustomWhiteModal
        isOpen={openModalWithdrawQuantity}
        title="Disponer efectivo"
        subTitle="Disponer cantidad"
        body={disponerBody()}
      />
      <CustomWhiteModal
        isOpen={openPayLoansWithSavings}
        title="Pagar préstamos con ahorros"
        subTitle="Disponer cantidad"
        body={PayLoansWithSavingsBody()}
      /> */}
      <div className="headerTabPrint">
        <div className="headerTabPrintItem" />
        <div className="headerTabPrintItem headerTabPrintBigItem">
          <p className="headerTabPrintItemSmallTitle">Tipo de ahorro</p>
          <p className="headerTabPrintItemBigTitle">
            {saving.savingBankTypeIdFormatted}
          </p>
        </div>
        <div className="headerTabPrintItem">
          <p className="headerTabPrintItemSmallTitle">Tasa vigente</p>
          <p className="headerTabPrintItemBigTitle">
            {_.round(saving.totalRate, 2)}
            <span>%</span>
          </p>
        </div>
        <div className="headerTabPrintItem headerTabPrintBigItem">
          <p className="headerTabPrintItemSmallTitle">Saldo total</p>
          {/* Commenting this while the backend is fully ready to use it properly, for now dispplaying the totalRate */}
          {/* <p className="headerTabPrintItemBigTitle"><span>$ </span>{props.savingHasTransactions && props.savings && props.getSpreadTotalBalance(saving.savingId, saving.companyYieldRate, saving.totalRate)}</p> */}
          <p className="headerTabPrintItemBigTitle">{saving.valueFormatted}</p>
        </div>
        <div className="headerTabPrintItem headerTabPrintBigItem">
          <p className="headerTabPrintItemSmallTitle">Días transcurridos</p>
          <p className="headerTabPrintItemBigTitle">{saving.daysEllapsed}</p>
        </div>
        <div className="headerTabPrintItem">
          {!showDetails && (
            <ArrowDropDown className="arrow" onClick={toggleShowDetatils} />
          )}
          {showDetails && (
            <ArrowDropUp className="arrow" onClick={toggleShowDetatils} />
          )}
        </div>
      </div>
      {showDetails && (
        <div className="bodyTabPrint">
          <div className="grayLine" />
          <div className="bodyTabPrintContent">
            <div className="bodyTabPrintElement">
              <p className="headerTabPrintItemSmallTitle">Fecha de apertura</p>
              <p className="headerTabPrintItemBigTitle headerTabPrintItemMediumTitle">
                {saving.createdDateOfSavingSlotFormatted}
              </p>
            </div>

            <div className="bodyTabPrintElement">
              <p className="headerTabPrintItemSmallTitle">
                Plazos transcurridos
              </p>
              <p className="headerTabPrintItemBigTitle headerTabPrintItemMediumTitle">
                {saving.periodsEllapsed}
              </p>
            </div>
            <div className="bodyTabPrintElement">
              <p className="headerTabPrintItemSmallTitle">Capital aportado</p>
              <p className="headerTabPrintItemBigTitle headerTabPrintItemMediumTitle">
                {saving.principalGivenFormatted}
              </p>
            </div>
            <div className="bodyTabPrintElement">
              <p className="headerTabPrintItemSmallTitle">Aportación</p>
              <p className="headerTabPrintItemBigTitle headerTabPrintItemMediumTitle">
                {savingInput}
              </p>
            </div>
            {saving.availableActions.length > 0 && (
              <div className="bodyTabPrintElement">
                <Select
                  label="Acciones"
                  handleChange={handleChange}
                  value={currentValue}
                  items={saving.availableActions}
                />
              </div>
            )}
          </div>
          <div className="grayLine" />
          <div className="loadHistoryPayments">
            <div className="loadHistoryPaymentsHeader">
              Desgloce de aportaciones
            </div>
            <div className="loadHistoryPaymentsTitles">
              <div className="loadHistoryPaymentsTitle">Periodo</div>
              <div className="loadHistoryPaymentsTitle">
                Fecha de aportación
              </div>
              <div className="loadHistoryPaymentsTitle">Aportación</div>
              <div className="loadHistoryPaymentsTitle">Tasa Vigente</div>
              <div className="loadHistoryPaymentsTitle">Saldo</div>
            </div>

            {saving.savingsTable &&
              saving.savingsTable.map((item) => (
                <div
                  className="loadHistoryPaymentsBody"
                  key={`contribution-${saving.savingSlotType}-${item.period}`}
                >
                  <div className="loadHistoryPaymentsItem">{item.period}</div>
                  <div className="loadHistoryPaymentsItem">
                    {moment(item.date, "DD-MM-YYYY").format("DD/MM/YYYY")}
                  </div>
                  <div className="loadHistoryPaymentsItem">{item.input}</div>
                  <div className="loadHistoryPaymentsItem greenText">
                    {item.rate}
                  </div>
                  <div className="loadHistoryPaymentsItem">{item.balance}</div>
                </div>
              ))}
          </div>
          {/* BUTTONS */}
          <div className="grayLine" />
          <div>
            <button className="blueButton" onClick={showUpdateModal}>
              Modificar
            </button>
            {/* <button className="blueButton" onClick={this.backToCompanies}>Disponer</button>
                  <button className="blueButton" onClick={this.backToCompanies}>Pagar Préstamo</button>
              <button className="blueButton" onClick={this.backToCompanies}>Reiniciar</button> */}
          </div>
        </div>
      )}
    </div>
  );
};

export default SavingSlot;
