import React, { Component } from "react";
import { connect } from "react-redux";
import Chart from "react-google-charts";
import { makeStyles } from "@mui/styles";
import { Button } from "@mui/material";
import { LoadingCircularSpinner } from "../../../components/LoadingCircularSpinner";

import { api } from "../../../services/api";

import { getAdminHomeInfo } from "../../../actions/adminAction";
import { setError } from "../../../actions/errorAction";
import { setSuccess } from "../../../actions/successAction";
// STYLES
import "./AdminHome.scss";

export class AdminHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      general: true,
      age: 10,
      // MODAL CANCELL
      openModalAskForCancelled: false,
      showDetailModalAskForCancelled: false,
      isLoading: false,
    };

    this.redirectToNewCompany = this.redirectToNewCompany.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(getAdminHomeInfo());
  }

  useStyles = makeStyles((theme) => ({
    margin: {
      margin: theme.spacing(1),
    },
  }));

  redirectToNewCompany() {
    this.props.history.push("/administrador/agregar-empresa");
  }

  handleRequestOperations = async () => {
    try {
      //debugger;
      this.setState({ isLoading: true });
      const response = await api.get(
        "Salvum/services/layouts/operationsLayout"
      );
      if (response.error) {
        this.props.dispatch(
          setError(
            response.message || "Error al solicitar archivo de operaciones."
          )
        );
        this.setState({ isLoading: false });
        return;
      } else {
        this.props.dispatch(
          setSuccess(
            response.message ||
              "Se ha solicitado el archivo de operaciones exitósamente."
          )
        );
        this.setState({ isLoading: false });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      this.props.dispatch(
        setError("Ha ocurrido un error en nuestros servidores.")
      );
    }
  };

  render = () => {
    const { adminHomeInfo } = this.props;

    if (!adminHomeInfo) {
      return null;
    }

    const { isLoading } = this.state;

    return (
      <>
        <LoadingCircularSpinner show={isLoading} />
        <div className={"homeAdmin"}>
          <div className={"companiesHeroContent"}>
            <div className={"companiesContainer"}>
              <div className={"companiesHero"}>
                <div className={"content"}>
                  <h2 className={"contentTitle"}>
                    Bienvenido {this.props.user.fullName}
                  </h2>
                  <p className={"contentSubTitle"}>Salvum Admin</p>
                </div>
              </div>
            </div>
          </div>
          <div className={"companiesContent"}>
            <div className={"companiesContentData"}>
              <div className={"contentPrincipalColumn"}>
                <div className={"principalContentCompany"}>
                  <div className={"savingsContent"}>
                    <h2 className={"savings_title"}>Valor de caja</h2>

                    <div className={"grayLine"}></div>

                    <div className={"graphicHeader"}>
                      <div className={"option"}>
                      <p className={"grayText"}>Acumulación de Ahorros (12 Meses)</p>
                        <p className={"blackText"}>
                          <span className={"smallText"}>$</span>
                          <span className={"greenText"}>&#8593; </span>
                        </p>
                      </div>
                    </div>
                    <Chart
                      width={"100%"}
                      height={"300px"}
                      chartType="Bar"
                      loader={<div>Loading Chart</div>}
                      data={[
                        ["Mes", ""],
                        ...adminHomeInfo.boxValues.map((saving) => [
                          saving.monthFormatted,
                          saving.value,
                        ]),
                      ]}
                      options={{
                        title: "",
                      hAxis: { title: "Mes", titleTextStyle: { color: "#048aa8" } },
                      vAxis: { minValue: 0 },
                        // For the legend to fit, we make the chart area smaller

                        // lineWidth: 25
                      }}
                      // For tests
                      rootProps={{ "data-testid": "1" }}
                    />
                  </div>
                </div>
                <div className={"principalContentCompany"}>
                  <div className={"savingsContent"}>
                    <h2 className={"savings_title"}>Ahorros</h2>
                    <div className={"grayLine"}></div>
                    <div className={"tabHeader"}>
                      <div className={"tabItem"}>
                        <p className={"grayText"}>Ahorros activos</p>
                        <p className={"blackText"}>
                          {adminHomeInfo.activeSavings}
                        </p>
                      </div>
                      <div className={"tabItem"}>
                        <p className={"grayText"}>Saldo total acumulado</p>
                        <p className="blackText">
                          {adminHomeInfo.sumOfTotalAcumSavingsFormatted}
                        </p>
                      </div>
                    </div>
                    <div className={"grayLine"}></div>
                    <div className="tabHeader">
                      <div className={"tabItem"}>
                        <p className={"grayText"}>Interés</p>
                        <p className="blackText">
                          {adminHomeInfo.interestsFormatted}
                        </p>
                      </div>

                      <div className={"tabItem"}>
                        <p className={"grayText"}>Aportaciones mensuales</p>
                        <p className="blackText">
                          {adminHomeInfo.sumOfSavingInputsFormatted}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={"principalContentCompany savingsContent"}>
                  <div className={"sideOpt w-full"}>
                    <h2 className={"savings_title"}>Préstamos</h2>
                    <div className={"grayLine"}></div>
                    <div id="item-container" className="flex justify-between">
                      <div className="flex flex-col">
                        <p className={"grayText"}>Total registrados</p>
                        <p className={"blackText"}>
                          {adminHomeInfo.numOfTotalLoansGiven}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className={"grayText"}>Intereses totales cobrados</p>
                        <p className={"blackText"}>
                          {adminHomeInfo.sumOfInterestsPaidFormatted}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className={"grayText"}>Saldo total prestado</p>
                        <p className={"blackText"}>
                          {adminHomeInfo.sumOfLoansGivenFormatted}
                        </p>
                      </div>
                    </div>
                    <div className={"grayLine"}></div>
                    <div className="flex justify-between">
                      <div className="flex flex-col">
                        <p className={"grayText"}>En curso</p>
                        <p className={"blackText"}>
                          {adminHomeInfo.numOfTotalLoansGivenActive}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className={"grayText"}>
                          Intereses totales por cobrar
                        </p>
                        <p className={"blackText"}>
                          {adminHomeInfo.sumOfInterestsToRecoverFormatted}
                        </p>
                      </div>
                      <div className="flex flex-col">
                        <p className={"grayText"}>Saldo pendiente por pagar</p>
                        <p className={"blackText"}>
                          {adminHomeInfo.sumOfLoansCapitalPendingToPayFormatted}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={"sideContent"}>
                <div className={"principalContentCompany savingsContent"}>
                  <div className={"sideOpt"}>
                    <h2 className={"savings_title"}>Archivo de operaciones</h2>
                    <div className={"grayLine"}></div>
                    <Button
                      variant="outlined"
                      color="primary"
                      onClick={this.handleRequestOperations}
                    >
                      Solicitar
                    </Button>
                  </div>
                </div>
                <div className={"principalContentCompany savingsContent"}>
                  <div className={"sideOpt"}>
                    <h2 className={"savings_title"}>Empresas</h2>
                    <div className={"grayLine"}></div>
                    <p className={"grayText"}>Inscritas en Salvum</p>
                    <p className={"blackText"}>
                      {adminHomeInfo.totalNumOfCompanies}{" "}
                    </p>

                    <Button
                      className="border"
                      onClick={this.redirectToNewCompany}
                    >
                      + Nueva empresa
                    </Button>
                  </div>
                </div>
                <div className={"principalContentCompany savingsContent"}>
                  <div className={"sideOpt"}>
                    <h2 className={"savings_title"}>Empleados</h2>
                    <div className={"grayLine"}></div>
                    <p className={"grayText"}>Ahorrando / Registrados</p>
                    <p className={"blackText"}>
                      {adminHomeInfo.numOfEmployeesSaving}
                      <span className={"smallText"}>
                        /{adminHomeInfo.totalNumOfEmployees}
                      </span>
                    </p>
                  </div>
                </div>
                <div className={"principalContentCompany savingsContent"}>
                  {this.props.summary && (
                    <div className={"sideOpt"}>
                      <h2 className={"savings_title"}>Adelantos</h2>
                      <div className={"grayLine"}></div>
                      <p className={"grayText"}>Total registrados</p>
                      <p className={"blackText"}>
                        {adminHomeInfo.numOfTotalCashAdvancesGiven}
                        <span className={"greenText"}>&#8593;</span>
                      </p>
                      <p className={"grayText marginTopbase"}>
                        Saldo total adelantado
                      </p>
                      <p className={"blackText"}>
                        {adminHomeInfo.sumOfTotalCashAdvanceGivenFormatted}
                      </p>
                      <div className={"grayLine"}></div>
                      <p className={"grayText marginTopbase"}>
                        Totales en curso
                      </p>
                      <p className={"blackText"}>
                        {adminHomeInfo.numOfActiveCashAdvance}
                      </p>
                      <p className={"grayText marginTopbase"}>
                        Saldo total en curso
                      </p>
                      <p className={"blackText"}>
                        {adminHomeInfo.sumOfActiveCashAdvanceFormatted}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
}

function mapStateToProps(state) {
  return {
    ...state,
    user: state.user.user,
    summary: state.company.summaryAdmin,
    banxico: state.savings.banxico,
    adminHomeInfo: state.admin.adminHomeInfo,
  };
}

export default connect(mapStateToProps)(AdminHome);
