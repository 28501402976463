import React from "react";
import {
  Box,
  InputLabel,
  MenuItem,
  FormControl,
  Select as DropDown,
} from "@mui/material";
import PropTypes from "prop-types";

/*
  items: array of objects:
  Object definition:
 {
   actionId: 1,
   label: "Ahorro"
 }
*/

export const Select = ({
  label,
  items = [],
  value = "",
  handleChange = () => {},
}) => {
  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <DropDown
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={value}
          label={label}
          onChange={handleChange}
        >
          {items.map((item) => (
            <MenuItem key={item.label} value={item.actionId}>
              {item.label}
            </MenuItem>
          ))}
        </DropDown>
      </FormControl>
    </Box>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  items: PropTypes.array,
  value: PropTypes.number,
  handleChange: PropTypes.func,
};

export default Select;
