import React, { useState } from "react";
import { Button } from "@mui/material";
import { Modal } from "../../../../../../../components/Modal";
import "./Modals.scss";
import { useSelector, useDispatch } from "react-redux";
import { TextField } from "@mui/material";
import { useFormik } from "formik";
import { api } from "../../../../../../../services/api";
import { setError } from "../../../../../../../actions/errorAction";
import { setSuccess } from "../../../../../../../actions/successAction";
import * as Yup from "yup";

const validationSchema = Yup.object({
  amount: Yup.number()
    .required("Debes ingresar una cantidad de balance minima para continuar")
    .test(
      "Is positive?",
      "Ingresa solamente numeros positivos",
      (value) => value > 0
    ),
});

export const UpdateSaving = ({
  setOpenUpdateSavings,
  isOpen,
  saving = {},
  setSavingInput,
}) => {
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const employee = useSelector((state) => state.employee.current);

  const closeConfirmModal = () => {
    setConfirmModalIsOpen(false);
  };

  const closeModal = () => {
    setOpenUpdateSavings(false);
  };

  const initialValues = {
    amount: saving.currentAmountToSave,
  };

  const handleContinue = () => {
    setConfirmModalIsOpen(true);
  };

  const dispatch = useDispatch();

  const handleSubmit = async () => {
    try {
      const requestOptions = {
        data: {
          employeeId: employee.userid,
          companyId: employee.companyid,
          newSavingValue: formik.values.amount,
          savingTypeId: saving.savingBankTypeId,
        },
      };
      const response = await api.post(
        "Salvum/services/savings/updateSavingSlots",
        requestOptions
      );
      if (response.data) {
        if (response.data.updated) {
          await dispatch(
            setSuccess(
              response.message || "Monto de ahorro actualizado exitosamente."
            )
          );

          if (setSavingInput) setSavingInput(formik.values.amount);
        }
      } else {
        dispatch(
          setError(
            response.message ||
              "Ha ocurrido un error al actualizar tus ahorros."
          )
        );
      }
      closeModal();
      closeConfirmModal();
    } catch (error) {
      dispatch(
        setError(
          "Ha ocurrido un error interno en nuestros servidores al actualizar tus ahorros."
        )
      );
      closeModal();
      closeConfirmModal();
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: handleSubmit,
  });

  if (!saving) {
    return null;
  }

  if (!isOpen) {
    return null;
  }

  return (
    <form onSubmit={formik.handleSubmit} className="modal-box">
      <Modal
        isOpen={isOpen}
        handleClose={closeModal}
        title="Modificar monto de tu ahorro"
      >
        <div className="inlineBoxElements">
          <div className="boxElement">
            <p className="grayTextModal">Tipo de ahorro</p>
            <p className="blackText">{saving.savingBankTypeIdFormatted}</p>
          </div>
          <div className="grayLine" />
          <div className="boxElement">
            <p className="grayTextModal">Tasa vigente</p>
            <p className="blackText">{saving.totalRate}%</p>

            <p className="grayTextModal">Tu aportación</p>
            <TextField
              fullWidth
              autoFocus
              name="amount"
              id="amount"
              style={{
                marginBottom: "20px",
              }}
              type="number"
              value={formik.values.amount}
              error={formik.touched.amount && Boolean(formik.errors.amount)}
              helperText={formik.touched.amount && formik.errors.amount}
              onChange={formik.handleChange}
            />
            {/* <p className="grayTextModal">Ganancias Anuales</p>
                  <p className="blackText"><span>$</span>{getAnnualProfitValue(formik.values.amount)}</p> */}
          </div>
          <div className="boxElement" />

          <div className="boxElement" />

          <div className="buttons-container">
            <Button
              variant="contained"
              color="primary"
              type="button"
              onClick={handleContinue}
            >
              Modificar
            </Button>
            <Button onClick={closeModal} type="button">
              Cancelar
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={confirmModalIsOpen}
        handleClose={closeConfirmModal}
        title="Confirmación de modificación"
      >
        <h4>
          Está a punto de modificar su capital aportado de
          {saving.currentAmountToSaveFormatted} a $
          {formik.values?.amount?.toLocaleString()}
        </h4>

        <h4>¿Desea continuar?</h4>

        <div className="buttons-container">
          <Button
            onClick={closeConfirmModal}
            variant="outlined"
            type="button"
            style={{ marginRight: "8px" }}
          >
            En otro momento
          </Button>
          <Button
            className="custom-button-success"
            color="success"
            variant="contained"
            type="submit"
            onClick={handleSubmit}
          >
            Actualizar capital
          </Button>
        </div>
      </Modal>
    </form>
  );
};

export default UpdateSaving;
