import {
  SET_ALERT_OPEN,
  SET_ALERT_TITLE,
  SET_ALERT_TYPE,
} from "../constants/actionTypes";

import initialState from "./initialState";

export default function globalReducer(state = initialState.global, action) {
  switch (action.type) {
    case SET_ALERT_TITLE:
      return {
        ...state,
        alertTitle: action.alertTitle,
      };
    case SET_ALERT_OPEN:
      return {
        ...state,
        alertIsOpen: action.alertIsOpen,
      };
    case SET_ALERT_TYPE:
      return {
        ...state,
        alertType: action.alertType,
      };
    default:
      return state;
  }
}
