import React from "react";
import { savingTypes } from "../SavingsUtils";
import { useSelector } from "react-redux";
import _ from "lodash";

export const SavingsHorizons = ({ savingSlots }) => {
  const employee = useSelector((state) => state.employee.current);
  const getPercentOfPayroll = (amount) => {
    const currentAmount = parseFloat(amount);
    const totalAmount = parseFloat(employee.netpay);
    if (totalAmount === 0) return 0;
    return _.round((currentAmount * 100) / totalAmount, 2);
  };

  return (
    <div className="chartItem">
      <span className="title">Tus horizontes de ahorro</span>
      {savingSlots
        .filter((savingStatus) => savingStatus.currentAmountToSave > 0)
        .map((saving) => (
          <div
            className="element"
            key={`saving-horizon-${saving.savingBankTypeIdFormatted}`}
          >
            <span
              className={`dot ${
                savingTypes[saving.savingBankTypeIdFormatted].dotClass
              }`}
            />
            <b>{saving.savingBankTypeIdFormatted} </b>
            {getPercentOfPayroll(
              saving.currentAmountToSave,
              savingSlots.length
            )}
            % &nbsp;(${saving.currentAmountToSave.toLocaleString("en-US")})
          </div>
        ))}
    </div>
  );
};

export default SavingsHorizons;
