import React from "react";
import AlertMaterial from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import { Slide } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import "./Alert.scss";

import { hideError } from "../../actions/errorAction";

export const ErrorAlert = () => {
  const error = useSelector((state) => state.error.error);
  const isOpen = useSelector((state) => state.error.isOpen);
  const dispatch = useDispatch();

  if (!isOpen) {
    return null;
  }

  if (isOpen) {
    setTimeout(() => {
      dispatch(hideError());
    }, 6000);
  }

  const closeAlert = () => {
    dispatch(hideError());
  };

  return (
    <div
      id="ErrorAlert"
      className=""
      style={{
        position: "fixed",
        zIndex: 1000000,
        alignSelf: "center",
        justifySelf: "center",
      }}
    >
      <Slide in={isOpen} direction="down" timeout={500}>
        <AlertMaterial
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={closeAlert}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {error}
        </AlertMaterial>
      </Slide>
    </div>
  );
};

export default ErrorAlert;
