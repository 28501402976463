import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Input from "@mui/material/Input";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { RowPeriod } from "./RowPeriod/RowPeriod";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import { LoadingCircularSpinner } from "../../../../components/LoadingCircularSpinner";

import "./LoanForm.scss";
import { useForm } from "../../../../hooks/useForm";
import { getFormattedNumber } from "../../../../utils/numberFormat";
import { api } from "../../../../services/api";

import { setError } from "../../../../actions/errorAction";
import { setSuccess } from "../../../../actions/successAction";

export const LoanForm = (props) => {
  const { isOpen, calculateAmortizationTable, toggle } = props;
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.loans);
  const [showCircularSpinner, setShowCircularSpinner] = useState(false);
  const { netPay, periods, numberOfEndorsements, companyCoWorkers } =
    useSelector((state) => state.loans.loansByEmployeeAndCompany);
  const {
    employeeName,
    creditAmount,
    comission,
    anualInterestRate,
    typeOfPeriods,
    table,
  } = useSelector((state) => state.loans.amortizationTableInfo);
  const { userid: employeeId, companyid } = useSelector(
    (state) => state.employee.current
  );
  const [messageError, setMessageError] = useState("");

  const initialForm = {
    netPayForm: "",
  };

  const [formValues, handleInputChange] = useForm(initialForm);
  const { netPayForm } = formValues;

  const [selectedPeriod, setSelectedPeriod] = useState(null);
  const animatedComponents = makeAnimated();
  const [selectedCoWorker, setSelectedCoWorker] = useState(null);

  const periodOptions = [];
  if (periods) {
    periods.forEach((period) => {
      periodOptions.push({ value: period, label: `${period}` });
    });
  }

  const coworkerOptions = [];
  if (companyCoWorkers) {
    companyCoWorkers.forEach((coworker) => {
      coworkerOptions.push({
        value: coworker.employeeId,
        label: `${coworker.firstName} ${coworker.lastName}`,
        numEndorsements: numberOfEndorsements,
      });
    });
  }

  const handleApplyForLoan = async () => {
    try {
      setShowCircularSpinner(true);
      const formatCoworkersIdsForRequest = selectedCoWorker
        ? selectedCoWorker.map((coworker) => coworker.value)
        : [];
      const requestParams = {
        data: {
          creditAmount: netPayForm,
          creditPeriods: selectedPeriod && selectedPeriod.value,
          employeeId,
          companyId: companyid,
          companyCoworkersForLoan: formatCoworkersIdsForRequest,
        },
      };

      if (!netPayForm || !selectedPeriod || !selectedCoWorker) {
        dispatch(
          setError(
            "Aún faltan campos por llenar. Por favor, ingrese la cantidad, el plazo y el aval(les) para continuar con su solicitud."
          )
        );
        setShowCircularSpinner(false);
        return;
      }
      const response = await api.post(
        "Salvum/services/loans/requestLoan",
        requestParams
      );

      if (!response.error) {
        dispatch(setSuccess(response.data.message));
      } else {
        dispatch(setError(response.message));
      }
      setShowCircularSpinner(false);
      toggle();
    } catch (error) {
      toggle();
      dispatch(setError("Ha ocurrido un error inesperado en el servidor."));
      setShowCircularSpinner(false);
    }
  };

  const handleCalculateAmortization = () => {
    if (netPayForm) {
      const parsedNetPay = parseFloat(netPayForm);
      const parsedPeriod = parseInt(selectedPeriod.value);
      setMessageError("");
      calculateAmortizationTable(parsedNetPay, parsedPeriod);
    } else {
      setMessageError("Debe ingresar un monto");
    }
  };

  useEffect(() => {
    if (netPayForm > netPay) {
      setMessageError("No debe exceder el monto máximo del préstamo");
    } else {
      setMessageError("");
    }
  }, [netPayForm]);

  return (
    <div className="loan-form-container">
      <LoadingCircularSpinner show={showCircularSpinner} />
      <div className="loan-form">
        <div className="loan-form__header">
          <div className="loan-form__left">
            <h1>Solicitar Préstamo</h1>
            <p>¿Qué monto desea solicitar?</p>
            <Input
              name="netPayForm"
              margin="dense"
              placeholder="$5,000"
              onChange={handleInputChange}
              value={netPayForm}
            />
            <span className="error_notification">{messageError}</span>
          </div>
          <div className="loan-form__right">
            <div
              className={`${
                netPayForm > netPay
                  ? "loan-form__circle-warning"
                  : "loan-form__circle"
              } flex flex-col items-center justify-center`}
            >
              <div className="flex flex-col items-center justify-center">
                <h2 className="flex">
                  $ {getFormattedNumber(netPay - netPayForm)}
                </h2>
                <p>Máximo a solicitar</p>
              </div>
            </div>
          </div>
        </div>
        <div className="loan-form__select-group">
          <p className="plazo">¿A qué plazo mensual?</p>
          <Select
            name="periodo"
            defaultValue={selectedPeriod}
            onChange={setSelectedPeriod}
            options={periodOptions}
          />
          <p className="aval">Selecciona el aval</p>
          <Select
            // defaultValue={[coworkerOptions[2], coworkerOptions[3]]}
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            options={coworkerOptions}
            onChange={setSelectedCoWorker}
            isOptionDisabled={(option) =>
              selectedCoWorker &&
              selectedCoWorker.length >= option.numEndorsements
            }
          />
        </div>

        <div className="loan-form__calculate_loan">
          <button
            type="button"
            className="transition ease-in-out border-2 p-2 rounded-2xl calcular-button text-sm "
            onClick={handleCalculateAmortization}
            isDisabled={!!(netPayForm === "" && selectedPeriod === null)}
          >
            Calcular préstamo
          </button>
        </div>
        {loading && (
          <div className="loan-form__card">
            <Box sx={{ width: "100%" }}>
              <LinearProgress />
            </Box>
          </div>
        )}

        {table.length > 0 && (
          <>
            <div className="loan-form__card">
              <div className="card__row">
                <div className="card__item">
                  <div>
                    <p>Empleado</p>
                  </div>
                  <div>
                    <p>Préstamo</p>
                  </div>
                  <div>
                    <p>Comisión por apertura %</p>
                  </div>
                  <div>
                    <p>Interés</p>
                  </div>
                  <div>
                    <p>Nómina</p>
                  </div>
                </div>
              </div>
              <div className="card__row">
                <div className="card__item">
                  <div>
                    <span>{employeeName}</span>
                  </div>
                  <div>
                    <span>{creditAmount}</span>
                  </div>
                  <div>
                    <span>{comission}</span>
                  </div>
                  <div>
                    <span>{anualInterestRate}</span>
                  </div>
                  <div>
                    <span>{typeOfPeriods}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="loan-form__table-container">
              <div className="loan-form__table">
                <div className="table__head-row">
                  <span>Periodo</span>
                  <span>Balance Inicial</span>
                  <span>Interés</span>
                  <span>Capital</span>
                  <span>Pago</span>
                  <span>Saldo Insoluto</span>
                </div>
                <div className="todo__entries">
                  {table.map((item) => (
                    <RowPeriod key={item.period} item={item} />
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
        <div className="loan-form__buttons mb-6">
          <button
            type="button"
            disabled={!selectedPeriod && !selectedCoWorker}
            className="transition ease-in-out border-2 p-3 text-white rounded-2xl bg-green-600 hover:bg-green-700 text-sm"
            onClick={handleApplyForLoan}
          >
            Solicitar préstamo
          </button>

          <button
            type="button"
            className="transition ease-in-out border-2 p-3 text-white rounded-2xl bg-gray-50 hover:bg-gray-100 text-sm text-black w-32 ml-3 mt-5"
            onClick={isOpen}
          >
            Cancelar
          </button>
        </div>
      </div>
    </div>
  );
};
