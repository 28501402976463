import React, { Component } from "react";
import { connect } from "react-redux";

import { getPendingCashAdvanceForBoss } from "../../../actions/payrollAdvanceAction";

import { setError } from "../../../actions/errorAction";
import { setSuccess } from "../../../actions/successAction";
// STYLES
import "./BossPayRolls.scss";

// MODAL
import CustomModal from "../../../shared/Modal";

// ASSETS
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

// services
import { api } from "../../../services/api";
export class BossPayRoll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      general: true,
      // MODAL
      openModalAskForReject: false,
      openModalAskForApproved: false,
      advanceId: 0,
      currentAdvanceAmount: null,
      employeeId: 0,
    };
    //this.selectBar = this.selectBar.bind(this);
    // Modal data
    this.setEmployeeData = this.setEmployeeData.bind(this);

    // Modal reject
    this.openModalAskForReject = this.openModalAskForReject.bind(this);
    this.toggleModalAskForReject = this.toggleModalAskForReject.bind(this);

    // Modal approved
    this.openModalAskForApproved = this.openModalAskForApproved.bind(this);
    this.toggleModalAskForApproved = this.toggleModalAskForApproved.bind(this);
  }

  componentDidMount = () => {
    this.props.dispatch(
      getPendingCashAdvanceForBoss({
        bossUserId: this.props.user.id,
        companyId: this.props.user.companyId,
      })
    );
  };

  // MODAL DATA
  setEmployeeData = (advanceId, advance, modal) => {
    this.setState({
      advanceId,
      currentAdvanceAmount: advance,
    });
    if (modal === "rejected") {
      return this.toggleModalAskForReject();
    } else if (modal === "approved") {
      return this.toggleModalAskForApproved();
    }
  };

  // MODAL APPROVED
  toggleModalAskForApproved() {
    this.setState({
      openModalAskForApproved: !this.state.openModalAskForApproved,
    });
  }

  openModalAskForApproved() {
    this.setState({
      openModalAskForApproved: true,
    });
  }

  modalBodyAskForApproved = () => {
    // Cuerpo del formulario
    const { currentAdvanceAmount, advanceId } = this.state;
    if (!this.state.openModalAskForApproved) return null;
    return (
      <div className={"modal-box"}>
        <div className={"modalSubtitle"}>
          <b>¿Aprobar adelanto?</b>
        </div>
        <div className={"grayLine"}></div>
        <div className={"boxElement"}>
          <p className={"grayTextModal"}>Monto del adelanto</p>
          <p className={"blackText"}>
            <span>$</span>
            {currentAdvanceAmount}
          </p>
        </div>
        <button
          className={"normal_button"}
          onClick={this.toggleModalAskForApproved}
        >
          Regresar
        </button>
        <button
          className={"approved_button"}
          onClick={() => this.advanceStatusApproved(advanceId)}
        >
          Aprobar
        </button>
      </div>
    );
  };

  // MODAL REJECT
  toggleModalAskForReject() {
    this.setState({
      openModalAskForReject: !this.state.openModalAskForReject,
    });
  }

  openModalAskForReject() {
    this.setState({
      openModalAskForReject: true,
    });
  }

  modalBodyAskForReject = () => {
    // Cuerpo del formulario
    if (!this.state.openModalAskForReject) return null;
    const { currentAdvanceAmount, advanceId } = this.state;
    return (
      <div className={"modal-box"}>
        <div className={"modalSubtitle"}>
          <b>¿Rechazar adelanto?</b>
        </div>
        <div className={"grayLine"}></div>
        <div className={"boxElement"}>
          <p className={"grayTextModal"}>Monto del adelanto</p>
          <p className={"blackText"}>
            <span>$</span>
            {currentAdvanceAmount}
          </p>
        </div>
        <button
          className={"normal_button"}
          onClick={this.toggleModalAskForReject}
        >
          Regresar
        </button>
        <button
          className={"approved_button"}
          onClick={() => this.advanceStatusRejected(advanceId)}
        >
          Rechazar
        </button>
      </div>
    );
  };

  advanceStatusChange = (advanceStatus) => {
    switch (advanceStatus) {
      case 1:
        return `toBeApproved`;

      case 3:
        return `approved`;

      case 4:
        return `rejected`;
      default:
        return "toBeApproved";
    }
  };

  advanceStatusLabel = (advanceStatus) => {
    switch (advanceStatus) {
      case 1:
        return `Pendiente`;

      case 2:
        return `Aprobado`;

      case 3:
        return `Rechazado`;
      default:
        return "";
    }
  };

  advanceStatusRejected = async (cashAdvanceId) => {
    try {
      const { id: bossUserId, companyId } = this.props.user;
      const response = await api.get(
        "Salvum/services/cashadvance/updateCashAdvanceStatusForBoss",
        {
          params: {
            cashAdvanceId,
            bossUserId,
            nextStep: false,
          },
        }
      );

      if (response.error) {
        await this.props.dispatch(setError(response.message));
      } else {
        await this.props.dispatch(
          getPendingCashAdvanceForBoss({ companyId, bossUserId })
        );
        await this.props.dispatch(setSuccess(response.data.message));
      }
      this.toggleModalAskForReject();
    } catch (error) {
      const message = error.response.data.message || error.message;
      this.props.dispatch(setError(message));
      this.toggleModalAskForReject();
    }
  };

  advanceStatusApproved = async (cashAdvanceId) => {
    try {
      const { id: bossUserId, companyId } = this.props.user;
      const response = await api.get(
        "Salvum/services/cashadvance/updateCashAdvanceStatusForBoss",
        {
          params: {
            cashAdvanceId,
            bossUserId,
            nextStep: true,
          },
        }
      );

      if (response.error) {
        await this.props.dispatch(setError(response.message));
      } else {
        await this.props.dispatch(
          getPendingCashAdvanceForBoss({ companyId, bossUserId })
        );
        await this.props.dispatch(setSuccess(response.data.message));
      }
      this.toggleModalAskForApproved();
    } catch (error) {
      const message = error.response.data.message || error.message;
      this.props.dispatch(setError(message));
      this.toggleModalAskForApproved();
    }
  };

  renderAdvanceByStatus(item) {
    return (
      <div className={"principalContentCompany"}>
        <div className={"savingsContent"}>
          <div className={"tabHeader"}>
            <div className={"tabItemB"}></div>
            <div className={"tabItemB half"}>
              <p className={"grayText"}>Monto de adelanto</p>
              <p className={"blackText"}>{item.amountFormatted}</p>
            </div>
            <div className={"tabItemB half"}>
              <p className={"grayText"}>Fecha de petición</p>
              <p className={"blackTextDate"}>{item.requestedDateFormatted}</p>
            </div>
            <div className={"tabItemB"}>
              <p className={"grayText"}>Nombre de empleado</p>
              <p className="text-black">{item.employeeName}</p>
            </div>
            <div className={"tabItemB half"}>
              <p className={"grayText"}>Estatus</p>
              <span className={`${this.advanceStatusChange(item.status)}`}>
                {this.advanceStatusLabel(item.status)}
              </span>
            </div>
            <div className={"tabItemB"}>
              <div className={"container-symbols"}>
                {
                  <button
                    onClick={() =>
                      this.setEmployeeData(
                        item.cashAdvanceId,
                        item.amount,
                        "approved"
                      )
                    }
                    className={"blackTextDate green-float"}
                  >
                    ✔
                  </button>
                }
                {
                  <button
                    onClick={() =>
                      this.setEmployeeData(
                        item.cashAdvanceId,
                        item.amount,
                        "rejected"
                      )
                    }
                    className={"blackTextDate red-float"}
                  >
                    ✘
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderPayrollAdvance(item) {
    return (
      <div className={"principalContentCompany"}>
        <div className={"savingsContent"}>
          <div className={"tabHeader"}>
            <div className={"tabItemB"}></div>
            <div className={"tabItemB half"}>
              <p className={"grayText"}>Monto de adelanto</p>
              <p className={"blackText"}>{item.amountFormatted}</p>
            </div>
            <div className={"tabItemB half"}>
              <p className={"grayText"}>Fecha de petición</p>
              <p className={"blackTextDate"}>{item.requestedDateFormatted}</p>
            </div>
            <div className={"tabItemB"}>
              <p className={"grayText"}>Nombre de empleado</p>
              <p className="text-black">{item.employeeName}</p>
            </div>
            <div className={"tabItemB half"}>
              <div className={"container-box"}>
                <p className={"grayText"}>Estatus</p>
                <span className={`${this.advanceStatusChange(item.status)}`}>
                  {item.statusFormatted}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    const { pendingCashAdvanceForBoss } = this.props;
    return (
      <>
        <CustomModal
          isOpen={this.state.openModalAskForApproved}
          toggle={this.toggleModalAskForApproved}
          title=""
          subTitle=""
          isGreen={true}
          body={this.modalBodyAskForApproved()}
        />
        <CustomModal
          isOpen={this.state.openModalAskForReject}
          toggle={this.toggleModalAskForReject}
          title=""
          subTitle=""
          isRed={true}
          body={this.modalBodyAskForReject()}
        />
        <div className={"bossPayRoll"}>
          <div className={"companiesHeroContent"}>
            <div className={"companiesContainer"}>
              <ul className={"breadcrumbs"}>
                <li>Inicio</li>
                <li>Adelantos de nómina</li>
              </ul>
              <div className={"companiesHero"}>
                <div className={"content"}>
                  <span className={"contentTitle"}>Adelantos de nómina</span>
                </div>
              </div>
            </div>
          </div>
          <div className={"companiesContent"}>
            <div className={"companiesContentData"}>
              <div className={"principalContentCompany"}>
                <div className={"savingsContent"}>
                  <div className={"tabHeader"}>
                    <h2 className={"savings_title"}>
                      Resumen de adelantos de nómina
                    </h2>
                    <div className={"icon-container"}>
                      <ThumbsUpDownIcon className={"icon-blue"} />
                      <span className={"blue-content"}>
                        Adelantos pendientes (
                        {pendingCashAdvanceForBoss &&
                          pendingCashAdvanceForBoss.cashAdvanceSummary &&
                          pendingCashAdvanceForBoss.cashAdvanceSummary.pending}
                        )
                      </span>
                    </div>
                  </div>
                  <div className={"grayLine"}></div>
                  <div className={"tabHeader"}>
                    <div className={"tabItem"}></div>
                    <div className={"tabItem"}>
                      <p className={"grayText"}>Por aprobar</p>
                      <p className={"blackText"}>
                        <span className={"blue-text"}>
                          {pendingCashAdvanceForBoss &&
                            pendingCashAdvanceForBoss.cashAdvanceSummary &&
                            pendingCashAdvanceForBoss.cashAdvanceSummary
                              .pending}
                        </span>
                      </p>
                    </div>
                    <div className={"tabItem"}>
                      <p className={"grayText"}>Aprobados</p>
                      <p className={"blackText"}>
                        <span className={"green-text"}>
                          {pendingCashAdvanceForBoss &&
                            pendingCashAdvanceForBoss.cashAdvanceSummary &&
                            pendingCashAdvanceForBoss.cashAdvanceSummary
                              .approved}
                        </span>
                      </p>
                    </div>
                    <div className={"tabItem"}>
                      <p className={"grayText"}>Rechazados</p>
                      <p className={"blackText"}>
                        <span className={"red-text"}>
                          {pendingCashAdvanceForBoss &&
                            pendingCashAdvanceForBoss.cashAdvanceSummary &&
                            pendingCashAdvanceForBoss.cashAdvanceSummary
                              .rejected}
                        </span>
                      </p>
                    </div>
                    <div className={"tabItem"}></div>
                    <div className={"tabItem"}>
                      <p className={"grayText"}>Total Aprobado</p>
                      <p className={"blackText"}>
                        {pendingCashAdvanceForBoss &&
                          pendingCashAdvanceForBoss.cashAdvanceSummary &&
                          pendingCashAdvanceForBoss.cashAdvanceSummary
                            .totalAmountLend}
                      </p>
                    </div>
                    <div className={"tabItem"}></div>
                  </div>
                </div>
              </div>
              <div className={"grayLine"}></div>
              <div className={"searchCompany"}>
                <div className={"searchIconCompany"}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Buscar empleado…"
                  classes={{
                    root: "inputRoot",
                    input: "inputInput",
                  }}
                  inputProps={{ "aria-label": "search" }}
                />
              </div>
              <h3 className="text-black text-3xl mb-4">
                Adelantos de nómina pendientes de aprobar
              </h3>
              {pendingCashAdvanceForBoss &&
                pendingCashAdvanceForBoss.pendingCashAdvanceToApprove &&
                pendingCashAdvanceForBoss.pendingCashAdvanceToApprove.map(
                  (item, index) => this.renderAdvanceByStatus(item, index)
                )}
              {pendingCashAdvanceForBoss &&
                pendingCashAdvanceForBoss.pendingCashAdvanceToApprove &&
                pendingCashAdvanceForBoss.pendingCashAdvanceToApprove.length ===
                  0 && <h3>No tiene adelantos pendientes de aprobación</h3>}
              <div className={"grayLine"}></div>
              <h3 className="text-black text-3xl mb-4">Historial</h3>
              {pendingCashAdvanceForBoss &&
                pendingCashAdvanceForBoss.history &&
                pendingCashAdvanceForBoss.history.map((item, index) =>
                  this.renderPayrollAdvance(item, index)
                )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
    payrollAdvanceResume: state.advances.payrollAdvanceResume || {},
    payrollAdvanceByStatus: state.advances.payrollAdvanceByStatus,
    payroll: state.advances.payrollAdvance,
    user: state.user.user,
    company: state.company.current,
    employees: state.employee.list,
    pendingCashAdvanceForBoss: state.advances.pendingCashAdvanceForBoss,
  };
}

export default connect(mapStateToProps)(BossPayRoll);
