import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";

// STYLES
import "./Modals.scss";

// ASSETS
import aproveMoney from "../assets/images/modals/dar-dinero.png";
import dontAproveMoney from "../assets/images/modals/sin-dinero.png";

class CustomModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.state = {};
  }

  handleClose = () => {
    if (this.props.toggle) {
      this.props.toggle();
    }
  };

  render() {
    const BuildModal = () => {
      const useStyles = makeStyles(() => ({
        modal: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        paper: {
          position: "absolute",
          minWidth: "80%",
          borderRadius: 7,
          //background: 'linear-gradient(0deg, #1d3557 0%, #048aa8 100%)',
          background: "#FFF",
        },
        paper2: {
          position: "relative!important",
        },
        bigModalW: {
          width: "700px",
        },
        orangeTopBack: {
          background:
            "linear-gradient(0deg, #c5274d 0%, #fa7e58 100%)!important",
          maxWidth: "650px",
        },
        greenTopBack: {
          background: "linear-gradient(0deg, #15db93, #11b78c)!important",
          maxWidth: "650px",
          minHeight: "160px",
        },
        redTopBack: {
          background: "linear-gradient(0deg, #fa7e58, #c5274d)!important",
          maxWidth: "650px",
          minHeight: "160px",
        },
        blueTopBack: {
          background: "linear-gradient(0deg, #048aa8, #1d3557)!important",
          maxWidth: "650px",
          minHeight: "160px",
        },
      }));

      const classes = useStyles();

      return (
        <div>
          <Modal
            disableEnforceFocus
            open={this.props.isOpen}
            onClose={this.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={this.modalStyle}
              className={`${[
                classes.paper,
                this.props.bigModal ? classes.bigModalW : "",
                this.props.isOrange ? classes.orangeTopBack : "",
                this.props.isGreen ? classes.greenTopBack : "",
                this.props.isRed ? classes.redTopBack : "",
                this.props.isBlue ? classes.blueTopBack : "",
                this.props.isRed || this.props.isGreen ? classes.paper2 : "",
              ].join(" ")} p-6`}
            >
              <div style={{ width: "100%", textAlign: "right" }}>
                <CloseIcon onClick={this.handleClose} />
              </div>
              <h2 id="simple-modal-title" style={{ marginTop: "0" }}>
                {this.props.title}
              </h2>
              <p id="simple-modal-description">{this.props.subTitle}</p>
              {this.props.isGreen && (
                <div className={"imageContent"}>
                  <img id={"logo-image"} alt="Compania" src={aproveMoney}></img>
                </div>
              )}
              {this.props.isRed && (
                <div className={"imageContent"}>
                  <img
                    id={"logo-image"}
                    alt="Compania"
                    src={dontAproveMoney}
                  ></img>
                </div>
              )}
              {this.props.body}
            </div>
          </Modal>
        </div>
      );
    };

    return <BuildModal />;
  }
}

CustomModal.propTypes = {
  toggle: PropTypes.any,
  isOpen: PropTypes.any,
  bigModal: PropTypes.any,
  title: PropTypes.any,
  subTitle: PropTypes.any,
  body: PropTypes.any,
};

export default CustomModal;
