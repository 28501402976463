import React, { useState } from "react";

// Components
import { TextField } from "@mui/material";
import { Buttons } from "./Buttons";

// Step 1
export const Authorizers = ({
  step,
  formik,
  handleNext = () => {},
  handleBack,
}) => {
  const [errors, setErrors] = useState({});

  if (step !== 4) {
    return null;
  }

  const validateForm = async () => {
    const errors = await formik.validateForm();
    const errorNames = Object.keys(errors);
    setErrors(errors);
    if (
      !errorNames.includes("approver_email") &&
      !errorNames.includes("authorizator_email")
    ) {
      handleNext();
    }
  };

  return (
    <div className="flex flex-col items-center p-6">
      <h2>
        Ingresa los correos electrónicos de tus aprobadores y autorizadores
      </h2>
      <div className="grid grid-cols-2 gap-2 bg-white p-6 rounded-lg">
        <TextField
          fullWidth
          id="approver_email"
          name="approver_email"
          type="text"
          label="Email de aprobador *"
          value={formik.values.approver_email}
          onChange={formik.handleChange}
          error={errors.approver_email && Boolean(formik.errors.approver_email)}
          helperText={errors.approver_email && formik.errors.approver_email}
        />

        <TextField
          fullWidth
          id="authorizator_email"
          name="authorizator_email"
          type="email"
          label="Email de autorizador *"
          value={formik.values.authorizator_email}
          onChange={formik.handleChange}
          error={
            errors.authorizator_email &&
            Boolean(formik.errors.authorizator_email)
          }
          helperText={
            errors.authorizator_email && formik.errors.authorizator_email
          }
        />
      </div>
      <Buttons
        handleNext={validateForm}
        activeStep={step}
        handleBack={handleBack}
      />
    </div>
  );
};

export default Authorizers;
