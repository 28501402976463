import { SET_ERROR, HIDE_ERROR } from "../constants/actionTypes";

// export function errorReducer(state = initState, action){
//   const { error } = action;

//   if(error){
//   return {
//   error: error,
//   isOpen: true
//   }
//   }else if(action.type === HIDE_ERROR){
//   return {
//   error: null,
//   isOpen: false
//   }
//   }

//   return state;
//  }

const initState = {
  error: null,
  isOpen: false,
};

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function errorReducer(state = initState, action) {
  switch (action.type) {
    case SET_ERROR:
      return {
        error: action.error,
        isOpen: true,
      };
    case HIDE_ERROR:
      return {
        error: null,
        isOpen: false,
      };
    default:
      return {
        ...state,
      };
  }
}
