import { apiBaseUrlAws } from "./consts";
import { instance, config } from "../services/axios";
import axios from "axios";

import { setError } from "./errorAction";
import { setSuccess } from "./successAction";
// import requestAws from "../utils/requestAws";

import {
  USER_LOGIN_BEGIN,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_FAILURE,
  USER_LOGOUT_BEGIN,
  USER_LOGOUT_SUCCESS,
  // USER_LOGOUT_FAILURE,
  CREATE_EMPLOYEE_BEGIN,
  CREATE_EMPLOYEE_SUCCESS,
  CREATE_EMPLOYEE_FAILURE,
  CONTACT_LOADING,
  CONTACT_SUCCESS,
  CONTACT_FAILURE,
  CHECK_FOR_SESSION_BEGIN,
  CHECK_FOR_SESSION_SUCCESS,
  CHECK_FOR_SESSION_FAILURE,
} from "../constants/actionTypes";

export const userLoginBegin = () => ({
  type: USER_LOGIN_BEGIN,
});

export const userLoginSuccess = (user) => ({
  type: USER_LOGIN_SUCCESS,
  user,
});

export const userLoginFailure = (error) => ({
  type: USER_LOGIN_FAILURE,
  error,
});

export const userLogoutBegin = () => ({
  type: USER_LOGOUT_BEGIN,
});

export const userLogoutSuccess = () => ({
  type: USER_LOGOUT_SUCCESS,
});

export const createEmployeeBegin = () => ({
  type: CREATE_EMPLOYEE_BEGIN,
});

export const createEmployeeSuccess = (employee) => ({
  type: CREATE_EMPLOYEE_SUCCESS,
  employee,
});

export const createEmployeeFailure = (error) => ({
  type: CREATE_EMPLOYEE_FAILURE,
  error,
});

export const contactLoading = () => ({
  type: CONTACT_LOADING,
});

export const contactSuccess = (message) => ({
  type: CONTACT_SUCCESS,
  message,
});

export const contactFailure = (error) => ({
  type: CONTACT_FAILURE,
  error,
});

export const checkForSessionBegin = () => ({
  type: CHECK_FOR_SESSION_BEGIN,
});

export const checkForSessionSuccess = (user) => ({
  type: CHECK_FOR_SESSION_SUCCESS,
  user,
});

export const checkForSessionFailure = (error) => ({
  type: CHECK_FOR_SESSION_FAILURE,
  error,
});

///

export function userLogin(email, password) {
  return async (dispatch) => {
    try {
      dispatch(userLoginBegin());
      const response = await axios.post(
        `${apiBaseUrlAws}/Salvum/services/users/login`,
        {
          userMail: email,
          userPassword: password,
        }
      );

      localStorage.setItem("userSession", response.data.jwt);
      dispatch(userLoginSuccess(response.data));
    } catch (error) {
      const errorResp = error.response.data;
      dispatch(setError(errorResp.message || "Error al iniciar sesion"));
      dispatch(userLoginFailure(error.response.data));
    }
  };
}

export const checkCurrentSession = (history) => {
  return async (dispatch) => {
    try {
      dispatch(checkForSessionBegin());
      const jwt = localStorage.getItem("userSession");
      const response = await axios.get(
        `${apiBaseUrlAws}/Salvum/services/users/checkJwt?jwt=${jwt}`
      );
      dispatch(checkForSessionSuccess(response.data));
    } catch (error) {
      let errorResp;
      if (error.response) {
        errorResp = error.response.data;
      } else {
        errorResp = error.message;
      }

      const jwt = localStorage.getItem("userSession");
      if (jwt) {
        localStorage.removeItem("userSession");
      }
      history.push("/");
      dispatch(setError(errorResp.message || "Inicie sesion nuevamente."));
      dispatch(checkForSessionFailure(error));
    }
  };
};

export function cleanAlertMessages() {
  return (dispatch) => {
    dispatch(userLoginFailure({}));
  };
}

export function contact(name, email, message) {
  return async (dispatch) => {
    try {
      const response = await instance.post(
        `Salvum/services/contactSalvum`,
        {
          fromUserName: name,
          fromUserMail: email,
          message: message,
        },
        config
      );

      if (response.error) {
        dispatch(
          setError(
            response.message ||
              "Ha ocurrido un error al enviar el formulario de contacto."
          )
        );
        return;
      }

      dispatch(
        setSuccess(response.message || "Datos de contacto han sido enviados.")
      );
    } catch (error) {
      dispatch(
        setError(
          "Ha ocurrido un error al intentar enviar los datos de contacto."
        )
      );
    }
  };
}

// export function contact(name, email, message) {
//   return (dispatch, getState) => {
//     dispatch(userLoginBegin());
//     return requestAws({
//       partialUrl: "/Salvum/services/contactSalvum",
//       method: "POST",
//       state: getState().user.request,
//       body: {
//         fromUserName: name,
//         fromUserMail: email,
//         message: message,
//       },
//     })
//       .then((request) => request.json())
//       .then((data) => {
//         dispatch(userLoginSuccess(data.data));
//       })
//       .catch((err) => {
//         return dispatch(userLoginFailure(err));
//       });
//   };
// }

export function userLogout() {
  return (dispatch) => {
    dispatch(userLogoutBegin());
    return dispatch(userLogoutSuccess());
  };
}
