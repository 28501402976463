import React, { useState } from "react";

// Components
import { TextField } from "@mui/material";
import { Buttons } from "./Buttons";

// Step 1
export const CompanyDetails = ({ step, formik, handleNext = () => {} }) => {
  const [errors, setErrors] = useState({});

  const validateForm = async () => {
    const errors = await formik.validateForm();
    const errorNames = Object.keys(errors);
    setErrors(errors);
    if (
      !errorNames.includes("name") &&
      !errorNames.includes("businessName") &&
      !errorNames.includes("rfc") &&
      !errorNames.includes("phone") &&
      !errorNames.includes("email")
    ) {
      handleNext();
    }
  };

  return (
    <div className="flex flex-col items-center p-6">
      <h2>Datos de la empresa</h2>
      <div className="grid grid-cols-3 gap-3 bg-white p-6 rounded-lg">
        <TextField
          fullWidth
          id="name"
          name="name"
          type="text"
          label="Nombre de la empresa *"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={errors.name && Boolean(formik.errors.name)}
          helperText={errors.name && formik.errors.name}
        />

        <TextField
          fullWidth
          id="businessName"
          name="businessName"
          type="text"
          label="Razon social *"
          value={formik.values.businessName}
          onChange={formik.handleChange}
          error={errors.businessName && Boolean(formik.errors.businessName)}
          helperText={errors.businessName && formik.errors.businessName}
        />

        <TextField
          fullWidth
          id="rfc"
          name="rfc"
          type="text"
          label="RFC *"
          value={formik.values.rfc}
          onChange={formik.handleChange}
          error={errors.rfc && Boolean(formik.errors.rfc)}
          helperText={errors.rfc && formik.errors.rfc}
        />

        <TextField
          fullWidth
          id="phone"
          name="phone"
          type="text"
          label="Telefono de la empresa *"
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={errors.phone && Boolean(formik.errors.phone)}
          helperText={errors.phone && formik.errors.phone}
        />

        <TextField
          fullWidth
          id="email"
          name="email"
          type="text"
          label="Correo de la empresa *"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={errors.email && Boolean(formik.errors.email)}
          helperText={errors.email && formik.errors.email}
        />

        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>Company ID</span>
          <TextField
            fullWidth
            id="companyId"
            name="companyId"
            type="text"
            value={formik.values.companyId}
            disabled
            variant="standard"
          />
        </div>
      </div>
      <Buttons handleNext={validateForm} activeStep={step} />
    </div>
  );
};

export default CompanyDetails;
