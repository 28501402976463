import React, { useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { Buttons } from "./Buttons";

const weeklyItems = [
  {
    label: "Lunes",
    value: 1,
  },
  {
    label: "Martes",
    value: 2,
  },
  {
    label: "Miercoles",
    value: 3,
  },
  {
    label: "Jueves",
    value: 4,
  },
  {
    label: "Viernes",
    value: 5,
  },
  {
    label: "Sabado",
    value: 6,
  },
  {
    label: "Domingo",
    value: 7,
  },
];

export const PaymentDays = ({
  step,
  formik,
  handleNext = () => {},
  handleBack,
}) => {
  const [weekly, setWeekly] = useState(formik.values.day_of_payment_w);
  const [errors, setErrors] = useState({});

  const validateForm = async () => {
    const errors = await formik.validateForm();
    const errorNames = Object.keys(errors);
    setErrors(errors);
    if (
      !errorNames.includes("day_of_payment_q_1") &&
      !errorNames.includes("day_of_payment_q_2") &&
      !errorNames.includes("day_of_payment_m")
    ) {
      handleNext();
    }
  };

  const handleChange = (event) => {
    setWeekly(event.target.value);
    formik.setFieldValue("day_of_payment_w", event.target.value);
  };

  return (
    <div className="flex flex-col items-center p-6">
      <h2>
        Ahora configura los días de pago por semana, por quincena y por mes.
      </h2>
      <div className="grid grid-cols-2 gap-2 bg-white p-6 rounded-lg">
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id="demo-simple-select-standard-label">
            Pago semanal
          </InputLabel>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={weekly}
            onChange={handleChange}
            defaultChecked={formik.values.day_of_payment_w}
            label="Age"
          >
            {weeklyItems.map((weekly) => (
              <MenuItem key={weekly.label} value={weekly.value}>
                {weekly.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <TextField
          fullWidth
          id="day_of_payment_q_1"
          name="day_of_payment_q_1"
          type="text"
          label="Pago quincena 1 *"
          value={formik.values.day_of_payment_q_1}
          onChange={formik.handleChange}
          error={
            errors.day_of_payment_q_1 &&
            Boolean(formik.errors.day_of_payment_q_1)
          }
          helperText={
            errors.day_of_payment_q_1 && formik.errors.day_of_payment_q_1
          }
        />
        <TextField
          fullWidth
          id="day_of_payment_q_2"
          name="day_of_payment_q_2"
          type="text"
          label="Pago quincena 2 *"
          value={formik.values.day_of_payment_q_2}
          onChange={formik.handleChange}
          error={
            errors.day_of_payment_q_2 &&
            Boolean(formik.errors.day_of_payment_q_2)
          }
          helperText={
            errors.day_of_payment_q_2 && formik.errors.day_of_payment_q_2
          }
        />
        <TextField
          fullWidth
          id="day_of_payment_m"
          name="day_of_payment_m"
          type="text"
          label="Pago mensual *"
          value={formik.values.day_of_payment_m}
          onChange={formik.handleChange}
          error={
            errors.day_of_payment_m && Boolean(formik.errors.day_of_payment_m)
          }
          helperText={errors.day_of_payment_m && formik.errors.day_of_payment_m}
        />
      </div>
      <Buttons
        handleNext={validateForm}
        activeStep={step}
        handleBack={handleBack}
      />
    </div>
  );
};

export default PaymentDays;
