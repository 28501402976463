import { SET_SUCCESS, HIDE_SUCCESS } from "../constants/actionTypes";

// successActions.js
export const setSuccess = (success) => {
  return {
    type: SET_SUCCESS,
    success: success,
  };
};

export const hideSuccess = () => {
  return {
    type: HIDE_SUCCESS,
  };
};
