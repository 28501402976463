import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Chart from "react-google-charts";
import { savingTypes } from "./SavingsUtils";
import { getEmployeeSavingDetailResponse } from "../../../actions/employeeAction";

// Icons
import { ArrowBack } from "@mui/icons-material";

export const SavingsDetails = ({ companyId, employeeId, setEmployeeId }) => {
  const dispatch = useDispatch();

  const savingDetailResponse = useSelector(
    (state) => state.employee.savingDetailResponse
  );

  useEffect(() => {
    dispatch(getEmployeeSavingDetailResponse({ companyId, employeeId }));
  }, []);

  const chartData = savingDetailResponse
    ? [
        ["Tiempo de nómina", "Porcentaje"],
        ...savingDetailResponse.typesOfSaving,
        savingDetailResponse.usedPercentageOfPayroll,
      ]
    : [];

  const chartColors = savingDetailResponse
    ? [
        ...savingDetailResponse.typesOfSaving.map(
          (saving) => savingTypes[saving] && savingTypes[saving].color
        ),
        "#fafafa",
      ]
    : [];

  const isChartValid = savingDetailResponse
    ? savingDetailResponse.typesOfSaving.filter((saving) => saving.amount)
    : [];

  const handleBack = () => {
    setEmployeeId(null, null);
  };

  return (
    <div className="w-full flex flex-col text-black mb-6">
      <button
        onClick={handleBack}
        className="flex transition ease-in-out my-6 border rounded-full border-gray-600 px-4 w-32 button-back"
      >
        <ArrowBack className="" /> Regresar
      </button>
      <h2 className="text-2xl mb-2">Detalles de empleado</h2>

      <div className="grayLine" />

      {savingDetailResponse && (
        <>
          <div className="grid grid-cols-3 gap-3 text-black border border-gray-700 p-4 rounded-2xl w-full">
            {isChartValid.length > 0 && (
              <div className="mb-3  flex flex-col">
                <h3 className="title text-sm text-black-50 mb-2">
                  Total usado de tu nómina
                </h3>
                <div className="chartDiv">
                  <Chart
                    width="200px"
                    height="200px"
                    chartType="PieChart"
                    loader={<div>Cargando..</div>}
                    data={chartData}
                    options={{
                      pieHole: 0.7,
                      legend: "none",
                      pieSliceText: "none",
                      colors: chartColors,
                    }}
                    rootProps={{ "data-testid": "3" }}
                  />
                </div>
              </div>
            )}

            <div className="mb-3  flex flex-col">
              <h3 className="text-sm mb-2">Tipos de ahorros</h3>
              {savingDetailResponse?.typesOfSaving?.map((saving) => (
                <div
                  key={saving.savingTypeIdFormatted}
                  className="flex items-center mb-2"
                >
                  <div
                    style={{ backgroundColor: saving.colorCode }}
                    className="h-3 w-3 rounded-full"
                  />
                  <p className="ml-2">{saving.savingTypeIdFormatted}</p>
                </div>
              ))}

              {/* {savingDetailResponse.typesOfSaving.map((saving) => (
            <div className="element flex items-center" key={saving}>
              <span className={`dot ${savingTypes[saving] && savingTypes[saving].dotClass} mr-2`} />

              <p>{saving}</p>
            </div>
          ))} */}
            </div>

            <div className="mb-3  flex flex-col ">
              <h3 className="text-sm mb-2">Saldo total acumulado</h3>
              <p className="text-xl">
                {savingDetailResponse.totalAcumFormatted}
              </p>
            </div>

            <div className="mb-3  flex flex-col ">
              <h3 className="text-sm mb-2">Última aportación</h3>
              <p className="text-xl">
                {savingDetailResponse.lastPaymentFormatted}
              </p>
            </div>

            <div className="mb-3  flex flex-col ">
              <h3 className="text-sm mb-2">Nómina</h3>
              <p className="text-xl">{savingDetailResponse.payrollFormatted}</p>
            </div>

            <div className="mb-3  flex flex-col ">
              <h3 className="text-sm mb-2">Aportación por periodo</h3>
              <p className="text-xl">
                {savingDetailResponse.principalGivenFormatted}
              </p>
            </div>

            <div className="mb-3  flex flex-col ">
              <h3 className="text-sm mb-2">Ganancias por interés</h3>
              <p className="text-xl">
                {savingDetailResponse.earningsPerInterestFormatted}
              </p>
            </div>
          </div>
        </>
      )}

      {!savingDetailResponse && (
        <h2 className="my-8 text-black text-2xl">
          No se han encontrado detalles para este empleado.
        </h2>
      )}
    </div>
  );
};

export default SavingsDetails;
