import React, { useState } from "react";
import { Button } from "@mui/material";
import { Modal } from "../../../../../../../components/Modal";
import "./Modals.scss";

export const ArrangeSavings = ({
  setOpenModalWithdrawQuantity,
  setCurrentValue,
  onClickAction,
  isOpen,
  currentValue,
  saving = {},
}) => {
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  if (!isOpen) {
    return null;
  }

  const closeConfirmModal = () => {
    setConfirmModalIsOpen(false);
  };

  const closeModal = () => {
    setOpenModalWithdrawQuantity(false);
    setCurrentValue("");
  };

  const handleSubmit = async () => {
    await onClickAction({ actionId: currentValue });
    closeModal();
    closeConfirmModal();
  };

  const handleContinue = () => {
    setConfirmModalIsOpen(true);
  };

  return (
    <>
      <Modal isOpen={isOpen} handleClose={closeModal} title="Disponer ahorros.">
        <h3 style={{ textAlign: "center" }}>Cantidad total disponible: </h3>

        <h2 style={{ textAlign: "center" }}>{saving.valueFormatted}</h2>

        <h4 style={{ textAlign: "center" }}>¿Desea disponer sus ahorros?</h4>
        <div className="buttons-container">
          <Button
            onClick={closeModal}
            variant="outlined"
            type="button"
            style={{ marginRight: "12px" }}
          >
            Cancelar
          </Button>
          <Button
            className="custom-button-success"
            color="primary"
            variant="contained"
            type="submit"
            onClick={handleSubmit}
          >
            Disponer
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={confirmModalIsOpen}
        handleClose={closeConfirmModal}
        title="Aviso"
      >
        <h4>
          El monto total de tu ahorro será depositado en tu cuenta CLABE el
          próximo día hábil después de la fecha de vencimiento del mismo.
        </h4>

        <h4>¿Desea continuar?</h4>

        <div className="buttons-container">
          <Button
            onClick={closeConfirmModal}
            variant="outlined"
            type="button"
            style={{ marginRight: "8px" }}
          >
            En otro momento
          </Button>
          <Button
            className="custom-button-success"
            color="success"
            variant="contained"
            type="submit"
            onClick={handleSubmit}
          >
            Disponer ahora.
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ArrangeSavings;
