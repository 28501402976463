import React from "react";

const steps = 7;

export const Buttons = ({
  activeStep,
  handleBack,
  handleSkip,
  handleNext,
  buttonType = "button",
}) => {
  const isStepOptional = (step) => {
    // Tipos de ahorros, Adelantos y Préstamos son opcionales.
    return step === 1 || step === 2;
  };

  return (
    <div className="flex mt-10 w-full justify-center">
      <button
        type="button"
        color="inherit"
        disabled={activeStep === 0}
        onClick={handleBack}
        className={`transition ease-in-out mr-10 px-4 border border-gray-500 rounded-full ${
          activeStep !== 0 ? "hover:bg-gray-50" : "bg-gray-200 text-gray-400"
        }`}
      >
        Regresar
      </button>

      <div>
        {isStepOptional(activeStep) && (
          <button
            type="button"
            className="transition py-2 ease-in-out mr-2 px-4 border border-gray-500 rounded-full text-black"
            onClick={handleSkip}
            sx={{ mr: 1 }}
          >
            Saltar paso
          </button>
        )}

        {activeStep === steps - 1 && (
          <button
            type="submit"
            className="transition ease-in-out mr-10 px-5 py-2 border bg-blue-700 text-white border-gray-500 rounded-full hover:cursor-pointer hover:bg-sky-900"
          >
            Actualizar datos.
          </button>
        )}
        {activeStep !== steps - 1 && (
          <button
            onClick={handleNext}
            type={buttonType}
            className="transition ease-in-out mr-10 px-5 py-2 border bg-sky-700 text-white border-gray-500 rounded-full hover:cursor-pointer hover:bg-sky-900"
          >
            Siguiente
          </button>
        )}
      </div>
    </div>
  );
};

export default Buttons;
