import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import "./Modals.scss";

class CustomWhiteModal extends React.Component {
  constructor(props) {
    super(props);
    this.handleClose = this.handleClose.bind(this);
    this.state = {};
  }

  handleClose = () => {
    if (this.props.toggle) {
      this.props.toggle();
    }
  };

  render() {
    const BuildModal = () => {
      const useStyles = makeStyles(() => ({
        modal: {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        },
        paper: {
          position: "absolute",
          minWidth: 550,
          borderRadius: 7,
          background: "#ffffff",
          fontFamily: "Poppins",
          fontFize: "32px",
          fontWeight: "bold",
          fontStretch: "normal",
          fontStyle: "italic",
          lineHeight: "normal",
          letterSpacing: "normal",
          textAlign: "center",
          color: "var(--b-w-5)",
        },
        titleSection: {
          width: "100%",
        },
        bigModalW: {
          width: "700px",
        },
      }));

      const classes = useStyles();

      return (
        <div>
          <Modal
            disableEnforceFocus
            open={this.props.isOpen}
            onClose={this.handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={this.modalStyle}
              className={`${[
                classes.paper,
                this.props.bigModal ? classes.bigModalW : "",
              ].join(" ")} form-left-bord-green`}
            >
              <div className={classes.titleSection}>
                {/*<div style={{width:'100%', textAlign:'right', color:"black"}}><CloseIcon onClick={this.handleClose} /></div>*/}
                <h2
                  id="simple-modal-title"
                  style={{
                    marginTop: "0",
                    color: "black",
                    textAlign: "center",
                  }}
                >
                  {this.props.title}
                </h2>
                {/*<p id="simple-modal-description">{this.props.subTitle}</p>*/}
              </div>
              {this.props.body}
            </div>
          </Modal>
        </div>
      );
    };

    return <BuildModal />;
  }
}

CustomWhiteModal.propTypes = {
  toggle: PropTypes.any,
  isOpen: PropTypes.any,
  bigModal: PropTypes.any,
  title: PropTypes.any,
  subTitle: PropTypes.any,
  body: PropTypes.any,
};

export default CustomWhiteModal;
