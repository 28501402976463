import React from "react";
import { HorizontalLinearStepper } from "./components/Stepper";

// Components
import { Header } from "./components/Header";

// Styles
import "./AdminAddCompany.scss";

export const AdminAddCompany = () => {
  return (
    <div className="companiesAdd">
      <Header />
      <div className="my-10">
        <HorizontalLinearStepper />
      </div>
    </div>
  );
};

export default AdminAddCompany;
