import React, { useState } from "react";

// Icons
import { Badge as BadgeIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { DeleteEmployeeModal } from "./Modals/DeleteModal";

import { useDispatch } from "react-redux";

import { parse } from "papaparse";

import { setError } from "../../../../../actions/errorAction";

import { instance, config } from "../../../../../services/axios";
import { setSuccess } from "../../../../../actions/successAction";
import { getEmployees } from "../../../../../actions/adminAction";

import { CustomizedTable } from "./CreatedEmployeesDisplay";

export const EmployeesDetails = ({ employees = [], companyId }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);
  const [employeeName, setEmployeeName] = useState("");
  const [rows, setRows] = useState([]);
  const dispatch = useDispatch();

  const csvRegister = async () => {
    try {
      const csvFile = document.getElementById("csvFile").files[0];

      parse(csvFile, {
        header: true,
        transformHeader: function (h) {
          return h
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .replace("N°", "Num")
            .replace(/\s/g, "");
        },
        skipEmptyLines: true,
      });

      const formData = new FormData();

      formData.append("file", csvFile);

      // Update the formData object

      const response = await instance.post(
        "Salvum/services/users/uploadUsers",
        formData,
        config
      );

      if (response.code >= 400) {
        dispatch(setError(response.message));
        return;
      }

      dispatch(
        setSuccess(
          response.message || "Archivo de empleados cargado con exito."
        )
      );

      setRows(response.data.data || []);
      dispatch(getEmployees({ companyId }));
    } catch (error) {
      const response = error.response.data;

      dispatch(setError(response.message));
    }
  };

  const handleDeleteEmployee = (id, name) => {
    setEmployeeId(id);
    setEmployeeName(name);
    setShowDeleteModal(true);
  };

  const closeModal = () => {
    setShowDeleteModal(false);
  };

  const handleBack = () => {
    setRows([]);
  };
  return (
    <>
      {rows.length === 0 && (
        <section className="flex justify-between w-full">
          <div className="flex flex-col justify-center w-full text-black h-full ">
            <DeleteEmployeeModal
              companyId={companyId}
              isOpen={showDeleteModal}
              closeModal={closeModal}
              employeeId={employeeId}
              employeeName={employeeName}
            />

            <div className="ml-10">
              <h2 className="text-3xl">Lista de empleados registrados</h2>

              {employees.length === 0 && (
                <h2 className="text-2xl mt-10">
                  No hay empleados registrados.
                </h2>
              )}

              {employees.length > 0 && (
                <div className="mt-10 flex flex-col h-full justify-around">
                  <div className="grid grid-cols-2 grid-gap-2">
                    <div className="flex">
                      <BadgeIcon className="text-blue-900 mr-1" />
                      <p>Nombre</p>
                    </div>
                    <div>Eliminar</div>
                    {employees.map((employee) => (
                      <React.Fragment key={employee.employeeId}>
                        <p className="m-2">{employee.employeeFullName}</p>
                        <DeleteIcon
                          className="cursor-pointer text-red-600 m-2"
                          onClick={() =>
                            handleDeleteEmployee(
                              employee.employeeId,
                              employee.employeeFullName
                            )
                          }
                        />
                      </React.Fragment>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-col text-black">
            <h1 className="text-2xl">Añadir nuevos empleados</h1>
            <div className="twoElements mt-6">
              <form>
                <input
                  className="inputfile"
                  type="file"
                  id="csvFile"
                  accept=".csv"
                  name="csvFile"
                  onChange={csvRegister}
                />
                <label className="blueButton" htmlFor="csvFile">
                  Registro CSV
                </label>
              </form>
            </div>
          </div>
        </section>
      )}

      {rows.length > 0 && (
        <div className="mx-2 text-black">
          <button
            className="border border-gray-500 px-4 rounded-full my-6 text-lg"
            onClick={handleBack}
          >
            Regresar
          </button>
          <h2 className=" text-2xl mb-10">
            Los siguientes usuarios fueron agregados exitosamente.
          </h2>
          <CustomizedTable rows={rows} />
        </div>
      )}
    </>
  );
};

export default EmployeesDetails;
