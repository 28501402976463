import React from "react";
import "./RowPeriod.scss";

export const RowPeriod = ({ item }) => {
  const {
    period,
    initialBalanceFormatted,
    interestFormatted,
    principalFormatted,
    totalPaymentFormatted,
    remainingBalanceFormatted,
  } = item;
  return (
    <div className="period-row">
      <div className="period-item">
        <div>{period}</div>
        <div>{initialBalanceFormatted}</div>
        <div>{interestFormatted}</div>
        <div>{principalFormatted}</div>
        <div>{totalPaymentFormatted}</div>
        <div>{remainingBalanceFormatted}</div>
      </div>
    </div>
  );
};
