import React, { Component } from "react";
import { connect } from "react-redux";
// import {
//   getLoansByBossAndStatusId,
//   getLoansByBossId,
//   getLoansResumeByBossId,
//   getLoanTransactionsByCompanyId,
// } from "../../../actions/loansAction";
// import { getCompanyById } from "../../../actions/companyAction";
// import { getEmployeesByCompanyId } from "../../../actions/employeeAction";
import { getPendingLoansForBoss } from "../../../actions/bossAction";
import { setError } from "../../../actions/errorAction";
import { setSuccess } from "../../../actions/successAction";
import { LoadingCircularSpinner } from "../../../components/LoadingCircularSpinner";

import { api } from "../../../services/api";

// MODAL
import CustomWhiteModal from "../../../shared/WhiteModal";

// STYLES
import "./BossLoan.scss";

// ASSETS
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export class BossLoan extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      general: true,
      loansDetailsVisibility: {},
      savingToggle: [],
      // MODAL
      openModalAskForReject: false,
      openModalAskForApproved: false,
      currentItemToApprove: {},
      loanId: 0,
      employeeId: 0,
    };
    //this.selectBar = this.selectBar.bind(this);

    // Modal data
    this.setEmployeeData = this.setEmployeeData.bind(this);

    // Modal reject
    this.openModalAskForReject = this.openModalAskForReject.bind(this);
    this.toggleModalAskForReject = this.toggleModalAskForReject.bind(this);

    // Modal approved
    this.openModalAskForApproved = this.openModalAskForApproved.bind(this);
    this.toggleModalAskForApproved = this.toggleModalAskForApproved.bind(this);
  }

  componentDidMount = () => {
    const { dispatch } = this.props;
    //this.props.dispatch(getEmployeeByUserId(this.props.user.id));
    // this.props.dispatch(getCompanyById(this.props.user.companyId));
    // this.props.dispatch(getEmployeesByCompanyId(this.props.user.companyId));
    // // LOANS
    // this.props.dispatch(getLoansByBossId(this.props.user.companyId));
    // this.props.dispatch(getLoansResumeByBossId(this.props.user.companyId));
    // this.props.dispatch(
    //   getLoansByBossAndStatusId(this.props.user.companyId, 1)
    // );
    // this.props.dispatch(
    //   getLoanTransactionsByCompanyId(this.props.user.companyId)
    // );

    dispatch(
      getPendingLoansForBoss({
        companyId: this.props.user.companyId,
        bossUserId: this.props.user.id,
      })
    );
  };

  // MODAL DATA
  setEmployeeData = (item, modal) => {
    // this.setState({
    //     employeeId: employee,
    //     loanId: loan
    // })
    this.setState({ currentItemToApprove: item });
    if (modal === "rejected") {
      return this.toggleModalAskForReject();
    } else if (modal === "approved") {
      return this.toggleModalAskForApproved();
    }
  };

  // MODAL APPROVED
  toggleModalAskForApproved() {
    this.setState({
      openModalAskForApproved: !this.state.openModalAskForApproved,
    });
  }

  openModalAskForApproved() {
    this.setState({
      openModalAskForApproved: true,
    });
  }

  modalBodyAskForApproved = () => {
    // Cuerpo del formulario
    const { currentItemToApprove: item } = this.state;
    if (!this.state.openModalAskForApproved) return null;
    return (
      <div className="modal-box">
        <div className="modalSubtitle">
          <b>¿Aprobar préstamo?</b>
        </div>
        <div className="grayLine"></div>
        <div className="inlineBoxElements">
          <div className="boxElement">
            <p className="grayTextModal">Nombre completo</p>
            <p className="blackText">{item.employeeName}</p>
          </div>
          <div className="boxElement">
            <p className="grayTextModal">Monto del préstamo</p>
            <p className="blackText">{item.loanQuanityFormatted}</p>
          </div>
          <button
            className="normal_button"
            onClick={this.toggleModalAskForApproved}
          >
            Regresar
          </button>
          <button
            className="approved_button"
            style={{ backgroundColor: "green", color: "white" }}
            onClick={() => this.loanStatusApproved(item.loanId)}
          >
            Aprobar
          </button>
        </div>
      </div>
    );
  };

  // MODAL REJECT
  toggleModalAskForReject() {
    this.setState({
      openModalAskForReject: !this.state.openModalAskForReject,
    });
  }

  openModalAskForReject() {
    this.setState({
      openModalAskForReject: true,
    });
  }

  modalBodyAskForReject = () => {
    // Cuerpo del formulario
    const { currentItemToApprove: item } = this.state;
    if (!this.state.openModalAskForReject) return null;
    return (
      <div className="modal-box">
        <div className="modalSubtitle">
          <b>¿Rechazar préstamo?</b>
        </div>
        <div className="grayLine"></div>
        <div className="inlineBoxElements">
          <div className="boxElement">
            <p className="grayTextModal">Nombre completo</p>
            <p className="blackText">{item.employeeName}</p>
          </div>
          <div className="boxElement">
            <p className="grayTextModal">Monto del préstamo</p>
            <p className="blackText">{item.loanQuanityFormatted}</p>
          </div>
          <button
            className="normal_button"
            onClick={this.toggleModalAskForReject}
          >
            Regresar
          </button>
          <button
            className="rejected_button"
            style={{ backgroundColor: "red", color: "white" }}
            onClick={() => this.loanStatusRejected(item.loanId)}
          >
            Rechazar
          </button>
        </div>
      </div>
    );
  };

  toggleLoanDetailVisibility = (loanId) => {
    const currentVisibility = !!this.state.loansDetailsVisibility[loanId];
    this.setState({
      loansDetailsVisibility: {
        ...this.state.loansDetailsVisibility,
        [loanId]: !currentVisibility,
      },
    });
  };

  getLoanDetailVisibility = (loanId) => {
    return !!this.state.loansDetailsVisibility[loanId];
  };

  totalPayments = (loanId) => {
    const total = this.props.loanHasTransactions.transactions.filter(
      (p) => p.loanId === loanId && p.paid === true
    ).length;
    return total;
  };

  loanStatusChange = (loanStatus) => {
    switch (loanStatus) {
      case "Pendiente":
        return `toBeApproved`;

      case "Aprobado":
        return `approved`;

      case "Rechazado":
        return `rejected`;

      case "Liquidado":
        return `paid`;

      case "Autorizado":
        return "autorizado";

      case "Dispersado":
        return "dispersado";
      default:
        return "";
    }
  };

  loanStatusLabel = (loanStatus) => {
    switch (loanStatus) {
      case 1:
        return `Pendiente`;

      case 2:
        return `Aprobado`;

      case 3:
        return `Rechazado`;

      case 4:
        return `Pagado`;
      default:
        return "";
    }
  };

  loanStatusRejected = async (id) => {
    try {
      this.setState({
        isLoading: true,
      });
      const { id: bossUserId, companyId } = this.props.user;
      const { dispatch } = this.props;
      const response = await api.get(
        "Salvum/services/loans/updateLoanStatusForBoss?loanId=" +
          id +
          "&bossUserId=" +
          bossUserId +
          "&nextStep=false"
      );

      if (response.error) {
        this.props.dispatch(
          setError(response.message || "Ha ocurrido un error.")
        );
      } else {
        this.props.dispatch(
          setSuccess(response.message || "Préstamo rechazado exitosamente.")
        );
        await dispatch(getPendingLoansForBoss({ companyId, bossUserId }));
      }
      this.toggleModalAskForReject();
      this.setState({
        isLoading: false,
      });
    } catch (error) {
      this.props.dispatch(
        setError(
          "Ha ocurrido un error inesperado en nuestros servidores. Intentalo de nuevo mas tarde."
        )
      );
      this.setState({
        isLoading: false,
      });
      this.toggleModalAskForReject();
    }
  };

  loanStatusApproved = async (id) => {
    try {
      this.setState({
        isLoading: true,
      });
      const { id: bossUserId, companyId } = this.props.user;
      const { dispatch } = this.props;
      const response = await api.get(
        "Salvum/services/loans/updateLoanStatusForBoss?loanId=" +
          id +
          "&bossUserId=" +
          bossUserId +
          "&nextStep=true"
      );
      if (response.error) {
        this.props.dispatch(
          setError(response.message || "Ha ocurrido un error.")
        );
      } else {
        this.props.dispatch(
          setSuccess(response.message || "Préstamo rechazado exitosamente.")
        );
        await dispatch(getPendingLoansForBoss({ companyId, bossUserId }));
      }
      this.toggleModalAskForApproved();
      this.setState({
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
        isAlertOpen: true,
        alertType: "error",
        alertMessage:
          "Ha ocurrido un error inesperado en nuestros servidores. Intentalo de nuevo mas tarde.",
      });
      this.toggleModalAskForApproved();
    }
  };

  renderLoansByStatus(item) {
    return (
      <div className="principalContentCompany">
        <div className="savingsContent">
          <div className="tabHeader">
            <div className="tabItemB">
              <p className="grayText">Monto de préstamo</p>
              <p className="blackText">{item.loanQuanityFormatted}</p>
            </div>
            <div className="tabItemB">
              <p className="grayText">Plazo</p>
              <p className="blackText">{item.periods}</p>
            </div>
            <div className="tabItemB">
              <p className="grayText">Nombre de empleado</p>
              <p className="blackText">{item.employeeName}</p>
            </div>
            <div className="tabItemB half">
              <p className="grayText">Estatus</p>
              <span className="purple-content">{item.loanStatusFormatted}</span>
            </div>
            <div className="tabItemB">
              <div className="container-symbols">
                {
                  <button
                    onClick={() => this.setEmployeeData(item, "approved")}
                    className="blackTextDate green-float"
                  >
                    ✔
                  </button>
                }
                {
                  <button
                    onClick={() => this.setEmployeeData(item, "rejected")}
                    className="blackTextDate red-float"
                  >
                    ✘
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderLoan(item) {
    return (
      <div className="principalContentCompany">
        <div className="savingsContent">
          <div className="tabHeader2">
            {this.getLoanDetailVisibility(item.loanId) && (
              <ArrowDropUpIcon
                className="arrow"
                onClick={() => this.toggleLoanDetailVisibility(item.loanId)}
              />
            )}
            {!this.getLoanDetailVisibility(item.loanId) && (
              <ArrowDropDownIcon
                className="arrow"
                onClick={() => this.toggleLoanDetailVisibility(item.loanId)}
              />
            )}
            <div className="tabItemC first">
              <p className="grayText">Monto de préstamo</p>
              <p className="blackText">{item.loanQuanityFormatted}</p>
            </div>
            <div className="tabItemC">
              <p className="grayText">Plazo</p>
              <p className="blackText"> {item.periods}</p>
            </div>
            <div className="tabItemC">
              <p className="grayText">Saldo por liquidar</p>
              <p className="blackText"> {item.amountToClose}</p>
            </div>
            <div className="tabItemC name">
              <p className="grayText">Nombre</p>
              <p className="name">{item.employeeName}</p>
            </div>
            <div className="tabItemC">
              <div className="container-box">
                <p className="grayText">Estatus</p>
                <span
                  className={`${this.loanStatusChange(
                    item.loanStatusFormatted
                  )}`}
                >
                  {item.loanStatusFormatted}
                </span>
              </div>
            </div>
          </div>
          {this.getLoanDetailVisibility(item.loanId) && (
            <>
              <div className="grayLine"></div>
              <div className="tabHeader">
                <div className="tabItemB"></div>
                <div className="tabItemB">
                  <p className="grayText">Fecha de expedición</p>
                  <p className="blackTextDate">{item.creationDateFormatted}</p>
                </div>
                <div className="tabItemB">
                  <p className="grayText">Última aportación</p>
                  <p className="blackTextDate">{item.lastPaymentFormatted}</p>
                </div>
                <div className="tabItemB">
                  <p className="grayText">Pagos realizados</p>
                  <p className="blackTextDate">{this.totalPayments(item.id)}</p>
                </div>
                <div className="tabItemB"></div>
              </div>
              <div className="loadHistoryPayments">
                <div className="loadHistoryPaymentsHeader">
                  Desgloce de aportaciones
                </div>
                <div className="loadHistoryPaymentsTitles">
                  <div className="loadHistoryPaymentsTitle">Periodo</div>
                  <div className="loadHistoryPaymentsTitle">Fecha</div>
                  <div className="loadHistoryPaymentsTitle">Pago</div>
                  <div className="loadHistoryPaymentsTitle">A capital</div>
                  <div className="loadHistoryPaymentsTitle">Interés</div>
                  <div className="loadHistoryPaymentsTitle">
                    Saldo por liquidar
                  </div>
                </div>
                {item.loanStatusFormatted === "Pendiente" && (
                  <div className="PaymentNoRegister">
                    <p>Préstamo por aprobar</p>
                  </div>
                )}
                {item.loanStatusFormatted === "Aprobado" &&
                  item.paymentsTable &&
                  item.paymentsTable.map((loan) => (
                    <div key={loan.period} className="loadHistoryPaymentsBody">
                      <div className="loadHistoryPaymentsItem">
                        {loan.period}
                      </div>
                      <div className="loadHistoryPaymentsItem">
                        {loan.paymentDateFormatted}
                      </div>
                      <div className="loadHistoryPaymentsItem">
                        {loan.paymentFormatted}
                      </div>
                      <div className="loadHistoryPaymentsItem">
                        {loan.capitalFormatted}
                      </div>
                      <div className="loadHistoryPaymentsItem">
                        {loan.interestFormatted}
                      </div>
                      <div className="loadHistoryPaymentsItem">
                        {loan.remainingBalanceFormatted}
                      </div>
                    </div>
                  ))}
                {item.loanStatusFormatted === "Rechazado" &&
                  item.paymentsTable &&
                  item.paymentsTable.map((loan) => (
                    <>
                      <div className="loadHistoryPaymentsBody">
                        <div className="loadHistoryPaymentsItem">
                          {loan.period}
                        </div>
                        <div className="loadHistoryPaymentsItem">
                          {loan.paymentDateFormatted}
                        </div>
                        <div className="loadHistoryPaymentsItem">
                          {loan.paymentFormatted}
                        </div>
                        <div className="loadHistoryPaymentsItem">
                          {loan.capitalFormatted}
                        </div>
                        <div className="loadHistoryPaymentsItem">
                          {loan.interestFormatted}
                        </div>
                        <div className="loadHistoryPaymentsItem">
                          {loan.remainingBalanceFormatted}
                        </div>
                      </div>
                      <div className="PaymentNoRegister">
                        <p>Préstamo Rechazado</p>
                      </div>
                    </>
                  ))}
                {item.loanStatusFormatted === "Autorizado" &&
                  item.paymentsTable &&
                  item.paymentsTable.map((loan) => (
                    <>
                      <div className="loadHistoryPaymentsBody">
                        <div className="loadHistoryPaymentsItem">
                          {loan.period}
                        </div>
                        <div className="loadHistoryPaymentsItem">
                          {loan.paymentDateFormatted}
                        </div>
                        <div className="loadHistoryPaymentsItem">
                          {loan.paymentFormatted}
                        </div>
                        <div className="loadHistoryPaymentsItem">
                          {loan.capitalFormatted}
                        </div>
                        <div className="loadHistoryPaymentsItem">
                          {loan.interestFormatted}
                        </div>
                        <div className="loadHistoryPaymentsItem">
                          {loan.remainingBalanceFormatted}
                        </div>
                      </div>
                    </>
                  ))}
                {item.loanStatusFormatted === "Liquidado" &&
                  item.paymentsTable &&
                  item.paymentsTable.map((loan) => (
                    <>
                      <div className="loadHistoryPaymentsBody">
                        <div className="loadHistoryPaymentsItem">
                          {loan.period}
                        </div>
                        <div className="loadHistoryPaymentsItem">
                          {loan.paymentDateFormatted}
                        </div>
                        <div className="loadHistoryPaymentsItem">
                          {loan.paymentFormatted}
                        </div>
                        <div className="loadHistoryPaymentsItem">
                          {loan.capitalFormatted}
                        </div>
                        <div className="loadHistoryPaymentsItem">
                          {loan.interestFormatted}
                        </div>
                        <div className="loadHistoryPaymentsItem">
                          {loan.remainingBalanceFormatted}
                        </div>
                      </div>
                    </>
                  ))}
              </div>
            </>
          )}
        </div>
      </div>
    );
  }

  closeAlert = () => {
    this.setState({
      isAlertOpen: false,
    });
  };

  render() {
    const { pendingLoans } = this.props;
    const { isLoading } = this.state;

    if (!pendingLoans) {
      return null;
    }

    return (
      <>
        <LoadingCircularSpinner show={isLoading} />
        <CustomWhiteModal
          isOpen={this.state.openModalAskForApproved}
          toggle={this.toggleModalAskForApproved}
          body={this.modalBodyAskForApproved()}
        />
        <CustomWhiteModal
          isOpen={this.state.openModalAskForReject}
          toggle={this.toggleModalAskForReject}
          body={this.modalBodyAskForReject()}
        />
        <div className="bossLoan">
          <div className="companiesHeroContent">
            <div className="companiesContainer">
              <ul className="breadcrumbs">
                <li>Inicio</li>
                <li>Préstamos</li>
              </ul>
              <div className="companiesHero">
                <div className="content">
                  <span className="contentTitle">Préstamos</span>
                </div>
              </div>
            </div>
          </div>
          <div className="companiesContent">
            <div className="companiesContentData">
              <div className="principalContentCompany">
                <div className="savingsContent">
                  <div className="tabHeader3">
                    <h2 className="loans_title">Resumen de préstamos</h2>
                  </div>
                  <div className="grayLine"></div>
                  <div className="tabHeader2">
                    <div className="tabItem">
                      <p className="grayText">Por aprobar</p>
                      <p className="blackText">
                        <span className="blue-text">
                          {pendingLoans && pendingLoans.loansSummary.pending}
                        </span>
                      </p>
                    </div>
                    <div className="tabItem">
                      <p className="grayText">Pagados</p>
                      <p className="blackText">
                        <span className="green-text">
                          {pendingLoans && pendingLoans.loansSummary.paid}
                        </span>
                      </p>
                    </div>
                    <div className="tabItem">
                      <p className="grayText">En curso</p>
                      <p className="blackText">
                        {pendingLoans && pendingLoans.loansSummary.inCurse}
                      </p>
                    </div>
                    <div className="tabItem">
                      <p className="grayText">Rechazados</p>
                      <p className="blackText">
                        <span className="red-text">
                          {pendingLoans && pendingLoans.loansSummary.rejected}
                        </span>
                      </p>
                    </div>
                    <div className="tabItem">
                      <p className="grayText">Saldo total prestado</p>
                      <p className="blackText">
                        <span className="smallText">
                          {pendingLoans &&
                            pendingLoans.loansSummary.totalAmountLend}
                        </span>
                      </p>
                    </div>
                    <div className="tabItem">
                      <p className="grayText">
                        Saldo pendiente de empleado por liquidar
                      </p>
                      <p className="blackText">
                        <span className="smallText">
                          {pendingLoans &&
                            pendingLoans.loansSummary.pendingAmountToRecover}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className={"searchCompany"}>
                            <div className={"searchIconCompany"}>
                                <SearchIcon/>
                            </div>
                            <InputBase
                                placeholder="Buscar empresa…"
                                classes={{
                                    root: "inputRoot",
                                    input: "inputInput",
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div> */}
              {pendingLoans &&
                pendingLoans.pendingLoans.map((item, index) =>
                  this.renderLoansByStatus(item, index)
                )}

              <div className="grayLine2"></div>
              <div className="history">Historial</div>
              {pendingLoans &&
                pendingLoans.history.map((item, index) =>
                  this.renderLoan(item, index)
                )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
    loansResume: state.loans.loansResume || {},
    loansByStatus: state.loans.loansByStatus,
    loans: state.loans.loans,
    user: state.user.user,
    company: state.company.current,
    employees: state.employee.list,
    loanHasTransactions: state.loans.loanTransactions,
    pendingLoans: state.boss.pendingLoans,
  };
}

export default connect(mapStateToProps)(BossLoan);
