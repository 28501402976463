import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../../../../../../components/Modal";
import { getEmployeeLoanDetail } from "../../../../../../../actions/employeeAction";

import { setError } from "../../../../../../../actions/errorAction";
import { setSuccess } from "../../../../../../../actions/successAction";
import { api } from "../../../../../../../services/api";
import "./Modals.scss";

export const ApproveLoanModal = ({
  isOpen = false,
  setIsOpen,
  loanId,
  companyId,
  employeeId,
}) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);

  const closeConfirmModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = async () => {
    try {
      const { id: bossUserId } = user;
      const response = await api.get(
        "Salvum/services/loans/updateLoanStatusForBoss?loanId=" +
          loanId +
          "&bossUserId=" +
          bossUserId +
          "&nextStep=true"
      );

      if (response.error) {
        await dispatch(setError(response.message));
      } else {
        await dispatch(setSuccess(response.data.message));
        await getEmployeeLoanDetail({
          companyId,
          bossUserId: user.id,
          employeeId,
        });
      }
      closeConfirmModal();
    } catch (error) {
      const message = error.response.data.message || error.message;
      await dispatch(setError(message));
      closeConfirmModal();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={closeConfirmModal}
        title="Aprobación de préstamo"
      >
        <h4>¿Está seguro de aprobar este préstamo?</h4>

        <div className="buttons-container">
          <button
            className="transition ease-in-out border-black border rounded-md p-2 hover:bg-gray-50"
            onClick={closeConfirmModal}
            type="button"
            style={{ marginRight: "8px" }}
          >
            En otro momento
          </button>
          <button
            className="transition bg-green-500 text-white ease-in-out border-black border rounded-md p-2 hover:bg-green-700"
            type="submit"
            onClick={handleSubmit}
          >
            Si, aprobar.
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ApproveLoanModal;
