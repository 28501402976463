import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getSavingsTablePerEmployee } from "../../../../../../actions/savingsAction";
import { SavingSlot } from "./SavingSlot";

export const SavingsDetails = ({ employee, setIsLoading }) => {
  const [formattedSavingSlots, setFormattedSavingSlots] = useState([]);

  const dispatch = useDispatch();
  const savingsTable = useSelector((state) => state.savings.savingsTable);
  const savingsSlots = useSelector((state) => state.savings.savingsSlots);

  const mapSavingSlotsBySavingTable = () => {
    if (savingsTable && savingsSlots) {
      const newSavingSlots = savingsSlots.slots.map((savingSlot) => {
        const table = savingsTable.find(
          (savingTable) =>
            savingSlot.savingBankTypeId === savingTable.savingBankType
        );
        if (table) {
          const { table: savingsTable } = table;
          return {
            ...savingSlot,
            savingsTable,
          };
        } else {
          return savingSlot;
        }
      });
      setFormattedSavingSlots(newSavingSlots);
    }
  };

  useEffect(() => {
    if (employee) {
      dispatch(
        getSavingsTablePerEmployee({
          companyId: employee?.companyid,
          employeeId: employee?.userid,
        })
      );
    }
  }, [employee]);

  useEffect(() => {
    mapSavingSlotsBySavingTable();
  }, [savingsSlots]);

  if (!formattedSavingSlots) {
    return null;
  }

  return (
    <>
      {formattedSavingSlots.length > 0 && (
        <div className="actions-container">
          <h4 className="generalTitle my-10">Detalle de tus ahorros</h4>
        </div>
      )}

      {formattedSavingSlots.length === 0 && (
        <div className="actions-container my-10">
          <h4 className="generalTitle">No tienes ahorros para mostrar</h4>
        </div>
      )}

      {formattedSavingSlots.map((slot) => (
        <SavingSlot
          key={slot.savingTypeId}
          setIsLoading={setIsLoading}
          saving={slot}
          employee={employee}
        />
      ))}
    </>
  );
};

export default SavingsDetails;
