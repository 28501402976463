import React, { Component } from "react";
import { connect } from "react-redux";
import "./EmployeePayRoll.scss";
import CustomModal from "../../../shared/Modal";

import { getCompanyById } from "../../../actions/companyAction";

import { getEmployeeCashAdvanceDetail } from "../../../actions/employeeAction";
import { getCashAdvanceEmployeeHomeInfo } from "../../../actions/payrollAdvanceAction";
import { EmployeePayRollForm } from "./EmployeePayRollForm";

import { AdvancesDetails } from "./components/AdvancesDetails";

export class EmployeePayRoll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggleTerms: false,
      disableTerms: false,
      askForLoad: false,
      openModalAskForLoad: false,
      marks: [
        {
          value: 0,
          label: "0°C",
        },
        {
          value: 2500,
          label: "20°C",
        },
      ],
      sliderValue: 0,
    };

    this.toggleModalAskForLoad = this.toggleModalAskForLoad.bind(this);
    this.isChecked = this.isChecked.bind(this);
  }

  componentDidMount = () => {
    const { dispatch } = this.props;
    if (this.props.user) {
      dispatch(getCompanyById(this.props.user.companyId));
      dispatch(
        getEmployeeCashAdvanceDetail({
          employeeId: this.props.user.id,
          companyId: this.props.user.companyId,
        })
      );
      dispatch(
        getCashAdvanceEmployeeHomeInfo({
          employeeId: this.props.user.id,
          companyId: this.props.user.companyId,
        })
      );
    }
  };

  getPayrollTypeText = () => {
    if (this.props.employee) {
      switch (this.props.employee.payrolltype) {
        case 1:
          return `esta Semana`;

        case 2:
          return `esta Quincena`;

        case 3:
          return `este Mes`;
      }
    }
  };

  modalBodyAskForLoad = () => {
    // Cuerpo del formulario
    if (!this.state.openModalAskForLoad) return null;
    const { cashAdvanceEmployeeHomeInfo } = this.props;
    return (
      <div className={"modal-box"}>
        <div className={"modal-content"}>
          {/* start PayRollForm*/}
          <EmployeePayRollForm
            companyId={this.props.employee.companyid}
            employeeId={this.props.employee.userid}
            toggleModal={this.toggleModalAskForLoad}
            maxAmount={cashAdvanceEmployeeHomeInfo.maxAmountToRequest}
          />
          {/* End PayRollForm */}
        </div>
      </div>
    );
  };

  isChecked() {
    this.setState({
      toggleTerms: !this.state.toggleTerms,
    });
  }

  openModalAskForLoad = () => {
    this.setState({
      openModalAskForLoad: true,
    });
  };

  toggleModalAskForLoad() {
    this.setState({
      openModalAskForLoad: !this.state.openModalAskForLoad,
    });
  }

  getPayrollType = () => {
    switch (this.props.employee.payrolltype) {
      case 1:
        return `Semanal`;

      case 2:
        return `Quincenal`;

      case 3:
        return `Mensual`;
    }
  };

  statusChange = (status) => {
    switch (status) {
      case 1:
        return `toBeApproved`;

      case 2:
        return `approved`;

      case 3:
        return `rejected`;
    }
  };

  statusLabel = (status) => {
    switch (status) {
      case 1:
        return `Pendiente`;

      case 2:
        return `Aprobado`;

      case 3:
        return `Rechazado`;
    }
  };

  render = () => {
    const { cashAdvanceEmployeeHomeInfo } = this.props;

    if (!cashAdvanceEmployeeHomeInfo) {
      return null;
    }

    const hasSavings = this.props.savingSlots
      ? !this.props.savingSlots.isEmployeeBrandNew
      : false;

    return (
      <>
        <CustomModal
          isOpen={this.state.openModalAskForLoad}
          toggle={this.toggleModalAskForLoad}
          title="Pedir adelanto de nómina"
          body={this.modalBodyAskForLoad()}
        />
        <div className={"savings"}>
          <div className={"savingHeroContent"}>
            <div className={"savingsContainer"}>
              <ul className={"breadcrumbs"}>
                <li>Inicio</li>
                <li>Adelantos de nómina</li>
              </ul>

              {!hasSavings && (
                <div className={"LoanHero"}>
                  <div className={"content"}>
                    <span className={"contentTitle mt-3"}>
                      Adelantos de nómina
                    </span>
                    <p className="mt-10">
                      Para solicitar un adelanto en Salvum, primero necesitas
                      comenzar a ahorrar con nosotros.
                    </p>
                  </div>
                </div>
              )}

              {hasSavings && (
                <div className={"LoanHero"}>
                  <div className={"content"}>
                    <span className={"contentTitle"}>Adelantos de nómina</span>
                    <p>
                      Para solicitar un adelanto es necesario que cumplas con
                      los siguientes requisitos:
                    </p>
                    <p>
                      <ul>
                        <li>1.- Estar inscrito a caja de ahorros.</li>
                        <li>2.- Estar inscrito a adelanto de nómina.</li>
                        <li>
                          3.- Tener mínimo un día laborado{" "}
                          <b>
                            {this.props.employee && this.getPayrollTypeText()}.
                          </b>
                        </li>
                      </ul>
                    </p>
                  </div>
                  <div className="bg-white text-black p-4 border border-gray-600 rounded-2xl shadow-xl text-center poppins">
                    <div className={"sideTableContent"}>
                      <div className={"sideItem flex flex-col"}>
                        <span className={"key  poppins"}>Nómina</span>
                        <span className={"value text-xl"}>
                          {cashAdvanceEmployeeHomeInfo.payrollType}
                        </span>
                      </div>
                      <div className={"sideItem flex flex-col"}>
                        <span className={"key "}>Días laborados </span>
                        <p className="text-xl">
                          {" "}
                          {cashAdvanceEmployeeHomeInfo.workDaysDuringMonth}
                        </p>
                      </div>
                      <div className={"sideItem flex flex-col"}>
                        <span className={"key "}>Inscrito a ahorros</span>
                        <span className={"value text-xl"}>
                          {cashAdvanceEmployeeHomeInfo.savingsEnrolled}
                        </span>
                      </div>
                      <div className={"sideItem flex flex-col"}>
                        <span className={"key"}>
                          Inscrito a adelanto de nómina
                        </span>
                        <span className={"value  text-xl"}>
                          {cashAdvanceEmployeeHomeInfo.cashAdvanceEnrolled}
                        </span>
                      </div>
                      <hr />
                      <div className={"sideItem flex flex-col"}>
                        <span className={"key"}>
                          Monto máximo que puedes disponer
                        </span>
                        <span className=" text-xl">
                          {
                            cashAdvanceEmployeeHomeInfo.maxAmountToRequestFormatted
                          }
                        </span>
                      </div>
                      <button
                        className="transition ease-in-out bg-white text-black hover:bg-gray-50 cursor-pointer rounded-full px-4 border mt-4 border-gray-800"
                        onClick={this.openModalAskForLoad}
                      >
                        Pedir adelanto
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {hasSavings && (
            <div style={{ width: "70%" }}>
              <AdvancesDetails
                employeeId={this.props.user.id}
                companyId={this.props.user.companyId}
              />
            </div>
          )}
        </div>
      </>
    );
  };
}

function mapStateToProps(state) {
  return {
    ...state,
    user: state.user.user,
    summary: state.loans.loans,
    employee: state.employee.current,
    savings: state.savings.savings,
    company: state.company.current,
    employees: state.employee.list,
    loanHasTransactions: state.loans.loanTransactions,
    payroll: state.employee.cashAdvanceDetail,
    cashAdvanceEmployeeHomeInfo: state.advances.cashAdvanceEmployeeHomeInfo,
    savingSlots: state.savings.savingsSlots,
  };
}

export default connect(mapStateToProps)(EmployeePayRoll);
