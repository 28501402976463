/* eslint-disable no-undef */
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useFormik } from "formik";

import { useDispatch } from "react-redux";

// Components
import { CompanyDetails } from "./Steps/CompanyDetails";
import { SavingTypes } from "./Steps/SavingTypes";
import { PayrollAdvances } from "./Steps/PayrollAdvances";
import { PaymentDays } from "./Steps/PaymentDays";
import { Authorizers } from "./Steps/Authorizers";
import { Loans } from "./Steps/Loans";
import { CreateEmployees } from "../CreateEmployees";

import { setError } from "../../../../../actions/errorAction";
import { setSuccess } from "../../../../../actions/successAction";
import { getCompanyById } from "../../../../../actions/companyAction";

// Config
import { initialValues, validationSchema } from "./FormikConfig";

import { instance, config } from "../../../../../services/axios";

const steps = [
  "Datos de la empresa",
  "Tipos de ahorros",
  "Adelantos",
  "Días de pago",
  "Aprobadores y autorizadores",
  "Préstamos",
];

export const HorizontalLinearStepper = () => {
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch();
  const [skipped, setSkipped] = useState(new Set());
  const [loansChecked, setLoansChecked] = useState(false);
  const [savingsChecked, setSavingsChecked] = useState(false);
  const [payrollChecked, setPayrollChecked] = useState(false);
  const [companyCreated, setCompanyCreated] = useState(false);
  const [companyId, setCompanyId] = useState(null);
  const [createdCompany, setCreatedCompany] = useState(null);
  const [newValues, setNewValues] = useState(null);

  const isStepOptional = (step) => {
    // Tipos de ahorros, Adelantos y Préstamos son opcionales.
    return step === 1 || step === 2;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSavingsChange = (event) => {
    setSavingsChecked(event.target.checked);
  };

  const handlePayrollChange = (event) => {
    setPayrollChecked(event.target.checked);
  };

  const handleLoansChange = (event) => {
    setLoansChecked(event.target.checked);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const formatSavingAccreditationCatalog = (values) => {
    const savingAccreditationCatalog = [];

    // format the 6 possible loans credit table
    for (let i = 0; i < 6; i++) {
      savingAccreditationCatalog.push({
        seniority: values[`seniority_${i + 1}`],
        payrolls: values[`payrolls_${i + 1}`],
        installments: values[`installments_${i + 1}`],
      });
    }

    return savingAccreditationCatalog;
  };

  const formatSavingBankRates = (values) => {
    return [
      { name: "now", yieldRate: values.now },
      { name: "month_1", yieldRate: values.month_1 },
      { name: "month_3", yieldRate: values.month_3 },
      { name: "month_6", yieldRate: values.month_6 },
      { name: "month_12", yieldRate: values.month_12 },
      { name: "month_24", yieldRate: values.month_24 },
      { name: "unlimit", yieldRate: values.unlimit },
    ];
  };

  const handleSubmit = async (values) => {
    try {
      const savingAccreditationCatalog =
        formatSavingAccreditationCatalog(values);
      const savingBankRates = formatSavingBankRates(values);

      const payload = {
        approver_email: values.approver_email,
        authorizator_email: values.authorizator_email,
        businessName: values.businessName,
        day_of_payment_m: values.day_of_payment_m,
        day_of_payment_q_1: values.day_of_payment_q_1,
        day_of_payment_q_2: values.day_of_payment_q_2,
        day_of_payment_w: values.day_of_payment_w,
        email: values.email,
        hasLoans: loansChecked,
        hasPayrollAdvance: payrollChecked,
        hasSavings: savingsChecked,
        loanCommissionPercentage: loansChecked
          ? values.loanCommissionPercentage
          : undefined,
        loanInterestPercentage: loansChecked
          ? values.loanInterestPercentage
          : undefined,
        loanMinimumLaborSeniority: loansChecked
          ? values.loanMinimumLaborSeniority
          : undefined,
        loanNumberOfEndorsements: loansChecked
          ? values.loanNumberOfEndorsements
          : undefined,
        name: values.name,
        payrollAdvanceCommissionPercentage: payrollChecked
          ? values.payrollAdvanceCommissionPercentage
          : undefined,
        phone: values.phone,
        rfc: values.rfc,
        savingBankRates,
        savingAccreditationCatalog,
      };

      const response = await instance.post(
        `Salvum/services/boss/createCompany`,
        payload,
        config
      );

      setCreatedCompany(payload);
      setCompanyCreated(true);
      setNewValues(values);

      if (response.error) {
        dispatch(setError(response.message) || "Ha ocurrido un error");
        return;
      }

      if (response.data.companyId) {
        await dispatch(getCompanyById(response.data.companyId));
        setCompanyId(response.data.companyId);
        await instance.post(
          "Salvum/services/users/generateBoss",
          {
            emails: [values.approver_email, values.authorizator_email],
            companyId: response.data.companyId,
          },
          config
        );
        await dispatch(
          setSuccess(response.data.message || "Empresa creada exitosamente.")
        );
        // Move this at the block below
      } else {
        dispatch(
          setError("Ha ocurrido un error al intentar crear la empresa.")
        );
        return;
      }
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div className="">
      {companyCreated && (
        <CreateEmployees
          createdCompany={createdCompany}
          companyId={companyId}
          values={newValues}
        />
      )}
      {!companyCreated && (
        <>
          <h1 className="text-3xl text-center my-8">Crear nueva empresa</h1>
          <p className="text-center my-6 text-xl">
            Completa los 6 pasos (puedes saltar los opcionales) para registrar
            una nueva empresa.
          </p>
          <Box sx={{ width: "100%" }}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption">Opcional</Typography>
                  );
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <form onSubmit={formik.handleSubmit}>
              {activeStep === steps.length ? (
                <React.Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <Box sx={{ flex: "1 1 auto" }} />
                    <Button onClick={handleReset}>Reset</Button>
                  </Box>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <CompanyDetails
                    step={activeStep}
                    formik={formik}
                    handleNext={handleNext}
                    skipped={skipped}
                    handleBack={handleBack}
                    handleSkip={handleSkip}
                  />
                  <SavingTypes
                    step={activeStep}
                    formik={formik}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    handleSkip={handleSkip}
                    checked={savingsChecked}
                    handleChange={handleSavingsChange}
                    skipped={skipped}
                  />
                  <PayrollAdvances
                    step={activeStep}
                    formik={formik}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    handleSkip={handleSkip}
                    checked={payrollChecked}
                    handleChange={handlePayrollChange}
                    skipped={skipped}
                  />
                  <PaymentDays
                    step={activeStep}
                    formik={formik}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    handleSkip={handleSkip}
                    skipped={skipped}
                  />
                  <Authorizers
                    step={activeStep}
                    formik={formik}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    handleSkip={handleSkip}
                    skipped={skipped}
                  />
                  <Loans
                    step={activeStep}
                    formik={formik}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    handleSkip={handleSkip}
                    checked={loansChecked}
                    handleChange={handleLoansChange}
                    skipped={skipped}
                  />
                </React.Fragment>
              )}
            </form>
          </Box>
        </>
      )}
    </div>
  );
};

export default HorizontalLinearStepper;
