import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import TransitionModal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import "./Modal.scss";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 6,
};

export const Modal = ({ isOpen, handleClose, children, title }) => {
  return (
    <div className="modal-container ">
      <TransitionModal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>
          <Box sx={style}>
            <h2 id="transition-modal-title" className="text-center text-2xl">
              {title}
            </h2>
            {children}
          </Box>
        </Fade>
      </TransitionModal>
    </div>
  );
};

export default TransitionModal;
