import request from "../utils/request";
import { api } from "../services/api";

import {
  GET_ADMIN_BY_USER_ID_BEGIN,
  GET_ADMIN_BY_USER_ID_SUCCESS,
  GET_ADMIN_BY_USER_ID_FAILURE,
  GET_ADMIN_HOME_INFO_SUCCESS,
  GET_ADMIN_HOME_INFO_FAILURE,
  GET_COMPANY_DETAIL_SUCCESS,
  GET_COMPANY_DETAIL_FAILURE,
  GET_EMPLOYEES_SUCCESS,
  GET_EMPLOYEES_FAILURE,
  GET_SAVING_SUMMARY_SUCCESS,
  GET_SAVING_SUMMARY_FAILURE,
  GET_SAVING_SUMMARY_BY_ID_SUCCESS,
  GET_SAVING_SUMMARY_BY_ID_FAILURE,
} from "../constants/actionTypes";
import { setError } from "./errorAction";

export const getAdminByUserIdBegin = () => ({
  type: GET_ADMIN_BY_USER_ID_BEGIN,
});

export const getAdminByUserIdSuccess = (boss) => ({
  type: GET_ADMIN_BY_USER_ID_SUCCESS,
  boss,
});

export const getAdminByUserIdFailure = (error) => ({
  type: GET_ADMIN_BY_USER_ID_FAILURE,
  error,
});

export const getAdminHomeInfoSuccess = (adminHomeInfo) => ({
  type: GET_ADMIN_HOME_INFO_SUCCESS,
  adminHomeInfo,
});

export const getAdminHomeInfoFailure = (error) => ({
  type: GET_ADMIN_HOME_INFO_FAILURE,
  error,
});

export const getCompanyDetailSuccess = (companyDetail) => ({
  type: GET_COMPANY_DETAIL_SUCCESS,
  companyDetail,
});

export const getCompanyDetailFailure = (error) => ({
  type: GET_COMPANY_DETAIL_FAILURE,
  error,
});

export const getEmployeesSuccess = (employees) => ({
  type: GET_EMPLOYEES_SUCCESS,
  employees,
});

export const getEmployeesFailure = (error) => ({
  type: GET_EMPLOYEES_FAILURE,
  error,
});

export const getSavingSummarySuccess = (savingSummary) => ({
  type: GET_SAVING_SUMMARY_SUCCESS,
  savingSummary,
});

export const getSavingSummaryFailure = (error) => ({
  type: GET_SAVING_SUMMARY_FAILURE,
  error,
});

export const getSavingSummaryByIdSuccess = (savingSummaryById) => ({
  type: GET_SAVING_SUMMARY_BY_ID_SUCCESS,
  savingSummaryById,
});

export const getSavingSummaryByIdFailure = (error) => ({
  type: GET_SAVING_SUMMARY_BY_ID_FAILURE,
  error,
});

///
export function getAdminByUserId(userId) {
  return (dispatch, getState) => {
    dispatch(getAdminByUserIdBegin());
    return request({
      partialUrl: "/api/v1/admins/find-admin-by-user-id",
      method: "POST",
      state: getState().user.request,
      body: {
        userId,
      },
    })
      .then((request) => request.json())
      .then((data) => {
        dispatch(getAdminByUserIdSuccess(data.data));
      })
      .catch((err) => {
        return dispatch(getAdminByUserIdFailure(err));
      });
  };
}

export const getAdminHomeInfo = () => {
  return async (dispatch) => {
    try {
      const response = await api.get("Salvum/services/boss/getAdminHomeInfo");
      dispatch(getAdminHomeInfoSuccess(response.data));

      if (response.error) {
        dispatch(setError(response.message));
      }
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getAdminHomeInfoFailure(error));
    }
  };
};

export const getCompanyDetail = ({ companyId }) => {
  return async (dispatch) => {
    try {
      const response = await api.get(
        `Salvum/services/boss/getCompanyDetail?companyId=${companyId}`
      );

      if (response.error) {
        dispatch(setError(response.message));
      }

      dispatch(getCompanyDetailSuccess(response.data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getAdminHomeInfoFailure(error));
    }
  };
};

export const getEmployees = ({ companyId }) => {
  return async (dispatch) => {
    try {
      const response = await api.get(
        `Salvum/services/boss/getEmployeesByCompanyId?companyId=${companyId}`
      );

      if (response.error) {
        dispatch(setError(response.message));
      }
      dispatch(getEmployeesSuccess(response.data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getEmployeesFailure(error));
    }
  };
};

export const getSavingSummary = () => {
  return async (dispatch) => {
    try {
      const response = await api.get(
        `Salvum/services/savings/getSavingSummaryForAdmin`
      );
      dispatch(getSavingSummarySuccess(response.data));
    } catch (error) {
      dispatch(getSavingSummaryFailure(error));
    }
  };
};

export const getSavingSummaryById = ({ companyId }) => {
  return async (dispatch) => {
    try {
      const response = await api.get(
        `Salvum/services/savings/getSavingSummaryForAdmin?companyId=${companyId}`
      );
      if (response.error) {
        dispatch(setError(response.message));
      }
      dispatch(getSavingSummaryByIdSuccess(response.data));
    } catch (error) {
      const message = error.response.data.message || error.message;
      dispatch(setError(message));
      dispatch(getSavingSummaryByIdFailure(error));
    }
  };
};
