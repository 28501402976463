import React, { Component } from "react";
import { connect } from "react-redux";

import { getCashAdvanceDashboard } from "../../../actions/payrollAdvanceAction";
import { Companies, CompanyLoansDetails } from "./components";
import { ArrowCircleLeftIcon } from "@heroicons/react/outline";

// STYLES
import "./AdminPayRoll.scss";
export class AdminPayroll extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyId: null,
      companyName: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(getCashAdvanceDashboard());
  }

  setCompanyId = (companyId, companyName) => {
    this.setState({ companyId, companyName });
  };

  handleBack = () => {
    this.setState({ companyId: null });
  };

  render = () => {
    const { cashAdvanceDashboard, user } = this.props;

    const { companyId } = this.state;
    if (!cashAdvanceDashboard) {
      return null;
    }

    return (
      <div className="loanAdmin">
        <div className="companiesHeroContent flex flex-col items-center">
          <div className="companiesContainer">
            <ul className="breadcrumbs">
              <li>Inicio</li>
              <li>Adelantos de nómina</li>
            </ul>
            <div className="companiesHero">
              <div className="content">
                <span className="contentTitle">Adelantos de nómina</span>
              </div>
            </div>
          </div>
          {companyId !== null && (
            <button
              onClick={this.handleBack}
              className="transition ease-in-out ml-20 flex text-white rounded-2xl self-start hover:text-blue-400"
            >
              <ArrowCircleLeftIcon className="mr-1 w-6 h-6" />
              Regresar
            </button>
          )}
        </div>
        {companyId === null && (
          <div className="companiesContent">
            <div className="companiesContentData">
              <div className="principalContentCompany">
                <div className="w-full flex flex-col">
                  <h2 className="text-black text-2xl w-full">
                    Adelantos de nómina
                  </h2>
                  <div className="grayLine"></div>
                  <div className="flex justify-around">
                    <div className="tabItem">
                      <p className="grayText">Total registrados</p>
                      <p className="blackText text-center">
                        {cashAdvanceDashboard.totalRegisteredCount}
                      </p>
                    </div>
                    <div className="tabItem">
                      <p className="grayText">Comisiónes totales cobradas</p>
                      <p className="blackText text-center">
                        {cashAdvanceDashboard.interestsAlreadyPaidFormatted}
                      </p>
                    </div>
                    <div className="tabItem">
                      <p className="grayText">En curso</p>
                      <p className="blackText text-center">
                        {cashAdvanceDashboard.activeCount}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grayLine bigLine"></div>
              <Companies
                setCompanyId={this.setCompanyId}
                loansDetails={cashAdvanceDashboard}
              />
            </div>
          </div>
        )}
        {companyId !== null && (
          <CompanyLoansDetails
            companyName={this.state.companyName}
            companyId={companyId}
            setCompanyId={this.setCompanyId}
            adminUserId={user.user.id}
          />
        )}
      </div>
    );
  };
}

function mapStateToProps(state) {
  return {
    ...state,
    pendingLoansAdmin: state.loans.pendingLoansAdmin,
    cashAdvanceDashboard: state.advances.cashAdvanceDashboard,
  };
}

export default connect(mapStateToProps)(AdminPayroll);
