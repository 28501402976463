import React, { useState } from "react";

// Components
import { TextField } from "@mui/material";
import { Buttons } from "./Buttons";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

// Step 1
export const Loans = ({
  step,
  formik,
  handleNext = () => {},
  handleBack,
  handleChange,
  checked,
}) => {
  const [errors, setErrors] = useState({});

  if (step !== 5) {
    return null;
  }

  const validateForm = async () => {
    const errors = await formik.validateForm();
    const errorNames = Object.keys(errors);
    setErrors(errors);
    if (
      !errorNames.includes("approver_email") &&
      !errorNames.includes("authorizator_email")
    ) {
      handleNext();
    }
  };

  return (
    <div className="flex flex-col items-center p-6">
      <h2>
        Si deseas habilitar préstamos en la compañia, habilita la opcion Agregar
        préstamos y llena los campos solicitados{" "}
      </h2>
      <FormGroup>
        <FormControlLabel
          control={<Switch onChange={handleChange} checked={checked} />}
          label="Agregar préstamos"
        />
      </FormGroup>
      <div className="grid grid-cols-2 gap-2 bg-white p-6 rounded-lg">
        <TextField
          fullWidth
          id="loanCommissionPercentage"
          name="loanCommissionPercentage"
          disabled={!checked}
          type="number"
          label="Comisión por apertura %"
          value={formik.values.loanCommissionPercentage}
          onChange={formik.handleChange}
          error={
            errors.loanCommissionPercentage &&
            Boolean(formik.errors.loanCommissionPercentage)
          }
          helperText={
            errors.loanCommissionPercentage &&
            formik.errors.loanCommissionPercentage
          }
        />

        <TextField
          fullWidth
          id="loanInterestPercentage"
          name="loanInterestPercentage"
          type="number"
          disabled={!checked}
          label="Tasa de interés %"
          value={formik.values.loanInterestPercentage}
          onChange={formik.handleChange}
          error={
            errors.loanInterestPercentage &&
            Boolean(formik.errors.loanInterestPercentage)
          }
          helperText={
            errors.loanInterestPercentage &&
            formik.errors.loanInterestPercentage
          }
        />
        <TextField
          fullWidth
          id="loanNumberOfEndorsements"
          name="loanNumberOfEndorsements"
          type="number"
          disabled={!checked}
          label="Avales"
          value={formik.values.loanNumberOfEndorsements}
          onChange={formik.handleChange}
          error={
            errors.loanNumberOfEndorsements &&
            Boolean(formik.errors.loanNumberOfEndorsements)
          }
          helperText={
            errors.loanNumberOfEndorsements &&
            formik.errors.loanNumberOfEndorsements
          }
        />
        <TextField
          fullWidth
          id="loanMinimumLaborSeniority"
          name="loanMinimumLaborSeniority"
          type="number"
          disabled={!checked}
          label="Plazo max"
          value={formik.values.loanMinimumLaborSeniority}
          onChange={formik.handleChange}
          error={
            errors.loanMinimumLaborSeniority &&
            Boolean(formik.errors.loanMinimumLaborSeniority)
          }
          helperText={
            errors.loanMinimumLaborSeniority &&
            formik.errors.loanMinimumLaborSeniority
          }
        />
      </div>
      <div className="grayLine" />
      <h2 className="my-6 text-2xl">Catálogo de acreditación</h2>
      <div className="grid grid-cols-3 gap-3">
        <h2>Antiguedad</h2>
        <h2>Sueldos</h2>
        <h2>Plazo Max</h2>
        <TextField
          fullWidth
          id="seniority_1"
          name="seniority_1"
          type="number"
          disabled={!checked}
          label="Antiguedad"
          value={formik.values.seniority_1}
          onChange={formik.handleChange}
          error={errors.seniority_1 && Boolean(formik.errors.seniority_1)}
          helperText={errors.seniority_1 && formik.errors.seniority_1}
        />
        <TextField
          fullWidth
          id="payrolls_1"
          name="payrolls_1"
          type="number"
          disabled={!checked}
          label="Sueldos"
          value={formik.values.payrolls_1}
          onChange={formik.handleChange}
          error={errors.payrolls_1 && Boolean(formik.errors.payrolls_1)}
          helperText={errors.payrolls_1 && formik.errors.payrolls_1}
        />
        <TextField
          fullWidth
          id="installments_1"
          name="installments_1"
          type="number"
          disabled={!checked}
          label="Plazo max"
          value={formik.values.installments_1}
          onChange={formik.handleChange}
          error={errors.installments_1 && Boolean(formik.errors.installments_1)}
          helperText={errors.installments_1 && formik.errors.installments_1}
        />
        {/*  */}
        <TextField
          fullWidth
          id="seniority_2"
          name="seniority_2"
          type="number"
          disabled={!checked}
          label="Antiguedad"
          value={formik.values.seniority_2}
          onChange={formik.handleChange}
          error={errors.seniority_2 && Boolean(formik.errors.seniority_2)}
          helperText={errors.seniority_2 && formik.errors.seniority_2}
        />
        <TextField
          fullWidth
          id="payrolls_2"
          name="payrolls_2"
          type="number"
          disabled={!checked}
          label="Sueldos"
          value={formik.values.payrolls_2}
          onChange={formik.handleChange}
          error={errors.payrolls_2 && Boolean(formik.errors.payrolls_2)}
          helperText={errors.payrolls_2 && formik.errors.payrolls_2}
        />
        <TextField
          fullWidth
          id="installments_2"
          name="installments_2"
          type="number"
          disabled={!checked}
          label="Plazo max"
          value={formik.values.installments_2}
          onChange={formik.handleChange}
          error={errors.installments_2 && Boolean(formik.errors.installments_2)}
          helperText={errors.installments_2 && formik.errors.installments_2}
        />
        {/*  */}
        <TextField
          fullWidth
          id="seniority_3"
          name="seniority_3"
          type="number"
          disabled={!checked}
          label="Antiguedad"
          value={formik.values.seniority_3}
          onChange={formik.handleChange}
          error={errors.seniority_3 && Boolean(formik.errors.seniority_3)}
          helperText={errors.seniority_3 && formik.errors.seniority_3}
        />
        <TextField
          fullWidth
          id="payrolls_3"
          name="payrolls_3"
          type="number"
          disabled={!checked}
          label="Sueldos"
          value={formik.values.payrolls_3}
          onChange={formik.handleChange}
          error={errors.payrolls_3 && Boolean(formik.errors.payrolls_3)}
          helperText={errors.payrolls_3 && formik.errors.payrolls_3}
        />
        <TextField
          fullWidth
          id="installments_3"
          name="installments_3"
          type="number"
          disabled={!checked}
          label="Plazo max"
          value={formik.values.installments_3}
          onChange={formik.handleChange}
          error={errors.installments_3 && Boolean(formik.errors.installments_3)}
          helperText={errors.installments_3 && formik.errors.installments_3}
        />
        {/*  */}
        <TextField
          fullWidth
          id="seniority_4"
          name="seniority_4"
          type="number"
          disabled={!checked}
          label="Antiguedad"
          value={formik.values.seniority_4}
          onChange={formik.handleChange}
          error={errors.seniority_4 && Boolean(formik.errors.seniority_4)}
          helperText={errors.seniority_4 && formik.errors.seniority_4}
        />
        <TextField
          fullWidth
          id="payrolls_4"
          name="payrolls_4"
          type="number"
          disabled={!checked}
          label="Sueldos"
          value={formik.values.payrolls_4}
          onChange={formik.handleChange}
          error={errors.payrolls_4 && Boolean(formik.errors.payrolls_4)}
          helperText={errors.payrolls_4 && formik.errors.payrolls_4}
        />
        <TextField
          fullWidth
          id="installments_4"
          name="installments_4"
          type="number"
          disabled={!checked}
          label="Plazo max"
          value={formik.values.installments_4}
          onChange={formik.handleChange}
          error={errors.installments_4 && Boolean(formik.errors.installments_4)}
          helperText={errors.installments_4 && formik.errors.installments_4}
        />
        {/*  */}
        <TextField
          fullWidth
          id="seniority_5"
          name="seniority_5"
          type="number"
          disabled={!checked}
          label="Antiguedad"
          value={formik.values.seniority_5}
          onChange={formik.handleChange}
          error={errors.seniority_5 && Boolean(formik.errors.seniority_5)}
          helperText={errors.seniority_5 && formik.errors.seniority_5}
        />
        <TextField
          fullWidth
          id="payrolls_5"
          name="payrolls_5"
          type="number"
          disabled={!checked}
          label="Sueldos"
          value={formik.values.payrolls_5}
          onChange={formik.handleChange}
          error={errors.payrolls_5 && Boolean(formik.errors.payrolls_5)}
          helperText={errors.payrolls_5 && formik.errors.payrolls_5}
        />
        <TextField
          fullWidth
          id="installments_5"
          name="installments_5"
          type="number"
          disabled={!checked}
          label="Plazo max"
          value={formik.values.installments_5}
          onChange={formik.handleChange}
          error={errors.installments_5 && Boolean(formik.errors.installments_5)}
          helperText={errors.installments_5 && formik.errors.installments_5}
        />
        {/*  */}
        <TextField
          fullWidth
          id="seniority_6"
          name="seniority_6"
          type="number"
          disabled={!checked}
          label="Antiguedad"
          value={formik.values.seniority_6}
          onChange={formik.handleChange}
          error={errors.seniority_6 && Boolean(formik.errors.seniority_6)}
          helperText={errors.seniority_6 && formik.errors.seniority_6}
        />
        <TextField
          fullWidth
          id="payrolls_6"
          name="payrolls_6"
          type="number"
          disabled={!checked}
          label="Sueldos"
          value={formik.values.payrolls_6}
          onChange={formik.handleChange}
          error={errors.payrolls_6 && Boolean(formik.errors.payrolls_6)}
          helperText={errors.payrolls_6 && formik.errors.payrolls_6}
        />
        <TextField
          fullWidth
          id="installments_6"
          name="installments_6"
          type="number"
          disabled={!checked}
          label="Plazo max"
          value={formik.values.installments_6}
          onChange={formik.handleChange}
          error={errors.installments_6 && Boolean(formik.errors.installments_6)}
          helperText={errors.installments_6 && formik.errors.installments_6}
        />
      </div>
      <Buttons
        handleNext={validateForm}
        activeStep={step}
        handleBack={handleBack}
      />
    </div>
  );
};

export default Loans;
