import {
  SAVINGS_CREATING_BEGIN,
  SAVINGS_CREATING_SUCEESS,
  SAVINGS_CREATING_FAILURE,
  SAVINGS_ADD_BENEFICIARY_BEGIN,
  SAVINGS_ADD_BENEFICIARY_SUCEESS,
  SAVINGS_ADD_BENEFICIARY_FAILURE,
  CLEAR_SAVINGS_CREATING_FAILURE,
  SAVINGS_REMOVE_BENEFICIARY_BEGIN,
  SAVINGS_REMOVE_BENEFICIARY_SUCEESS,
  SAVINGS_REMOVE_BENEFICIARY_FAILURE,
  CREATE_SAVING_BANK_BEGIN,
  CREATE_SAVING_BANK_SUCCESS,
  CREATE_SAVING_BANK_FAILURE,
  GET_SAVING_BANKS_BY_EMPLOYEE_ID_BEGIN,
  GET_SAVING_BANKS_BY_EMPLOYEE_ID_SUCCESS,
  GET_SAVING_BANKS_BY_EMPLOYEE_ID_FAILURE,
  TESTING_SAVINGS_RESET_BEGIN,
  TESTING_SAVINGS_RESET_SUCCESS,
  TESTING_SAVINGS_RESET_FAILURE,
  GET_SAVINGS_BANKS_RESUME_BY_BOSS_ID_BEGIN,
  GET_SAVINGS_BANKS_RESUME_BY_BOSS_ID_SUCCESS,
  GET_SAVINGS_BANKS_RESUME_BY_BOSS_ID_FAILURE,
  GET_SAVING_BANKS_BY_BOSS_ID_BEGIN,
  GET_SAVING_BANKS_BY_BOSS_ID_SUCCESS,
  GET_SAVING_BANKS_BY_BOSS_ID_FAILURE,
  GET_SAVING_TRANSACTIONS_BY_EMPLOYEE_ID_BEGIN,
  GET_SAVING_TRANSACTIONS_BY_EMPLOYEE_ID_SUCCESS,
  GET_SAVING_TRANSACTIONS_BY_EMPLOYEE_ID_FAILURE,
  GET_BANXICO_RATES_BEGIN,
  GET_BANXICO_RATES_SUCCESS,
  GET_BANXICO_RATES_FAILURE,
  GET_TOP_SAVINGS_ACCOUNT_BEGIN,
  GET_TOP_SAVINGS_ACCOUNT_SUCCESS,
  GET_TOP_SAVINGS_ACCOUNT_FAILURE,
  USER_LOGOUT_SUCCESS,
  GET_TOTAL_SAVINGS_EMPLOYEE_BEGIN,
  GET_TOTAL_SAVINGS_EMPLOYEE_SUCCESS,
  GET_TOTAL_SAVINGS_EMPLOYEE_FAILURE,
  GET_SAVINGS_SLOTS_BEGIN,
  GET_SAVINGS_SLOTS_SUCCESS,
  GET_SAVINGS_SLOTS_FAILURE,
  GET_SAVINGS_TABLE_PER_EMPLOYEE_BEGIN,
  GET_SAVINGS_TABLE_PER_EMPLOYEE_SUCCESS,
  GET_SAVINGS_TABLE_PER_EMPLOYEE_FAILURE,
  GET_PAYROLL_STATUS_INFO_BEGIN,
  GET_PAYROLL_STATUS_INFO_SUCCESSFUL,
  GET_PAYROLL_STATUS_INFO_FAILURE,
} from "../constants/actionTypes";

import initialState from "./initialState";

// IMPORTANT: Note that with Redux, state should NEVER be changed.
// State is considered immutable. Instead,
// create a copy of the state passed and set new values on the copy.
// Note that I'm using Object.assign to create a copy of current state
// and update values on the copy.
export default function userReducer(state = initialState.savings, action) {
  switch (action.type) {
    case SAVINGS_CREATING_BEGIN: {
      const newSaving = JSON.parse(JSON.stringify(state.newSaving));
      newSaving.loading = true;
      newSaving.creating = false;
      return {
        ...state,
        newSaving,
      };
    }
    case SAVINGS_CREATING_SUCEESS: {
      const newSaving_ = JSON.parse(JSON.stringify(state.newSaving));
      newSaving_.loading = false;
      newSaving_.creating = true;
      return {
        ...state,
        newSaving: newSaving_,
      };
    }
    case SAVINGS_CREATING_FAILURE: {
      const newSaving__ = JSON.parse(JSON.stringify(state.newSaving));
      newSaving__.loading = false;
      newSaving__.creating = false;
      return {
        ...state,
        newSaving: newSaving__,
      };
    }
    case CLEAR_SAVINGS_CREATING_FAILURE: {
      const w = JSON.parse(JSON.stringify(state.newSaving));
      w.loading = false;
      w.creating = false;
      w.created = false;
      w.savingType = initialState.savings.savingType;
      w.beneficiaries = initialState.savings.beneficiaries;
      return {
        ...state,
        newSaving: w,
      };
    }
    case SAVINGS_ADD_BENEFICIARY_BEGIN:
      return {
        ...state,
      };
    case SAVINGS_ADD_BENEFICIARY_SUCEESS: {
      const newSaving___ = JSON.parse(JSON.stringify(state.newSaving));
      newSaving___.beneficiaries.push({
        name: "",
        relationship: "",
        number: "",
        percent: 0.0,
      });
      return {
        ...state,
        newSaving: newSaving___,
      };
    }
    case SAVINGS_ADD_BENEFICIARY_FAILURE:
      return {
        ...state,
      };
    case SAVINGS_REMOVE_BENEFICIARY_BEGIN:
      return {
        ...state,
      };
    case SAVINGS_REMOVE_BENEFICIARY_SUCEESS: {
      const newSavingBeneficy = JSON.parse(JSON.stringify(state.newSaving));
      newSavingBeneficy.beneficiaries.splice(action.index, 1);
      return {
        ...state,
        newSaving: newSavingBeneficy,
      };
    }
    case SAVINGS_REMOVE_BENEFICIARY_FAILURE:
      return {
        ...state,
      };
    case CREATE_SAVING_BANK_BEGIN:
      return {
        ...state,
        created: false,
      };
    case CREATE_SAVING_BANK_SUCCESS: {
      return {
        ...state,
        created: true,
      };
    }
    case CREATE_SAVING_BANK_FAILURE:
      return {
        ...state,
        creatd: false,
      };
    case GET_SAVING_BANKS_BY_EMPLOYEE_ID_BEGIN: {
      return state;
    }
    case GET_SAVING_BANKS_BY_EMPLOYEE_ID_SUCCESS: {
      return {
        ...state,
        savings: action.data.savings,
        beneficiaries: action.data.beneficiaries,
      };
    }
    case GET_SAVING_BANKS_BY_EMPLOYEE_ID_FAILURE: {
      return state;
    }
    case TESTING_SAVINGS_RESET_BEGIN:
      return state;
    case TESTING_SAVINGS_RESET_SUCCESS:
      return state;
    case TESTING_SAVINGS_RESET_FAILURE:
      return state;
    case GET_SAVINGS_BANKS_RESUME_BY_BOSS_ID_BEGIN:
      return state;
    case GET_SAVINGS_BANKS_RESUME_BY_BOSS_ID_SUCCESS:
      return {
        ...state,
        companySavingsResume: action.companySavingsResume,
      };
    case GET_SAVINGS_BANKS_RESUME_BY_BOSS_ID_FAILURE:
      return state;

    case GET_SAVING_BANKS_BY_BOSS_ID_BEGIN:
      return state;
    case GET_SAVING_BANKS_BY_BOSS_ID_SUCCESS:
      return {
        ...state,
        savings: action.savings,
      };
    case GET_SAVING_BANKS_BY_BOSS_ID_FAILURE:
      return state;
    case GET_SAVING_TRANSACTIONS_BY_EMPLOYEE_ID_BEGIN:
      return state;
    case GET_SAVING_TRANSACTIONS_BY_EMPLOYEE_ID_SUCCESS:
      return {
        ...state,
        savingTransactions: action.savingTransactions,
      };
    case GET_SAVING_TRANSACTIONS_BY_EMPLOYEE_ID_FAILURE:
      return state;
    case GET_BANXICO_RATES_BEGIN:
      return state;
    case GET_BANXICO_RATES_SUCCESS:
      return {
        ...state,
        banxico: action.banxico,
      };
    case GET_BANXICO_RATES_FAILURE:
      return state;
    case USER_LOGOUT_SUCCESS:
      return {
        ...initialState.savings,
      };

    case GET_TOP_SAVINGS_ACCOUNT_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_TOP_SAVINGS_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        topSavingsAccount: action.topSavingsAccount,
        errorMessage: "",
      };
    case GET_TOP_SAVINGS_ACCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.error || "Fallo la solicitud",
      };
    case GET_TOTAL_SAVINGS_EMPLOYEE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_TOTAL_SAVINGS_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        totalSavingsEmployee: action.totalSavingsEmployee,
        errorMessage: "",
      };
    case GET_TOTAL_SAVINGS_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage:
          action.error || "No hemos podido cargar el total de ahorros",
      };
    case GET_SAVINGS_SLOTS_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_SAVINGS_SLOTS_SUCCESS:
      return {
        ...state,
        loading: false,
        savingsSlots: action.savingsSlots,
        errorMessage: "",
      };
    case GET_SAVINGS_SLOTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.error || "Fallo la solicitud",
      };
    case GET_SAVINGS_TABLE_PER_EMPLOYEE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_SAVINGS_TABLE_PER_EMPLOYEE_SUCCESS:
      return {
        ...state,
        loading: false,
        savingsTable: action.savingsTable,
        errorMessage: "",
      };
    case GET_SAVINGS_TABLE_PER_EMPLOYEE_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.error || "Fallo la solicitud",
      };
    case GET_PAYROLL_STATUS_INFO_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_PAYROLL_STATUS_INFO_SUCCESSFUL:
      return {
        ...state,
        payrollStatusInfo: action.payrollStatusInfo,
        loading: false,
      };
    case GET_PAYROLL_STATUS_INFO_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}
