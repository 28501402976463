import { publicUrl } from "../actions/consts";

export const request = async ({
  partialUrl,
  method,
  body,
  headers = null,
  state,
}) => {
  // Aca hay que validar que el token no este vencido
  return fetch(`${publicUrl}${partialUrl}`, {
    method,
    headers: headers || state.headers,
    body:
      method === "GET"
        ? null
        : JSON.stringify({
            ...state.body,
            ...body,
          }),
  }).then((response) => {
    if (response.ok) {
      return response;
    }
    throw new Error(response.error);
  });
};

export default request;
