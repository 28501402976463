import React, { useState } from "react";
import { Button } from "@mui/material";
import { Modal } from "../../../../../../../components/Modal";
import { savingTypes } from "../../../SavingsUtils";
import { useSelector } from "react-redux";
import _ from "lodash";
import "./Modals.scss";

const initialSelectedSlotState = {
  id: null,
  label: null,
};

export const ReinvestSavingsModal = ({
  setOpenReinvestModal,
  setCurrentValue,
  onClickAction,
  isOpen,
  savingSlot,
  currentValue,
}) => {
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false);
  const closeModal = () => {
    setOpenReinvestModal(false);
    setCurrentValue("");
  };

  const closeConfirmModal = () => {
    setConfirmModalIsOpen(false);
  };

  const [selectedSlot, setSelectedSlot] = useState(initialSelectedSlotState);

  const totalSavingsEmployee = useSelector(
    (state) => state.savings.totalSavingsEmployee
  );

  const handleSubmit = async () => {
    await onClickAction({
      actionId: currentValue,
      selectedSavingTypeId: selectedSlot.id,
    });
    closeModal();
    closeConfirmModal();
  };

  const handleContinue = () => {
    setConfirmModalIsOpen(true);
  };

  if (!savingSlot.availableActions) {
    return null;
  }

  if (!isOpen) {
    return null;
  }

  const [filteredAction] = savingSlot.availableActions.filter(
    (action) => action.actionId === 1
  );

  if (!filteredAction) {
    return null;
  }

  if (!filteredAction.optionalAvailableSavingsToReInvest) {
    return null;
  }

  const handleSelect = (evt, label) => {
    const id = evt.currentTarget.id;
    setSelectedSlot({
      id,
      label,
    });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={closeModal}
        title="Reinvertir ahorros."
      >
        <div className="modals">
          <h3 style={{ color: "black" }}>Usted tiene un total ahorrado de:</h3>

          <h2 style={{ textAlign: "center" }}>
            {savingSlot.valueFormatted}
          </h2>

          <p>Por favor, elija el tipo de reinversión que desea aplicar:</p>
          <div className="slots-container">
            {filteredAction.optionalAvailableSavingsToReInvest.map((action) => (
              <div
                key={action.savingTypeId}
                id={action.savingTypeId}
                className={`slot ${
                  selectedSlot.label === action.savingTypeIdFormatted
                    ? "selected"
                    : ""
                }`}
                onClick={(e) => handleSelect(e, action.savingTypeIdFormatted)}
              >
                <div
                  className={`form-left ${
                    savingTypes[action.savingTypeIdFormatted].borderClass
                  } ${
                    selectedSlot.label === action.savingTypeIdFormatted
                      ? "selected"
                      : ""
                  }`}
                >
                  <div>{action.savingTypeIdFormatted}</div>
                </div>
              </div>
            ))}
          </div>

          <div className="buttons-container">
            <Button
              onClick={closeModal}
              variant="outlined"
              type="button"
              style={{ marginRight: "12px" }}
            >
              Cancelar
            </Button>
            <Button
              className="custom-button-success"
              color="primary"
              variant="contained"
              disabled={!selectedSlot.id}
              type="submit"
              onClick={handleContinue}
            >
              Continuar
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={confirmModalIsOpen}
        handleClose={closeConfirmModal}
        title="¡Atención!"
      >
        <h4>
          Usted está a punto de reinvertir sus ahorros, diferidos a:{" "}
          {selectedSlot.label}
        </h4>

        <h5>¿Desea continuar?</h5>

        <div className="buttons-container">
          <Button
            onClick={closeConfirmModal}
            variant="outlined"
            type="button"
            style={{ marginRight: "8px" }}
          >
            Regresar
          </Button>
          <Button
            className="custom-button-success"
            color="success"
            variant="contained"
            type="submit"
            onClick={handleSubmit}
          >
            Reinvertir ahora.
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default ReinvestSavingsModal;
