import {
  CREATE_COMPANY_BEGIN,
  CREATE_COMPANY_FAILURE,
  GET_COMPANY_BY_ID_BEGIN,
  GET_COMPANY_BY_ID_SUCCESS,
  GET_COMPANY_BY_ID_FAILURE,
  GET_COMPANIES_BEGIN,
  GET_COMPANIES_SUCCESS,
  GET_COMPANIES_FAILURE,
  GET_COMPANY_SUMMARY_BEGIN,
  GET_COMPANY_SUMMARY_SUCCESS,
  GET_COMPANY_SUMMARY_FAILURE,
  GET_COMPANIES_SUMMARY_BEGIN,
  GET_COMPANIES_SUMMARY_SUCCESS,
  GET_COMPANIES_SUMMARY_FAILURE,
  USER_LOGOUT_SUCCESS,
  GET_PAYROLL_ADVANCE_BY_COMPANY_ID_BEGIN,
  GET_PAYROLL_ADVANCE_BY_COMPANY_ID_FAILURE,
  GET_PAYROLL_ADVANCE_BY_COMPANY_ID_SUCCESS,
} from "../constants/actionTypes";

import initialState from "./initialState";

export default function companyReducer(state = initialState.company, action) {
  switch (action.type) {
    case CREATE_COMPANY_BEGIN:
    case GET_COMPANY_BY_ID_BEGIN:
    case GET_COMPANIES_BEGIN:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };
    case GET_COMPANY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        current: {
          ...action.company,
        },
      };
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        resume: action.resume,
        // savingDetail: action.savingDetails,
      };
    case CREATE_COMPANY_FAILURE:
    case GET_COMPANY_BY_ID_FAILURE:
    case GET_COMPANIES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
        current: null,
      };
    case GET_COMPANY_SUMMARY_BEGIN:
      return {
        ...state,
      };
    case GET_COMPANY_SUMMARY_SUCCESS:
      return {
        ...state,
        summary: action.summary,
      };
    case GET_COMPANY_SUMMARY_FAILURE:
      return {
        ...state,
      };
    case GET_COMPANIES_SUMMARY_BEGIN:
      return {
        ...state,
      };
    case GET_COMPANIES_SUMMARY_SUCCESS:
      return {
        ...state,
        summaryAdmin: action.summaryAdmin,
      };
    case GET_COMPANIES_SUMMARY_FAILURE:
      return {
        ...state,
      };
    case USER_LOGOUT_SUCCESS:
      return {
        ...initialState.company,
      };

    case GET_PAYROLL_ADVANCE_BY_COMPANY_ID_BEGIN:
      return {
        ...state,
        error: null,
        success: false,
        loading: true,
      };

    case GET_PAYROLL_ADVANCE_BY_COMPANY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        payrollAdvanceList: action.payrollAdvanceList,
      };

    case GET_PAYROLL_ADVANCE_BY_COMPANY_ID_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error || "Fallo la solicitud",
      };

    default:
      return state;
  }
}
