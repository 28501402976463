import { instance, config } from "./axios";

const createRequestPayrollAdvance = (companyId, employeeId, amount) => {
  return instance.post(
    `/Salvum/services/cashadvance/requestCashAdvance`,
    {
      companyId: companyId,
      employeeId: employeeId,
      amount: amount,
    },
    config
  );
};

export default {
  createRequestPayrollAdvance,
};
