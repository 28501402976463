export default {
  global: {
    alertIsOpen: false,
    alertType: "success",
    alertTitle: "",
  },
  user: {
    token: "",
    isAuthenticated: false,
    redirectToLogin: false,
    role: null,
    lastLogin: null,
    request: {
      headers: {
        "Content-Type": "application/json",
      },
      body: {},
    },
    loading: false,
    checkingForSession: true,
    error: null,
    contactData: "",
    errorMessage: "",
  },
  recovery: {
    loading: false,
    data: [],
    request: {
      headers: {
        "Content-Type": "application/json",
      },
      body: {},
    },
    errorMessage: "",
  },
  employee: {
    numberDetails: {
      cashAdvanceQuantity: 0,
      totalCashAdvanceRequested: 0,
      totalCashAdvanceRequestedFormatted: "",
      currentActiveLoans: 0,
      remainingBalanceToPay: 0,
      remainingBalanceToPayFormatted: "",
    },
    cashAdvanceDetail: null,
    savingDetailResponse: null,
    employeeList: [],
    list: {
      count: 0,
      rows: [],
    },
    loanDetail: null,
    errorList: null,
    loadingList: false,
    successList: false,
    errorAdd: null,
    loadingAdd: false,
    successAdd: false,
    current: null,
    summary: {
      hasSavings: null,
      loans: {
        inProgress: null,
        outstandingBalance: null,
      },
      payrollAdvances: {
        count: null,
        total: null,
      },
      savings: [],
    },
  },
  notification: {
    title: null,
    message: null,
    notificationType: "",
    insert: "bottom",
    container: "bottom-right",
    dismiss: {
      duration: 5000,
      showIcon: true,
      click: false,
      touch: false,
      onScreen: false,
      pauseOnHover: false,
      waitForAnimation: false,
    },
  },
  fuelSavings: {
    newMpg: "",
    tradeMpg: "",
    newPpg: "",
    tradePpg: "",
    milesDriven: "",
    milesDrivenTimeframe: "week",
    displayResults: false,
    dateModified: null,
    necessaryDataIsProvidedToCalculateSavings: false,
    savings: {
      monthly: 0,
      annual: 0,
      threeYear: 0,
    },
    leo: false,
  },
  savings: {
    savingTransactions: {
      transactions: [],
    },
    payrollStatusInfo: null,
    topSavingsAccount: [],
    banxico: null,
    totalSavingsEmployee: null,
    savingsSlots: null,
    savingsTable: [
      {
        savingBankType: 0,
        table: [
          {
            period: 0,
            date: "",
            input: "",
            rate: "",
            balance: "",
          },
        ],
      },
    ],
    newSaving: {
      creating: false,
      created: false,
      loading: false,
      savingType: {
        now: {
          amount: 0,
        },
        month_1: {
          amount: 0,
        },
        month_3: {
          amount: 0,
        },
        month_6: {
          amount: 0,
        },
        month_12: {
          amount: 0,
        },
        month_24: {
          amount: 0,
        },
        unlimit: {
          amount: 0,
        },
      },
      beneficiaries: [{ name: "", relationship: "", number: "", percent: 0 }],
      personalInfo: {
        name: "",
        lastname: "",
        phonenumber: "",
        email: "",
        employeenumber: "",
        companyname: "",
        joblocation: "",
        companyphone: "",
        dateofadmission: "",
        salvumid: "",
        companybank: "",
        headline: "",
        cardnumber: "",
        clabe: "",
      },
    },
    savings: [],
    beneficiaries: [],
    savingsResume: [],
    companySavingsResume: {
      activeSavings: null,
      amountOfContributionsPerMonth: null,
      totalAccumulatedBalance: null,
      savingBankType: [],
      savingDetails: [],
      employeesSavingResume: [],
    },
    created: false,
  },
  loans: {
    updated: false,
    newLoan: {
      created: false,
    },
    loans: {
      loans: [],
      endorsements: [],
    },
    loansByStatus: {
      loans: [],
    },
    loansGeneralAdminResponse: null,
    pendingLoansAdmin: null,
    loansResume: {
      toBeApproved: null,
      approved: null,
      rejected: null,
      paid: null,
      accumulatedLoan: null,
      accumulatedBalance: null,
    },
    loanTransactions: {
      transactions: [],
    },
    loansByEmployeeAndCompany: {
      netPay: null,
      periods: [],
      periodType: "",
      numberOfEndorsements: null,
      companyCoWorkers: [],
    },
    amortizationTableInfo: {
      employeeName: "",
      creditAmount: "",
      comission: "",
      anualInterestRate: "",
      totalInterests: "",
      typeOfPeriods: "",
      table: [],
    },
    loanGeneralDetails: null,
  },
  boss: {
    homeInfo: null,
    employeeDetail: null,
    pendingLoans: null,
    companySummary: null,
  },
  admin: {
    adminHomeInfo: null,
  },
  company: {
    payrollAdvanceList: [],
    summaryAdmin: {
      requests: {
        payrollAdvance: null,
        loans: null,
        activeSavings: null,
      },
      companies: {
        activeCompanies: null,
      },
      employees: {
        savingEmployees: null,
        registeredEmployees: null,
      },
      payrollAdvances: {
        approvedAdvances: null,
        allAdvances: null,
        totalApproved: null,
        totalAdvances: null,
        advancesInProgress: null,
        totalMonth: null,
      },
      loans: {
        approvedLoans: null,
        allLoans: null,
        totalPaidInterest: null,
        totalInterest: null,
        loansTotal: null,
        loansInProgress: null,
        totalPendingInterest: null,
        totalPending: null,
      },
      savings: [],
    },
    summary: {
      requests: {
        payrollAdvance: null,
        loans: null,
      },
      employees: {
        savingEmployees: null,
        registeredEmployees: null,
      },
      payrollAdvances: {
        approvedAdvances: null,
        allAdvances: null,
        totalApproved: null,
        totalAdvances: null,
        advancesInProgress: null,
        totalMonth: null,
      },
      loans: {
        approvedLoans: null,
        allLoans: null,
        totalPaidInterest: null,
        totalInterest: null,
        loansTotal: null,
        loansInProgress: null,
        totalPendingInterest: null,
        totalPending: null,
      },
      savings: [],
    },
    error: null,
    loading: false,
    success: false,
    current: null,
    resume: {
      resume: [],
      savingDetails: [],
    },
    list: {
      count: 0,
      rows: [],
    },
  },
  advances: {
    payrollAdvance: {
      advances: [],
    },
    payrollAdvanceByStatus: {
      advances: [],
    },
    payrollAdvanceResume: {
      toBeApproved: null,
      approved: null,
      rejected: null,
      approvedTotal: null,
    },
    requestPayrollAdvanceInfo: {
      message: "",
    },
  },
};
