import {
  RECOVERY_PASSWORD_LOADING,
  RECOVERY_PASSWORD_SUCCESS,
  RECOVERY_PASSWORD_FAILURE,
  RESET_PASSWORD_LOADING,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from "../constants/actionTypes";

import initialState from "./initialState";

export default function recoveryPasswordReducer(
  state = initialState.recovery,
  action
) {
  switch (action.type) {
    case RECOVERY_PASSWORD_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: "",
      };
    case RECOVERY_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.user,
        errorMessage: "",
      };
    case RECOVERY_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.error || "Fallo la solicitud",
      };
    case RESET_PASSWORD_LOADING:
      return {
        ...state,
        loading: true,
        errorMessage: "",
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.user,
        errorMessage: "",
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        errorMessage: action.error || "Fallo la solicitud",
      };

    default:
      return state;
  }
}
