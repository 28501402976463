import React, { Component } from "react";
import { connect } from "react-redux";
import "./Profile.scss";
import moment from "moment";

import { getCompanyById } from "../../../actions/companyAction";
import { getEmployeeByUserId } from "../../../actions/employeeAction";
import { userLogout } from "../../../actions/userActions";
import { recoveryPassword } from "../../../actions/recoveryPasswordAction";

export class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      info: true,
      notifications: false,
      account: false,
      changePassword: false,
      checkedSavingsNot: false,
      checkedLoadNot: false,
      checkedNomNot: false,
    };
    this.selectBar = this.selectBar.bind(this);
    this.allFalse = this.allFalse.bind(this);
  }

  componentDidMount = () => {
    if (this.props.user) {
      this.props.dispatch(getCompanyById(this.props.user.companyId));
      this.props.dispatch(
        getEmployeeByUserId(this.props.user.id, this.props.user.companyId)
      );
    }
  };

  toggleCheckedSavings = () => {
    this.setState({
      checkedSavingsNot: !this.state.checkedSavingsNot,
    });
  };
  toggleCheckedLoad = () => {
    this.setState({
      checkedLoadNot: !this.state.checkedLoadNot,
    });
  };
  toggleCheckedNom = () => {
    this.setState({
      checkedNomNot: !this.state.checkedNomNot,
    });
  };

  allFalse() {
    this.setState({
      info: false,
      notifications: false,
      account: false,
      changePassword: false,
    });
  }

  changePassword = async () => {
    this.props.dispatch(recoveryPassword(this.props.user.email));
    this.props.dispatch(userLogout());
    this.props.history.push("/");
  };

  selectBar(event) {
    this.allFalse();
    if (event.target.id == "info") {
      this.setState({
        info: true,
      });
    } else if (event.target.id == "account") {
      this.setState({
        account: true,
      });
    }
  }

  render() {
    return (
      this.props.user &&
      this.props.company && (
        <div className={"profile"}>
          <div className={"profileHeroContent"}>
            <div className={"profileContainer"}>
              <ul className={"breadcrumbs"}>
                <li>Inicio</li>
                <li>Perfil</li>
              </ul>
              <div className={"profileHero"}>
                <div className={"content"}>
                  <span className={"contentTitle"}>Perfil</span>
                </div>
              </div>
            </div>
          </div>
          <div className={"profileContent"}>
            <div className={"profileContentData"}>
              <div className={"navtabstable"}>
                <div
                  id="info"
                  className={[
                    "navtab",
                    this.state.info ? "navtabselected" : "",
                    !this.state.info ? "navtabsunelected" : "",
                  ].join(" ")}
                  onClick={this.selectBar}
                >
                  Información de perfil
                </div>
                {/* <div id="notifications" className={['navtab', this.state.notifications ? 'navtabselected' : '', !this.state.notifications  ? 'navtabsunelected' : ''].join(' ')} onClick={this.selectBar}>Notificaciones</div> */}
                <div
                  id="account"
                  className={[
                    "navtab",
                    this.state.account ? "navtabselected" : "",
                    !this.state.account ? "navtabsunelected" : "",
                  ].join(" ")}
                  onClick={this.selectBar}
                >
                  Cuenta de depósito
                </div>
              </div>
              <div className={"profileContentBase"}>
                {this.state.info && (
                  <div className={"profileContentBaseInfo"}>
                    <div className={"firstContent"}>
                      <h5 className={"profileTitle"}>Información personal</h5>
                      <div className={"profileInfoContainer"}>
                        <div className={"profileInfoElement"}>
                          <label>Nombres</label>
                          <p>{this.props.employee.firstname}</p>
                        </div>
                        <div className={"profileInfoElement"}>
                          <label>Apellidos</label>
                          <p>{this.props.employee.lastname}</p>
                        </div>
                        <div className={"profileInfoElement"}>
                          <label>Teléfono registrado</label>
                          <p>{this.props.employee.phone}</p>
                        </div>
                        <div className={"profileInfoElement"}>
                          <label>Correo electrónico</label>
                          <p>{this.props.employee.email} </p>
                        </div>
                        <div className={"profileInfoElement"}>
                          <label>Número de empleado</label>
                          <p>{this.props.employee.employeeidentifier}</p>
                        </div>
                        <div className={"profileInfoElement"}>
                          <label>Nombre de la empresa</label>
                          <p>{this.props.company.name}</p>
                        </div>
                        <div className={"profileInfoElement"}>
                          <label>Localidad donde labora</label>
                          <p>{this.props.employee.worklocation}</p>
                        </div>
                        <div className={"profileInfoElement"}>
                          <label> </label>
                          <p> </p>
                        </div>
                        <div className={"profileInfoElement"}>
                          <label>Teléfono de la empresa</label>
                          <p>{this.props.company.phone}</p>
                        </div>
                        <div className={"profileInfoElement"}>
                          <label>Fecha de ingreso</label>
                          <p>
                            {moment.unix(this.props.employee.admissionat).format("DD/MM/YYYY")}
                          </p>
                        </div>
                        <div className={"profileInfoElement"}>
                          <label> </label>
                          <p> </p>
                        </div>
                      </div>
                    </div>
                    <div className={"grayLine"}></div>
                    <div className={"firstContent"}>
                      <h5 className={"profileTitle"}>Mi contraseña</h5>
                      {!this.state.changePassword && (
                        <div className={"profileInfoContainer start_content"}>
                          <button
                            className="transition ease-in-out text-black bg-white cursor-pointer px-4 py-2 rounded-2xl border hover:bg-gray-50"
                            onClick={this.changePassword}
                          >
                            Cambiar contraseña
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {this.state.account && (
                  <div className={"profileContentBaseInfo"}>
                    <div className={"firstContent"}>
                      <h5 className={"profileTitle"}>Datos bancarios</h5>
                      <div className={"halfPart"}>
                        <div className={"profileInfoContainer"}>
                          <div className={"profileInfoElement bigSize"}>
                            <label>Compañia</label>
                            <p>{this.props.company.businessName}</p>
                          </div>
                          <div className={"profileInfoElement bigSize"}>
                            <label>Titular</label>
                            <p>{this.props.employee.bankdataholder}</p>
                          </div>
                          <div className={"profileInfoElement bigSize"}>
                            <label>Número de tarjeta</label>
                            <p>{this.props.employee.bankdatacardnumber}</p>
                          </div>
                          <div className={"profileInfoElement bigSize"}>
                            <label>CLABE</label>
                            <p>{this.props.employee.bankdataclabe}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    ...state,
    user: state.user.user,
    employee: state.employee.current,
    company: state.company.current,
  };
}

export default connect(mapStateToProps)(Profile);
