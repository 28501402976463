import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../../../../components/Modal";
import { getPendingLoansForAdmin } from "../../../../../actions/loansAction";

import { setError } from "../../../../../actions/errorAction";
import { setSuccess } from "../../../../../actions/successAction";
import { api } from "../../../../../services/api";
import "./Modals.scss";

export const ApproveLoanModal = ({
  isOpen = false,
  setIsOpen,
  loanId,
  companyId,
}) => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user.user);

  const closeConfirmModal = () => {
    setIsOpen(false);
  };

  const handleSubmit = async () => {
    try {
      // this.setState({
      //   isLoading: true,
      // });
      const { id: adminUserId } = user;
      const response = await api.get(
        "Salvum/services/loans/updateLoanStatusForAdmin?loanId=" +
          loanId +
          "&adminUserId=" +
          adminUserId +
          "&nextStep=true",
        {
          params: {
            loanId,
            adminUserId: adminUserId,
            nextStep: true,
          },
        }
      );
      if (response.error) {
        dispatch(
          setError(
            response.message || "Ha occurido un error al autorizar el préstamo."
          )
        );
      } else {
        dispatch(
          setSuccess(response.message || "Préstamo aprobado exitosamente.")
        );
        await dispatch(getPendingLoansForAdmin({ companyId, adminUserId }));
      }
      closeConfirmModal();
    } catch (error) {
      dispatch(setError("Ha occurido un error en nuestro servidor."));
      closeConfirmModal();
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        handleClose={closeConfirmModal}
        title="Aprobación de préstamo"
      >
        <h4>¿Está seguro de aprobar este préstamo?</h4>

        <div className="buttons-container">
          <button
            className="transition ease-in-out border-black border rounded-md p-2 hover:bg-gray-50"
            onClick={closeConfirmModal}
            type="button"
            style={{ marginRight: "8px" }}
          >
            En otro momento
          </button>
          <button
            className="transition bg-green-500 text-white ease-in-out border-black border rounded-md p-2 hover:bg-green-700"
            type="submit"
            onClick={handleSubmit}
          >
            Si, aprobar.
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ApproveLoanModal;
