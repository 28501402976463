/* eslint-disable */
import React, { Component } from "react";
import { connect } from "react-redux";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import DeleteIcon from "@mui/icons-material/Delete";
import Chart from "react-google-charts";
import "./EmployeeSavings.scss";
// import SavingResume from './SavingResume/SavingResume'
import { SavingsResumev2 } from "./SavingResume/SavingResumev2";
import StartSaving from "./StartSaving";
import { getCompanyById } from "../../../actions/companyAction";
import { setError } from "../../../actions/errorAction";
import { setSuccess } from "../../../actions/successAction";
import { getSavingsSlots } from "../../../actions/savingsAction";
import SavingAmountInput from "./SavingAmountInput";
import {
  getEmployeeByUserId,
  getEmployeeNumberDetails,
} from "../../../actions/employeeAction";
import { getInfoLoanByemployeeIdAndCompanyId } from "../../../actions/loansAction";
import _ from "lodash";

import { api } from "../../../services/api";

import { instance, config } from "../../../services/axios";

import { Redirect } from "react-router-dom";

import moment from "moment";

export class EmployeeSavings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeState: 0,
      beneficiaries: [
        {
          fullName: "",
          phone: "",
          relationship: "",
          percentage: 0,
        },
      ],
      invalidPhone: false,
      errorOnBeneficiaries: false,
      errorBeneficiaryPercentage: false,
      hasSavings: false,
      showStart: true,
      startNow: false,
      redirect: false,
      finishRegister: false,
      newSaving: {
        Inmediato: { amount: null, annualProfit: 0 },
        ["1 Mes"]: { amount: null, annualProfit: 0 },
        ["3 Meses"]: { amount: null, annualProfit: 0 },
        ["6 Meses"]: { amount: null, annualProfit: 0 },
        ["1 Año"]: { amount: null, annualProfit: 0 },
        ["2 Años"]: { amount: null, annualProfit: 0 },
        ["Ilimitado"]: { amount: null, annualProfit: 0 },
      },
    };
  }

  savingTypes = {
    Inmediato: {
      name: "Inmediato",
      label: "Inmediato",
      color: "#1d3557",
      dotClass: "dot-black",
    },
    ["1 Mes"]: {
      name: "1 Mes",
      label: "1 Mes",
      color: "#048aa8",
      dotClass: "dot-blue",
    },
    ["3 Meses"]: {
      name: "3 Meses",
      label: "3 Meses",
      color: "#6cb6f5",
      dotClass: "dot-softblue",
    },
    ["6 Meses"]: {
      name: "6 Meses",
      label: "6 meses",
      color: "#ef476f",
      dotClass: "dot-red",
    },
    ["1 Año"]: {
      name: "1 Año",
      label: "1 Año",
      color: "#fa7e58",
      dotClass: "dot-orange",
    },
    "2 Años": {
      name: "2 Años",
      label: "2 Años",
      color: "#f5a61d",
      dotClass: "dot-yellow",
    },
    Ilimitado: {
      name: "Ilimitado",
      label: "Ilimitado",
      color: "#15db93",
      dotClass: "dot-green",
    },
  };

  chartColors = [
    { color: "#1d3557", dotClass: "dot-black" },
    { color: "#048aa8", dotClass: "dot-blue" },
    { color: "#6cb6f5", dotClass: "dot-softblue" },
    { color: "#ef476f", dotClass: "dot-red" },
    { color: "#fa7e58", dotClass: "dot-orange" },
    { color: "#f5a61d", dotClass: "dot-yellow" },
    { color: "#15db93", dotClass: "dot-green" },
  ];

  componentDidMount = () => {
    const { dispatch } = this.props;

    if (this.props.user) {
      this.props.dispatch(
        getEmployeeByUserId(this.props.user.id, this.props.user.companyId)
      );

      this.props.dispatch(getCompanyById(this.props.user.companyId));

      dispatch(
        getInfoLoanByemployeeIdAndCompanyId(
          this.props.user.id,
          this.props.user.companyId
        )
      );
      dispatch(
        getEmployeeNumberDetails({
          employeeId: this.props.user.id,
          companyId: this.props.user.companyId,
        })
      );

      if (this.props.user.id && this.props.user.companyId) {
        dispatch(
          getSavingsSlots({
            employeeId: this.props.user.id,
            companyId: this.props.user.companyId,
          })
        );
      }
    }
  };

  componentDidUpdate = (prevProps) => {
    if (!prevProps.savings.created && this.props.savings.created) {
      this.props.dispatch(getEmployeeByUserId(this.props.user.companyId));
    }
    if (prevProps.employee) {
      if (prevProps.employee.savings) {
        if (
          prevProps.employee.savings.length === 0 &&
          this.props.employee.savings.length > 0
        ) {
          this.setState({
            hasSavings: true,
            showStart: false,
          });
        }
      }
    }
  };

  startNow = () => {
    this.setState({
      ...this.state,
      showStart: false,
      startNow: true,
    });
  };

  requestNewSaving = async () => {
    try {
      // Adapting to previous endpoint format requested. Remove when this endpoint is migrated
      // to the new Java API
      const { userid, companyid } = this.props.employee;
      const formatSavingTypes = () => {
        const originalObj = this.state.newSaving;

        return {
          now: originalObj["Inmediato"].amount,
          month_1: originalObj["1 Mes"].amount,
          month_3: originalObj["3 Meses"].amount,
          month_6: originalObj["6 Meses"].amount,
          month_12: originalObj["1 Año"].amount,
          month_24: originalObj["2 Años"].amount,
          unlimit: originalObj["Ilimitado"].amount,
        };
      };

      const newSaving = {
        employeeId: userid,
        companyId: companyid,
        savingAmounts: Object.entries(formatSavingTypes()).map((entry) => ({
          type: entry[0],
          amount: entry[1],
        })),
        beneficiaries: this.state.beneficiaries,
      };

      const response = await instance.post(
        "Salvum/services/savings/initialSavingRequest",
        newSaving,
        config
      );

      if (response.data.updated) {
        this.props.dispatch(setSuccess(response.data.message));
        this.setState({
          finishRegister: true,
          startNow: false,
        });
        await this.printRequest();
      } else {
        this.props.dispatch(setError(response.data.message));
      }
    } catch (error) {
      const response = error.response;
      this.props.dispatch(setError(response?.data?.message));
    }
  };

  printRequest = async () => {
    try {
      const { userid, companyid } = this.props.employee;
      const response = await api.get(
        `Salvum/services/users/sendSavingRules?companyId=${companyid}&employeeId=${userid}`
      );

      if (response.error) {
        this.props.dispatch(
          setError(
            response.data.message ||
              "Ha ocurrido un error al intentar enviar tu solicitud a tu correo, pero tus ahorros han sido creados."
          )
        );
      } else {
        this.props.dispatch(
          setSuccess(
            response.data.message ||
              "Hemos enviado un correo con tu solicitud a tu email."
          )
        );
      }
    } catch (error) {
      this.props.dispatch(setError(error.message));
    }
  };

  getSteps() {
    return ["", "", "", ""];
  }

  getTotalSavingsAmount = () => {
    const total = Object.values(this.state.newSaving).reduce(
      (prev, current) => {
        if (current.amount) {
          return prev + current.amount;
        }

        return prev;
      },
      0
    );

    return _.round(Number(total), 2);
  };

  getAnnualProfit = (savingName, yieldRate) => {
    const savingAmount = this.state.newSaving[savingName].amount;
    const percentage = yieldRate / 100;

    return _.round(savingAmount * percentage, 2);
  };

  getTotalAnnualProfit = () => {
    const total = Object.values(this.state.newSaving).reduce(
      (prev, current) => {
        if (current.annualProfit) {
          return prev + Number(current.annualProfit);
        }

        return prev;
      },
      0
    );

    return _.round(total || 0, 2).toLocaleString("en-US");
  };

  getPayrollWithoutSavings = () => {
    const multiplier =
      this.props.employee.payrolltype === 3
        ? 1
        : this.props.employee.payrolltype === 2
        ? 2
        : 4;

    const payroll = parseFloat(this.props.employee.netpay / multiplier);
    const totalAnnualProfit = this.getTotalSavingsAmount();

    const result = _.round(payroll - totalAnnualProfit, 2);

    return result < 0 ? 0 : result;
  };

  onChangeSavingAmount = (savingName, amount, totalRate) => {
    const { typeOfPayroll } = this.props.employeeDetails;
    let newAmount = parseFloat(amount);
    const currentAmount = this.state.newSaving[savingName].amount;
    const totalAmount =
      this.getTotalSavingsAmount() - currentAmount + newAmount;
    if (totalAmount > parseFloat(this.props.employee.payroll)) {
      newAmount = currentAmount;
      alert(
        "Supera el máximo salarial, favor de reducir la cantidad ingresada"
      );
    }

    const percentage = totalRate / 100;

    const monthCalculation = 1 / 12;

    const formulaElevated = (1 + percentage) ** monthCalculation - 1;

    const weeklyFormula = amount * (4 + 2.5 * formulaElevated);

    const biWeeklyFormula = amount * (2 + 1.5 * formulaElevated);

    const monthFornula = amount * (formulaElevated + 1);

    const leftHandOperation =
      typeOfPayroll === 3
        ? monthFornula
        : typeOfPayroll === 2
        ? biWeeklyFormula
        : weeklyFormula;

    const rightHandOperation = percentage / formulaElevated;

    const annualProfit = leftHandOperation * rightHandOperation;

    const profit = _.round(annualProfit, 2);

    // const annualProfit = (weekly) * amount

    this.setState({
      newSaving: {
        ...this.state.newSaving,
        [savingName]: {
          amount: newAmount,
          annualProfit: profit,
        },
      },
    });
  };

  onChangeBeneficiary = (index, propertyName, value) => {
    const beneficiaries = [...this.state.beneficiaries];
    const beneficiary = { ...beneficiaries[index] };
    if (propertyName == "percentage") {
      const currentPercentage = beneficiary[propertyName];
      const newPercentage = parseFloat(value);
      const totalPercentage =
        beneficiaries.reduce((a, b) => a + parseFloat(b.percentage), 0) -
        currentPercentage +
        newPercentage;

      beneficiary[propertyName] = newPercentage;

      if (totalPercentage > 100) {
        alert("Se ha superado el 100%");
        return;
      }
    } else {
      beneficiary[propertyName] = value;
    }

    beneficiaries[index] = beneficiary;
    this.setState({
      beneficiaries: [...beneficiaries],
    });
  };

  numberInputOnWheelPreventChange = (e) => {
    // Prevent the input value change
    e.target.blur();

    // Prevent the page/container scrolling
    e.stopPropagation();

    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  buildBeneficiariesChartOptions = () => {
    const totalPercentage = this.state.beneficiaries.reduce(
      (a, b) => a + parseFloat(b.percentage),
      0
    );
    const options = [
      ["Tiempo de nómina", "Porcentaje"],
      ...this.state.beneficiaries.map((b) => [b.fullName, b.percentage]),
      ["Porcentaje restante", 100 - totalPercentage],
    ];
    return options;
  };

  getPercentOfPayroll = (savingName, slot) => {
    const currentAmount = this.state.newSaving[savingName].amount;
    const multiplier =
      this.props.employee.payrolltype === 3
        ? 1
        : this.props.employee.payrolltype === 2
        ? 2
        : 4;
    const totalAmount = parseFloat(this.props.employee.netpay / multiplier);
    if (totalAmount === 0) return 0;
    return _.round((currentAmount * 100) / totalAmount, 2);
  };

  addBeneficiary = () => {
    this.setState({
      beneficiaries: [
        ...this.state.beneficiaries,
        {
          fullName: "",
          phone: "",
          relationship: "",
          percentage: 0,
        },
      ],
    });
  };

  handleContinue = () => {
    if (this.state.activeState === 1) {
      const firstBeneficiary = this.state.beneficiaries[0];
      const totalPercentage = this.state.beneficiaries.reduce(
        (a, b) => a + parseFloat(b.percentage),
        0
      );
      if (
        firstBeneficiary.fullName === "" ||
        firstBeneficiary.phone === "" ||
        firstBeneficiary.relationship === "" ||
        firstBeneficiary.percentage === 0
      ) {
        this.setState({ errorOnBeneficiaries: true });
        return;
      } else if (this.state.errorOnBeneficiaries) {
        this.setState({ errorOnBeneficiaries: false });
      }

      const regexp =
        /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

      if (!regexp.test(firstBeneficiary.phone)) {
        this.setState({ invalidPhone: true });
        return;
      } else {
        this.setState({ invalidPhone: false });
      }

      if (totalPercentage !== 100) {
        this.setState({ errorBeneficiaryPercentage: true });
        return;
      } else {
        this.setState({ errorBeneficiaryPercentage: false });
      }
    }
    this.setState({ activeState: this.state.activeState + 1 });
  };

  removeBeneficiary = (index) => {
    const beneficiaries = [...this.state.beneficiaries];
    beneficiaries.splice(index);
    this.setState({
      beneficiaries: [...beneficiaries],
    });
  };

  handleRedirect = () => {
    this.setState({ redirect: true });
  };

  render() {
    const steps = this.getSteps();

    const calculateSlotsAmounts = () => {
      if (!this.props.savingSlots?.slots) return;
      return this.props.savingSlots.slots.map((slot) => {
        const slotName = slot.savingBankTypeIdFormatted;
        const slotAmount =
          this.state.newSaving[slot.savingBankTypeIdFormatted].amount;

        const amountFormatted = slotAmount < 0 ? 0 : slotAmount;
        return [slotName, amountFormatted];
      });
    };

    const date = new Date();

    const { savingSlots } = this.props;

    if (!savingSlots?.isEmployeeBrandNew) {
      return <SavingsResumev2 />;
    }

    if (savingSlots?.isEmployeeBrandNew && this.state.showStart) {
      return <StartSaving onStart={() => this.startNow()} />;
    }

    if (this.state.redirect) {
      return <Redirect to="/employee/" />;
    }

    if (this.state.finishRegister) {
      return (
        <div className="startSavings">
          <div className={"savingsContainer"}>
            <ul className={"breadcrumbs"}>
              <li>Inicio</li>
              <li>Ahorros</li>
              <li>Comenzar a ahorrar</li>
            </ul>
            <div className={"savingsHero"}>
              <div className={"content"}>
                <p>
                  <span className={"text-style-2"}>
                    ¡Registro de ahorros completado!
                  </span>
                  <span className={"contentTitle"}>
                    Hemos completado tu proceso de registro de ahorros con los
                    datos que acabas de proporcionarnos.
                  </span>
                  <p>
                    Ahora puedes dirigirte a la sección de Inicio para comenzar
                    tu aventura en Salvum. Se te ha enviado un correo con la
                    información que acabas de solicitarnos.
                  </p>

                  <div>
                    <button
                      className="mt-10 mr-4 rounded-2xl transition ease-in-out py-1 px-4 bg-white text-black cursor-pointer hover:bg-gray-50 w-fit"
                      onClick={this.handleRedirect}
                    >
                      Ir a Inicio
                    </button>
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    }

    if (this.state.startNow) {
      return (
        <div>
          {/* Parte superior */}
          <div className={"startSavings"}>
            {this.state.activeState == 0 && (
              <div className={"savingsContainer"}>
                <ul className={"breadcrumbs"}>
                  <li>Inicio</li>
                  <li>Ahorros</li>
                  <li>Comenzar a ahorrar</li>
                </ul>
                <div className={"savingsHero"}>
                  <div className={"content"}>
                    <p>
                      <span className={"text-style-2"}>Comenzar a ahorrar</span>
                      <span className={"contentTitle"}>
                        PASO 1 - Aportación
                      </span>
                      Anota la cantidad que deseas aportar de tu nómina en los
                      diferentes Tipos de Ahorro. <br />
                      Recuerda que puedes ahorrar hasta el 100% de tu salario
                      disponible&nbsp;
                    </p>
                  </div>
                </div>
              </div>
            )}
            {this.state.activeState == 1 && (
              <div className={"savingsContainer"}>
                <ul className={"breadcrumbs"}>
                  <li>Inicio</li>
                  <li>Ahorros</li>
                  <li>Comenzar a ahorrar</li>
                </ul>
                <div className={"savingsHero"}>
                  <div className={"content"}>
                    <p>
                      <span className={"text-style-2"}>Comenzar a ahorrar</span>
                      <span className={"contentTitle"}>
                        PASO 2 - Beneficiarios
                      </span>
                      Ahora indícanos quienes serían tus beneficiarios, puede
                      ser cualquier persona de tu confianza que podrá gozar de
                      tus ahorros en caso de fallecimiento.&nbsp;
                      <span className={"text-style-1"}>
                        NOTA: Debes de utilizar el 100%
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            )}
            {this.state.activeState == 2 && (
              <div className={"savingsContainer"}>
                <ul className={"breadcrumbs"}>
                  <li>Inicio</li>
                  <li>Ahorros</li>
                  <li>Comenzar a ahorrar</li>
                </ul>
                <div className={"savingsHero"}>
                  <div className={"content"}>
                    <p>
                      <span className={"text-style-2"}>Comenzar a ahorrar</span>
                      <span className={"contentTitle"}>
                        PASO 3 - Datos de solicitante
                      </span>
                      Revisa que tus datos personales sean correctos, si tu
                      información personal no coincide te pedimos que acudas al
                      departamento de RH de tu empresa para que modifiquen tu
                      información personal.&nbsp;
                    </p>
                  </div>
                </div>
              </div>
            )}
            {this.state.activeState == 3 && (
              <div className={"savingsContainer"}>
                <ul className={"breadcrumbs"}>
                  <li>Inicio</li>
                  <li>Ahorros</li>
                  <li>Comenzar a ahorrar</li>
                </ul>
                <div className={"savingsHero"}>
                  <div className={"content"}>
                    <p>
                      <span className={"text-style-2"}>Comenzar a ahorrar</span>
                      <span className={"contentTitle"}>
                        PASO 4 - Información de tu ahorro
                      </span>
                      Antes de enviar tu solicitud revisa que toda la
                      información capturada sea la correcta. Una vez enviada,
                      ésta será revisada por alguno de tus superiores.&nbsp;
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Navegación */}
          <div className={"startSavings-2"}>
            <div className={"navigation"}>
              <div>
                <button
                  className={"button-back"}
                  onClick={() =>
                    this.setState({ activeState: this.state.activeState - 1 })
                  }
                >
                  &#60; Regresar
                </button>
              </div>
              <div className={"stepper-lane"}>
                <Stepper activeStep={this.state.activeState} alternativeLabel>
                  {steps.map((label, index) => (
                    <Step key={index}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </div>
              {this.state.activeState != 3 && (
                <div>
                  <button
                    className={"button-next"}
                    onClick={this.handleContinue}
                  >
                    Continuar
                  </button>
                </div>
              )}
              {this.state.activeState == 3 && (
                <div>
                  <button
                    className={"button-next"}
                    onClick={this.requestNewSaving}
                  >
                    Enviar solicitud.
                  </button>
                </div>
              )}
            </div>

            {/* Contenido */}
            {this.state.activeState == 0 && (
              <div className={"form-savings"}>
                <div className={"up-title"}>Tipos de ahorros</div>
                <div className={"up-warning"}>
                  <b>!</b> El mínimo de aportación a cada tipo de ahorro que
                  desees utilizar debe ser mayor a <b>$200.</b>
                </div>
                <div className={"paso-1"}>
                  <div className={"form-left"}>
                    <div className={"form-message"}>
                      Tasas de intereses actualizadas el día 01/
                      {date.getMonth() + 1}/{date.getFullYear()} por BANXICO
                    </div>
                    {this.props.savingSlots.slots.map((slot) => (
                      <SavingAmountInput
                        key={slot.savingBankTypeIdFormatted}
                        label={slot.savingBankTypeIdFormatted}
                        totalRate={slot.totalRate}
                        className="input-savings"
                        savingAmount={
                          this.state.newSaving[slot.savingBankTypeIdFormatted]
                            .amount
                        }
                        savingType={slot.savingBankTypeIdFormatted}
                        onChange={(amount) =>
                          this.onChangeSavingAmount(
                            slot.savingBankTypeIdFormatted,
                            amount,
                            slot.totalRate
                          )
                        }
                        annualProfit={this.state.newSaving[
                          slot.savingBankTypeIdFormatted
                        ].annualProfit.toLocaleString("en-US")}
                      />
                    ))}
                  </div>
                  <div className={"info-right"}>
                    <span className={"comment"}>
                      Porcentaje total utilizado de nómina
                    </span>
                    <div>
                      <Chart
                        width={"100%"}
                        height={"100%"}
                        chartType="PieChart"
                        loader={<div>Cargando..</div>}
                        data={[
                          ["Tiempo de nómina", "Porcentaje"],
                          ...calculateSlotsAmounts(),
                          ["Salario restante", this.getPayrollWithoutSavings()],
                        ]}
                        options={{
                          pieHole: 0.7,
                          legend: "none",
                          pieSliceText: "none",
                          colors: [
                            ...this.props.savingSlots.slots.map(
                              (slot) =>
                                this.savingTypes[slot.savingBankTypeIdFormatted]
                                  .color
                            ),
                            "#fafafa",
                          ],
                        }}
                        rootProps={{ "data-testid": "3" }}
                      />
                    </div>

                    <div className={"rightChartSaving"}>
                      <span className={"topCurrencySymbol"}>$</span>
                      <span className={"topAmount"}>
                        {this.getTotalSavingsAmount()}
                      </span>
                      <span className={"bottomAmount"}>
                        /$
                        {this.props.employee.netpay /
                          (this.props.employee.payrolltype === 3
                            ? 1
                            : this.props.employee.payrolltype === 2
                            ? 2
                            : 4)}
                      </span>
                    </div>
                    <div className={"border-option"}></div>
                    <span className={"comment"}>Tipos de ahorros</span>
                    {this.props.savingSlots.slots.map((slot) => (
                      <div
                        className={"rightSavingTypes"}
                        key={`percentage-of-payroll-${slot.savingBankTypeIdFormatted}`}
                      >
                        <span
                          className={`dot ${
                            this.savingTypes[slot.savingBankTypeIdFormatted]
                              .dotClass
                          }`}
                        ></span>
                        <span className={"rightSavingTypesTitle"}>
                          {
                            this.savingTypes[slot.savingBankTypeIdFormatted]
                              .label
                          }
                        </span>
                        &nbsp;
                        <span className={"rightSavingTypesAmount"}>
                          ${" "}
                          {
                            this.state.newSaving[slot.savingBankTypeIdFormatted]
                              .amount
                          }
                        </span>
                      </div>
                    ))}
                    <div className={"border-option"}></div>
                    <span className={"comment"}>
                      Total de ganancias por año
                    </span>
                    <span className={"total-gain"}>
                      <span className={"currencySymbol"}>$</span>
                      {this.getTotalAnnualProfit()}
                    </span>
                  </div>
                </div>
              </div>
            )}

            {this.state.activeState == 1 && (
              <div className={"form-savings"}>
                <div className={"up-title"}>Mis beneficiarios</div>
                <div className={"paso-1"}>
                  <div className={"form-left form-left-bord-gray"}>
                    <div className={"form-message"}>Máximo 3 beneficiarios</div>
                    {this.state.errorOnBeneficiaries && (
                      <p className="ml-10 text-red-600 text-base">
                        Debes llenar toda la información de al menos 1
                        beneficiario
                      </p>
                    )}
                    {this.state.errorBeneficiaryPercentage && (
                      <p className="ml-10 text-red-600 text-base">
                        Para continuar, por favor distribuye el 100% entre tu(s)
                        beneficiarios.
                      </p>
                    )}

                    {this.state.invalidPhone && (
                      <p className="ml-10 text-red-600 text-base">
                        El telefono proporcionado es invalido. Por favor,
                        introduce un numero de telefono válido.
                      </p>
                    )}

                    {
                      //No he encontrado solución para este input
                      this.state.beneficiaries.map((beneficiary, index) => (
                        <div
                          className={"form-row"}
                          style={{ BorderBottom: "none" }}
                          key={`beneficiary-input-${index}`}
                        >
                          <div className={"off-item "}>
                            <div className={"item2"}>
                              <span className={"title"}>
                                Beneficiario {index + 1}{" "}
                              </span>
                              <span className={"value value-data"}>
                                <input
                                  className={"input-info"}
                                  placeholder={"Nombre del beneficiario"}
                                  value={beneficiary.fullName}
                                  onChange={(e) =>
                                    this.onChangeBeneficiary(
                                      index,
                                      "fullName",
                                      e.target.value
                                    )
                                  }
                                />
                              </span>
                              <span className={"title"}>Parentesco</span>
                              <span className={"value value-data"}>
                                <input
                                  className={"input-info"}
                                  placeholder={"Familiar, Amigo, etc."}
                                  value={beneficiary.relationship}
                                  onChange={(e) =>
                                    this.onChangeBeneficiary(
                                      index,
                                      "relationship",
                                      e.target.value
                                    )
                                  }
                                />
                              </span>
                            </div>
                          </div>
                          <div className={"off-item"}>
                            <div className={"item2"}>
                              <span className={"title"}>Contacto</span>
                              <span className={"value value-data"}>
                                <input
                                  className={"input-info"}
                                  placeholder={"Número de teléfono"}
                                  value={beneficiary.phone}
                                  onChange={(e) =>
                                    this.onChangeBeneficiary(
                                      index,
                                      "phone",
                                      e.target.value
                                    )
                                  }
                                />
                              </span>
                              <span className={"title"}>% de beneficio</span>
                              <span className={"value value-data input-pack"}>
                                <input
                                  className={"input-percent"}
                                  placeholder={"porcentaje"}
                                  type="number"
                                  onWheel={this.numberInputOnWheelPreventChange}
                                  min="0"
                                  max="100"
                                  step="1"
                                  value={beneficiary.percentage}
                                  onChange={(e) =>
                                    this.onChangeBeneficiary(
                                      index,
                                      "percentage",
                                      e.target.value
                                    )
                                  }
                                />
                                {this.state.beneficiaries.length > 1 && (
                                  <button
                                    className={"input-delete"}
                                    onClick={() =>
                                      this.removeBeneficiary(index)
                                    }
                                  >
                                    <DeleteIcon
                                      className={"red-delete"}
                                    ></DeleteIcon>
                                  </button>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    }
                    {this.state.beneficiaries.length < 3 && (
                      <div className={"form-row"}>
                        <div className={"off-item pad-left"}>
                          <div className={"item"}>
                            <button
                              className={"button-more"}
                              onClick={() => this.addBeneficiary()}
                            >
                              + Agregar otro beneficiario
                            </button>
                          </div>
                        </div>
                        <div className={"off-item pad-left"}>&nbsp;</div>
                      </div>
                    )}
                  </div>
                  <div className={"info-right"}>
                    <span className={"comment"}>
                      Porcentaje total designado
                    </span>
                    <div>
                      <Chart
                        width={"100%"}
                        height={"100%"}
                        chartType="PieChart"
                        loader={<div>Cargando..</div>}
                        data={this.buildBeneficiariesChartOptions()}
                        options={{
                          pieHole: 0.7,
                          legend: "none",
                          pieSliceText: "none",
                          colors: [
                            ...this.state.beneficiaries.map(
                              (b, i) => this.chartColors[i].color
                            ),
                            "#d9d9d9",
                          ],
                        }}
                        rootProps={{ "data-testid": "3" }}
                      />
                    </div>

                    <div className={"border-option"}></div>
                    <span className={"comment"}>Beneficiarios</span>
                    {this.state.beneficiaries.map((item, index) => (
                      <div
                        className={""}
                        key={`beneficiary-percentage-${index}`}
                      >
                        <span
                          className={`dot ${this.chartColors[index].dotClass}`}
                        />
                        {item.fullName && item.fullName.length > 0
                          ? item.fullName
                          : "[ Sin Nombre ]"}
                        &nbsp;
                        <br />
                        {item.percentage}%
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}

            {this.state.activeState == 2 && this.props.company && (
              <div className={"form-savings"}>
                <div className={"up-title"}>Mis datos personales</div>
                <div className={"info-title"}>
                  <b>IMPORTANTE</b>: Si tus datos personales no coinciden te
                  pedimos detener tu solicitud de ahorro, acudas al departamento
                  de RH (Recursos Humanos) de la empresa donde laboras para que
                  modifiquen tu información personal para que ésta sea la
                  indicada.
                </div>
                <div className={"paso-3"}>
                  <div className={"form-left form-left-bord-green"}>
                    <div className={"form-row"}>
                      <div className={"off-item pad-left"}>
                        <div className={"item"}>
                          <div className={"form-message"}>
                            Información personal
                          </div>
                          <span className={"title"}>Nombres</span>
                          <span className={"value value-data"}>
                            <input
                              className={"input-info"}
                              placeholder={"Nombres"}
                              value={this.props.employee.firstname}
                              disabled
                            />
                          </span>
                          <span className={"title"}>Teléfono registrado</span>
                          <span className={"value value-data"}>
                            <input
                              className={"input-info"}
                              placeholder={"Teléfono"}
                              value={this.props.employee.phone}
                              disabled
                            />
                          </span>
                          <span className={"title"}>Número de empleado</span>
                          <span className={"value value-data"}>
                            <input
                              className={"input-info"}
                              placeholder={"Número"}
                              value={this.props.employee.employeeidentifier}
                              disabled
                            />
                          </span>
                          <span className={"title"}>
                            Localidad donde labora
                          </span>
                          <span className={"value value-data"}>
                            <input
                              className={"input-info"}
                              placeholder={"Localidad"}
                              value={this.props.employee.worklocation}
                              disabled
                            />
                          </span>
                          <span className={"title"}>Fecha de ingreso</span>
                          <span className={"value value-data"}>
                            <input
                              className={"input-info"}
                              placeholder={"Fecha"}
                              value={moment.unix(this.props.employee.admissionat).format("DD/MM/YYYY")}
                              disabled
                            />
                          </span>
                        </div>
                      </div>
                      <div className={"off-item"}>
                        <div className={"item"}>
                          <div className={"form-message"}>&nbsp;</div>
                          <span className={"title"}>Apellidos</span>
                          <span className={"value value-data"}>
                            <input
                              className={"input-info"}
                              placeholder={"Apellidos"}
                              value={this.props.employee.lastname}
                              disabled
                            />
                          </span>
                          <span className={"title"}>
                            Correo electrónico registrado
                          </span>
                          <span className={"value value-data"}>
                            <input
                              className={"input-info"}
                              placeholder={"Correo electrónico"}
                              value={this.props.employee.email}
                              disabled
                            />
                          </span>
                          <span className={"title"}>
                            Nombre de la empresa que labora
                          </span>
                          <span className={"value value-data"}>
                            <input
                              className={"input-info"}
                              placeholder={"Empresa"}
                              value={this.props.company.name}
                              disabled
                            />
                          </span>
                          <span className={"title"}>
                            Teléfono de la empresa
                          </span>
                          <span className={"value value-data"}>
                            <input
                              className={"input-info"}
                              placeholder={"Teléfono de la empresa"}
                              value={this.props.company.phone}
                              disabled
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={"form-row"}>
                      <div className={"off-item pad-left"}>
                        <div className={"item"}>
                          <div className={"form-message"}>
                            Información bancaria
                          </div>

                          <span className={"title"}>Compañia</span>
                          <span className={"value value-data"}>
                            <input
                              className={"input-info"}
                              placeholder={"Nombre de compañia"}
                              value={this.props.employee.bankname}
                              disabled
                            />
                          </span>
                          <span className={"title"}>Número de tarjeta</span>
                          <span className={"value value-data"}>
                            <input
                              className={"input-info"}
                              placeholder={"Número de tarjeta"}
                              value={this.props.employee.bankdatacardnumber}
                              disabled
                            />
                          </span>
                        </div>
                      </div>
                      <div className={"off-item"}>
                        <div className={"item"}>
                          <div className={"form-message"}>&nbsp;</div>
                          <span className={"title"}>Titular</span>
                          <span className={"value value-data"}>
                            <input
                              className={"input-info"}
                              placeholder={"Titular"}
                              value={this.props.employee.bankdataholder}
                              disabled
                            />
                          </span>
                          <span className={"title"}>CLABE</span>
                          <span className={"value value-data"}>
                            <input
                              className={"input-info"}
                              placeholder={"Número CLABE"}
                              value={this.props.employee.bankdataclabe}
                              disabled
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {this.state.activeState == 3 && (
              <div className={"form-savings"}>
                <div className={"up-title"}>Mis ahorros</div>
                <div className={"paso-3"}>
                  <div className={"form-left form-left-bord-green"}>
                    <div className={"form-row"}>
                      <div className={"off-item pad-left"}>
                        <div className={"item"}>
                          <div className={"form-message2"}>Aportación</div>
                          <span className={"title"}>
                            Porcentaje total utilizado de nómina
                          </span>
                          <div>
                            <Chart
                              width={"250px"}
                              height={"250px"}
                              chartType="PieChart"
                              loader={<div>Cargando..</div>}
                              data={[
                                ["Tiempo de nómina", "Porcentaje"],
                                ...this.props.savingSlots.slots.map((slot) => [
                                  this.savingTypes[
                                    slot.savingBankTypeIdFormatted
                                  ].label,
                                  this.state.newSaving[
                                    slot.savingBankTypeIdFormatted
                                  ].amount,
                                ]),
                                [
                                  "Salario restante",
                                  this.getPayrollWithoutSavings(),
                                ],
                              ]}
                              options={{
                                pieHole: 0.7,
                                legend: "none",
                                pieSliceText: "none",
                                colors: [
                                  ...this.props.savingSlots.slots.map(
                                    (slot) =>
                                      this.savingTypes[
                                        slot.savingBankTypeIdFormatted
                                      ].color
                                  ),
                                  "#d9d9d9",
                                ],
                              }}
                              rootProps={{ "data-testid": "3" }}
                            />
                          </div>

                          <div className={"chartAmount"}>
                            <span className={"topCurrencySymbol"}>$</span>
                            <span className={"topAmount"}>
                              {" "}
                              {this.getTotalSavingsAmount()}
                            </span>
                            <span className={"bottomAmount"}>
                              /$
                              {this.props.employee.netpay /
                                (this.props.employee.payrolltype === 3
                                  ? 1
                                  : this.props.employee.payrolltype === 2
                                  ? 2
                                  : 4)}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={"off-item"}>
                        <div className={"item"}>
                          <div className={"form-message"}>&nbsp;</div>
                          <span className={"title"}>Tipos de ahorros</span>
                          {this.props.savingSlots.slots.map((slot) => (
                            <div
                              key={`percentage-of-payroll-${slot.savingBankTypeIdFormatted}`}
                            >
                              <span
                                className={`dot ${
                                  this.savingTypes[
                                    slot.savingBankTypeIdFormatted
                                  ].dotClass
                                }`}
                              ></span>
                              <b>
                                {
                                  this.savingTypes[
                                    slot.savingBankTypeIdFormatted
                                  ].label
                                }
                              </b>
                              &nbsp;
                              {this.getPercentOfPayroll(
                                slot.savingBankTypeIdFormatted,
                                slot
                              )}
                              % ($
                              {this.state.newSaving[
                                slot.savingBankTypeIdFormatted
                              ].amount || 0}
                              )
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className={"off-item"}>
                        <div className={"item"}>
                          <div className={"form-message"}>&nbsp;</div>
                          <span className={"title"}>
                            Total de ganancias por año
                          </span>
                          <span className={"total-gain"}>
                            ${this.getTotalAnnualProfit()}
                          </span>
                        </div>
                      </div>
                      <div className={"border-option"}></div>
                    </div>
                    <div className={"form-message2"}>Beneficiarios</div>
                    <div className={"form-row"}>
                      <div className={"off-item pad-left"}>
                        <div className={"item"}>
                          <span className={"title"}>Beneficiarios</span>
                          <span className={"data-focus"}>
                            {this.state.beneficiaries[0].fullName}
                          </span>
                          {this.state.beneficiaries[1] && (
                            <span className={"data-focus"}>
                              {this.state.beneficiaries[1].fullName}
                            </span>
                          )}
                          {this.state.beneficiaries[2] && (
                            <span className={"data-focus"}>
                              {this.state.beneficiaries[2].fullName}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={"off-item"}>
                        <div className={"item"}>
                          <span className={"title"}>Contacto</span>
                          <span className={"data-focus"}>
                            {this.state.beneficiaries[0].phone}
                          </span>
                          {this.state.beneficiaries[1] && (
                            <span className={"data-focus"}>
                              {this.state.beneficiaries[1].phone}
                            </span>
                          )}
                          {this.state.beneficiaries[2] && (
                            <span className={"data-focus"}>
                              {this.state.beneficiaries[2].phone}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={"off-item"}>
                        <div className={"item"}>
                          <span className={"title"}>Parentesco</span>
                          <span className={"data-focus"}>
                            {this.state.beneficiaries[0].relationship}
                          </span>
                          {this.state.beneficiaries[1] && (
                            <span className={"data-focus"}>
                              {this.state.beneficiaries[1].relationship}
                            </span>
                          )}
                          {this.state.beneficiaries[2] && (
                            <span className={"data-focus"}>
                              {this.state.beneficiaries[2].relationship}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={"off-item"}>
                        <div className={"item"}>
                          <span className={"title"}>% de beneficio</span>
                          <span className={"data-focus"}>
                            {this.state.beneficiaries[0].percentage}%
                          </span>
                          {this.state.beneficiaries[1] && (
                            <span className={"data-focus"}>
                              {this.state.beneficiaries[1].percentage}%
                            </span>
                          )}
                          {this.state.beneficiaries[2] && (
                            <span className={"data-focus"}>
                              {this.state.beneficiaries[2].percentage}%
                            </span>
                          )}
                        </div>
                      </div>
                      <div className={"border-option"}></div>
                    </div>
                    <div className={"form-row"}>
                      <div className={"off-item pad-left"}>
                        <div className={"item"}>
                          <div className={"form-message2"}>
                            Datos del solicitante
                          </div>
                          <div className={"sub-title"}>
                            Información personal
                          </div>
                          <span className={"title"}>Nombres</span>
                          <span className={"data-focus"}>
                            {this.props.employee.firstname}{" "}
                          </span>
                          <span className={"title"}>Número de empleado</span>
                          <span className={"data-focus"}>
                            {this.props.employee.employeeidentifier}
                          </span>
                          <span className={"title"}>
                            Teléfono de la empresa
                          </span>
                          <span className={"data-focus"}>
                            {this.props.company.phone}
                          </span>
                          <div className={"form-message2 mt-6"}>
                            Información bancaria
                          </div>
                          <span className={"title"}>Compañia</span>
                          <span className={"data-focus"}>
                            {this.props.employee.bankname}
                          </span>

                          <span className={"data-focus"}>
                            {this.props.employee.businessname}
                          </span>
                          <span className={"title"}>CLABE</span>
                          <span className={"data-focus"}>
                            {this.props.employee.bankdataclabe}
                          </span>
                          <span className={"title"}>Titular</span>
                          <span className={"data-focus"}>
                            {this.props.employee.bankdataholder}
                          </span>
                          <div className={"sub-title"}>&nbsp;</div>
                          <span className={"title"}>Número de tarjeta</span>
                          <span className={"data-focus"}>
                            {this.props.employee.bankdatacardnumber}
                          </span>
                        </div>
                      </div>
                      <div className={"off-item"}>
                        <div className={"item"}>
                          <div className={"form-message"}>&nbsp;</div>
                          <div className={"sub-title"}>&nbsp;</div>
                          <span className={"title"}>Apellidos</span>
                          <span className={"data-focus"}>
                            {this.props.employee.lastname}
                          </span>
                          <span className={"title"}>Nombre de la empresa</span>
                          <span className={"data-focus"}>
                            {this.props.company.name}
                          </span>
                          <span className={"title"}>Fecha de ingreso</span>
                          <span className={"data-focus"}>
                            {moment.unix(this.props.employee.admissionat).format("DD/MM/YYYY")}
                          </span>
                          <div className={"sub-title"}>&nbsp;</div>
                        </div>
                      </div>
                      <div className={"off-item"}>
                        <div className={"item"}>
                          <div className={"form-message"}>&nbsp;</div>
                          <div className={"sub-title"}>&nbsp;</div>
                          <span className={"title"}>Teléfono registrado</span>
                          <span className={"data-focus"}>
                            {this.props.employee.phone}
                          </span>
                          <span className={"title"}>
                            Localidad donde labora
                          </span>
                          <span className={"data-focus"}>
                            {this.props.employee.worklocation}
                          </span>
                        </div>
                      </div>
                      <div className={"off-item"}>
                        <div className={"item"}>
                          <div className={"form-message"}>&nbsp;</div>
                          <div className={"sub-title"}>&nbsp;</div>
                          <span className={"title"}>Correo electrónico</span>
                          <span className={"data-focus"}>
                            {this.props.employee.email}
                          </span>
                          <span className={"title"}>&nbsp;</span>
                          <span className={"data-focus"}>&nbsp;</span>
                          <span className={"title"}>&nbsp;</span>
                          <span className={"data-focus"}>&nbsp;</span>
                          <div className={"sub-title"}>&nbsp;</div>
                        </div>
                      </div>
                      <div className={"border-option"}></div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
    savings: state.savings,
    employee: state.employee.current,
    employeeDetails: state.employee.numberDetails,
    company: state.company.current,
    banxico: state.savings.banxico,
    savingSlots: state.savings.savingsSlots,
    loansByEmployeeAndCompany: state.loans.loansByEmployeeAndCompany,
  };
}

export default connect(mapStateToProps)(EmployeeSavings);
