// ERROR
export const SET_ERROR = "SET_ERROR";
export const HIDE_ERROR = "HIDE_ERROR";

// SUCCESS
export const SET_SUCCESS = "SET_SUCCESS";
export const HIDE_SUCCESS = "HIDE_SUCCESS";

// AUTH
export const USER_LOGIN_BEGIN = "USER_LOGIN_BEGIN";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAILURE = "USER_LOGIN_FAILURE";

export const CHECK_FOR_SESSION_BEGIN = "CHECK_FOR_SESSION_BEGIN";
export const CHECK_FOR_SESSION_SUCCESS = "CHECK_FOR_SESSION_SUCCESS";
export const CHECK_FOR_SESSION_FAILURE = "CHECK_FOR_SESSION_FAILURE";

export const USER_LOGOUT_BEGIN = "USER_LOGOUT_BEGIN";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILURE = "USER_LOGOUT_FAILURE";

export const NOTIFICATION_ADD = "NOTIFICATION_ADD";
export const NOTIFICATION_CLEAR = "NOTIFICATION_CLEAR";

export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const RECOVERY_PASSWORD_LOADING = "RECOVERY_PASSWORD_LOADING";
export const RECOVERY_PASSWORD_SUCCESS = "RECOVERY_PASSWORD_SUCCESS";
export const RECOVERY_PASSWORD_FAILURE = "RECOVERY_PASSWORD_FAILURE";

export const CONTACT_LOADING = "CONTACT_LOADING";
export const CONTACT_SUCCESS = "CONTACT_SUCCESS";
export const CONTACT_FAILURE = "CONTACT_FAILURE";

// Savings

export const SAVINGS_CREATING_BEGIN = "SAVINGS_CREATING_BEGIN";
export const SAVINGS_CREATING_SUCEESS = "SAVINGS_CREATING_SUCEESS";
export const SAVINGS_CREATING_FAILURE = "SAVINGS_CREATING_FAILURE";
export const CLEAR_SAVINGS_CREATING_FAILURE = "CLEAR_SAVINGS_CREATING_FAILURE";

export const GET_SAVING_TRANSACTIONS_BY_EMPLOYEE_ID_BEGIN =
  "GET_SAVING_TRANSACTIONS_BY_EMPLOYEE_ID_BEGIN";
export const GET_SAVING_TRANSACTIONS_BY_EMPLOYEE_ID_SUCCESS =
  "GET_SAVING_TRANSACTIONS_BY_EMPLOYEE_ID_SUCCESS";
export const GET_SAVING_TRANSACTIONS_BY_EMPLOYEE_ID_FAILURE =
  "GET_SAVING_TRANSACTIONS_BY_EMPLOYEE_ID_FAILURE";

export const SAVINGS_ADD_BENEFICIARY_BEGIN = "SAVINGS_ADD_BENEFICIARY_BEGIN";
export const SAVINGS_ADD_BENEFICIARY_SUCEESS =
  "SAVINGS_ADD_BENEFICIARY_SUCEESS";
export const SAVINGS_ADD_BENEFICIARY_FAILURE =
  "SAVINGS_ADD_BENEFICIARY_FAILURE";

export const SAVINGS_REMOVE_BENEFICIARY_BEGIN =
  "SAVINGS_REMOVE_BENEFICIARY_BEGIN";
export const SAVINGS_REMOVE_BENEFICIARY_SUCEESS =
  "SAVINGS_REMOVE_BENEFICIARY_SUCEESS";
export const SAVINGS_REMOVE_BENEFICIARY_FAILURE =
  "SAVINGS_REMOVE_BENEFICIARY_FAILURE";

export const CREATE_SAVING_BANK_BEGIN = "CREATE_SAVING_BANK_BEGIN";
export const CREATE_SAVING_BANK_SUCCESS = "CREATE_SAVING_BANK_SUCCESS";
export const CREATE_SAVING_BANK_FAILURE = "CREATE_SAVING_BANK_FAILURE";

export const GET_SAVING_BANKS_BY_EMPLOYEE_ID_BEGIN =
  "GET_SAVING_BANKS_BY_EMPLOYEE_ID_BEGIN";
export const GET_SAVING_BANKS_BY_EMPLOYEE_ID_SUCCESS =
  "GET_SAVING_BANKS_BY_EMPLOYEE_ID_SUCCESS";
export const GET_SAVING_BANKS_BY_EMPLOYEE_ID_FAILURE =
  "GET_SAVING_BANKS_BY_EMPLOYEE_ID_FAILURE";

export const GET_SAVING_BANKS_BY_BOSS_ID_BEGIN =
  "GET_SAVING_BANKS_BY_BOSS_ID_BEGIN";
export const GET_SAVING_BANKS_BY_BOSS_ID_SUCCESS =
  "GET_SAVING_BANKS_BY_BOSS_ID_SUCCESS";
export const GET_SAVING_BANKS_BY_BOSS_ID_FAILURE =
  "GET_SAVING_BANKS_BY_BOSS_ID_FAILURE";

export const TESTING_SAVINGS_RESET_BEGIN = "TESTING_SAVINGS_RESET_BEGIN";
export const TESTING_SAVINGS_RESET_SUCCESS = "TESTING_SAVINGS_RESET_SUCCESS";
export const TESTING_SAVINGS_RESET_FAILURE = "TESTING_SAVINGS_RESET_FAILURE";

export const GET_SAVINGS_BANKS_RESUME_BY_BOSS_ID_BEGIN =
  "GET_SAVINGS_BANKS_RESUME_BY_BOSS_ID_BEGIN";
export const GET_SAVINGS_BANKS_RESUME_BY_BOSS_ID_SUCCESS =
  "GET_SAVINGS_BANKS_RESUME_BY_BOSS_ID_SUCCESS";
export const GET_SAVINGS_BANKS_RESUME_BY_BOSS_ID_FAILURE =
  "GET_SAVINGS_BANKS_RESUME_BY_BOSS_ID_FAILURE";

export const GET_BOSS_COMPANY_SUMMARY_SUCCESS =
  "GET_BOSS_COMPANY_SUMMARY_SUCCESS";
export const GET_BOSS_COMPANY_SUMMARY_FAILURE =
  "GET_BOSS_COMPANY_SUMMARY_FAILURE";

export const GET_EMPLOYEE_SAVING_DETAIL_RESPONSE_SUCCESS =
  "GET_EMPLOYEE_SAVING_DETAIL_RESPONSE_SUCCESS";
export const GET_EMPLOYEE_SAVING_DETAIL_RESPONSE_FAILURE =
  "GET_EMPLOYEE_SAVING_DETAIL_RESPONSE_FAILURE";

export const GET_BANXICO_RATES_BEGIN = "GET_BANXICO_RATES_BEGIN";
export const GET_BANXICO_RATES_SUCCESS = "GET_BANXICO_RATES_SUCCESS";
export const GET_BANXICO_RATES_FAILURE = "GET_BANXICO_RATES_FAILURE";

export const CREATE_SAVINGS_TRANSACTIONS_BEGIN =
  "CREATE_SAVINGS_TRANSACTIONS_BEGIN";
export const CREATE_SAVINGS_TRANSACTIONS_SUCCESS =
  "CREATE_SAVINGS_TRANSACTIONS_SUCCESS";
export const CREATE_SAVINGS_TRANSACTIONS_FAILURE =
  "CREATE_SAVINGS_TRANSACTIONS_FAILURE";

export const UPDATE_SAVINGS_TRANSACTIONS_BEGIN =
  "UPDATE_SAVINGS_TRANSACTIONS_BEGIN";
export const UPDATE_SAVINGS_TRANSACTIONS_SUCCESS =
  "UPDATE_SAVINGS_TRANSACTIONS_SUCCESS";
export const UPDATE_SAVINGS_TRANSACTIONS_FAILURE =
  "UPDATE_SAVINGS_TRANSACTIONS_FAILURE";

export const GET_TOP_SAVINGS_ACCOUNT_BEGIN = "GET_TOP_SAVINGS_ACCOUNT_BEGIN";
export const GET_TOP_SAVINGS_ACCOUNT_SUCCESS =
  "GET_TOP_SAVINGS_ACCOUNT_SUCCESS";
export const GET_TOP_SAVINGS_ACCOUNT_FAILURE =
  "GET_TOP_SAVINGS_ACCOUNT_FAILURE";

export const GET_TOTAL_SAVINGS_EMPLOYEE_BEGIN =
  "GET_TOTAL_SAVINGS_EMPLOYEE_BEGIN";
export const GET_TOTAL_SAVINGS_EMPLOYEE_SUCCESS =
  "GET_TOTAL_SAVINGS_EMPLOYEE_SUCCESS";
export const GET_TOTAL_SAVINGS_EMPLOYEE_FAILURE =
  "GET_TOTAL_SAVINGS_EMPLOYEE_FAILURE";

export const GET_SAVINGS_SLOTS_BEGIN = "GET_SAVINGS_SLOTS_BEGIN";
export const GET_SAVINGS_SLOTS_SUCCESS = "GET_SAVINGS_SLOTS_SUCCESS";
export const GET_SAVINGS_SLOTS_FAILURE = "GET_SAVINGS_SLOTS_FAILURE";

export const GET_SAVINGS_TABLE_PER_EMPLOYEE_BEGIN =
  "GET_SAVINGS_TABLE_PER_EMPLOYEE_BEGIN";
export const GET_SAVINGS_TABLE_PER_EMPLOYEE_SUCCESS =
  "GET_SAVINGS_TABLE_PER_EMPLOYEE_SUCCESS";
export const GET_SAVINGS_TABLE_PER_EMPLOYEE_FAILURE =
  "GET_SAVINGS_TABLE_PER_EMPLOYEE_FAILURE";

export const GET_PAYROLL_STATUS_INFO_BEGIN = "GET_PAYROLL_STATUS_INFO_BEGIN";
export const GET_PAYROLL_STATUS_INFO_SUCCESSFUL =
  "GET_PAYROLL_STATUS_INFO_SUCCESSFUL";
export const GET_PAYROLL_STATUS_INFO_FAILURE =
  "GET_PAYROLL_STATUS_INFO_FAILURE";

// Loans

export const GET_LOANS_BY_EMPLOYEE_ID_BEGIN = "GET_LOANS_BY_EMPLOYEE_ID_BEGIN";
export const GET_LOANS_BY_EMPLOYEE_ID_SUCCESS =
  "GET_LOANS_BY_EMPLOYEE_ID_SUCCESS";
export const GET_LOANS_BY_EMPLOYEE_ID_FAILURE =
  "GET_LOANS_BY_EMPLOYEE_ID_FAILURE";

export const GET_LOANS_BY_EMPLOYEE_ID_AND_COMPANY_ID_BEGIN =
  "GET_LOANS_BY_EMPLOYEE_ID_AND_COMPANY_ID_BEGIN";
export const GET_LOANS_BY_EMPLOYEE_ID_AND_COMPANY_ID_SUCCESS =
  "GET_LOANS_BY_EMPLOYEE_ID_AND_COMPANY_ID_SUCCESS";
export const GET_LOANS_BY_EMPLOYEE_ID_AND_COMPANY_ID_FAILURE =
  "GET_LOANS_BY_EMPLOYEE_ID_AND_COMPANY_ID_FAILURE";

export const GET_LOAN_TRANSACTIONS_BY_EMPLOYEE_ID_BEGIN =
  "GET_LOAN_TRANSACTIONS_BY_EMPLOYEE_ID_BEGIN";
export const GET_LOAN_TRANSACTIONS_BY_EMPLOYEE_ID_SUCCESS =
  "GET_LOAN_TRANSACTIONS_BY_EMPLOYEE_ID_SUCCESS";
export const GET_LOAN_TRANSACTIONS_BY_EMPLOYEE_ID_FAILURE =
  "GET_LOAN_TRANSACTIONS_BY_EMPLOYEE_ID_FAILURE";

export const GET_LOAN_TRANSACTIONS_BY_COMPANY_ID_BEGIN =
  "GET_LOAN_TRANSACTIONS_BY_COMPANY_ID_BEGIN";
export const GET_LOAN_TRANSACTIONS_BY_COMPANY_ID_SUCCESS =
  "GET_LOAN_TRANSACTIONS_BY_COMPANY_ID_SUCCESS";
export const GET_LOAN_TRANSACTIONS_BY_COMPANY_ID_FAILURE =
  "GET_LOAN_TRANSACTIONS_BY_COMPANY_ID_FAILURE";

export const CREATE_LOANS_BEGIN = "CREATE_LOANS_BEGIN";
export const CREATE_LOANS_SUCCESS = "CREATE_LOANS_SUCCESS";
export const CREATE_LOANS_FAILURE = "CREATE_LOANS_FAILURE";

export const CLEAR_CREATED_LOAN = "CLEAR_CREATED_LOAN";

export const GET_LOANS_BY_BOSS_AND_STATUS_ID_BEGIN =
  "GET_LOANS_BY_BOSS_AND_STATUS_ID_BEGIN";
export const GET_LOANS_BY_BOSS_AND_STATUS_ID_SUCCESS =
  "GET_LOANS_BY_BOSS_AND_STATUS_ID_SUCCESS";
export const GET_LOANS_BY_BOSS_AND_STATUS_ID_FAILURE =
  "GET_LOANS_BY_BOSS_AND_STATUS_ID_FAILURE";

export const GET_LOANS_BY_BOSS_ID_BEGIN = "GET_LOANS_BY_BOSS_ID_BEGIN";
export const GET_LOANS_BY_BOSS_ID_SUCCESS = "GET_LOANS_BY_BOSS_ID_SUCCESS";
export const GET_LOANS_BY_BOSS_ID_FAILURE = "GET_LOANS_BY_BOSS_ID_FAILURE";

export const GET_LOANS_RESUME_BY_BOSS_ID_BEGIN =
  "GET_LOANS_RESUME_BY_BOSS_ID_BEGIN";
export const GET_LOANS_RESUME_BY_BOSS_ID_SUCCESS =
  "GET_LOANS_RESUME_BY_BOSS_ID_SUCCESS";
export const GET_LOANS_RESUME_BY_BOSS_ID_FAILURE =
  "GET_LOANS_RESUME_BY_BOSS_ID_FAILURE";

export const UPDATE_LOAN_STATUS_ID_BEGIN = "UPDATE_LOAN_STATUS_ID_BEGIN";
export const UPDATE_LOAN_STATUS_ID_SUCCESS = "UPDATE_LOAN_STATUS_ID_SUCCESS";
export const UPDATE_LOAN_STATUS_ID_FAILURE = "UPDATE_LOAN_STATUS_ID_FAILURE";

export const CALCULATE_AMORTIZATION_TABLE_BEGIN =
  "CALCULATE_AMORTIZATION_TABLE_BEGIN";
export const CALCULATE_AMORTIZATION_TABLE_SUCCESS =
  "CALCULATE_AMORTIZATION_TABLE_SUCCESS";
export const CALCULATE_AMORTIZATION_TABLE_FAILURE =
  "CALCULATE_AMORTIZATION_TABLE_FAILURE";
export const GET_LOAN_GENERAL_DETAILS_BEGIN = "GET_LOAN_GENERAL_DETAILS_BEGIN";
export const GET_LOAN_GENERAL_DETAILS_SUCCESSFUL =
  "GET_LOAN_GENERAL_DETAILS_SUCCESSFUL";
export const GET_LOAN_GENERAL_DETAILS_FAILURE =
  "GET_LOAN_GENERAL_DETAILS_FAILURE";

export const GET_LOANS_GENERAL_ADMIN_RESPONSE_BEGIN =
  "GET_LOANS_GENERAL_ADMIN_RESPONSE_BEGIN";
export const GET_LOANS_GENERAL_ADMIN_RESPONSE_SUCCESS =
  "GET_LOANS_GENERAL_ADMIN_RESPONSE_SUCCESS";
export const GET_LOANS_GENERAL_ADMIN_RESPONSE_FAILURE =
  "GET_LOANS_GENERAL_ADMIN_RESPONSE_FAILURE";

export const UPLOAD_USERS_SUCCESS = "UPLOAD_USERS_SUCCESS";
export const UPLOAD_USERS_FAILURE = "UPLOAD_USERS_FAILURE";

// Payroll Advance

export const GET_PAYROLL_ADVANCE_BY_EMPLOYEE_ID_BEGIN =
  "GET_PAYROLL_ADVANCE_BY_EMPLOYEE_ID_BEGIN";
export const GET_PAYROLL_ADVANCE_BY_EMPLOYEE_ID_SUCCESS =
  "GET_PAYROLL_ADVANCE_BY_EMPLOYEE_ID_SUCCESS";
export const GET_PAYROLL_ADVANCE_BY_EMPLOYEE_ID_FAILURE =
  "GET_PAYROLL_ADVANCE_BY_EMPLOYEE_ID_FAILURE";

export const CREATE_PAYROLL_ADVANCE_BEGIN = "CREATE_PAYROLL_ADVANCE_BEGIN";
export const CREATE_PAYROLL_ADVANCE_SUCCESS = "CREATE_PAYROLL_ADVANCE_SUCCESS";
export const CREATE_PAYROLL_ADVANCE_FAILURE = "CREATE_PAYROLL_ADVANCE_FAILURE";

export const CLEAR_CREATED_PAYROLL_ADVANCE = "CLEAR_CREATED_PAYROLL_ADVANCE";

export const REQUEST_PAYROLL_ADVANCE_BEGIN = "REQUEST_PAYROLL_ADVANCE_BEGIN";
export const REQUEST_PAYROLL_ADVANCE_SUCCESS =
  "REQUEST_PAYROLL_ADVANCE_SUCCESS";
export const REQUEST_PAYROLL_ADVANCE_FAILURE =
  "REQUEST_PAYROLL_ADVANCE_FAILURE";

export const GET_PAYROLL_ADVANCE_BY_BOSS_AND_STATUS_ID_BEGIN =
  "GET_PAYROLL_ADVANCE_BY_BOSS_AND_STATUS_ID_BEGIN";
export const GET_PAYROLL_ADVANCE_BY_BOSS_AND_STATUS_ID_SUCCESS =
  "GET_PAYROLL_ADVANCE_BY_BOSS_AND_STATUS_ID_SUCCESS";
export const GET_PAYROLL_ADVANCE_BY_BOSS_AND_STATUS_ID_FAILURE =
  "GET_PAYROLL_ADVANCE_BY_BOSS_AND_STATUS_ID_FAILURE";

export const GET_PAYROLL_ADVANCE_BY_BOSS_ID_BEGIN =
  "GET_PAYROLL_ADVANCE_BY_BOSS_ID_BEGIN";
export const GET_PAYROLL_ADVANCE_BY_BOSS_ID_SUCCESS =
  "GET_PAYROLL_ADVANCE_BY_BOSS_ID_SUCCESS";
export const GET_PAYROLL_ADVANCE_BY_BOSS_ID_FAILURE =
  "GET_PAYROLL_ADVANCE_BY_BOSS_ID_FAILURE";

export const GET_PAYROLL_ADVANCE_RESUME_BY_BOSS_ID_BEGIN =
  "GET_PAYROLL_ADVANCE_RESUME_BY_BOSS_ID_BEGIN";
export const GET_PAYROLL_ADVANCE_RESUME_BY_BOSS_ID_SUCCESS =
  "GET_PAYROLL_ADVANCE_RESUME_BY_BOSS_ID_SUCCESS";
export const GET_PAYROLL_ADVANCE_RESUME_BY_BOSS_ID_FAILURE =
  "GET_PAYROLL_ADVANCE_RESUME_BY_BOSS_ID_FAILURE";

export const UPDATE_PAYROLL_ADVANCE_STATUS_ID_BEGIN =
  "UPDATE_PAYROLL_ADVANCE_STATUS_ID_BEGIN";
export const UPDATE_PAYROLL_ADVANCE_STATUS_ID_SUCCESS =
  "UPDATE_PAYROLL_ADVANCE_STATUS_ID_SUCCESS";
export const UPDATE_PAYROLL_ADVANCE_STATUS_ID_FAILURE =
  "UPDATE_PAYROLL_ADVANCE_STATUS_ID_FAILURE";

export const GET_PAYROLL_ADVANCE_BY_COMPANY_ID_BEGIN =
  "GET_PAYROLL_ADVANCE_BY_COMPANY_ID_BEGIN";
export const GET_PAYROLL_ADVANCE_BY_COMPANY_ID_SUCCESS =
  "GET_PAYROLL_ADVANCE_BY_COMPANY_ID_SUCCESS";
export const GET_PAYROLL_ADVANCE_BY_COMPANY_ID_FAILURE =
  "GET_PAYROLL_ADVANCE_BY_COMPANY_ID_FAILURE";

export const GET_CASH_ADVANCE_DASHBOARD_SUCCESS =
  "GET_CASH_ADVANCE_DASHBOARD_SUCCESS";
export const GET_CASH_ADVANCE_DASHBOARD_FAILURE =
  "GET_CASH_ADVANCE_DASHBOARD_FAILURE";

export const GET_PENDING_CASH_ADVANCE_FOR_ADMIN_SUCCESS =
  "GET_PENDING_CASH_ADVANCE_FOR_ADMIN_SUCCESS";
export const GET_PENDING_CASH_ADVANCE_FOR_ADMIN_FAILURE =
  "GET_PENDING_CASH_ADVANCE_FOR_ADMIN_FAILURE";

export const GET_PENDING_CASH_ADVANCE_FOR_BOSS_SUCCESS =
  "GET_PENDING_CASH_ADVANCE_FOR_BOSS_SUCCESS";
export const GET_PENDING_CASH_ADVANCE_FOR_BOSS_FAILURE =
  "GET_PENDING_CASH_ADVANCE_FOR_BOSS_FAILURE";

export const GET_CASH_ADVANCE_EMPLOYEE_HOME_INFO_SUCCESS =
  "GET_CASH_ADVANCE_EMPLOYEE_HOME_INFO_SUCCESS";
export const GET_CASH_ADVANCE_EMPLOYEE_HOME_INFO_FAILURE =
  "GET_CASH_ADVANCE_EMPLOYEE_HOME_INFO_FAILURE";

// Employee
export const GET_EMPLOYEE_BY_USER_ID_BEGIN = "GET_EMPLOYEE_BY_USER_ID_BEGIN";
export const GET_EMPLOYEE_BY_USER_ID_SUCCESS =
  "GET_EMPLOYEE_BY_USER_ID_SUCCESS";
export const GET_EMPLOYEE_BY_USER_ID_FAILURE =
  "GET_EMPLOYEE_BY_USER_ID_FAILURE";

export const GET_EMPLOYEES_BY_BOSS_ID_BEGIN = "GET_EMPLOYEES_BY_BOSS_ID_BEGIN";
export const GET_EMPLOYEES_BY_BOSS_ID_SUCCESS =
  "GET_EMPLOYEES_BY_BOSS_ID_SUCCESS";
export const GET_EMPLOYEES_BY_BOSS_ID_FAILURE =
  "GET_EMPLOYEES_BY_BOSS_ID_FAILURE";

export const CREATE_EMPLOYEE_BEGIN = "CREATE_EMPLOYEE_BEGIN";
export const CREATE_EMPLOYEE_SUCCESS = "CREATE_EMPLOYEE_SUCCESS";
export const CREATE_EMPLOYEE_FAILURE = "CREATE_EMPLOYEE_FAILURE";

export const GET_EMPLOYEES_BY_COMPANY_ID_BEGIN =
  "GET_EMPLOYEES_BY_COMPANY_ID_BEGIN";
export const GET_EMPLOYEES_BY_COMPANY_ID_SUCCESS =
  "GET_EMPLOYEES_BY_COMPANY_ID_SUCCESS";
export const GET_EMPLOYEES_BY_COMPANY_ID_FAILURE =
  "GET_EMPLOYEES_BY_COMPANY_ID_FAILURE";

export const ADD_EMPLOYEE_BEGIN = "ADD_EMPLOYEE_BEGIN";
export const ADD_EMPLOYEE_SUCCESS = "ADD_EMPLOYEE_SUCCESS";
export const ADD_EMPLOYEE_FAILURE = "ADD_EMPLOYEE_FAILURE";

export const GET_EMPLOYEE_SUMMARY_BEGIN = "GET_EMPLOYEE_SUMMARY_BEGIN";
export const GET_EMPLOYEE_SUMMARY_SUCCESS = "GET_EMPLOYEE_SUMMARY_SUCCESS";
export const GET_EMPLOYEE_SUMMARY_FAILURE = "GET_EMPLOYEE_SUMMARY_FAILURE";

export const GET_EMPLOYEE_NUMBER_DETAILS_BEGIN =
  "GET_EMPLOYEE_NUMBER_DETAILS_BEGIN";
export const GET_EMPLOYEE_NUMBER_DETAILS_SUCCESS =
  "GET_EMPLOYEE_NUMBER_DETAILS_SUCCESS";
export const GET_EMPLOYEE_NUMBER_DETAILS_FAILURE =
  "GET_EMPLOYEE_NUMBER_DETAILS_FAILURE";

// Boss

export const GET_BOSS_BY_USER_ID_BEGIN = "GET_BOSS_BY_USER_ID_BEGIN";
export const GET_BOSS_BY_USER_ID_SUCCESS = "GET_BOSS_BY_USER_ID_SUCCESS";
export const GET_BOSS_BY_USER_ID_FAILURE = "GET_BOSS_BY_USER_ID_FAILURE";

export const GET_BOSS_HOME_INFO_BEGIN = "GET_BOSS_HOME_INFO_BEGIN";
export const GET_BOSS_HOME_INFO_SUCCESS = "GET_BOSS_HOME_INFO_SUCCESS";
export const GET_BOSS_HOME_INFO_FAILURE = "GET_BOSS_HOME_INFO_FAILURE";

export const GET_BOSS_EMPLOYEE_DETAIL_BEGIN = "GET_BOSS_EMPLOYEE_DETAIL_BEGIN";
export const GET_BOSS_EMPLOYEE_DETAIL_SUCCESS =
  "GET_BOSS_EMPLOYEE_DETAIL_SUCCESS";
export const GET_BOSS_EMPLOYEE_DETAIL_FAILURE =
  "GET_BOSS_EMPLOYEE_DETAIL_FAILURE";

export const GET_PENDING_LOANS_FOR_BOSS_BEGIN =
  "GET_PENDING_LOANS_FOR_BOSS_BEGIN";
export const GET_PENDING_LOANS_FOR_BOSS_SUCCESS =
  "GET_PENDING_LOANS_FOR_BOSS_SUCCESS";
export const GET_PENDING_LOANS_FOR_BOSS_FAILURE =
  "GET_PENDING_LOANS_FOR_BOSS_FAILURE";

export const GET_PENDING_LOANS_FOR_ADMIN_SUCCESS =
  "GET_PENDING_LOANS_FOR_ADMIN_SUCCESS";
export const GET_PENDING_LOANS_FOR_ADMIN_FAILURE =
  "GET_PENDING_LOANS_FOR_ADMIN_FAILURE";

export const GET_EMPLOYEE_CASH_ADVANCE_DETAIL_SUCCESS =
  "GET_EMPLOYEE_CASH_ADVANCE_DETAIL_SUCCESS";
export const GET_EMPLOYEE_CASH_ADVANCE_DETAIL_FAILURE =
  "GET_EMPLOYEE_CASH_ADVANCE_DETAIL_FAILURE";

export const GET_EMPLOYEE_LOAN_DETAIL_SUCCESS =
  "GET_EMPLOYEE_LOAN_DETAIL_SUCCESS";
export const GET_EMPLOYEE_LOAN_DETAIL_FAILURE =
  "GET_EMPLOYEE_LOAN_DETAIL_FAILURE";

// Admin

export const GET_ADMIN_BY_USER_ID_BEGIN = "GET_ADMIN_BY_USER_ID_BEGIN";
export const GET_ADMIN_BY_USER_ID_SUCCESS = "GET_ADMIN_BY_USER_ID_SUCCESS";
export const GET_ADMIN_BY_USER_ID_FAILURE = "GET_ADMIN_BY_USER_ID_FAILURE";

export const GET_ADMIN_HOME_INFO_SUCCESS = "GET_ADMIN_HOME_INFO_SUCCESS";
export const GET_ADMIN_HOME_INFO_FAILURE = "GET_ADMIN_HOME_INFO_FAILURE";

export const GET_COMPANY_DETAIL_SUCCESS = "GET_COMPANY_DETAIL_SUCCESS";
export const GET_COMPANY_DETAIL_FAILURE = "GET_COMPANY_DETAIL_FAILURE";

export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS";
export const GET_EMPLOYEES_FAILURE = "GET_EMPLOYEES_FAILURE";

export const GET_SAVING_SUMMARY_SUCCESS = "GET_SAVING_SUMMARY_SUCCESS";
export const GET_SAVING_SUMMARY_FAILURE = "GET_SAVING_SUMMARY_FAILURE";

export const GET_SAVING_SUMMARY_BY_ID_SUCCESS =
  "GET_SAVING_SUMMARY_BY_ID_SUCCESS";
export const GET_SAVING_SUMMARY_BY_ID_FAILURE =
  "GET_SAVING_SUMMARY_BY_ID_FAILURE";

// Company

export const CREATE_COMPANY_BEGIN = "CREATE_COMPANY_BEGIN";
export const CREATE_COMPANY_SUCCESS = "CREATE_COMPANY_SUCCESS";
export const CREATE_COMPANY_FAILURE = "CREATE_COMPANY_FAILURE";

export const UPDATE_COMPANY_BEGIN = "UPDATE_COMPANY_BEGIN";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";

export const GET_COMPANY_BY_ID_BEGIN = "GET_COMPANY_BY_ID_BEGIN";
export const GET_COMPANY_BY_ID_SUCCESS = "GET_COMPANY_BY_ID_SUCCESS";
export const GET_COMPANY_BY_ID_FAILURE = "GET_COMPANY_BY_ID_FAILURE";

export const GET_COMPANIES_BEGIN = "GET_COMPANIES_BEGIN";
export const GET_COMPANIES_SUCCESS = "GET_COMPANIES_SUCCESS";
export const GET_COMPANIES_FAILURE = "GET_COMPANIES_FAILURE";

export const GET_COMPANY_SUMMARY_BEGIN = "GET_COMPANY_SUMMARY_BEGIN";
export const GET_COMPANY_SUMMARY_SUCCESS = "GET_COMPANY_SUMMARY_SUCCESS";
export const GET_COMPANY_SUMMARY_FAILURE = "GET_COMPANY_SUMMARY_FAILURE";

export const GET_COMPANIES_SUMMARY_BEGIN = "GET_COMPANIES_SUMMARY_BEGIN";
export const GET_COMPANIES_SUMMARY_SUCCESS = "GET_COMPANIES_SUMMARY_SUCCESS";
export const GET_COMPANIES_SUMMARY_FAILURE = "GET_COMPANIES_SUMMARY_FAILURE";

// global actions
export const SET_ALERT_OPEN = "SET_ALERT_OPEN";
export const SET_ALERT_TYPE = "SET_ALERT_TYPE";
export const SET_ALERT_TITLE = "SET_ALERT_TITLE";
