import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "@mui/material/Input";

import { useForm } from "../../../hooks/useForm";
import { getFormattedNumber } from "../../../utils/numberFormat";
import {
  requestPayrollAdvance,
  clearPayrollAdvanceInfo,
} from "../../../actions/payrollAdvanceAction";

import { setSuccess } from "../../../actions/successAction";

export const EmployeePayRollForm = ({
  companyId,
  employeeId,
  toggleModal,
  maxAmount = 0,
}) => {
  const dispatch = useDispatch();
  const { requestPayrollAdvanceInfo } = useSelector(
    (state) => state.advances || {}
  );
  const initialForm = {
    netPayrollForm: "",
  };

  const [formValues, handleInputChange] = useForm(initialForm);
  const { netPayrollForm } = formValues;
  const [messageError, setMessageError] = useState("");

  const handleApplyForPayroll = () => {
    toggleModal();
    dispatch(
      requestPayrollAdvance(companyId, employeeId, parseInt(netPayrollForm))
    );
  };

  useEffect(() => {
    if (netPayrollForm > maxAmount) {
      setMessageError("No debe exceder el monto máximo asignado.");
    } else {
      setMessageError("");
    }

    if (requestPayrollAdvanceInfo) {
      if (requestPayrollAdvanceInfo.code === 201) {
        dispatch(setSuccess(requestPayrollAdvanceInfo.message));
        dispatch(clearPayrollAdvanceInfo());
      }
    }
  }, [netPayrollForm, requestPayrollAdvanceInfo]);

  return (
    <div className="payroll-form-container">
      <div className="payroll-form__header">
        <div className="payroll-form__left">
          <p>¿Qué monto desea solicitar?</p>
          <Input
            name="netPayrollForm"
            margin="dense"
            placeholder={`$5,000`}
            onChange={handleInputChange}
            value={netPayrollForm}
          />
          <span className="error_notification">{messageError}</span>
        </div>
        <div className="payroll-form__right">
          <div
            className={`${
              netPayrollForm > maxAmount
                ? "payroll-form__circle-warning"
                : "payroll-form__circle"
            } `}
          >
            <h2>$ {getFormattedNumber(maxAmount - netPayrollForm)}</h2>
            <p className="">Monto máximo</p>
          </div>
        </div>
      </div>
      <div className={"box-button"}>
        <button
          className={"green-button green-text"}
          disabled={
            netPayrollForm === "" || netPayrollForm > maxAmount ? true : false
          }
          onClick={handleApplyForPayroll}
        >
          Pedir adelanto
        </button>
      </div>
    </div>
  );
};
