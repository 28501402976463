import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { contact } from "../../actions/userActions";
import { useForm } from "../../hooks/useForm";

import contactIcon from "../../assets/images/landingPage/contact_icon.svg";
import imgSalvumppl from "../../assets/images/landingPage/img_salvumppl.svg";

import "./ContactForm.scss";

export const ContactForm = () => {
  const dispatch = useDispatch();

  const initialForm = { name: "", email: "", message: "" };
  const [formValues, handleInputChange, reset] = useForm(initialForm);
  const [emailValue, setEmailValue] = useState("");
  const { name, message } = formValues;

  const [nameError] = useState("");
  const [emailError] = useState("");
  const [messageError] = useState("");

  const handleEmailChange = (event) => {
    setEmailValue(event.currentTarget.value);
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    dispatch(contact(name, emailValue, message));
    reset();
    document.getElementById("name").value = "";
    setEmailValue("");
    setTimeout(() => {
      document.getElementById("message").value = "";
    });
  };

  return (
    <div className="contact-form">
      <div className="contact-form__container">
        <div className="contact-form__container-icon">
          <img src={contactIcon} alt="icono contacto" />
        </div>
        <h1>Contáctanos</h1>
        <h2>
          ¿Quiéres saber más sobre <strong>Salvum</strong> y todos sus
          beneficios? Envíanos un correo
        </h2>

        <form method="POST" onSubmit={onSubmitForm}>
          <div className="form_row">
            <div className="form__group">
              <span className="form__label">Nombre</span>
              <input
                className="form_input"
                id="name"
                name="name"
                type="text"
                placeholder="Ingresa aquí tu nombre"
                autoComplete="off"
                onChange={handleInputChange}
              />
              <span className="error_notification">{nameError}</span>
            </div>
            <div className="form__group">
              <span className="form__label">Correo electrónico</span>
              <input
                className="form_input"
                id="email"
                name="email"
                value={emailValue}
                type="email"
                placeholder="ejemplo@gmail.com"
                onChange={handleEmailChange}
              />
              <span className="error_notification">{emailError}</span>
            </div>
          </div>
          <div className="form__group">
            <span className="form__label">Asunto</span>
            <input
              className="form_input input-large"
              id="message"
              name="message"
              type="text"
              placeholder="Escribe aquí tu mensaje"
              autoComplete="off"
              onChange={handleInputChange}
            />
            <span className="error_notification">{messageError}</span>
          </div>
          <button className="primary-btn">Enviar</button>
        </form>
      </div>

      <div className="imagen">
        <img src={imgSalvumppl} alt="imagen salvum" />
      </div>
    </div>
  );
};
