import React, { Component } from "react";
import { connect } from "react-redux";
import { NavLink, Redirect, withRouter } from "react-router-dom";

import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import logo from "../../../../assets/images/general/logoSalvumWhite.svg";

import { routes } from "../../../../Routers";

import PropTypes from "prop-types";

import "./EmployeeAppBar.scss";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { userLogout } from "../../../../actions/userActions";

export class EmployeeAppBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      redirect: null,
    };

    this.handleMenu = this.handleMenu.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.redirectStart = this.redirectStart.bind(this);
    this.redirectToProfile = this.redirectToProfile.bind(this);
  }

  handleMenu(event) {
    this.setState({ anchorEl: event.currentTarget });
  }

  handleClose() {
    this.setState({ anchorEl: null });
  }

  redirectStart() {
    this.props.dispatch(userLogout());
    localStorage.removeItem("userSession");
    this.props.history.push("/");
  }

  redirectToProfile() {
    this.setState({ anchorEl: null, redirect: "/employee/perfil" }, () => {
      this.setState({ redirect: false });
    });
  }

  render() {
    const auth = true;

    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: this.state.redirect,
          }}
        />
      );
    }

    return (
      <>
        <AppBar position="fixed" className="appBar">
          <Toolbar>
            <IconButton
              onClick={this.redirectStart}
              edge="start"
              // className={clsx(classes.menuButton, open && classes.hide)}
            >
              <img alt="logo" src={logo} />
            </IconButton>

            <div className="linkContainer">
              {routes({ role: this.props.user.role }).map((r, index) => {
                return (
                  <NavLink
                    to={`/${r.role}/${r.path}`}
                    className="link"
                    activeClassName="linkActive"
                    key={index}
                    exact
                  >
                    {r.menuTitle || r.title}
                  </NavLink>
                );
              })}
            </div>

            {/* <div className={"search"}>
                            <div className={"searchIcon"}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder="Buscar"
                                classes={{
                                    root: "inputRoot",
                                    input: "inputInput",
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div> */}

            {auth && (
              <div>
                {/* <IconButton aria-label="show 10 new notifications" color="inherit">
                                    <Badge badgeContent={0} color="secondary">
                                        <NotificationsIcon />
                                    </Badge>
                                </IconButton> */}

                <IconButton
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={this.handleMenu}
                  color="inherit"
                >
                  <span className="profileName">
                    {this.props.employee.fullName}
                  </span>
                  <ArrowDropDownIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(this.state.anchorEl)}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={this.redirectToProfile}>
                    Ir a perfil
                  </MenuItem>
                  <MenuItem onClick={this.redirectStart}>
                    Terminar sesión
                  </MenuItem>
                </Menu>
              </div>
            )}
          </Toolbar>
        </AppBar>
      </>
    );
  }
}

EmployeeAppBar.propTypes = {
  history: PropTypes.any,
  dispatch: PropTypes.any,
  user: PropTypes.any,
};

function mapStateToProps(state) {
  return {
    ...state,
    user: state.user,
    employee: state.user.user,
  };
}

export default withRouter(connect(mapStateToProps)(EmployeeAppBar));
