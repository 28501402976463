export const loanStatusChange = (loanStatus) => {
  switch (loanStatus) {
    case "Pendiente":
      return `toBeApproved`;

    case "Aprobado":
      return `approved`;

    case "Aprobado por salvum":
      return "approved";
    case "Aprobado por compañia":
      return "approved";
    case "Rechazado":
      return `rejected`;

    case "Liquidado":
      return `paid`;

    case "Autorizado":
      return "autorizado";

    case "Dispersado":
      return "dispersado";
    default:
      return "";
  }
};
